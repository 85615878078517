import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import classnames from "classnames";
import { Floaty } from "@spring/smeargle";
import {
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
} from "@spring/constants";
import { useInViewportOnce } from "hooks";
import { clickableDivProps } from "lib/accessibility_helpers";
import { TRACK_EVENT } from "utils/mixpanel";

import styles from "./styles.module.scss";
import { Box, Center, Text } from "design-system/components";

const Slot = (props) => {
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px"); // Trigger as soon as the element becomes visible
  const isRecommendationPage =
    window.location.pathname === "/members/recommendations";

  let [trackedOnce, setTrackedOnce] = useState(0);

  useEffect(() => {
    if (props.disableTracking) return;
    const location = props.isProviderASpecializedCareNavigator
      ? "Specialized Care Navigator Availability Modal"
      : isRecommendationPage
        ? "Recommended Provider Profile"
        : "Provider Profile";
    if (inViewport && props.isCNAvailabilityModal) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Available Appointment",
        {
          spring_doc_id: "directsched03",
          location: location,
          provider_id: getOr("", "providerId", props),
          appointment_type: getAppointmentKindForTracking(
            getOr("", "kind", props),
          ),
          appointment_medium: getAppointmentMediumForTracking(
            getOr("", "medium", props),
          ),
          appointment_time_utc: props.slot,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          offset: new Date().getTimezoneOffset(),
          ["Virtual tab"]:
            getAppointmentMediumForTracking(props?.medium) === "Video",
          ["In Person tab"]:
            getAppointmentMediumForTracking(props?.medium) === "In Person",
          assigned_provider: props?.isAssignedCN === true ? "true" : "false",
          query_request_id: props.queryRequestId,
          browse_page_number: props.pageNumber,
          provider_order: props.providerIndex + 1,
          order: props.slotIndex + 1,
        },
      );
      props?.onTrack();
    } else {
      if (inViewport && trackedOnce === 0) {
        TRACK_EVENT.COMPONENT_VIEWED(
          window.location.pathname,
          "Available Appointment",
          {
            spring_doc_id: "directsched03",
            location: location,
            provider_id: getOr("", "providerId", props),
            appointment_type: getAppointmentKindForTracking(
              getOr("", "kind", props),
            ),
            appointment_medium: getAppointmentMediumForTracking(
              getOr("", "medium", props),
            ),
            appointment_time_utc: props.slot,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            offset: new Date().getTimezoneOffset(),
            ["Virtual tab"]:
              getAppointmentMediumForTracking(props?.medium) === "Video",
            ["In Person tab"]:
              getAppointmentMediumForTracking(props?.medium) === "In Person",
            query_request_id: props.queryRequestId,
            browse_page_number: props.pageNumber,
            provider_order: props.providerIndex + 1,
            order: props.slotIndex + 1,
          },
        );
        props?.onTrack();
        setTrackedOnce(1);
      }
    }
  }, [inViewport]);

  return (
    <Floaty radius={3}>
      <Box
        px={props.isCNAvailabilityModal ? 5 : null}
        ref={ref}
        className={classnames(
          props.smallSlotStyle ? styles.smallSlot : styles.slot,
          {
            [styles.active]: props.active,
          },
        )}
        onClick={props.onClick}
        data-cy={props.dataCy ? props.dataCy + "-slot" : ""}
        aria-label={props.timeFormatted}
        {...clickableDivProps({ onClick: props.onClick })}
      >
        <Center>
          <Text variant="bodyBold_v1">{props.timeFormatted}</Text>
        </Center>
      </Box>
    </Floaty>
  );
};

Slot.propTypes = {
  active: PropTypes.bool,
  dataCy: PropTypes.string,
  onClick: PropTypes.func,
  providerIndex: PropTypes.number,
  slot: PropTypes.string,
  slotIndex: PropTypes.number,
  timeFormatted: PropTypes.string,
  isProviderASpecializedCareNavigator: PropTypes.bool,
};

export default Slot;
