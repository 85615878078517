import {
  AppointmentKind,
  AppointmentMedium,
  links,
  RequestableAppointmentKind,
} from "@spring/constants";
import { getOr } from "lodash/fp";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import { EmptyCareNavigatorIcon, Section, Tab, Tabs } from "@spring/smeargle";
import { useTranslation } from "react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { isMinor } from "utils/memberHelpers";
import { track, TRACK_EVENT } from "utils/mixpanel";
import { schedulingAccessName } from "constants/schedulingAccessName";
import { Text } from "@springcare/sh-component-library";
import {
  CareProviderScheduleModalV2,
  CoveredCareContent,
  EAPBenefit,
} from "components";

import {
  useAllUpcomingAppointmentSlots,
  useCareTeam,
  useMemberBenefits,
} from "hooks";
import { useScheduleModalWithProps } from "shared/hooks";
import { formatMemberExactAge } from "utils/displayHelpers";
import { useHelpInfo } from "hooks/useHelpInfo";
import { BenefitsModalT2 as WPOCoaching } from "modules/MemberDashboard/Home/components/BenefitsModalT2/components/BenefitsModalT2";

type MemberPortalReduxState = {
  global: {
    showGlobalExperience?: boolean;
    [key: string]: any;
  };
  [key: string]: any;
};

type CoveredCareTabType = {
  label: string;
  tracking: () => void;
  child: JSX.Element;
  dataCy?: string;
};

type CoveredCareTabsType = {
  modalControls: { isOpen: boolean; onClose: () => void; onOpen: () => void };
  memberInfoData: any; //TODO - generate gql types for this
  hasSpecializedCareNavigator?: boolean;
};

export const CoveredCareTabs = ({
  modalControls,
  memberInfoData,
  hasSpecializedCareNavigator,
}: CoveredCareTabsType) => {
  const { t } = useTranslation(["benefits", "limitedLangBenefits"]);
  const router = useRouter();
  const showEvidenceOfCoverage = useFeatureFlag(FLAGS.EVIDENCE_OF_COVERAGE);

  const { data: careTeamData } = useCareTeam();
  const showGlobalExperience = useSelector<MemberPortalReduxState>(
    (state) => state?.global?.showGlobalExperience,
  ) as boolean;

  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose,
    modalProps: scheduleModalProps,
  } = useScheduleModalWithProps();

  const {
    care_navigator: careNavigator,
    therapist,
    coach,
    medication_manager: medicationManager,
  } = careTeamData?.user.member.care_team || {};
  const { id: customerId, name: customerName } =
    memberInfoData?.user.member.cohort.customer || {};
  const member = memberInfoData?.user.member || null;
  const enableDirectSchedulingInCoaching =
    member?.cohort?.unlimited_coaching_direct_visits;
  const isAMinor = isMinor(member);
  const memberUnderSix = formatMemberExactAge(member?.date_of_birth) < 6;
  const country = memberInfoData?.user.member.postal_address.country;
  const isFamilyMember =
    member?.managed_dependents?.length > 0 || member?.manager !== null;

  const query = router.query;
  let tabId = query.tabId ? query.tabId : 0;
  const { data: upcomingTherapyAppointmentSlots } =
    useAllUpcomingAppointmentSlots(
      therapist,
      RequestableAppointmentKind.Therapy,
    );
  const { data: upcomingMedicationManagerAppointmentSlots } =
    useAllUpcomingAppointmentSlots(
      medicationManager,
      AppointmentKind.FollowUpMedicationManagement,
    );
  const cohort = memberInfoData?.user.member.cohort;
  const isInNetwork = cohort?.in_network;
  const internationalCohort = cohort?.international;
  const virtualSessionCostTerm =
    cohort?.contract_term?.virtual_session_cost_term;
  const {
    shouldShowCoaching,
    showCoachingPaidExperience,
    qualifiesForCoachingViaCN,
  } = useMemberBenefits(member?.id);
  const { helpInfo, loading: helpInfoLoading } = useHelpInfo();
  const { coaching_support = false } = cohort?.contract_term || {};
  const wpoCoachingEnabled =
    memberInfoData?.user?.member?.cohort?.contract_term?.wpo_coaching_supported;

  const coachingVisitsCovered = getOr(
    0,
    "user.member.visits_covered_coaching",
    memberInfoData,
  );
  const coachingVisitsUsed = getOr(
    0,
    "user.member.visits_used_coaching",
    memberInfoData,
  );
  const coachingVisitsRemaining = getOr(
    0,
    "user.member.visits_remaining_coaching",
    memberInfoData,
  );
  // Basic visits can only be used for Therapy
  const totalVisitsUsed = getOr(
    0,
    "user.member.visits_used_total",
    memberInfoData,
  );
  // Specialist visits may be used for Therapy OR Medication Management
  const specialistVisitsCovered = getOr(
    0,
    "user.member.visits_covered_specialist",
    memberInfoData,
  );
  const specialistVisitsUsed = getOr(
    0,
    "user.member.visits_used_specialist",
    memberInfoData,
  );
  const totalVisitsCovered = getOr(
    0,
    "user.member.visits_covered_total",
    memberInfoData,
  );
  const totalRemainingCovered = getOr(
    0,
    "user.member.visits_remaining_total",
    memberInfoData,
  );
  const specialistVisitsRemaining = getOr(
    0,
    "user.member.visits_remaining_specialist",
    memberInfoData,
  );

  const getCareNavigatorChecklist = (): string[] => {
    return isAMinor
      ? t("benefits:careNavigation.minorChecklistNew", {
          returnObjects: true,
        })
      : t("benefits:careNavigation.checklistNew", { returnObjects: true });
  };

  const trackTabClick = (label) =>
    TRACK_EVENT.CARD_CLICKED(window.location.pathname, "", label);

  const trackButtonClick = (location) =>
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Schedule therapy session",
      { location },
    );

  const eapTabData: CoveredCareTabType = {
    label: t("limitedLangBenefits:EAPBenefit.label"),
    child: (
      <EAPBenefit
        cohort={cohort}
        country={country}
        showGlobalExperience={showGlobalExperience}
      />
    ),
    tracking: () =>
      track("EAP - Work-Life Support Tab Clicked", { customer: customerName }),
  };

  const onlineAssessmentsTabData = {
    label: t("benefits:onlineAssessments.label"),
    tracking: () => trackTabClick(t("benefits:onlineAssessments.label")),
    child: (
      <CoveredCareContent
        modalControls={modalControls}
        title={t("benefits:onlineAssessments.title")}
        description={t("benefits:onlineAssessments.description")}
        checklist={t("benefits:onlineAssessments.checklist", {
          returnObjects: true,
        })}
        callToActionText={t("benefits:onlineAssessments.callToActionText")}
        callToActionRoute="MemberProgressDashboard"
        onScheduleModalOpen={onScheduleModalOpen}
      />
    ),
  };
  const validV0CoachingSchedulingAccess = !qualifiesForCoachingViaCN;
  const medicationManagementMedium = AppointmentMedium.Video;
  const medicationManagementInitialStartTime =
    upcomingMedicationManagerAppointmentSlots &&
    upcomingMedicationManagerAppointmentSlots?.appointment_slots?.available[0];
  let initialStartTime;
  let medium;
  const therapyVideoStartTime =
    upcomingTherapyAppointmentSlots &&
    upcomingTherapyAppointmentSlots?.firstVirtualSlot?.appointment_slots
      ?.available[0];
  const therapyInPersonStartTime =
    upcomingTherapyAppointmentSlots &&
    upcomingTherapyAppointmentSlots?.firstInPersonSlot?.appointment_slots
      ?.available[0];
  const isGlobalWithNoCoachingSessions =
    shouldShowCoaching &&
    showGlobalExperience &&
    coachingVisitsRemaining === 0 &&
    coachingVisitsCovered > 0;

  if (therapyInPersonStartTime === null && therapyVideoStartTime) {
    initialStartTime = therapyVideoStartTime;
    medium = AppointmentMedium.Video;
  } else if (therapyVideoStartTime === null && therapyInPersonStartTime) {
    initialStartTime = therapyInPersonStartTime;
    medium = AppointmentMedium.InPerson;
  } else {
    initialStartTime =
      therapyVideoStartTime > therapyInPersonStartTime
        ? therapyInPersonStartTime
        : therapyVideoStartTime;
    medium =
      therapyVideoStartTime > therapyInPersonStartTime
        ? AppointmentMedium.InPerson
        : AppointmentMedium.Video;
  }
  const therapyTabData: CoveredCareTabType = {
    label: t("benefits:therapyVisits.label"),
    tracking: () => trackTabClick(t("benefits:therapyVisits.label")),
    child: (
      <CoveredCareContent
        modalControls={modalControls}
        therapist={therapist}
        title={t("limitedLangBenefits:therapyVisits.title")}
        needHelpText={
          showGlobalExperience
            ? "coveredCareContent.needHelpTextGlobal"
            : "coveredCareContent.needHelpText"
        }
        isGlobalTherapy={showGlobalExperience}
        description={
          isAMinor
            ? t("benefits:therapyVisits.minorDescription")
            : t("benefits:therapyVisits.description")
        }
        supportType="Basic"
        callToActionText={
          therapist
            ? t("benefits:therapyVisits.followUpcallToActionText")
            : t("benefits:therapyVisits.callToActionText")
        }
        callToActionRoute="TherapistsBrowse"
        onClickCallToAction={() => trackButtonClick("Therapy")}
        checklist={
          isAMinor
            ? t("benefits:therapyVisits.minorChecklist", {
                returnObjects: true,
              })
            : t("benefits:therapyVisits.checklist", { returnObjects: true })
        }
        image={t("benefits:therapyVisits.image", {
          springCDN: links.SpringCDN,
        })}
        initialStartTime={initialStartTime}
        medium={medium}
        careNavigator={showGlobalExperience && careNavigator}
        therapyVisitsCovered={totalVisitsCovered}
        therapyVisitsRemaining={totalRemainingCovered}
        specialistVisitsCovered={specialistVisitsCovered}
        therapyVisitsUsed={totalVisitsUsed}
        specialistVisitsUsed={specialistVisitsUsed}
        isTherapy={true}
        customerId={customerId}
        customerName={customerName}
        isInNetwork={isInNetwork}
        internationalCohort={internationalCohort}
        virtualSessionCostTerm={virtualSessionCostTerm}
        onScheduleModalOpen={onScheduleModalOpen}
      />
    ),
    dataCy: "therapy-visits",
  };

  const medicationManagementTabData: CoveredCareTabType = {
    label: t("benefits:medicationManagement.label"),
    tracking: () => trackTabClick(t("benefits:medicationManagement.label")),
    child: (
      <CoveredCareContent
        modalControls={modalControls}
        medicationManager={medicationManager}
        title={t("benefits:medicationManagement.title")}
        description={t("benefits:medicationManagement.description")}
        supportType="Specialist"
        callToActionText={
          medicationManager
            ? t("benefits:medicationManagement.followUpcallToActionText")
            : t("benefits:medicationManagement.callToActionText")
        }
        callToActionRoute="Medication_ManagersBrowse"
        checklist={t("benefits:medicationManagement.checklist", {
          returnObjects: true,
        })}
        image={t("benefits:medicationManagement.image", {
          springCDN: links.SpringCDN,
        })}
        initialStartTime={medicationManagementInitialStartTime}
        medium={medicationManagementMedium}
        therapyVisitsCovered={totalVisitsCovered}
        specialistVisitsCovered={specialistVisitsCovered}
        specialistVisitsRemaining={specialistVisitsRemaining}
        specialistVisitsUsed={specialistVisitsUsed}
        isMedicationManager={true}
        customerName={customerName}
        customerId={customerId}
        isInNetwork={isInNetwork}
        internationalCohort={internationalCohort}
        virtualSessionCostTerm={virtualSessionCostTerm}
        onScheduleModalOpen={onScheduleModalOpen}
      />
    ),
  };

  const coachingTabData: CoveredCareTabType = {
    label: t("limitedLangBenefits:coachesVisit.label"),
    tracking: () => trackTabClick(t("limitedLangBenefits:coachesVisit.label")),
    child:
      (showCoachingPaidExperience ||
        enableDirectSchedulingInCoaching ||
        validV0CoachingSchedulingAccess) &&
      (shouldShowCoaching || coach) ? (
        <CoveredCareContent
          modalControls={modalControls}
          title={t("limitedLangBenefits:coachesVisit.title")}
          description={t("benefits:coachesVisit.description")}
          callToActionRoute="CoachesBrowse"
          callToActionText={t("benefits:coachesVisit.callToActionText")}
          image={t("benefits:coachesVisit.image", {
            springCDN: links.SpringCDN,
          })}
          coachesHelpDesc={t("benefits:coachesVisit.aboutCoachesHelp")}
          needHelpText={t("benefits:coachesVisit.needHelpText")}
          isCoach={true}
          supportType="Coach"
          checklist={t("benefits:coachesVisit.checklist", {
            returnObjects: true,
          })}
          coachingVisitsRemaining={coachingVisitsRemaining}
          coachingVisitsCovered={coachingVisitsCovered}
          coachingVisitsUsed={coachingVisitsUsed}
          paidCoaching={showCoachingPaidExperience}
          customerId={customerId}
          onScheduleModalOpen={onScheduleModalOpen}
          memberCostOfCoaching={cohort?.contract_term?.member_cost_of_coaching}
          showCTA={!isGlobalWithNoCoachingSessions}
        />
      ) : showCoachingPaidExperience || enableDirectSchedulingInCoaching ? (
        <CoveredCareContent
          modalControls={modalControls}
          title={t("limitedLangBenefits:coachesVisit.title")}
          description={t("benefits:coachesVisit.descriptionWithCN")}
          callToActionRoute="CoachesBrowse"
          callToActionText={t("benefits:careNavigation.callToActionTextNew")}
          image={t("benefits:coachesVisit.image", {
            springCDN: links.SpringCDN,
          })}
          coachesHelpDesc={t("benefits:coachesVisit.aboutCoachesHelp")}
          needHelpText={t("benefits:coachesVisit.needHelpText")}
          isCoach={true}
          supportType="Care Navigation"
          careNavigator={careNavigator}
          checklist={getCareNavigatorChecklist()}
          paidCoaching={showCoachingPaidExperience}
          coachingVisitsRemaining={coachingVisitsRemaining}
          coachingVisitsCovered={coachingVisitsCovered}
          coachingVisitsUsed={coachingVisitsUsed}
          customerId={customerId}
          onScheduleModalOpen={onScheduleModalOpen}
          memberCostOfCoaching={cohort?.contract_term?.member_cost_of_coaching}
        />
      ) : wpoCoachingEnabled ? (
        <WPOCoaching
          item="Coaching"
          memberData={memberInfoData}
          helpInfo={helpInfo}
          setShowBenefitsModal={modalControls.onOpen}
          isWpoCoaching={true}
        />
      ) : (
        <CoveredCareContent
          modalControls={modalControls}
          title={t("limitedLangBenefits:coachesVisit.title")}
          description={t("benefits:coachesVisit.description")}
          callToActionRoute="CoachesBrowse"
          callToActionText={t("benefits:careNavigation.callToActionTextNew")}
          image={t("benefits:coachesVisit.image", {
            springCDN: links.SpringCDN,
          })}
          coachesHelpDesc={t("benefits:coachesVisit.aboutCoachesHelp")}
          needHelpText={t("benefits:coachesVisit.needHelpText")}
          isCoach={true}
          supportType="Care Navigation"
          careNavigator={careNavigator}
          checklist={getCareNavigatorChecklist()}
          paidCoaching={showCoachingPaidExperience}
          coachingVisitsRemaining={coachingVisitsRemaining}
          customerId={customerId}
          onScheduleModalOpen={onScheduleModalOpen}
          memberCostOfCoaching={cohort?.contract_term?.member_cost_of_coaching}
        />
      ),
    dataCy: "coaching-visits",
  };

  const specializedCareNavigationTabData = {
    label: t("benefits:careNavigation.specializedLabel"),
    tracking: () =>
      trackTabClick(t("benefits:careNavigation.specializedLabel")),
    child: (
      <CoveredCareContent
        modalControls={modalControls}
        title={t("benefits:careNavigation.title")}
        description={t("benefits:careNavigation.specializedDescription")}
        supportType="Care Navigation"
        image={<EmptyCareNavigatorIcon aria-hidden="true" />}
        callToActionText={t("benefits:careNavigation.specializedCallToAction")}
        callToActionRoute="SubstanceUseSupport"
        careNavigator={careNavigator}
        checklist={t("benefits:careNavigation.specializedChecklist", {
          returnObjects: true,
        })}
        onScheduleModalOpen={onScheduleModalOpen}
        hasSpecializedCareNavigator={hasSpecializedCareNavigator}
      />
    ),
  };

  const careNavigationTabData = {
    label: isFamilyMember
      ? t("benefits:careNavigation.familyLabel")
      : t("benefits:careNavigation.label"),
    tracking: () => trackTabClick(t("benefits:careNavigation.label")),
    child: (
      <CoveredCareContent
        modalControls={modalControls}
        title={t("benefits:careNavigation.title")}
        description={
          isAMinor
            ? t("benefits:careNavigation.minorDescription")
            : showGlobalExperience
              ? t("benefits:careNavigation.descriptionGlobal")
              : t("benefits:careNavigation.description")
        }
        supportType="Care Navigation"
        image={<EmptyCareNavigatorIcon aria-hidden="true" />}
        callToActionText={t("benefits:careNavigation.callToActionTextNew")}
        careNavigator={careNavigator}
        checklist={getCareNavigatorChecklist()}
        onScheduleModalOpen={onScheduleModalOpen}
      />
    ),
  };

  const momentsTabData = {
    label: t("benefits:selfGuidedExercises.label"),
    tracking: () => trackTabClick(t("benefits:selfGuidedExercises.label")),
    child: (
      <CoveredCareContent
        isMoments={true}
        isAMinor={isAMinor}
        onScheduleModalOpen={onScheduleModalOpen}
      />
    ),
    dataCy: "selfGuidedExercises",
  };

  /*
  Cannot conditionally add tabs without having them built from an array like this,
  otherwise you get an 'expected function but got undefined' error when the conditional tab does not meet conditions.
  */
  const getTabs = (): CoveredCareTabType[] => {
    const tabs = [];

    if (!memberUnderSix) {
      tabs.push(therapyTabData);
    }

    if (hasSpecializedCareNavigator) {
      tabs.push(specializedCareNavigationTabData);
    }

    tabs.push(momentsTabData);

    // For adult members we show online assessments tab
    if (!isAMinor) {
      tabs.push(onlineAssessmentsTabData);
    }

    // If coaching flag is turned on and enabled through cohort, show Coaching tab - no assessment/optimism check here
    if ((coaching_support && !isAMinor) || wpoCoachingEnabled) {
      tabs.push(coachingTabData);
    }

    // For global experience and "managed minors" we do not show the medication management tab
    if (!showGlobalExperience && !isAMinor) {
      tabs.push(medicationManagementTabData);
    }

    if (cohort && cohort.cohort_eap_link && !isAMinor) {
      tabs.push(eapTabData);
    }

    if (!hasSpecializedCareNavigator) {
      tabs.push(careNavigationTabData);
    }

    return tabs;
  };

  const coveredCareTabsToRender = getTabs();

  if (query.tabKey) {
    tabId = coveredCareTabsToRender.findIndex((tab) => {
      return tab.label === query.tabKey;
    });
    if (tabId === -1) {
      tabId = 0;
    }
  }

  const currentTab = router?.asPath?.split("#")?.[1];
  if (currentTab) {
    const currentTabIndex = coveredCareTabsToRender.findIndex((tab) => {
      return tab?.label?.toLowerCase()?.replaceAll(" ", "-") === currentTab;
    });
    if (currentTabIndex >= 0) tabId = currentTabIndex;
  }

  const evidenceOfCoverageCopy = (tabLabel) => {
    if (showEvidenceOfCoverage && tabLabel != "Medication Management") {
      return (
        <Text
          color="#068262"
          size="body-medium-strong"
          paddingTop={20}
          paddingLeft={tabLabel === "Self-Guided Exercises" ? "16px" : ""}
        >
          {t("benefits:evidenceOfCoverage")}
        </Text>
      );
    }
  };

  return (
    <Section size="xlg">
      <Tabs activeTab={tabId} justification="flex-start">
        {coveredCareTabsToRender.map((tab) => {
          return (
            <Tab
              key={`tab_${tab.label}`}
              label={tab.label}
              tracking={tab.tracking}
              dataCy={tab.dataCy ? tab.dataCy : ""}
            >
              {tab.child}
              {evidenceOfCoverageCopy(tab.label)}
            </Tab>
          );
        })}
      </Tabs>
      <CareProviderScheduleModalV2
        isOpen={isScheduleModalOpen}
        onClose={onScheduleModalClose}
        {...scheduleModalProps}
      />
    </Section>
  );
};
