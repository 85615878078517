import { useEffect } from "react";
import { Link } from "@springcare/sh-component-library";
import NextLink from "next/link";
import PropTypes from "prop-types";

import {
  Center,
  Button,
  Box,
  Text,
  Heading,
  Image,
  VStack,
  HStack,
  Stack,
  Flex,
  Circle,
  useBreakpointValue,
} from "design-system/components";
import { TRACK_EVENT } from "utils/mixpanel";
import { useTranslation } from "react-i18next";
import { LevelUpCoachingSection } from "../LevelUpCoachingSection";

/**
 * NOTE: CTA prop notes
 * @prop {string} primaryCTAType - Primary CTA type, has either a value of "button" or "link"
 * - if it's a button then a @prop {function} primaryCTACallback needs to be provided
 * - if it's a link then a @prop {string} primaryCTAURL needs to be provided with the link URL
 * @prop {string} secondaryCTAType - Secondary CTA type, has either a value of "button" or "link"
 * - if it's a button then a @prop {function} secondaryCTACallback needs to be provided
 * - if it's a link then a @prop {string} secondaryCTAURL needs to be provided with the link URL
 */
export const UpNextCard = ({
  showFlag = false,
  showNewCoachingExperience = false,
  imageUrl,
  overline,
  cardOrder,
  cardTitle,
  cardTrackingName,
  primaryCTAType,
  primaryCTAText,
  primaryCTAURL,
  primaryCTACallback,
  primaryCTAIsLoading = false,
  buttonVariant = "outline",
  subtitle,
  secondaryCTAType,
  secondaryCTAText,
  secondaryCTAURL,
  secondaryCTACallback,
  headerText,
  initialSUDRisk = null,
  latestSUDRisk = null,
  isSUDLoading = false,
}) => {
  /**  TODO REFACTORING OPPORTUNITY:
   * Probably can turn the below two components into it's own thing
   * ...after unifying usage of primary/secondaryCTA otherwise it doesn't make as much sense
   * - once that's done, remove buttonText, buttonCallback, buttonVariant, linkText, linkURL
   *   ... and make primaryCTAType, primaryCTAText required props
   */
  const { t } = useTranslation(["homepage"]);
  const cardTitleSize = useBreakpointValue([
    "heading-small",
    "heading-small",
    "heading-medium",
    "heading-medium",
  ]);

  useEffect(() => {
    if (!isSUDLoading) {
      trackComponentView();
    }
  }, [isSUDLoading]);

  const trackComponentView = () => {
    TRACK_EVENT.COMPONENT_VIEWED(window.location.pathname, headerText, {
      spring_doc_id: "HPR_001",
      recommended_action: cardTrackingName,
      order: cardOrder + 1,
      location: "Up Next section",
      initial_sud_risk: initialSUDRisk ?? "None",
      latest_sud_risk: latestSUDRisk ?? "None",
    });
  };

  const imageSpacing = cardTitle.length > 42 ? "75%" : "100%";
  let secondPadding = secondaryCTAText ? "6px" : "25px";
  if (secondaryCTAText && imageSpacing === "75%") {
    secondPadding = "30px";
  }

  const PrimaryCTA = () => {
    if (primaryCTAType === "button") {
      return (
        <Button
          size="lg"
          variant={showFlag ? "solid" : buttonVariant}
          colorScheme="primary"
          onClick={primaryCTACallback}
          w={{ base: "100%", lg: "346px" }}
          isLoading={primaryCTAIsLoading}
          whiteSpace="normal"
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          data-cy={`${primaryCTAText.replace(/ /g, "-").toLowerCase()}-button`}
        >
          {primaryCTAText}
        </Button>
      );
    } else if (primaryCTAType === "link") {
      return (
        <NextLink href={primaryCTAURL} passHref>
          <Button
            size="lg"
            as={Link}
            colorScheme="primary"
            variant={showFlag ? "solid" : buttonVariant}
            w={{ base: "100%", lg: "346px" }}
            whiteSpace="nowrap"
            textAlign="center"
            _focusVisible={{ boxShadow: "0 0 0 3px black" }}
            onClick={() => {
              TRACK_EVENT.LINK_CLICKED(
                window.location.pathname,
                primaryCTAURL,
                headerText,
                {
                  spring_doc_id: "HPR_010",
                  location: `${cardTrackingName} card`,
                },
              );
            }}
            data-cy={`${primaryCTAText.replace(/ /g, "-").toLowerCase()}-button`}
          >
            {primaryCTAText}
          </Button>
        </NextLink>
      );
    }
    return null;
  };

  const SecondaryCTA = () => {
    if (secondaryCTAType === "link") {
      return (
        <Center>
          <NextLink href={secondaryCTAURL} passHref>
            <Link
              mt={16}
              _focusVisible={{ boxShadow: "0 0 0 3px black" }}
              onClick={() => {
                TRACK_EVENT.LINK_CLICKED(
                  window.location.pathname,
                  secondaryCTAURL,
                  cardTrackingName,
                  {
                    spring_doc_id: "HPR_006",
                    location: "Up Next section",
                  },
                );
              }}
            >
              {secondaryCTAText}
            </Link>
          </NextLink>
        </Center>
      );
    } else if (secondaryCTAType === "button") {
      return (
        <Button
          size="lg"
          pt={16}
          colorScheme="primary"
          variant="link"
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          onClick={() => {
            TRACK_EVENT.LINK_CLICKED(
              window.location.pathname,
              `Informational fly-out - ${overline}`,
              `${headerText} - ${secondaryCTAText}`,
              {
                spring_doc_id: "HPR_004",
                location: `${cardTrackingName} card`,
              },
            );
            secondaryCTACallback();
          }}
          w={{ base: "100%", lg: "346px" }}
        >
          {secondaryCTAText}
        </Button>
      );
    }
    return null;
  };

  return (
    <Box
      w="100%"
      borderWidth="3px"
      borderRadius="12px"
      borderColor="tertiary.light"
      backgroundColor="platform.on"
    >
      <Box
        w="100%"
        h="100%"
        p={!showNewCoachingExperience ? "24px" : "0px"}
        data-cy={`${overline.toLowerCase()}-up-next-card`}
      >
        <Stack
          direction={{ base: "column", lg: "row", md: "column", sm: "column" }}
          w="100%"
        >
          {showNewCoachingExperience ? (
            <LevelUpCoachingSection width={"100%"} />
          ) : (
            <>
              <Flex w="100%" align="center">
                <Center>
                  <VStack align="stretch" spacing="4px">
                    <Box>
                      <HStack spacing="8px">
                        <Box display="flex" flexDirection="row">
                          <Text variant="bodyBold_v1" mt="6px">
                            {overline}
                          </Text>
                        </Box>
                        {showFlag && (
                          <Box ps="0px">
                            <Box bg="primary.light" borderRadius={6}>
                              <Box ps="12px" pe="12px">
                                <Text variant="body_v1" color="success.base">
                                  {t(
                                    "homepage:upNextSection.coachAssignment.startHere",
                                  )}
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </HStack>
                    </Box>
                    <Box h="fit-content">
                      <HStack>
                        <Flex
                          w={showNewCoachingExperience ? "174px" : imageSpacing}
                        >
                          <Heading
                            size={`${cardTitleSize}`}
                            color="platform.900"
                          >
                            {cardTitle}
                          </Heading>
                        </Flex>
                        {/* @ts-ignore */}
                        <Circle w="40px" h="40px">
                          {Boolean(imageUrl) && (
                            <>
                              {typeof imageUrl !== "string" ? (
                                imageUrl
                              ) : (
                                <Image
                                  object-fit="cover"
                                  height="100%"
                                  width="100%"
                                  borderRadius="full"
                                  src={imageUrl}
                                  alt="A photograph of your care provider"
                                  aria-hidden="true"
                                />
                              )}
                            </>
                          )}
                        </Circle>
                      </HStack>
                    </Box>
                    {subtitle && (
                      <Box>
                        <Text variant="bodyBold_v1" color="platform.500">
                          {subtitle}
                        </Text>
                      </Box>
                    )}
                  </VStack>
                </Center>
              </Flex>
              <Box pt={secondPadding}>
                <Flex
                  w="100%"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <PrimaryCTA />
                  <SecondaryCTA />
                </Flex>
              </Box>
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

UpNextCard.propTypes = {
  showFlag: PropTypes.bool,
  imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  overline: PropTypes.string,
  cardTitle: PropTypes.string,
  subtitle: PropTypes.string,
  buttonVariant: PropTypes.string,
  primaryCTAType: PropTypes.oneOf(["button", "link"]).isRequired,
  primaryCTAText: PropTypes.string.isRequired,
  primaryCTAURL: PropTypes.string,
  primaryCTACallback: PropTypes.func,
  secondaryCTAType: PropTypes.oneOf(["button", "link"]),
  secondaryCTAText: PropTypes.string,
  secondaryCTAURL: PropTypes.string,
  secondaryCTACallback: PropTypes.func,
  primaryCTAIsLoading: PropTypes.bool,
};
