import { Flex, Text, VIndividualIcon } from "@springcare/sh-component-library";

import { useTranslation } from "react-i18next";

const CameraPlaceholder = ({ borderRadius = "8px", initials = null }) => {
  const { t } = useTranslation("a11y");

  return (
    <Flex
      w="full"
      h="full"
      position="relative"
      align="center"
      justify="center"
      bg="black"
      borderWidth="v-width-thick"
      borderRadius={borderRadius}
      borderColor="border-media-subtle"
      role="img"
      aria-label={
        initials ? `Participant initials: ${initials}` : "Participant icon"
      }
    >
      <Flex
        align="center"
        justify="center"
        borderRadius="full"
        bg="content-media-disabled"
        w="56px"
        h="56px"
      >
        {initials ? (
          <Text size="body-medium-regular" color="white">
            {initials}
          </Text>
        ) : (
          <VIndividualIcon color="white" aria-hidden={true} />
        )}
      </Flex>
    </Flex>
  );
};

export default CameraPlaceholder;
