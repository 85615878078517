import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  VisuallyHidden,
} from "design-system";
import { DateTime } from "luxon";
import { Link } from "components/atoms";
import { sanitizeEnum } from "@spring/immutability";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { TRACK_EVENT } from "utils/mixpanel";
import { getIsUniqueCCACopy } from "utils/employerServicesHelpers";
import { handleBookAgainClick } from "components/templates/CareVisitsPage/components/Appointments/pastAppointments.util";
import { Hide, useBreakpointValue } from "@springcare/sh-component-library";
import { SHTooltip as Tooltip } from "design-system/components";
import { isMinor } from "utils/memberHelpers";
import { useMemberInfo } from "hooks";
import { useMediaQuery } from "@chakra-ui/react";
import { Spinner } from "design-system/components";

import {
  AppointmentAction,
  AppointmentKind,
  isCareConsultantAppointment,
  isCareNavigation,
  isCoaching,
} from "@spring/constants";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { getFormLinkWPMHC } from "utils/employerServicesHelpers";
import {
  AttendedStatusDisplayI18nKeyMapping,
  AttendedStatusTooltipI18nKeyMapping,
} from "./attendedStatusTooltip";

const getProvider = (bookings) => {
  const users = bookings
    .filter((person) => person.role !== "Member")
    .map((provider) => provider.name)
    .join(", ");

  return users || "N/A";
};

const getKindMedium = (row, t, customerId) => {
  const isUniqueCCACopy = getIsUniqueCCACopy(customerId);

  // Target wants overrides to the wording of CCA appointments
  if (
    isUniqueCCACopy &&
    row.kind === AppointmentKind.WellnessAndSocialSupportSession
  ) {
    return t(`appointmentsTable.wellnessAndSocialSupport.${customerId}`);
  } else if (row.kind === AppointmentKind.WellnessAndSocialSupportSession) {
    return t(`appointmentsTable.wellnessAndSocialSupport.default`);
  }

  if (!row.kind.toLowerCase().includes("therapy")) {
    return sanitizeEnum(row.kind);
  }

  const medium = row.medium.toLowerCase().includes("person")
    ? t("appointmentsTable.inPerson")
    : t("appointmentsTable.virtual");
  return `${sanitizeEnum(row.kind)} - ${medium}`;
};

const AppointmentsTable = ({
  appointmentsData,
  openModal,
  actionType,
  isGlobalMemberWithNoVisitsRemaining,
  getLazyNextAppointmentSlot,
  getLazySlotRequestVars,
  onScheduleModalOpen,
}) => {
  const { t } = useTranslation(["careVisits"]);
  const DEFAULT_TABLE_LENGTH = 3;
  const [showFullTable, setShowFullTable] = useState(false);
  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);

  const isPastApptTable = actionType === "button";
  const isUpcomingApptTable = actionType === "link";

  const parsedTableData = showFullTable
    ? appointmentsData.slice(0, DEFAULT_TABLE_LENGTH)
    : appointmentsData;
  const shouldRenderMoreLessButton =
    isPastApptTable && appointmentsData?.length > DEFAULT_TABLE_LENGTH;

  const buttonStyling = useBreakpointValue([
    { pl: "10px", border: 0 },
    { border: 0 },
    {},
  ]);
  const viewMoreLink = useBreakpointValue([
    t("appointmentsTable.view"),
    t("appointmentsTable.view"),
    t("appointmentsTable.viewSession"),
  ]);
  const formattedAppointmentTime = useBreakpointValue([
    "LLL dd ",
    "LLL dd, yyyy",
  ]);

  const anchorTag = !isPastApptTable ? "PastAppointmentsTableAnchorTag" : "";
  const { data: memberInfoData } = useMemberInfo();
  const isAMinor = isMinor(memberInfoData?.user?.member);
  const customerId = memberInfoData?.user?.member?.cohort?.customer?.id;
  const MINOR_EXCLUDED_APPT_KINDS = ["COACHING"];

  const coachingSupported =
    memberInfoData?.user?.member?.cohort?.contract_term?.coaching_support ??
    false;
  const continuedCoaching =
    memberInfoData?.user?.member?.cohort?.contract_term?.continued_coaching ??
    false;

  const [isSmallerScreen] = useMediaQuery("(max-width: 650px)");

  function handleExpandCollapseTable() {
    if (!showFullTable) {
      TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "View More");
    }
    if (document.getElementById(anchorTag)) {
      document.getElementById(anchorTag).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setShowFullTable(!showFullTable);
  }

  const openScheduleModal = (appointmentRow) => {
    if (
      shouldShowNewInsuranceModal &&
      !isCareNavigation(appointmentRow?.kind)
    ) {
      onScheduleModalOpen({
        kind: appointmentRow.kind,
        provider: appointmentRow.provider,
        buttonText: "Confirm Appointment",
        action: AppointmentAction.Create,
        dataCy: "schedule-provider-modal",
        mpLocation: "Your Past Appointments",
        telemetryProps: {
          springDocId: appointmentRow.spring_doc_id,
          location: "Book again",
          appointment_medium: appointmentRow.medium,
        },
      });
    } else {
      handleBookAgainClick(
        appointmentRow,
        openModal,
        getLazyNextAppointmentSlot,
        getLazySlotRequestVars,
      );
    }
  };

  const BookAgainStatus = ({
    appointmentRow,
    coachingSupported,
    continuedCoaching,
  }) => {
    const isCareConsultant = isCareConsultantAppointment(appointmentRow?.kind);

    const { data: memberInfoData, loading: memberDataLoading } =
      useMemberInfo();
    const customerId = memberInfoData?.user?.member?.cohort?.customer?.id;
    const formLinkSDOH = getFormLinkWPMHC(customerId);

    const openLCCForm = () =>
      window.open(formLinkSDOH, "_blank", "noopener noreferrer");

    const appointmentAttended = appointmentRow?.attended;
    if (appointmentRow?.provider?.status === "Disabled") {
      return (
        <Box
          p={"10px"}
          w={["auto", "120px", "150px"]}
          color="platform.500"
          fontWeight={400}
        >
          {t("appointmentsTable.providerStatusDisabled")}
        </Box>
      );
    }

    const attendedStatusCopy =
      t(`${AttendedStatusDisplayI18nKeyMapping(appointmentAttended)}`) !==
      "undefined"
        ? t(`${AttendedStatusDisplayI18nKeyMapping(appointmentAttended)}`)
        : "";
    const toolTipCopy =
      t(`${AttendedStatusTooltipI18nKeyMapping(appointmentAttended)}`) !==
      "undefined"
        ? t(`${AttendedStatusTooltipI18nKeyMapping(appointmentAttended)}`)
        : "";
    const isPeerRecoverySpecialist =
      appointmentRow?.kind === AppointmentKind.FollowUpPeerRecoverySpecialist ||
      appointmentRow?.kind === AppointmentKind.InitialPeerRecoverySpecialist;
    const isCoachingProvider = isCoaching(appointmentRow?.kind);
    const bookAgainCoaching =
      isCoachingProvider && (coachingSupported || continuedCoaching);

    if (
      appointmentRow?.status === "Booked" &&
      !isGlobalMemberWithNoVisitsRemaining &&
      !(
        isAMinor &&
        MINOR_EXCLUDED_APPT_KINDS.includes(appointmentRow.kind) &&
        !isCoachingProvider
      )
    ) {
      // member has coaching appointment and bookAgainCoaching is strictly false
      if (
        isCoachingProvider &&
        !(bookAgainCoaching === undefined) &&
        !bookAgainCoaching
      ) {
        return null;
      }

      const isPeerRecoverySpecialistSupported =
        memberInfoData?.user?.member?.is_peer_recovery_specialist_supported &&
        useFeatureFlag(FLAGS.PEER_RECOVERY_SPECIALIST_EXPERIENCE);

      if (memberDataLoading) {
        return <Spinner />;
      }

      if (isPeerRecoverySpecialist && !isPeerRecoverySpecialistSupported) {
        return null;
      }

      if (
        appointmentRow.attended == "Kept" ||
        appointmentRow.attended === null
      ) {
        return (
          <Button
            {...buttonStyling}
            colorScheme="primary"
            variant="outline"
            data-cy="book-again-button"
            onClick={() =>
              isCareConsultant
                ? openLCCForm()
                : openScheduleModal(appointmentRow)
            }
          >
            {t("appointmentsTable.bookAgain")}
          </Button>
        );
      }
      return (
        <Tooltip
          hasArrow
          label={toolTipCopy}
          aria-label={toolTipCopy}
          placement="top"
          id="appointmentStatusTooltip"
          role="tooltip"
        >
          <Box p={"10px"} color="platform.500">
            {attendedStatusCopy}
          </Box>
        </Tooltip>
      );
    } else if (appointmentRow?.status === "Cancelled") {
      if (appointmentRow.attended !== null) {
        return (
          <Tooltip
            hasArrow
            label={toolTipCopy}
            aria-label={toolTipCopy}
            placement="top"
            id="appointmentStatusTooltip"
            role="tooltip"
          >
            <Box p={"10px"} color="platform.500">
              {attendedStatusCopy}
            </Box>
          </Tooltip>
        );
      }
      return (
        <Box
          p={"10px"}
          w={["auto", "120px", "150px"]}
          color="platform.500"
          fontWeight={400}
        >
          {t("appointmentsTable.cancelled")}
        </Box>
      );
    }
    return null;
  };

  return (
    <>
      <Table size={isSmallerScreen ? "sm" : "md"} variant="unstyled" my={5}>
        <Thead fontWeight={500} color="platform.900">
          <Tr>
            <Td pl={0} pr={2} minW={{ base: "0px", sm: "150px" }}>
              {t("appointmentsTable.date")}
            </Td>
            <Hide below="lg">
              <Td pl={0} pr={2} minW={120} w={150}>
                {t("appointmentsTable.time")}
              </Td>
              <Td pl={0} pr={2}>
                {t("appointmentsTable.kind")}
              </Td>
            </Hide>
            <Td pl={0} pr={2} minW={170}>
              {t("appointmentsTable.provider")}
            </Td>
            <Td pl={0} pr={2}>
              <VisuallyHidden>{t("apptTableStatusColHead")}</VisuallyHidden>
            </Td>
          </Tr>
        </Thead>

        <Tbody id={anchorTag}>
          {parsedTableData.map((appointmentDetails) => {
            const appointmentId = appointmentDetails.id;
            const apptDateTime = DateTime.fromISO(appointmentDetails.start_at);
            return (
              <Tr
                color="platform.800"
                borderBottom="1px solid #EFEDE8"
                height={50}
                key={appointmentId}
                data-cy={`appointment-row-${appointmentId}`}
              >
                <Td pl={0} pr={[0, 2, 2, 2]} minW={[75, ""]}>
                  {apptDateTime.toLocal().toFormat(formattedAppointmentTime)}
                </Td>
                <Hide below="lg">
                  <Td pl={0} pr={2}>
                    {apptDateTime.toLocal().toFormat("h:mm a ZZZZ")}
                  </Td>
                  <Td pl={0} pr={2}>
                    {getKindMedium(appointmentDetails, t, customerId)}
                  </Td>
                </Hide>
                <Td pl={0} pr={2}>
                  <Text maxWidth={isSmallerScreen ? "162px" : ""}>
                    {getProvider(appointmentDetails.bookings)}
                  </Text>
                </Td>
                {isUpcomingApptTable && (
                  <Td pl={0} pr={2} fontWeight={500}>
                    {appointmentDetails.status === "Cancelled" ? (
                      <Text color={"platform.600"}>
                        {t("appointmentsTable.cancelled")}
                      </Text>
                    ) : (
                      <Link
                        dataCy="view-appointment"
                        ariaLabel={t("a11y:viewAppointment", {
                          provider_name: getProvider(
                            appointmentDetails.bookings,
                          ),
                          appt_time: apptDateTime.toLocaleString(
                            DateTime.DATETIME_SHORT,
                          ),
                        })}
                        alias="MemberAppointmentDetail"
                        queries={{ id: appointmentId }}
                        mpTracking={{
                          page: window.location.pathname,
                          type: "View Appointment Details Link",
                          props: { appointment_id: appointmentId },
                        }}
                      >
                        {viewMoreLink}
                      </Link>
                    )}
                  </Td>
                )}

                {isPastApptTable && (
                  <Td pl={0} pr={2}>
                    <BookAgainStatus
                      appointmentRow={appointmentDetails}
                      coachingSupported={coachingSupported}
                      continuedCoaching={continuedCoaching}
                    />
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {shouldRenderMoreLessButton && (
        <Box color="primary.500" position="absolute" right={0}>
          <Button
            variant="button"
            border={0}
            onClick={handleExpandCollapseTable}
          >
            {!showFullTable
              ? t("pastAppointments.viewLess")
              : t("pastAppointments.viewMore")}
          </Button>
        </Box>
      )}
    </>
  );
};

export default AppointmentsTable;
