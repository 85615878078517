import { useState, useMemo } from "react";
import { useRemoteParticipants } from "@livekit/components-react";
import { useLazyQuery } from "@apollo/client";

import {
  Box,
  Button,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Text,
} from "@springcare/sh-component-library";

import { sessionAdmitParticipant } from "operations/queries/appointment";

export const AdmitParticipantPopover = () => {
  const [isLoading, setIsLoading] = useState(false);

  const remoteParticipants = useRemoteParticipants();

  const waitingRoomParticipants = useMemo(
    () =>
      remoteParticipants.filter(
        (participant) => !participant.permissions?.canPublish,
      ),
    [remoteParticipants],
  );

  const popoverText = useMemo(() => {
    setIsLoading(false);
    if (waitingRoomParticipants.length === 1) {
      return `${waitingRoomParticipants[0]?.name ?? "A participant"} is requesting to join the session`;
    } else if (waitingRoomParticipants.length > 1) {
      return `${waitingRoomParticipants.length} participants are requesting to join the session`;
    }
    return "";
  }, [waitingRoomParticipants]);

  const [loadSessionAdmitParticipant, { data, error }] = useLazyQuery(
    sessionAdmitParticipant,
  );
  if (error) {
    setIsLoading(false);
    //TODO: Handle error
  }

  const urlPaths = window.location.pathname.split("/");
  // url is: <envUrl>[0]/session[1]/<appointmentId>[2]/<participantId>[3]
  const appointmentId = urlPaths[2];

  // TODO: Q4-2024/Q1-2025 -- admit multiple participants with a single call
  const admitParticipant = (identity) => {
    setIsLoading(true);
    loadSessionAdmitParticipant({
      variables: {
        appointmentId,
        participantId: identity,
      },
    });
  };
  return (
    <Popover
      //TODO: Ensure only provider can see and admit participants
      isOpen={waitingRoomParticipants.length > 0}
      placement="top"
    >
      <PopoverAnchor>
        <Box />
      </PopoverAnchor>
      <PopoverContent
        w="v-4xl"
        p="v-8"
        borderRadius="v-xl"
        marginBottom="v-8"
        aria-label="Admit participant"
      >
        <Text textStyle="body-medium-regular" p="v-8" aria-live="polite">
          {popoverText}
        </Text>
        <Button
          w="100%"
          onClick={() => admitParticipant(waitingRoomParticipants[0]?.identity)}
          aria-label="Admit participant"
          isLoading={isLoading}
        >
          Admit
        </Button>
      </PopoverContent>
    </Popover>
  );
};

export default AdmitParticipantPopover;

AdmitParticipantPopover.displayName = "AdmitParticipantPopover";
