import { assessments as assessmentConstants } from "@spring/constants";
import { React } from "react";
import { connect } from "react-redux";
import { Select, Input } from "@spring/smeargle";
import PropTypes from "prop-types";
import AppetiteElement from "components/assessments/AppetiteElement";
import { setField } from "actions/assessment/actions";

import Raven from "utils/raven/getRaven";
import {
  Checkbox as CircleCheckbox,
  TextInput,
  Confidence,
  Slider,
  FillInArea,
  SingleSelect,
} from "components/assessments/Elements";
import { nullFormFieldSelector } from "selectors/form";
import { useTranslation } from "hooks/react-i18next";

const { elementKinds } = assessmentConstants;

const ElementCurator = (props) => {
  const {
    fieldKey,
    formKey,
    title,
    subtitle,
    id,
    element,
    disabled,
    dataCy,
    isSpinnerOn,
    toolTipRef,
    advance,
  } = props;

  const {
    options,
    label,
    placeholder,
    min,
    max,
    step,
    markers,
    left_label,
    right_label,
    appetite_options,
  } = element;

  const { t } = useTranslation(["assessments"]);

  // Throw a new exception in Sentry if element.kind does not exist and not on localhost
  const handleError = () => {
    if (Raven) {
      if (
        typeof window !== "undefined" &&
        !window.location.host.includes("localhost")
      ) {
        Raven.captureException(
          new Error("Missing element type in assessments/ElementCurator"),
        );
      }
    }
    return null;
  };

  const getRadioButtons = () => {
    return (
      <SingleSelect
        fieldKey={fieldKey}
        formKey={formKey}
        options={options}
        dataCy={dataCy}
        advance={advance}
      />
    );
  };

  const getCheckbox = () => {
    return (
      <>
        <CircleCheckbox
          key={id}
          label={label}
          fieldKey={fieldKey}
          formKey={formKey}
          dataCy={dataCy + "-checkbox"}
        />
      </>
    );
  };

  const getRange = () => {
    return (
      <>
        <Slider
          title={title}
          subtitle={subtitle}
          min={min}
          max={max}
          markers={markers}
          step={step}
          fieldKey={fieldKey}
          formKey={formKey}
          toolTipDisabled={disabled || isSpinnerOn}
          toolTipRef={toolTipRef}
        />
      </>
    );
  };

  const getTextarea = () => {
    return (
      <>
        <FillInArea
          formKey={formKey}
          fieldKey={fieldKey}
          placeholder={placeholder}
          dataCy={`${dataCy}-textarea`}
        />
      </>
    );
  };

  const getTextInput = () => {
    return (
      <TextInput
        key={id}
        label={label}
        fieldKey={fieldKey}
        formKey={formKey}
        dataCy={dataCy + "-text-input"}
        placeholder={t("issues.otherInputPlaceholder")}
      />
    );
  };

  const getSelect = () => {
    return (
      <Select
        key={id}
        fieldKey={fieldKey}
        formKey={formKey}
        options={options}
        placeholder={placeholder}
      />
    );
  };

  const getNumber = () => {
    return (
      <Input
        key={id}
        type={"number"}
        fieldKey={fieldKey}
        formKey={formKey}
        placeholder={placeholder}
        min={min}
        max={max}
      />
    );
  };

  const getAppetite = () => {
    return (
      <AppetiteElement
        key={id}
        fieldKey={fieldKey}
        formKey={formKey}
        options={appetite_options}
        left={left_label}
        right={right_label}
      />
    );
  };

  const getConfidence = () => {
    return (
      <>
        <Confidence formKey={formKey} fieldKey={fieldKey} />
      </>
    );
  };

  switch (props.element.kind) {
    case elementKinds.RADIO:
      return getRadioButtons();
    case elementKinds.CHECKBOX:
      return getCheckbox();
    case elementKinds.RANGE:
      return getRange();
    case elementKinds.TEXT:
      return getTextInput();
    case elementKinds.TEXTAREA:
      return getTextarea();
    case elementKinds.SELECT:
      return getSelect();
    case elementKinds.NUMBER:
      return getNumber();
    case elementKinds.APPETITE:
      return getAppetite();
    case elementKinds.CONFIDENCE:
      return getConfidence();
    case elementKinds.COMPLETE:
      return null;
    default:
      return handleError();
  }
};

ElementCurator.propTypes = {
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  element: PropTypes.shape({
    appetite_options: PropTypes.object,
    kind: PropTypes.any,
    label: PropTypes.string,
    left_label: PropTypes.string,
    markers: PropTypes.array,
    max: PropTypes.number,
    min: PropTypes.number,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    right_label: PropTypes.string,
    step: PropTypes.number,
  }).isRequired,
  fieldKey: PropTypes.string.isRequired,
  formKey: PropTypes.string,
  id: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  isSpinnerOn: PropTypes.bool,
  toolTipRef: PropTypes.any, //React.RefObject<any>
  advance: PropTypes.func,
};

ElementCurator.defaultProps = {
  formKey: "",
  title: "",
  subtitle: "",
  disabled: false,
  dataCy: "",
  isSpinnerOn: false,
  toolTipRef: undefined,
};

export { ElementCurator };
export default connect(
  (state, ownProps) => nullFormFieldSelector(state, ownProps),
  { setField },
)(ElementCurator);
