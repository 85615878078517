import { useQuery } from "@apollo/client";
import { DateTime } from "luxon";

import Meowth from "@spring/meowth";
import { getAppointmentSlots } from "operations/queries/appointment";
import { getStartSpan } from "utils/schedulingHelpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

// NOTE: see components/molecules/CareTeamList for example usage
export function useUpcomingAppointmentSlots(
  careProvider,
  kind,
  medium,
  increment = 30,
) {
  const shouldShowLeadTime =
    useFeatureFlag(FLAGS.DYNAMIC_LEAD_TIME_RELEASE) &&
    careProvider?.in_house_availability_source_of_truth_at;

  const startSpan = getStartSpan(kind, shouldShowLeadTime);
  const { data: appointmentSlotsData, loading: appointmentSlotsLoading } =
    useQuery(getAppointmentSlots, {
      variables: {
        user_ids: [Meowth.getUserId(), careProvider?.user_id],
        slot_increment: increment,
        kind: kind, // e.g Therapy, Coaching, etc
        medium: medium, // e.g video, in-person
        start_span: startSpan,
        end_span: DateTime.fromISO(startSpan)
          .plus({ days: 29 })
          .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 })
          .toISO(),
        upcoming_slots_only: true,
        upcoming_slots_count: 1,
      },
      skip: !careProvider || !kind,
    });
  return {
    data: { ...appointmentSlotsData },
    loading: appointmentSlotsLoading,
  };
}
