import { Box } from "@springcare/sh-component-library";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import routes from "routes";
import Router from "next/router";

// import { images } from "@spring/constants";
import { SHCareCard } from "design-system/components/custom/cards/SHCareCard";
import {
  getBadgeExists,
  getIsUniqueWPCCopy,
} from "utils/employerServicesHelpers";

const WorkplaceConsultationCard = ({ customerId }): ReactElement => {
  const { t } = useTranslation("careVisits");
  const badgeExists = getBadgeExists(customerId);
  const isUniqueWPCCopy = getIsUniqueWPCCopy(customerId);
  const stringKey = isUniqueWPCCopy ? customerId : "default";

  return (
    <Box mr={{ base: 0, md: 8 }} mb={{ base: 11, md: 0 }}>
      <SHCareCard
        badgeText={
          badgeExists
            ? t(`findNewCareTab.sdohCards.cards.${stringKey}.wc.badge`)
            : null
        }
        buttonTrackingText="WC Care Card"
        cardSubtitle={t(
          `findNewCareTab.sdohCards.cards.${stringKey}.wc.description`,
        )}
        cardTitle={t(`findNewCareTab.sdohCards.cards.${stringKey}.wc.title`)}
        componentTrackingText="WC Care Card"
        onClick={() => {
          Router.push(routes.WorkplaceConsultationsBrowse.as);
        }}
        onClickTrackingText="CCA Modal Opened"
        imageAlt={t(`findNewCareTab.sdohCards.cards.${stringKey}.wc.imageAlt`)}
        //TODO: remove placeholder and add image from S3 when ready
        imageUrl={"https://placehold.co/318x168"}
        testId="wc-card"
      />
    </Box>
  );
};

export default WorkplaceConsultationCard;
