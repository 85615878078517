import React from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { Box, Text } from "design-system/components";
import { Link } from "@springcare/sh-component-library";
import envUtils from "utils/environment";
import { isCoaching } from "@spring/constants";

export const MakeChangeCopy = ({ time, kind }) => {
  const { t } = useTranslation("careVisits");
  const subject = t("appointmentDetails.makeChangeCopy.subject");
  const mailToURL =
    `mailto:${envUtils.careTeamEmail}?subject=` +
    encodeURIComponent(`${subject}${time}`);

  return (
    <Box as="section" my={16}>
      <Text variant="body_v1">
        <Trans
          ns={"careVisits"}
          i18nKey={
            isCoaching(kind)
              ? "appointmentDetails.makeChangeCopy.coachCopyContent"
              : "appointmentDetails.makeChangeCopy.content"
          }
          values={{
            kind: "your Care Navigator at",
            careTeamEmail: envUtils.careTeamEmail,
          }}
        >
          <Text as="span">
            <Link href={mailToURL} />
          </Text>
        </Trans>
      </Text>
    </Box>
  );
};

MakeChangeCopy.propTypes = {
  kind: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};
