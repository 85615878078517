//

import React from "react";
import {
  Card,
  Col,
  Content,
  FlexCol,
  Floaty,
  Grid,
  SpringCommunity,
  SpringGroup,
  SpringNavigation,
  Subtitle,
} from "@spring/smeargle";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import { Link } from "components";

function HelpCards() {
  const { t } = useTranslation("help");

  return (
    <Grid>
      <Col sm={6} md={4}>
        <Link alias="MemberHelp--Basic_Information">
          <Floaty float={0} hover={1} radius={3}>
            <Card>
              <div className={styles.helpCard}>
                <FlexCol alignment="center">
                  <div className={styles.icon}>
                    <SpringNavigation />
                  </div>
                  <Subtitle>
                    {t("helpCards.basicInformation.subtitle")}
                  </Subtitle>
                  <Content>{t("helpCards.basicInformation.content")}</Content>
                </FlexCol>
              </div>
            </Card>
          </Floaty>
        </Link>
      </Col>

      <Col sm={6} md={4}>
        <Link alias="MemberHelp--Providers">
          <Floaty float={0} hover={1} radius={3}>
            <Card>
              <div className={styles.helpCard}>
                <FlexCol alignment="center">
                  <div className={styles.icon}>
                    <SpringCommunity />
                  </div>
                  <Subtitle>{t("helpCards.providers.subtitle")}</Subtitle>
                  <Content>{t("helpCards.providers.content")}</Content>
                </FlexCol>
              </div>
            </Card>
          </Floaty>
        </Link>
      </Col>

      <Col sm={6} md={4}>
        <Link alias="MemberHelp--Recommendations">
          <Floaty float={0} hover={1} radius={3}>
            <Card>
              <div className={styles.helpCard}>
                <FlexCol alignment="center">
                  <div className={styles.icon}>
                    <SpringGroup />
                  </div>
                  <Subtitle>{t("helpCards.recommendations.subtitle")}</Subtitle>
                  <Content>{t("helpCards.recommendations.content")}</Content>
                </FlexCol>
              </div>
            </Card>
          </Floaty>
        </Link>
      </Col>
    </Grid>
  );
}

export default HelpCards;
