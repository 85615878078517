import { useQuery } from "@apollo/client";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Subtitle,
  Bolded,
  Content,
  Button,
  PlayIcon,
  BackgroundCurve,
  LanguageSvg,
} from "@spring/smeargle";
import {
  SimpleGrid,
  GridItem,
  Box,
  Text,
} from "@springcare/sh-component-library";
import { openModal } from "@spring/smeargle/actions";
import { modalIds } from "@spring/constants";
import Meowth from "@spring/meowth";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "redux";
import { useTranslation } from "hooks/react-i18next";

import { CheckinTodo } from "components/molecules";
import { lookupLanguage } from "utils/language";

import styles from "./styles.module.scss";

import getExercise from "operations/queries/exercise/getExercise";
import { getFirstError } from "utils/apollo/errorHandler";
import { getMemberTodos } from "operations/queries/member";
import { MomentsStartExerciseModal } from "components";
import { Heading } from "design-system/components";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

const useExerciseData = () => {
  const {
    data: deepBreathingData,
    loading: deepBreathingLoading,
    error: deepBreathingError,
  } = useQuery(getExercise, {
    variables: {
      id: "32adf2fb-f955-412f-8bc9-79831d76ebf4",
    },
  });

  return {
    data: deepBreathingData,
    loading: deepBreathingLoading,
    error: deepBreathingError,
  };
};

const MomentsHeader = (props) => {
  const { data, loading, error } = useExerciseData();
  const { t } = useTranslation("moments");
  const language = lookupLanguage(props.lang).nativeName;
  const langIsEnglish = language === "English";
  const { momentsOnly } = props;
  const showDailyMoments = useFeatureFlag(FLAGS.DAILY_MOMENTS);

  return (
    <>
      <Box as="header">
        <SimpleGrid columns={12}>
          <GridItem colSpan={[12, 7, 9]}>
            <div className={styles.header}>
              <Heading
                as="h1"
                my={24}
                fontSize={48}
                fontWeight={50}
                data-cy="moments"
              >
                {t("momentsHeader.title")}
              </Heading>
              <Subtitle>
                <span>{t("momentsHeader.subtitle")}</span>
              </Subtitle>
              {momentsOnly && (
                <Text marginBottom={"2rem"} data-test="momentsOnlyContent">
                  {t("momentsOnlyHeader.content")}
                </Text>
              )}
              {!langIsEnglish && (
                <div className={styles.momentsTranslation}>
                  <LanguageSvg />
                  <div>
                    &nbsp;
                    {t("momentsHeader.languageNote", { language: language })}
                  </div>
                </div>
              )}
              {!showDailyMoments && (
                <div className={styles.buttonWrapper}>
                  <Button
                    full
                    flat
                    dataCy="start-a-moment"
                    iconOnLeft={true}
                    text={t("momentsHeader.buttonText")}
                    customIcon={<PlayIcon />}
                    onClick={() => {
                      props.openModal(
                        modalIds.momentsStartExerciseModal,
                        data.exercise,
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </GridItem>
          <GridItem colSpan={[0, 5, 3]}>
            <div className={styles.momentsIllustration}>
              <img
                src="/static/momentsIllustrationGrey.svg"
                alt="Moments Illustration"
                aria-hidden="true"
              />
            </div>
          </GridItem>
        </SimpleGrid>
      </Box>

      <BackgroundCurve marginTop="-530px" pageRoute="Moments" />
      {!momentsOnly && <CheckinTodo data={props.data} />}
      {!loading && error && (
        <div className={styles.error}>
          <Bolded>{t("error")}</Bolded>
          <Content inheritColor>{getFirstError(error)}</Content>
        </div>
      )}
      <MomentsStartExerciseModal />
    </>
  );
};

MomentsHeader.propTypes = {
  data: PropTypes.any,
  lang: PropTypes.any,
  openModal: PropTypes.func,
};
const mapStateToProps = (state) => ({
  lang: state.global.lang,
});

export default compose(
  graphql(getMemberTodos, {
    options: Meowth.apolloOptionsUserId,
    fetchPolicy: "network-only",
  }),
  connect(mapStateToProps, { openModal }),
)(MomentsHeader);
