import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import Router from "next/router";
import { useEffect } from "react";

import getMemberDOB from "modules/MemberDashboard/Neurodiversity/graphql/queries/getMemberDOB";
import { getHomeRoute } from "shared/utils/getHomeRoute";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const useNeurodiversityPagesAccess = (redirectToHomeRoute = false) => {
  const neurodiversityPageFlag = useFeatureFlag(
    FLAGS.NEURODIVERSITY_PO_DEMO_NOT_PROD,
  );
  const isProductionEnv =
    process.env.APP_ENV === "production" || process.env.APP_ENV === "prod";

  // IMPORTANT: These pages should NOT be accessible in the prod env.
  // It is vaporware: parts of the page are static, mocked, or hardcoded.
  // It is only intended for our sales team to use in the demo env.
  // It was built without performance, scalability, or maintainability in mind.
  // See https://springhealth.atlassian.net/browse/MXE-520 for more detail.
  const canAccessNeurodiversityPage =
    neurodiversityPageFlag && !isProductionEnv;

  const { data: memberData, loading: isLoading } = useQuery(getMemberDOB, {
    ...Meowth.apolloOptionsUserId(),
  });

  const memberDOB = memberData?.user?.member?.date_of_birth;

  // Redirect members who shouldn't be able to access this page
  useEffect(() => {
    if (!canAccessNeurodiversityPage && !isLoading && redirectToHomeRoute) {
      const memberHomeRoute = getHomeRoute(memberDOB);
      Router.replace(memberHomeRoute.to, memberHomeRoute.as);
    }
  }, [canAccessNeurodiversityPage, isLoading]);

  return { canAccessNeurodiversityPage };
};
