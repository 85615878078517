import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { get, getOr } from 'lodash/fp';
import { modalIds } from '@spring/constants';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';

import styles from './styles.module.scss';

import { Icon, FlexRow } from '../../../components';
import { closeModal, openModal } from '../../../actions/modal';



const FullScreenModal = (props) => {

	useEffect(() => {
		const root = document.querySelector('[data-reactroot]');

		if (props.open) {
			document.body.style.overflow = 'hidden';
			root && (root.ariaHidden = 'true');
		} else {
			document.body.style.overflow = 'unset';
			root && (root.ariaHidden = 'false');
		}
	}, [props.open]);

	const header = () => {
		return (
			<div className={styles.headerWrapper}>
				<FlexRow center justification="space-between">
					<div className={styles.modalTitle} aria-label={props.title}>
						{props.title}
					</div>
					<div className={styles.close}>
						<Icon
							type="close"
							onClick={() => {
								if (props.confirmExit) {
									props.openModal(modalIds.exitModal);
								} else {
									props.closeModal(props.id);
									if (props.closeRedirect) {
										props.closeRedirect();
									}
								}}}
						/>
					</div>
				</FlexRow>
			</div>
		);
	};

	return (
		props.open ?
			createPortal(
				<FocusTrap
					focusTrapOptions={{
            			fallbackFocus: () => {
						  return document.getElementById(props.id);
					  	},
					  	...(props.data?.setReturnFocus && { setReturnFocus: props.data?.setReturnFocus }),
          			}}
				>
					<div
						className={classnames(styles.wrapper, {
							[styles.open]: props.open,
						})}
					>
						<div
							className={classnames(styles.modal, styles.full, {
								[styles.open]: props.open,
							})}
						>
							<div
								className={classnames(styles.body)}
								onClick={(e) => {e.stopPropagation();}}
							>
								{header()}
								<div className={styles.content}>
									{props.children}
								</div>
							</div>
						</div>
					</div>
				</FocusTrap>,
				document.body
			) : null
	);
};

FullScreenModal.defaultProps = {
	open          : false,
	closeRedirect : false,
	confirmExit   : false,
};

FullScreenModal.propTypes = {
	id            : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	title         : PropTypes.string,
	confirmExit   : PropTypes.bool,
	closeModal    : PropTypes.func ,
	openModal     : PropTypes.func ,
	closeRedirect : PropTypes.func,
	children      : PropTypes.node,
	open          : PropTypes.bool,
	data          : PropTypes.any,
};

export { FullScreenModal };

export default connect(
	(state, ownProps) => ({
		open : getOr(ownProps.open, `${ownProps.id}.open`, state.modal),
		data : get(`${ownProps.id}.data`, state.modal),
	}),
	{ openModal, closeModal }
)(FullScreenModal);
