import { useMediaDeviceSelect } from "@livekit/components-react";
import {
  MenuOptionGroup,
  MenuItemOption,
} from "@springcare/sh-component-library";

import { useSessionDevices } from "context/SessionRoomContext";
import { DeviceTypes } from "components/templates/SHSessionRoom/types";

export const DeviceMenuOptionGroup = ({ type }) => {
  const { sessionDevices, setSessionDevices } = useSessionDevices();
  const {
    devices,
    selectedAudioOutputDevice,
    selectedAudioInputDevice,
    selectedVideoDevice,
  } = sessionDevices;
  const { setActiveMediaDevice: setActiveAudioInputDevice } =
    useMediaDeviceSelect({
      kind: DeviceTypes.AudioInput,
    });
  const { setActiveMediaDevice: setActiveVideoInputDevice } =
    useMediaDeviceSelect({
      kind: DeviceTypes.VideoInput,
    });

  const { setActiveMediaDevice: setActiveAudioOutputDevice } =
    useMediaDeviceSelect({
      kind: DeviceTypes.AudioOutput,
    });

  let defaultValue = { deviceId: "", label: "" };
  let title = "";
  let selectFunction = (device) => {};
  switch (type) {
    case DeviceTypes.AudioOutput:
      defaultValue = selectedAudioOutputDevice;
      title = "Select a speaker";
      selectFunction = (device) => {
        setActiveAudioOutputDevice(device.deviceId);
        setSessionDevices((prevState) => ({
          ...prevState,
          selectedAudioOutputDevice: device,
        }));
      };
      break;
    case DeviceTypes.AudioInput:
      defaultValue = selectedAudioInputDevice;
      title = "Select a microphone";
      selectFunction = (device) => {
        setActiveAudioInputDevice(device.deviceId);
        setSessionDevices((prevState) => ({
          ...prevState,
          selectedAudioInputDevice: device,
        }));
      };
      break;
    case DeviceTypes.VideoInput:
      defaultValue = selectedVideoDevice;
      title = "Select a camera";
      selectFunction = (device) => {
        setActiveVideoInputDevice(device.deviceId);
        setSessionDevices((prevState) => ({
          ...prevState,
          selectedVideoDevice: device,
        }));
      };
      break;
    default:
      throw new Error("Unsupported device type");
  }

  return (
    <>
      <MenuOptionGroup
        defaultValue={defaultValue.deviceId}
        title={title}
        type="radio"
      >
        {devices[type].map((device) => (
          <MenuItemOption
            value={device.deviceId}
            key={device.deviceId}
            onClick={() => selectFunction(device)}
            _focus={{ outline: "0px solid transparent !important" }}
            _focusVisible={{ outline: "2px solid black !important" }}
          >
            {device.label}
          </MenuItemOption>
        ))}
      </MenuOptionGroup>
    </>
  );
};

export default DeviceMenuOptionGroup;
