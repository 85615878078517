import {
  Badge,
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  VVisitsIcon,
  VNightIcon,
  useDisclosure,
} from "@springcare/sh-component-library";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import NextLink from "next/link";
import { TRACK_EVENT } from "utils/mixpanel";
import { isCareNavigation } from "@spring/constants";
import { useEffect } from "react";
import { getSeasonalityWarningConfig } from "components/molecules/CareProviderScheduleContent/SeasonalityWarning.utils";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import routes from "routes/PublicRoutes";
import { CancellationDetailsModal } from "components/modals";

type MemberPortalReduxState = {
  global: {
    lang?: string;
    [key: string]: any;
  };
  [key: string]: any;
};

export const UpcomingAppointmentCard = ({ appointment, memberId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation("homepage");

  const showSeasonalityBadges = useFeatureFlag(
    FLAGS.HOMEPAGE_SEASONALITY_APPOINTMENT_TIME_WARNING,
  );
  const showConfirmCta = useFeatureFlag(
    FLAGS.UPCOMING_APPT_CARD_CONFIRMATION_CTA,
  );
  const showDeltaConfirmationText = useFeatureFlag(
    FLAGS.DELTA_UPCOMING_APPT_CARD_CONFIRMATION_COPY,
  );

  const locale = useSelector<MemberPortalReduxState>(
    (state) => state?.global?.lang,
  );

  const appointmentStartAt = DateTime.fromISO(appointment?.start_at).setLocale(
    locale,
  );
  const dayOfWeek = appointmentStartAt.weekdayLong;
  const imageUrl = appointment?.provider?.avatar;
  const providerFirstName = appointment?.provider?.name.split(" ")[0];
  const cardTitleSize = useBreakpointValue([
    "heading-small",
    "heading-small",
    "heading-medium",
    "heading-medium",
  ]);
  let text = "";
  const { isWeekend, isOutsideOfNormalHours } = getSeasonalityWarningConfig(
    appointment?.start_at,
  );

  useEffect(() => {
    if (appointment) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Upcoming appointment card",
        {
          location: "Home page up next section",
          provider_id: appointment?.provider_id,
          appointment_id: appointment?.id,
          kind: appointment?.kind,
          start_at: appointment?.start_at,
          medium: appointment?.medium,
          badge_time: text,
          weekend_badge: showSeasonalityBadges ?? isWeekend,
          off_hours_badge: showSeasonalityBadges ?? isOutsideOfNormalHours,
        },
      );
    }
  }, []);

  const getSessionTypeString = () => {
    switch (appointment?.kind) {
      case "THERAPY":
      case "MINOR_THERAPY":
        return t("upNextSection.therapistAssignment.overline");
      case "COACHING":
        return t("upNextSection.coachAssignment.overline");
      case "FOLLOW_UP_CARE_NAVIGATION":
      case "INITIAL_CARE_NAVIGATION":
        return t("upNextSection.careNavigatorScheduling.overline");
      case "FOLLOW_UP_MEDICATION_MANAGEMENT":
      case "INITIAL_MEDICATION_MANAGEMENT":
        return t("upNextSection.medicationManagerAssignment.overline");
      case "FOLLOW_UP_PEER_RECOVERY":
      case "INITIAL_PEER_RECOVERY":
        return t("upNextSection.peerRecoverySupport.overline");
      case "INDIVIDUAL_SOLUTIONS_FOCUSED_CONSULTATION":
      case "COUPLES_THERAPY":
      case "MANAGER_CONSULTATION_AND_SUPPORT":
      case "TRAINING_AND_AWARENESS_EVENT_CONSULTATION":
      case "WELLNESS_AND_SOCIAL_SUPPORT_SESSION":
        break;
      default:
    }
  };

  const hrDiff = appointmentStartAt.diffNow("hours").toObject().hours;
  const minuteDiff = appointmentStartAt.diffNow("minutes").toObject().minutes;
  const dayDiff = Math.round(
    appointmentStartAt.diffNow("days").toObject().days,
  );

  const shouldRenderConfirmCta =
    showConfirmCta &&
    minuteDiff >= 10 &&
    !appointment?.confirmed_at &&
    !isCareNavigation(appointment?.kind);

  const shouldShowConfirmationCopy =
    showDeltaConfirmationText &&
    showConfirmCta &&
    hrDiff >= 24 &&
    !appointment?.confirmed_at &&
    !isCareNavigation(appointment?.kind);

  // TODO: these subcomponents are getting big. move to separate files

  const TimedBadge = () => {
    /**
     * non granular 48hrs + do day diff
     * less than 36 hrs BUT greater than 24 hrs 1 DAY X HRS
     * less than 24 hrs show Starts in less than 24 hrs badge
     */
    if (hrDiff <= 72 && dayDiff === 3) {
      text = t("upNextSection.upcomingAppointments.badge.3days");
    } else if (dayDiff === 2) {
      text = t("upNextSection.upcomingAppointments.badge.2days");
    } else if (dayDiff === 1 && hrDiff <= 36 && hrDiff >= 24) {
      text = `${t("upNextSection.upcomingAppointments.badge.startsWithin")} ${Math.ceil(hrDiff)} ${t("upNextSection.upcomingAppointments.badge.pluralHour")}`;
    } else if (hrDiff <= 24 && minuteDiff > 10) {
      text = t("upNextSection.upcomingAppointments.badge.1day");
    } else if (minuteDiff <= 10 && Date.now() < appointmentStartAt) {
      text = t("upNextSection.upcomingAppointments.badge.10mins");
    } else {
      text = t("upNextSection.upcomingAppointments.badge.now");
    }

    return (
      <Badge status test-id="timed-badge" colorScheme="neutral">
        <Text>{text}</Text>
      </Badge>
    );
  };

  const SeasonalityWarningBadges = () => {
    return (
      <>
        {isWeekend && (
          <Badge icon={VVisitsIcon} colorScheme="info">
            <Text>{t("upNextSection.upcomingAppointments.badge.weekend")}</Text>
          </Badge>
        )}
        {isOutsideOfNormalHours && (
          <Badge icon={VNightIcon} colorScheme="info">
            <Text>
              {t("upNextSection.upcomingAppointments.badge.off-hours")}
            </Text>
          </Badge>
        )}
      </>
    );
  };

  const ConfirmAppointmentCTA = () => {
    return (
      <NextLink
        href={{
          pathname: routes.ConfirmAppointment.as,
          query: {
            id: appointment?.id,
            memberId: memberId,
          },
        }}
        passHref
      >
        <Button
          size="md"
          as={Link}
          w={{ base: "100%", lg: "346px" }}
          whiteSpace="normal"
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          _focus={{ textColor: "warning-bold" }}
          colorScheme="warning"
          onClick={() => {
            TRACK_EVENT.LINK_CLICKED(
              window.location.pathname,
              routes.ConfirmAppointment.as,
              "Confirm Appointment",
              {
                location: "Home page up next section",
                provider_id: appointment?.provider_id,
                appointment_id: appointment?.id,
                kind: appointment?.kind,
                start_at: appointment?.start_at,
                medium: appointment?.medium,
              },
            );
          }}
        >
          {t("upNextSection.upcomingAppointments.confirmAppointmentCTA")}
        </Button>
      </NextLink>
    );
  };

  /*
    button for in person GREATER THAN 10 mins from start = View appointment
    UNDER = Join appointment

    IN PERSON will always show View appointment
  */
  const PrimaryCTA = () => {
    return minuteDiff >= 10 || appointment?.medium !== "VIDEO" ? (
      <NextLink href={`/members/care_visits/${appointment?.id}`} passHref>
        <Button
          size="md"
          as={Link}
          w={{ base: "100%", lg: "346px" }}
          whiteSpace="normal"
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          _focus={{ textColor: "primary-on-base" }}
          onClick={() => {
            TRACK_EVENT.LINK_CLICKED(
              window.location.pathname,
              `/members/care_visits/${appointment?.id}`,
              "View appointment",
              {
                location: "Home page up next section",
                provider_id: appointment?.provider_id,
                appointment_id: appointment?.id,
                kind: appointment?.kind,
                start_at: appointment?.start_at,
                medium: appointment?.medium,
                badge_time: text,
              },
            );
          }}
        >
          {t(
            "upNextSection.upcomingAppointments.primaryCTAText.viewAppointment",
          )}
        </Button>
      </NextLink>
    ) : (
      <Button
        size="lg"
        as={Link}
        w={{ base: "100%", lg: "346px" }}
        whiteSpace="normal"
        _focusVisible={{ boxShadow: "0 0 0 3px black" }}
        onClick={() => {
          TRACK_EVENT.LINK_CLICKED(
            window.location.pathname,
            appointment?.location,
            "Join appointment",
            {
              location: "Home page up next section",
              provider_id: appointment?.provider_id,
              appointment_id: appointment?.id,
              kind: appointment?.kind,
              start_at: appointment?.start_at,
              medium: appointment?.medium,
              badge_time: text,
            },
          );
        }}
        // @ts-ignore
        href={appointment?.location}
        isExternal
      >
        {t("upNextSection.upcomingAppointments.primaryCTAText.join")}
      </Button>
    );
  };

  const SecondaryCTA = () => {
    let isLateCancel = false;
    let isOver10Mins = true;
    let buttonCopy;
    let trackingCopy;

    // OVER 24 hrs left til start
    // buttonCopy with link to reschedule modal

    // UNDER 24 hrs but OVER 10 mins left til start
    // buttonCopy with link to cancellation policy

    // UNDER 10 mins
    // render nothing

    if (
      (isCareNavigation(appointment?.kind) &&
        DateTime.now() < appointmentStartAt) ||
      hrDiff >= 48
    ) {
      buttonCopy = "makeChanges";
      trackingCopy = "Make changes";
    } else if (hrDiff >= 24) {
      buttonCopy = "makeChanges";
      trackingCopy = "Make changes";
    } else if (minuteDiff >= 10) {
      buttonCopy = "appointmentSecondaryCTALateCancel";
      trackingCopy =
        "Making changes now may result in loss of session or a fee.";
      isOver10Mins = false;
      isLateCancel = true;
    }

    if (!buttonCopy) {
      return null;
    }

    return (
      <Flex>
        {(isCareNavigation(appointment?.kind) || isOver10Mins) && (
          <Text size="body-small-regular">
            <Trans
              ns={"homepage"}
              i18nKey={`upNextSection.upcomingAppointments.secondaryCTAText.${buttonCopy}`}
              components={[
                <Link
                  colorScheme={shouldRenderConfirmCta ? "warning" : "base"}
                  key={"0"}
                  href={`/members/care_visits/${appointment?.id}`}
                  // @ts-ignore
                  size="md"
                  onClick={() => {
                    TRACK_EVENT.LINK_CLICKED(
                      window.location.pathname,
                      `/members/care_visits/${appointment?.id}`,
                      trackingCopy,
                      {
                        location: "Home page up next section",
                        provider_id: appointment?.provider_id,
                        appointment_id: appointment?.id,
                        kind: appointment?.kind,
                        start_at: appointment?.start_at,
                        medium: appointment?.medium,
                        badge_time: text,
                      },
                    );
                  }}
                />,
              ]}
            />
          </Text>
        )}
        {!isCareNavigation(appointment?.kind) && isLateCancel && (
          <Flex direction="column" alignItems="center">
            <Text size="body-medium-regular" align="center">
              {
                <Trans
                  ns={"homepage"}
                  i18nKey={
                    "upNextSection.upcomingAppointments.secondaryCTAText.appointmentSecondaryCTALateCancel"
                  }
                  components={[
                    <Link
                      key={0}
                      colorScheme={shouldRenderConfirmCta ? "warning" : "base"}
                      onClick={() => {
                        onOpen();
                        TRACK_EVENT.BUTTON_CLICKED(
                          window.location.pathname,
                          "View cancellation policy",
                          {
                            location: "Home page up next section",
                            provider_id: appointment?.provider_id,
                            appointment_id: appointment?.id,
                            kind: appointment?.kind,
                            start_at: appointment?.start_at,
                            medium: appointment?.medium,
                            badge_time: text,
                          },
                        );
                      }}
                    ></Link>,
                  ]}
                ></Trans>
              }
            </Text>
          </Flex>
        )}
        <CancellationDetailsModal isOpen={isOpen} onClose={onClose} />
      </Flex>
    );
  };

  return (
    <Box
      w="100%"
      borderWidth="3px"
      borderRadius="12px"
      borderColor="tertiary.light"
      backgroundColor="platform.on"
    >
      <Box
        w="100%"
        h="100%"
        px="24px"
        py={shouldShowConfirmationCopy ? "16px" : "24px"}
      >
        <Stack
          direction={{ base: "column", lg: "row", md: "column", sm: "column" }}
          w="100%"
          gap={shouldShowConfirmationCopy ? "16px" : "8px"}
        >
          <Flex w="full" align="center">
            <VStack align="stretch" spacing="4px">
              <Flex mb="4px" flexWrap={"wrap"} gap={8}>
                <TimedBadge />
                {showSeasonalityBadges && <SeasonalityWarningBadges />}
              </Flex>
              <Box>
                <Text variant="bodyBold_v1">
                  {dayOfWeek}
                  {", "}
                  {appointmentStartAt.toLocaleString({
                    month: "long",
                    day: "numeric",
                  })}{" "}
                  {t("upNextSection.upcomingAppointments.at")}{" "}
                  {appointmentStartAt.toLocaleString({
                    hour: "numeric",
                    minute: "numeric",
                    timeZoneName: "short",
                  })}
                </Text>
              </Box>
              <Box h="fit-content">
                <HStack>
                  <Heading size={`${cardTitleSize}`} data-testid="card-title">
                    {getSessionTypeString()}{" "}
                    {t("upNextSection.upcomingAppointments.sessionWith")}{" "}
                    {providerFirstName}
                  </Heading>
                  {/* @ts-ignore */}
                  <Circle w="40px" h="40px">
                    {Boolean(imageUrl) && (
                      <>
                        {typeof imageUrl !== "string" ? (
                          imageUrl
                        ) : (
                          <Image
                            object-fit="cover"
                            height="100%"
                            width="100%"
                            borderRadius="full"
                            src={imageUrl}
                            alt={t(
                              "upNextSection.upcomingAppointments.avatarCaption",
                            )}
                            aria-hidden="true"
                          />
                        )}
                      </>
                    )}
                  </Circle>
                </HStack>
              </Box>
            </VStack>
          </Flex>
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            gap="8px"
          >
            {shouldShowConfirmationCopy && (
              <Trans i18nKey="upNextSection.upcomingAppointments.confirmationCopy">
                <Text
                  align="center"
                  color="warning-on-base"
                  textStyle="body-medium-regular"
                  maxW="300px"
                >
                  {t("upNextSection.upcomingAppointments.confirmationCopy")}
                </Text>
              </Trans>
            )}
            <Flex
              w="full"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
              gap={{ base: "16px", lg: "8px" }}
            >
              {shouldRenderConfirmCta ? (
                <ConfirmAppointmentCTA />
              ) : (
                <PrimaryCTA />
              )}
              <SecondaryCTA />
            </Flex>
          </Flex>
        </Stack>
      </Box>
    </Box>
  );
};
