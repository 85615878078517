import {
  Box,
  Flex,
  Heading,
  Text,
  useMediaQuery,
} from "@springcare/sh-component-library";
import Image from "next/image";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { images } from "@spring/constants";
import { SHCareCard } from "design-system/components/custom/cards/SHCareCard";
import { useInViewportOnce } from "hooks/useInViewport";
import { CareContextValues } from "pages/apps/MemberDashboard/care_visits";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";

const TherapyCard = ({ t }) => (
  <Box mr={[0, 0, 8]} mb={[11, 11, 11, 8]}>
    <SHCareCard
      imageUrl={images.TherapyCareCard}
      imageAlt={t("findNewCareTab.careProviderList.cards.therapist.imageAlt")}
      cardTitle={t("findNewCareTab.careProviderList.cards.therapist.title")}
      cardSubtitle={t(
        "findNewCareTab.careProviderList.cards.therapist.description",
      )}
      route={routes.TherapistsBrowse}
      componentTrackingText="Therapist Care Card"
      buttonTrackingText="Therapist Care Card"
      tabIndex={0}
      dataCy="browse-therapist-card"
    />
  </Box>
);

const CoachingCard = ({ t }) => (
  <Box mr={[0, 0, 8]} mb={[11, 11, 11, 8]}>
    <SHCareCard
      imageUrl={images.CoachingCareCard}
      imageAlt={t("findNewCareTab.careProviderList.cards.coaches.imageAlt")}
      cardTitle={t("findNewCareTab.careProviderList.cards.coaches.title")}
      cardSubtitle={t(
        "findNewCareTab.careProviderList.cards.coaches.description",
      )}
      route={routes.CoachesBrowse}
      componentTrackingText="Coach Care Card"
      buttonTrackingText="Coach Care Card"
      tabIndex={0}
    />
  </Box>
);

const MMCard = ({ t }) => (
  <Box mr={[0, 0, 8]} mb={[11, 11, 11, 8]}>
    <SHCareCard
      imageUrl={images.MedicationManagerCareCard}
      imageAlt={t(
        "findNewCareTab.careProviderList.cards.medicationManager.imageAlt",
      )}
      cardTitle={t(
        "findNewCareTab.careProviderList.cards.medicationManager.title",
      )}
      cardSubtitle={t(
        "findNewCareTab.careProviderList.cards.medicationManager.description",
      )}
      route={routes.Medication_ManagersBrowse}
      componentTrackingText="Medication Manager Care Card"
      buttonTrackingText="Medication Manager Care Card"
      tabIndex={0}
    />
  </Box>
);

const SudCNCard = ({ t }) => (
  <Box mr={[0, 0, 8]} mb={[11, 11, 11, 8]}>
    <SHCareCard
      imageUrl={images.SUDCNCard}
      imageAlt={t("findNewCareTab.careProviderList.cards.sudCn.imageAlt")}
      cardTitle={t("findNewCareTab.careProviderList.cards.sudCn.title")}
      cardSubtitle={t(
        "findNewCareTab.careProviderList.cards.sudCn.description",
      )}
      route={routes.SubstanceUseSupport}
      componentTrackingText="SUD CN Care Card"
      buttonTrackingText="SUD CN Care Card"
      tabIndex={0}
    />
  </Box>
);

const EmptyCard = ({ t }) => {
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [viewedEmptyOnce, setViewedEmptyOnce] = useState(false);

  useEffect(() => {
    if (inViewport && !viewedEmptyOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Empty Care Provider Card",
      );
      setViewedEmptyOnce(true);
    }
  }, [inViewport, viewedEmptyOnce]);

  return (
    <Flex
      ref={ref}
      direction={["column", "column", "row"]}
      border="2px dashed"
      borderColor="border-subtle"
      borderRadius={12}
      w="100%"
      h={363}
      px={[0, 0, 50]}
      py={[30, 30, 0]}
      justifyContent="space-between"
      alignItems="center"
      tabIndex={0}
    >
      <Box w={["80%", "80%", "30%"]}>
        <Heading variant="sm_v1" mb={10}>
          {t("findNewCareTab.careProviderList.cards.empty.title")}
        </Heading>
        <Text>
          {t("findNewCareTab.careProviderList.cards.empty.description")}
        </Text>
      </Box>
      <Box
        w={["167px", "167px", "300px"]}
        h={["167px", "167px", "300px"]}
        pos="relative"
      >
        <Image layout="fill" src="/static/safety.svg" alt="Safety Image" />
      </Box>
    </Flex>
  );
};

export const CareProviderList = () => {
  const { t } = useTranslation("careVisits");
  const {
    memberInfo,
    showGlobalExperience,
    isAMinor,
    isMemberUnderSix,
    showEmptyCard,
    setShowEmptyCard,
    showSUDCopy: shouldShowSUDCNCard,
  } = useContext(CareContextValues);
  const { coaching_support = false } =
    memberInfo?.user?.member?.cohort?.contract_term || {};
  const showTherapyCard = !isMemberUnderSix;
  const showCoachingCard = coaching_support && !isAMinor;
  const showMMCard = !showGlobalExperience && !isAMinor;

  const [listSmallerThanScreen] = useMediaQuery(["(max-width: 890px)"]);

  useEffect(() => {
    setShowEmptyCard(
      !(
        showTherapyCard ||
        showCoachingCard ||
        showMMCard ||
        shouldShowSUDCNCard
      ),
    );
  }, [showTherapyCard, showCoachingCard, showMMCard, shouldShowSUDCNCard]);

  return (
    <Flex
      flexDirection={listSmallerThanScreen ? "column" : "row"}
      flexWrap="wrap"
    >
      {showTherapyCard && <TherapyCard t={t} />}
      {showCoachingCard && <CoachingCard t={t} />}
      {showMMCard && <MMCard t={t} />}
      {shouldShowSUDCNCard && <SudCNCard t={t} />}
      {showEmptyCard && <EmptyCard t={t} />}
    </Flex>
  );
};
