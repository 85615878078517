//

import PropTypes from "prop-types";
import React from "react";
import { useWordPressContent } from "@spring/hooks";
import {
  LoadingCircle,
  DangerousHtml,
  Content,
  Headline,
  Subtitle,
  Bolded,
  Stout,
  Underlined,
} from "@spring/smeargle";
import { isLoading, isLoaded, hasException } from "@spring/immutability";

import { Link } from "components/atoms";
import { BackButton, ArticleError } from "components/molecules";

import styles from "./styles.module.scss";

const ArticleListError = () => (
  <ArticleError>
    Sorry! There are no articles to be found in this category.
  </ArticleError>
);

const FeaturedImage = ({ alt, id }) => {
  const { data, status } = useWordPressContent(`/media/${id}`);

  return (
    <>
      {isLoading(status) && (
        <div className={styles.loadingContainer}>
          <LoadingCircle />
        </div>
      )}
      {isLoaded(status) && (
        <img className={styles.featuredImage} src={data.source_url} alt={alt} />
      )}
    </>
  );
};

FeaturedImage.propTypes = {
  id: PropTypes.any,
};

const PsychArticleList = ({ categoryId, categorySlug }) => {
  const { data: articleData, status: articleStatus } = useWordPressContent(
    `/posts?categories=${categoryId}`,
  );

  return (
    <div className={styles.postContainer}>
      {isLoading(articleStatus) && <LoadingCircle />}
      {isLoaded(articleStatus) &&
        articleData.map((article) => (
          <div className={styles.articleContainer} key={article.id}>
            <Link
              aria-label={`${article.title.rendered.replace("&#038;", "&")} - ${article.excerpt.rendered.replace(/['<p>''</p>']/g, "").replace("&#038;", "&")}`}
              alias="MemberLearningCenterArticle"
              queries={{
                category_slug: categorySlug,
                article_slug: article.slug,
              }}
              dataCy={`${article.title.rendered}-article`}
            >
              <div className={styles.article}>
                <FeaturedImage
                  alt={`Article Image: ${article.title.rendered.replace("&#038;", "&")}`}
                  id={article.featured_media}
                />
                <div className={styles.articleContent}>
                  <Subtitle key={article.id} noMargin>
                    <Bolded>
                      <DangerousHtml ariaLabel={article.title.rendered}>
                        {article.title.rendered}
                      </DangerousHtml>
                    </Bolded>
                  </Subtitle>
                  <Content>
                    <DangerousHtml ariaLabel={article.excerpt.rendered}>
                      {article.excerpt.rendered}
                    </DangerousHtml>
                  </Content>
                </div>
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
};

PsychArticleList.propTypes = {
  categoryId: PropTypes.any,
  categorySlug: PropTypes.any,
};

const PsychArticles = ({ categorySlug }) => {
  const { data: categoryData, status: categoryStatus } = useWordPressContent(
    `/categories?slug=${categorySlug}`,
  );

  return (
    <>
      {hasException(categoryStatus) && <ArticleListError />}
      {isLoading(categoryStatus) && <LoadingCircle />}
      {isLoaded(categoryStatus) && (
        <>
          {!categoryData.length && <ArticleListError />}
          {!!categoryData.length && (
            <div role="main">
              <div className={styles.backButtonContainer}>
                <BackButton alias="MemberLearningCenter">
                  <Stout>
                    <Underlined>Back To Learning Center</Underlined>
                  </Stout>
                </BackButton>
              </div>
              <div className={styles.titleContainer}>
                <div
                  role="heading"
                  aria-level="1"
                  data-cy={`${categoryData[0].name}-headline`}
                >
                  <Headline noMargin>{categoryData[0].name}</Headline>
                </div>
                <Subtitle dataCy={`${categoryData[0].description}`} noMargin>
                  <DangerousHtml>{categoryData[0].description}</DangerousHtml>
                </Subtitle>
              </div>
              <PsychArticleList
                categoryId={categoryData[0].id}
                categorySlug={categoryData[0].slug}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

PsychArticles.propTypes = {
  categorySlug: PropTypes.any,
};

export default PsychArticles;
