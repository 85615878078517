import React from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { Heading, Box, Text } from "design-system/components";
import { Link } from "@springcare/sh-component-library";

import { formatAppointmentTime } from "components/templates/CareVisitDetails/utils";
import routes from "routes";

export const PhoneCopy = ({ duration, time, provider }) => {
  const { t } = useTranslation("careVisits");
  return (
    <>
      <Box as="section">
        <Heading as="h2" fontWeight="bold" fontSize={16} mb={16}>
          {t("appointmentDetails.details")}
        </Heading>
        <Text mb={16} variant="body_v1">
          <Trans
            ns={"careVisits"}
            i18nKey={"appointmentDetails.phoneCopy.content1"}
            values={{
              providerName: provider.name,
              providerFirstName: provider.first_name,
              appointmentTime: formatAppointmentTime(time),
              duration,
            }}
          />
        </Text>
        <Text mb={16}>
          <Trans
            ns={"careVisits"}
            i18nKey={"appointmentDetails.phoneCopy.content2"}
          >
            <Link href={routes.MemberSettings.as} />
          </Trans>
        </Text>
      </Box>
    </>
  );
};

PhoneCopy.propTypes = {
  duration: PropTypes.any,
  provider: PropTypes.shape({
    first_name: PropTypes.any,
    name: PropTypes.any,
  }),
  time: PropTypes.any,
};
