//

import PropTypes from "prop-types";
import React from "react";
import { FlexCol, Bolded, Subtitle } from "@spring/smeargle";

import { Link } from "components/atoms";

const ArticleError = ({ children }) => (
  <FlexCol>
    <Bolded>
      <Subtitle>{children}</Subtitle>
    </Bolded>
    <Link alias="MemberLearningCenter">Back to Learning Center</Link>
  </FlexCol>
);

ArticleError.propTypes = {
  children: PropTypes.string,
};

ArticleError.defaultProps = {
  children: "Sorry! The article you're looking for cannot be found.",
};

export default ArticleError;
