import { graphql } from "modules/shared/graphql-codegen";

export const getCNsByStartTimes = graphql(`
  query getCNsByStartTimes(
    $member_id: ID!
    $start_date: DateTime!
    $utc_offset: String
    $sud_only: Boolean
  ) {
    all_available_cn_appointment_times(
      member_id: $member_id
      start_date: $start_date
      utc_offset: $utc_offset
      sud_only: $sud_only
    ) {
      day_of_the_month
      cn_appointments {
        start_time
        care_provider {
          id
          user_id
        }
      }
    }
  }
`);
