import {
  Box,
  Flex,
  Text,
  Avatar,
  Divider,
} from "@springcare/sh-component-library";

import { useParticipants } from "@livekit/components-react";

const AttendeeSection = () => {
  const participants = useParticipants();

  return (
    <Box px="v-24" pt="v-24">
      <Box>
        <Text size="body-medium-strong">In Session</Text>
      </Box>
      {participants.map((participant) => (
        <Box key={participant?.sid}>
          <Flex py="v-20" px="v-4">
            <Avatar name={participant?.name ?? "Guest"} />
            <Text ml="v-8" size="body-medium">
              {participant?.name ?? "Guest"}
            </Text>
          </Flex>
          <Divider w="100%" />
        </Box>
      ))}
    </Box>
  );
};

export default AttendeeSection;
