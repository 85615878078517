/**
 * MicVolumeLevelHelper is a utility class for monitoring microphone volume levels.
 * It uses AudioWorkletNode from Web Audio API to process the audio stream and
 * calculate the current volume level of an audio input (i.e microphone).
 *
 * This class loads the MicVolumeProcessor module, which processes the audio
 * samples to compute the instantaneous volume level. The computed volume level
 * is then sent back to this class via a message port.
 */

export class MicVolumeLevelHelper {
  context: AudioContext;
  instant: number; // current (i.e 'instantaneous') volume level
  node: AudioWorkletNode;
  mic?: MediaStreamAudioSourceNode;
  moduleLoaded: Promise<void>;

  constructor(context: AudioContext) {
    this.context = context;
    this.instant = 0.0;

    this.moduleLoaded = this.context.audioWorklet
      .addModule("/utils/MicVolumeProcessor.js")
      .then(() => {
        this.node = new AudioWorkletNode(this.context, "MicVolumeProcessor");
        this.node.port.onmessage = (event) => {
          const { instant } = event.data;
          this.instant = instant;
        };
      })
      .catch((error) => {
        this.handleError(new Error(`constructor: ${error.message}`));
      });
  }

  connectToSource(
    stream: MediaStream,
    onError?: (error: Error | null) => void,
  ): void {
    try {
      if (this.context.state === "suspended") {
        this.context.resume().then(() => {
          this._connectStream(stream, onError);
        });
      } else {
        this._connectStream(stream, onError);
      }
    } catch (e) {
      this.handleError(new Error(`connectToSource: ${e.message}`), onError);
    }
  }

  _connectStream(
    stream: MediaStream,
    onError?: (error: Error | null) => void,
  ): void {
    try {
      if (stream.getAudioTracks().length === 0) {
        throw new Error("No audio tracks in the stream");
      }
      this.mic = this.context.createMediaStreamSource(stream);
      this.mic.connect(this.node);
      // necessary to make sample run, but should not be.
      this.node.connect(this.context.destination);
    } catch (e) {
      this.handleError(new Error(`_connectStream: ${e.message}`), onError);
    }
  }

  stop(): void {
    if (this.mic) {
      this.mic.disconnect();
    }
    this.node.disconnect();
  }

  private handleError(
    error: Error,
    onError?: (error: Error | null) => void,
  ): void {
    if (onError) {
      onError(error);
    }
  }
}
