import Router from "next/router";
import { getHomeRoute } from "shared/utils/getHomeRoute";
import { findRouteMatch, findVariablePositions } from "utils/routes";

/**
 * Hook to handle buisnes logic redirects
 */

export const useRedirects = () => {
  const doRouteToInitialTarget = () => {
    if (localStorage.getItem("initialTarget")) {
      const initialTarget = localStorage.getItem("initialTarget");
      const targetAlias = findRouteMatch(initialTarget);
      const variablePositions =
        targetAlias?.as && findVariablePositions(targetAlias?.as);

      const query = {};
      if (targetAlias && variablePositions && !!variablePositions.length) {
        // Sets query for routes with dynamic values
        for (const x of Object.values(variablePositions)) {
          const key = x.key.slice(1);
          const value = initialTarget.split("/").slice()[x.position];
          query[key] = value;
        }

        Router.push(
          {
            pathname: targetAlias.to,
            query,
          },
          initialTarget,
        );
        localStorage.removeItem("initialTarget");
        return true;
      } else if (
        targetAlias &&
        initialTarget !== "/" &&
        initialTarget !== "/sign_in" &&
        initialTarget !== "/sign_up" &&
        initialTarget !== "/forgot_password" &&
        initialTarget !== "/reset_password" &&
        initialTarget !== "/logout" &&
        initialTarget !== "/register" &&
        initialTarget !== "/sso_sign_in" &&
        initialTarget !== "/sso_register"
      ) {
        Router.push(targetAlias.to, initialTarget);
        localStorage.removeItem("initialTarget");
        return true;
      }
      return false;
    }
    return false;
  };

  const doRoutePostSignIn = async (date_of_birth) => {
    if (doRouteToInitialTarget()) {
      return;
    }

    if (localStorage.getItem("fromSignIn") !== "true" && date_of_birth) {
      const memberHomeRoute = getHomeRoute(date_of_birth);
      Router.push(memberHomeRoute.to, memberHomeRoute.as);
      localStorage.removeItem("initialTarget");
    }
  };

  return {
    doRoutePostSignIn,
    doRouteToInitialTarget,
  };
};
