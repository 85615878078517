import React from "react";
import styles from "./styles.module.scss";
import { compose } from "redux";
import { connect } from "react-redux";

import { openModal } from "@spring/smeargle/actions";
import {
  CoachingBenefitsTable,
  MedicationManagerBenefitsTable,
  TherapyBenefitsTable,
} from "./components";
import { isBoeing } from "constants/customers";

// This component conditionally renders the cost transparency table based on which type of provider is currently displayed
const BenefitsTracker = (props) => {
  const {
    coachingVisitsCovered,
    coachingVisitsRemaining,
    coachingVisitsUsed,
    customerName,
    customerId,
    isCoaching,
    isInNetwork,
    isMedicationManager,
    isTherapy,
    internationalCohort,
    specialistVisitsCovered,
    specialistVisitsRemaining,
    specialistVisitsUsed,
    therapyVisitsCovered,
    therapyVisitsRemaining,
    therapyVisitsUsed,
    virtualSessionCostTerm,
    memberCostOfCoaching,
  } = props;

  return (
    <div className={styles.sessionsWrapper}>
      {isCoaching && (
        <CoachingBenefitsTable
          coachingVisitsCovered={coachingVisitsCovered}
          coachingVisitsRemaining={coachingVisitsRemaining}
          coachingVisitsUsed={coachingVisitsUsed}
          customerId={customerId}
          memberCostOfCoaching={memberCostOfCoaching}
          isBoeing={isBoeing(customerId)}
        />
      )}
      {isMedicationManager && (
        <MedicationManagerBenefitsTable
          specialistVisitsCovered={specialistVisitsCovered}
          specialistVisitsRemaining={specialistVisitsRemaining}
          specialistVisitsUsed={specialistVisitsUsed}
          therapyVisitsCovered={therapyVisitsCovered}
          isInNetwork={isInNetwork}
          internationalCohort={internationalCohort}
          virtualSessionCostTerm={virtualSessionCostTerm}
          customerName={customerName}
          isBoeing={isBoeing(customerId)}
        />
      )}
      {isTherapy && (
        <TherapyBenefitsTable
          specialistVisitsCovered={specialistVisitsCovered}
          therapyVisitsCovered={therapyVisitsCovered}
          therapyVisitsRemaining={therapyVisitsRemaining}
          therapyVisitsUsed={therapyVisitsUsed}
          isInNetwork={isInNetwork}
          internationalCohort={internationalCohort}
          virtualSessionCostTerm={virtualSessionCostTerm}
          customerName={customerName}
          isBoeing={isBoeing(customerId)}
        />
      )}
    </div>
  );
};

export default compose(connect(null, { openModal }))(BenefitsTracker);
