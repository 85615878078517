import React, { useEffect, useState } from "react";
import {
  IconButton,
  Button,
  Text,
  FormLabel,
  VTrashIcon,
  VisuallyHiddenInput,
  Link,
  FormErrorMessage,
  FormControl,
  FieldErrors,
  UseFormTrigger,
} from "@springcare/sh-component-library";
import { ErrorMessage } from "@hookform/error-message";
import { useQuery } from "@apollo/client";
import { useTranslation } from "hooks/react-i18next";
import { getDocument } from "operations/queries/member";
import { useToast, SHNotification } from "design-system/components";
import { useDocumentUpload } from "./useDocumentUpload";

const useDocument = (documentId: String) => {
  return useQuery(getDocument, {
    variables: { id: documentId },
    skip: !documentId,
  });
};

type DocumentUploadButtonProps = {
  userId: string;
  documentId: string;
  isDisabled: boolean;
  required?: boolean;
  label: string;
  buttonId: string;
  inputId: string;
  register: (idName, options) => { [key: string]: any };
  trigger?: UseFormTrigger<any>;
  setValue: (id, file) => void;
  setError: (err, options) => void;
  setDocumentUrlObjectKey: (objectKey) => void;
  deactivateFormSubmitButton: (value) => void;
  setShowFileUploadDisclaimer: (value) => void;
  clearErrors: (id) => void;
  errors: FieldErrors<any>;
};

export const DocumentUploadButton = ({
  userId,
  label,
  buttonId,
  inputId,
  register,
  setValue,
  setError,
  clearErrors,
  errors,
  documentId,
  deactivateFormSubmitButton,
  setDocumentUrlObjectKey,
  isDisabled,
  setShowFileUploadDisclaimer,
  required = true,
  trigger,
}: DocumentUploadButtonProps) => {
  const { t } = useTranslation("insurance");
  const [insuranceCardFileText, setInsuranceCardFileText] = useState(null);
  const [insuranceCardFileUrl, setInsuranceCardFileUrl] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const { processFile, uploadStatus, insuranceCardIsLoading } =
    useDocumentUpload(userId, setDocumentUrlObjectKey);

  const {
    data: documentData,
    loading: documentIsLoading,
    refetch: refetchDocument,
  } = useDocument(documentId);

  useEffect(() => {
    if (!documentIsLoading && documentData) {
      setInsuranceCardFileText(documentData?.document?.file_name.slice(0, 20));
    }
  }, [documentData, documentIsLoading, deactivateFormSubmitButton]);

  useEffect(() => {
    if (uploadStatus === "success") {
      setValue(inputId, uploadedFile);
      setInsuranceCardFileText(uploadedFile.name.slice(0, 20));
      setInsuranceCardFileUrl(URL.createObjectURL(uploadedFile));
      deactivateFormSubmitButton(false);
      // retrigger validation
      trigger && trigger();
    } else if (uploadStatus === "failed") {
      setError(inputId, {
        type: "custom",
        message: t("form.errors.cardError"),
      });
      deactivateFormSubmitButton(false);
    }
  }, [
    uploadStatus,
    uploadedFile,
    setValue,
    setError,
    inputId,
    t,
    deactivateFormSubmitButton,
  ]);

  const toast = useToast();
  const createToast = (text: string, status: string) => {
    toast({
      containerStyle: {
        maxWidth: "100%",
        width: "80%",
      },
      render: () => (
        <SHNotification notification={{ text: text, status: status }} />
      ),
    });
  };

  const handleDocumentClick = () => {
    // insuranceCardFileUrl will only have a value if the image
    if (insuranceCardFileUrl) {
      window.open(insuranceCardFileUrl, "_blank");
    } else {
      refetchDocument()
        .then((response) => {
          if (response?.data?.document?.url) {
            window.open(response.data.document.url, "_blank");
          }
        })
        .catch((_error) => {
          createToast(t("form.errors.somethingWentWrong"), "error");
        });
    }
  };

  const fileTypeAudit = (files) => {
    const file = files[0];
    const acceptableFileTypes = [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ];

    if (acceptableFileTypes.includes(file?.type)) {
      setFile(file);
    } else {
      setError(inputId, {
        type: "custom",
        message: t("form.errors.invalidFileType"),
      });
    }
  };

  const setFile = (file) => {
    clearErrors(inputId);
    deactivateFormSubmitButton(true);
    setShowFileUploadDisclaimer(true);
    setUploadedFile(file);
    deactivateFormSubmitButton(true);
    processFile(file);
  };

  const clearFile = () => {
    clearErrors(inputId);
    setValue(inputId, null);
    setInsuranceCardFileText(null);
    setInsuranceCardFileUrl(null);
    setUploadedFile(null);
    processFile(null);
    trigger();
  };

  return (
    <FormControl isInvalid={Boolean(errors)}>
      <FormLabel fontWeight="normal" id={inputId + "Label"}>
        {label}
        {required && (
          <Text
            as="span"
            role="presentation"
            aria-hidden="true"
            style={{ color: "#c44848" }}
          >
            {" "}
            &#42;
          </Text>
        )}
      </FormLabel>
      {!insuranceCardFileText && (
        <Button
          variant="medium-emphasis"
          id={buttonId}
          isLoading={insuranceCardIsLoading || documentIsLoading}
          onClick={() => document.getElementById(inputId).click()}
          width={{ base: "100%", md: "auto", lg: "auto" }}
          isDisabled={isDisabled}
        >
          {t("form.selectImageButton")}
        </Button>
      )}
      <FormLabel
        visibility={!insuranceCardFileText ? "hidden" : "visible"}
        display={insuranceCardFileText ? "inline" : "none"}
        height={"50px"}
        position="relative"
        paddingY="2rem"
        whiteSpace="nowrap"
        zIndex={0}
      >
        <Link
          paddingLeft={0}
          onClick={handleDocumentClick}
          // This href fixes keyboard navigation to this element
          href="#insuranceForm"
          fontWeight={600}
          isDisabled={isDisabled}
          /*
            NOTE: this is a design debt item as Verdant disabled links actually
            fail the a11y color contrast test by quite a bit
            - currently using the same color but the opacity raised up until it hits the bare minimum contrast
          */
          {...(isDisabled && { color: "#01141994" })}
        >
          {insuranceCardFileText}
        </Link>
        <VisuallyHiddenInput
          tabIndex={-1}
          id={inputId}
          name={inputId}
          padding={0}
          type="file"
          accept={"application/pdf, image/jpeg, image/jpg, image/png"}
          width="1px"
          height="50px"
          _before={{
            border: "1px solid #999",
            borderRadius: "3px",
            padding: "5px 8px",
            outline: "none",
            whiteSpace: "nowrap",
            cursor: "pointer",
            textShadow: "1px 1px #fff",
            fontWeight: "700",
            fontSize: "10px",
          }}
          {...register(inputId, {
            validate: (value) => {
              const isFileSet = insuranceCardFileText;
              const isValid = value instanceof FileList ? value?.length : value;
              return required
                ? Boolean(isValid || isFileSet) || t("form.errors.cardError")
                : true;
            },
          })}
          onChange={(e) => fileTypeAudit(e.target.files)}
          aria-label={"select-file-button"}
        />
        <IconButton
          type="button"
          aria-label={t("a11y.removeImageButtonLabel")}
          icon={VTrashIcon}
          variant="no-emphasis"
          colorScheme="negative"
          isDisabled={isDisabled}
          id={inputId + "RemovalButton"}
          onClick={clearFile}
        />
      </FormLabel>
      <ErrorMessage
        errors={errors}
        name={inputId}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  );
};
