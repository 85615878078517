// TODO: remove this eslint-disable when ready
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { TRACK_EVENT } from "utils/mixpanel";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

import { getAppointmentSlotsV2 } from "operations/queries/appointment";
import {
  modalIds,
  AppointmentKind,
  AppointmentMedium,
  AppointmentAction,
  ProviderRole,
  getAppointmentKindByProviderRole,
  getProviderRoleForTracking,
} from "@spring/constants";
import { setField, openModal } from "@spring/smeargle/actions"; // TODO: Remove

import { OnsiteBadge } from "components";
import { Box, Heading, Flex, Spinner, Show } from "design-system/components";
import { DesktopSlots } from "./DesktopSlots";
import { MobileSlots } from "./MobileSlots";
import {
  trackAppointmentSlotSelected,
  trackMoreTimesClicked,
  trackProviderCardClicked,
} from "components/templates/Browse/ProviderBrowsePage/analytics";

import { TherapyTypeOffered } from "./TherapyTypeOffered";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import useHighmarkExperience from "hooks/useHighmarkExperience";

type Props = {
  provider: any;
  providerIndex: number;
  pageNumber: number;
  queryRequestId: string;
  setField: any;
  openModal: any;
  showGlobalExperience: boolean;
};

const AppointmentSection = ({
  provider,
  providerIndex,
  pageNumber,
  queryRequestId,
  openModal,
  setField,
  showGlobalExperience,
}: Props) => {
  const {
    global,
    memberInfo,
    queriedFilters,
    memberCanSchedule,
    providerCardClick,
    setProviderCardClick,
    scheduleModalWithPropsV2,
    providerType,
  } = useProviderBrowseContext();
  const shouldShowDynamicLeadTime =
    useFeatureFlag(FLAGS.DYNAMIC_LEAD_TIME_RELEASE) &&
    provider?.in_house_availability_source_of_truth_at;

  const shouldShowNewInsuranceModal = useFeatureFlag(FLAGS.NEW_INSURANCE_MODAL);
  const { isHighmark } = useHighmarkExperience();

  const { t } = useTranslation("careProvider");
  const [tabIndex, setTabIndex] = useState(0);
  const [videoAppointments, setVideoAppointments] = useState([]);
  const [inPersonAppointments, setInPersonAppointments] = useState([]);
  const [nextAvailableAppointment, setNextAvailableAppointment] = useState<any>(
    [],
  );
  const [skip, setSkip] = useState(true);
  const couplesTherapyFlag = useFeatureFlag(FLAGS.ENABLE_COUPLES_THERAPY);

  const isMinor = memberInfo?.user?.member?.minor;
  const showCouplesTherapy = couplesTherapyFlag && !isMinor;

  const appointmentMedium = provider.supported_appointment_mediums[0];

  const appointmentKind = () => {
    const therapyBasedOnAge = isMinor
      ? AppointmentKind.MinorTherapy
      : AppointmentKind.Therapy;
    const medManagementType = AppointmentKind.InitialMedicationManagement;

    switch (providerType) {
      case ProviderRole.Therapist:
        return therapyBasedOnAge;
      case ProviderRole.MedicationManager:
        return medManagementType;
      case ProviderRole.Coach:
        return AppointmentKind.Coaching;
    }
  };

  const formKey = `care-provider-schedule-${provider.id}`;

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const setInitialStartTimeField = (initialStartTime, appointmentMedium) => {
    setField(
      formKey,
      "initialStartTime",
      {
        selectedDateTime: initialStartTime,
        selectedMedium: appointmentMedium,
      },
      true, // sets dirty
    );
  };

  const handleChange = (dateTime, appointmentMedium) => {
    // Updates the selected dateTime on the Modal UI (highlighted slot / button text)
    setField(
      formKey,
      "startTime",
      {
        selectedDateTime: dateTime,
        selectedMedium: appointmentMedium,
      },
      true, // sets dirty
    );
  };

  const setStartTimeToModal = (time, medium) => {
    const dateTime = DateTime.fromISO(time).setLocale(global.lang);
    const localTime = dateTime.toLocal();
    const localIso = localTime.toISO();
    const initialStartTime = dateTime
      .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
      .toISO();

    setInitialStartTimeField(initialStartTime, medium);
    handleChange(localIso, medium);
  };

  const modalId = () => {
    // basic + specialist + sponsored
    const totalTherapySessionsRemaining =
      memberInfo?.user?.member?.visits_remaining_total || 0;
    // displays insurance modal if the user has 1 or fewer sessions remaining and is not HighMark
    if (
      !memberCanSchedule &&
      totalTherapySessionsRemaining <= 1 &&
      !isHighmark
    ) {
      // TELEMETRY
      TRACK_EVENT.MODAL_OPENED(
        window.location.pathname,
        "Profile Settings and Insurance",
        {
          provider_role: ProviderRole.Therapist,
        },
      );
      return modalIds.beforeYouScheduleModal;
    }

    return modalIds.careProviderScheduleModal;
  };

  const openScheduleModal = (provider, index, pageNumber) => {
    if (shouldShowNewInsuranceModal && !isHighmark) {
      scheduleModalWithPropsV2.onOpen({
        provider,
        kind: appointmentKind(),
        buttonText:
          providerType === ProviderRole.Therapist
            ? t("listings.scheduleTherapyButtonText")
            : providerType === ProviderRole.MedicationManager
              ? t("listings.scheduleAppointmentButtonText")
              : t("listings.scheduleCoachButtonText"),
        action: AppointmentAction.Create,
        dataCy: "schedule-provider-modal",
        providerOrder: index,
        queryRequestId: queryRequestId,
        pageNumber: pageNumber,
        defaultTab: tabIndex,
      });
    } else {
      openModal(modalId(), {
        ...provider,
        providerRole: getProviderRoleForTracking(providerType),
        kind: appointmentKind(),
        medium: appointmentMedium,
        buttonText:
          providerType === ProviderRole.Therapist
            ? t("listings.scheduleTherapyButtonText")
            : providerType === ProviderRole.MedicationManager
              ? t("listings.scheduleAppointmentButtonText")
              : t("listings.scheduleCoachButtonText"),
        action: AppointmentAction.Create,
        dataCy: "schedule-provider-modal",
        providerOrder: index,
        queryRequestId: queryRequestId,
        isMinor,
        pageNumber: pageNumber,
        defaultTab: tabIndex,
      });
    }
  };

  const handleSelectedSlot = (index, dateTime, appointmentMedium) => {
    const appointmentKind = getAppointmentKindByProviderRole(providerType);
    const trackingData = {
      index,
      dateTime,
      appointmentMedium,
      provider,
      pageNumber,
      providerIndex,
      queryRequestId,
      appointmentKind,
    };

    trackAppointmentSlotSelected(trackingData);
    setStartTimeToModal(dateTime, appointmentMedium);
    openScheduleModal(provider, providerIndex, pageNumber);
  };

  const trackingProviderCardClicked = () => {
    const appointmentKind = getAppointmentKindByProviderRole(providerType);
    const trackingData = {
      provider,
      providerIndex,
      pageNumber,
      queryRequestId,
      appointmentKind,
    };

    trackProviderCardClicked(trackingData);
  };

  const providerCardClicked = () => {
    trackingProviderCardClicked();
    setInitialStartTimeField(
      nextAvailableAppointment?.appointment_slot,
      nextAvailableAppointment?.medium,
    );
    handleChange(null, nextAvailableAppointment?.medium);
    openScheduleModal(provider, providerIndex, pageNumber);
  };

  const seeMoreTimes = () => {
    const trackingData = {
      ...nextAvailableAppointment?.medium,
      provider,
      providerIndex,
      pageNumber,
      queryRequestId,
    };

    trackMoreTimesClicked(trackingData);
    setInitialStartTimeField(
      nextAvailableAppointment?.appointment_slot,
      nextAvailableAppointment?.medium,
    );
    handleChange(null, nextAvailableAppointment?.medium);
    openScheduleModal(provider, providerIndex, pageNumber);
  };

  // @ts-ignore
  const memberId = memberInfo?.user?.id;
  const providerId = provider?.user_id;
  let start_span;

  if (shouldShowDynamicLeadTime) {
    start_span = DateTime.local()
      .plus({ hours: 3 })
      .set({ minutes: 0, seconds: 0, milliseconds: 0 })
      .toISO();
  } else {
    start_span = DateTime.local()
      .plus({ hours: 25 })
      .set({ minutes: 0, seconds: 0, milliseconds: 0 })
      .toISO();
  }
  const end_span = DateTime.fromISO(start_span)
    .plus({ days: 21 })
    .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 })
    .toISO();

  useEffect(() => {
    if (memberInfo) {
      setSkip(false);
    }
  }, [memberInfo]);

  useEffect(() => {
    if (nextAvailableAppointment) {
      const providerCardClickCopy = { ...providerCardClick };
      const providerClicks = {
        onClick: providerCardClicked,
        tracking: trackingProviderCardClicked,
      };
      providerCardClickCopy[provider.id] = providerClicks;

      setProviderCardClick({
        ...providerCardClick,
        ...providerCardClickCopy,
      });
    }
  }, [nextAvailableAppointment]);

  const payload = {
    upcoming_slots_count: 3,
    user_ids: {
      member_id: memberId,
      care_provider_id: providerId,
    },
    kind: appointmentKind(),
    upcoming_slots_only: true,
    range: {
      start_span: start_span,
      end_span: end_span,
    },
    filters: {
      days_of_week: queriedFilters?.filters?.days_of_week,
      time_of_day: queriedFilters?.filters?.time_of_day,
    },
  };

  const { data: appointmentSlotsData, loading: appointmentSlotsLoading } =
    useQuery(getAppointmentSlotsV2, {
      variables: payload,
      skip: skip,
      fetchPolicy: "network-only",
      onCompleted: () => {
        const isVirtualSupported = () => provider.virtual_supported;
        const isNextAvailableMedium = () =>
          !!appointmentSlotsData?.appointment_slots_v2
            ?.next_available_appointment?.medium;
        const isFilterInPerson = () =>
          queriedFilters?.filters?.mediums?.includes("In-Person");
        const isFilterVirtual = () =>
          queriedFilters?.filters?.mediums?.includes("Virtual");
        const isNextAvailableMediumVideo = () =>
          appointmentSlotsData?.appointment_slots_v2?.next_available_appointment
            ?.medium === AppointmentMedium.Video;

        if (
          (!isNextAvailableMedium() && !isFilterInPerson()) ||
          !isVirtualSupported()
        ) {
          setTabIndex(0);
        } else if (isFilterInPerson() && !isFilterVirtual()) {
          setTabIndex(1);
        } else {
          setTabIndex(isNextAvailableMediumVideo() ? 0 : 1);
        }
        setVideoAppointments(
          appointmentSlotsData?.appointment_slots_v2?.video?.available,
        );
        setInPersonAppointments(
          appointmentSlotsData?.appointment_slots_v2?.in_person?.available,
        );
        setNextAvailableAppointment(
          appointmentSlotsData?.appointment_slots_v2
            ?.next_available_appointment,
        );
      },
    });

  const hasVideoAppointments = videoAppointments?.length > 0;
  const hasInPersonAppointments = inPersonAppointments?.length > 0;

  return (
    <Flex justifyContent="space-between" flexDirection="column" py={16}>
      {appointmentSlotsLoading ? (
        <Flex align="center" justify="center" h="436px">
          {/* h=436 (default h of card, minus default padding) */}
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="primary.base"
            size="xl"
          />
        </Flex>
      ) : (
        <>
          <Box py={24}>
            <Heading variant="sm_v1">{t("schedule")}</Heading>
            {showCouplesTherapy && provider.roles.includes("Therapist") && (
              <TherapyTypeOffered
                careProviderTags={provider.care_provider_tags}
              />
            )}
            {provider?.on_site && (
              <Box pt={5}>
                <OnsiteBadge />
              </Box>
            )}
          </Box>
          <Show above="sm">
            <DesktopSlots
              {...{
                videoAppointments,
                inPersonAppointments,
                tabIndex,
                handleTabsChange,
                handleSelectedSlot,
                provider,
                providerIndex,
                pageNumber,
                queryRequestId,
                seeMoreTimes,
                showGlobalExperience,
              }}
            />
          </Show>
          <Show below="sm">
            <MobileSlots
              {...{
                videoAppointments,
                inPersonAppointments,
                provider,
                providerIndex,
                pageNumber,
                queryRequestId,
                hasVideoAppointments,
                hasInPersonAppointments,
                setInitialStartTimeField,
                handleChange,
                openScheduleModal,
                isFilterInPerson:
                  queriedFilters?.filters?.mediums?.includes("In-Person"),
              }}
            />
          </Show>
        </>
      )}
    </Flex>
  );
};

// export default AppointmentSection;
const mapStateToProps = ({ global: { showGlobalExperience } }) => ({
  showGlobalExperience,
});
export default connect(mapStateToProps, { setField, openModal })(
  AppointmentSection,
);
