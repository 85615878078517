import { MouseEventHandler } from "react";
import { useLocalParticipant } from "@livekit/components-react";
import {
  IconButton,
  Tooltip,
  VMicOffIcon,
  VMicOnIcon,
  VPresentIcon,
  VSmsIcon,
  VVideoChatIcon,
  VVideoOffIcon,
  VIndividualIcon,
} from "@springcare/sh-component-library";
import { ToggleButtonTypes } from "components/templates/SHSessionRoom/types";

import { useSessionRoomDrawer } from "context/SessionRoomContext";

interface ToggleButtonProps {
  type: ToggleButtonTypes;
}

export const ToggleButton = ({ type }: ToggleButtonProps) => {
  const {
    localParticipant,
    isCameraEnabled,
    isMicrophoneEnabled,
    isScreenShareEnabled,
  } = useLocalParticipant();

  const {
    isSessionRoomDrawerOpen,
    setIsSessionRoomDrawerOpen,
    setActiveDrawerTab,
  } = useSessionRoomDrawer();

  let icon: object,
    toggleFunction: MouseEventHandler<HTMLButtonElement> | undefined,
    ariaLabel: string;
  let tooltipLabel = "Toggle button";

  switch (type) {
    case ToggleButtonTypes.Microphone:
      icon = isMicrophoneEnabled ? VMicOnIcon : VMicOffIcon;
      toggleFunction = () => {
        localParticipant.setMicrophoneEnabled(!isMicrophoneEnabled);
      };
      ariaLabel = isMicrophoneEnabled
        ? "Turn microphone off"
        : "Turn microphone on";
      tooltipLabel = "Audio";
      break;
    case ToggleButtonTypes.Camera:
      icon = isCameraEnabled ? VVideoChatIcon : VVideoOffIcon;
      toggleFunction = () => {
        localParticipant.setCameraEnabled(!isCameraEnabled);
      };
      ariaLabel = isCameraEnabled ? "Turn camera off" : "Turn camera on";
      tooltipLabel = "Video";
      break;
    case ToggleButtonTypes.Chat:
      icon = VSmsIcon;
      toggleFunction = () => {
        setIsSessionRoomDrawerOpen(!isSessionRoomDrawerOpen);
        setActiveDrawerTab("chat");
      };
      ariaLabel = "open/close chat";
      tooltipLabel = "Chat";
      break;
    case ToggleButtonTypes.Participants:
      icon = VIndividualIcon;
      toggleFunction = () => {
        setIsSessionRoomDrawerOpen(!isSessionRoomDrawerOpen);
        setActiveDrawerTab("participants");
      };
      ariaLabel = "open/close participant list";
      tooltipLabel = "Participants";
      break;
    case "screensharing":
      icon = VPresentIcon;
      toggleFunction = () => {
        localParticipant.setScreenShareEnabled(!isScreenShareEnabled);
      };
      ariaLabel = isScreenShareEnabled
        ? "Stop screen sharing"
        : "Start screen sharing";
      tooltipLabel = "Screensharing";
      break;
  }

  return (
    <Tooltip label={tooltipLabel} aria-label={tooltipLabel}>
      <IconButton
        icon={icon}
        aria-label={ariaLabel}
        aria-live="polite"
        variant="medium-emphasis"
        bg="white"
        color="black"
        onClick={toggleFunction}
      />
    </Tooltip>
  );
};

ToggleButton.displayName = "ToggleButton";
