//

import PropTypes from "prop-types";
import React from "react";
import { useWordPressContent } from "@spring/hooks";
import {
  LoadingCircle,
  DangerousHtml,
  Stout,
  FlexRow,
  Icon,
  Subtitle,
  Bolded,
  Underlined,
} from "@spring/smeargle";
import { isLoading, isLoaded, hasException } from "@spring/immutability";

import { Link } from "components/atoms";

import styles from "./styles.module.scss";

const PsychCategory = ({ name, description, id, slug }) => {
  const { data, status } = useWordPressContent(
    `/media?slug=category-image-${id}`,
  );
  const mediaData = data && data[0];

  return (
    <>
      {isLoaded(status) && (
        <div className={styles.categoryContainer}>
          <Link
            ariaLabel={`${name}. ${description}`}
            alias="MemberLearningCenterArticles"
            name={name}
            queries={{ category_slug: slug }}
            dataCy={`${name}-article`}
          >
            <div
              className={styles.contentContainer}
              style={{ backgroundImage: `url(${mediaData.source_url})` }}
            >
              <div className={styles.descriptionContainer}>
                <div className={styles.description}>
                  <DangerousHtml>{description}</DangerousHtml>
                </div>
              </div>
            </div>
            <FlexRow alignment="center">
              <Stout>
                <Underlined>
                  <div className={styles.categoryTitle}>{name}</div>
                </Underlined>
              </Stout>
              <div className={styles.icon}>
                <Icon type="arrow-right" />
              </div>
            </FlexRow>
          </Link>
        </div>
      )}
    </>
  );
};

PsychCategory.propTypes = {
  description: PropTypes.any,
  id: PropTypes.any,
  name: PropTypes.any,
  slug: PropTypes.any,
};

const PsychCategories = () => {
  const { data: categoryData, status } = useWordPressContent("/categories");
  const filteredData =
    categoryData &&
    categoryData.filter((category) => category.name !== "Uncategorized");

  return (
    <>
      {isLoading(status) && <LoadingCircle />}
      {isLoaded(status) && (
        <div className={styles.container}>
          {filteredData.map((category) => (
            <PsychCategory
              key={category.id}
              id={category.id}
              name={category.name}
              description={category.description}
              slug={category.slug}
            />
          ))}
        </div>
      )}
      {hasException(status) && (
        <Bolded>
          <Subtitle>
            Couldn't get categories. Please refresh and try again.
          </Subtitle>
        </Bolded>
      )}
    </>
  );
};

export default PsychCategories;
