import { gql } from "@apollo/client";

export const getMemberBalance = gql`
  query getMemberBalance($member_id: ID!) {
    member_balance(member_id: $member_id) {
      balance
      lookup_code
      apero_payment_url
      latest_statement_url
    }
  }
`;
