import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Router from "next/router";
import { TRACK_EVENT } from "utils/mixpanel";

import {
  Box,
  VHelpIcon,
  Text,
  ActionChip,
} from "@springcare/sh-component-library";

const HelpButton = ({ setOnOpen }) => {
  const { t } = useTranslation("limitedLangMemberHelpCard");

  const openHelpFlyout = () => {
    setOnOpen();
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Help Button Clicked", {});
  };

  return (
    <Box position="relative">
      <ActionChip
        variant="low-emphasis"
        colorScheme="base"
        icon={VHelpIcon}
        onClick={openHelpFlyout}
        h="full"
        data-cy={"help-button"}
      >
        <Text size="body-medium-strong">{t(["helpButtonText", "Help"])}</Text>
      </ActionChip>
    </Box>
  );
};

HelpButton.propTypes = {
  setOnOpen: PropTypes.func,
};

export default HelpButton;
