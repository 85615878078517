import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "design-system/components";

/**
 * Matches the following variations with spaces/dashes:
 * 2345678900
 * 12345678900
 * (234) 567 8900
 * +1 (234) 567 8900
 */
const phoneRegExp = new RegExp(
  /^(\+?\d{1,2})?[\s-]?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}$/,
);

const mfaPhoneNumberFormSchema = yup.object().shape({
  phone: yup
    .string()
    .trim()
    .matches(phoneRegExp, "A valid phone number is required")
    .required(),
});

const MfaPhoneNumberForm = ({ onSubmit }) => {
  const { t } = useTranslation("limitedLangSettings", {
    keyPrefix: "multiFactorAuthentication",
  });

  const fieldName = "phone";
  const { formState, getValues, handleSubmit, register, watch } = useForm({
    defaultValues: { [fieldName]: "" },
    mode: "onTouched",
    resolver: yupResolver(mfaPhoneNumberFormSchema),
  });
  const { errors, isSubmitting } = formState;
  const isInvalidInput = !phoneRegExp.test(watch(fieldName));

  return (
    <>
      {/* eslint-disable-next-line no-unused-vars */}
      <form
        onSubmit={handleSubmit((data, _event) => onSubmit(data))}
        aria-label="mfa phone number form"
      >
        <FormControl isInvalid={!!errors?.[fieldName]} isRequired>
          <FormLabel>{t("submitPhoneLabel")}</FormLabel>
          <Flex gap={24}>
            <Box>
              <Input
                {...register(fieldName)}
                aria-label="phone input"
                data-testid="mfa-phone-form-input"
                type="tel"
                placeholder="(123) 456-7890"
                _placeholder={{ opacity: 0.7 }}
                colorScheme={errors?.[fieldName] ? "error" : "platform"}
                height={40}
              />
              {errors?.[fieldName] && (
                <FormErrorMessage
                  aria-label="phone input error"
                  color="error.base"
                  fontWeight={700}
                >
                  {errors[fieldName].message}
                </FormErrorMessage>
              )}
            </Box>
            <Button
              type="submit"
              variant="solid"
              colorScheme="primary"
              h={56}
              isLoading={isSubmitting}
              isDisabled={isInvalidInput}
            >
              {t("submitPhoneButton")}
            </Button>
          </Flex>
        </FormControl>
      </form>
      <Text textStyle="body1" mt={4}>
        {t("resendVerificationText")}
        <Button
          data-testid="mfa-phone-form-resend"
          variant="link"
          color="primary.base"
          onClick={() => onSubmit(getValues())}
          isDisabled={isInvalidInput}
        >
          {t("resendVerificationButton")}
        </Button>
      </Text>
    </>
  );
};

MfaPhoneNumberForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default MfaPhoneNumberForm;
