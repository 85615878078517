import React, { useState } from "react";

import {
  Box,
  ChevronUpIcon,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
} from "@springcare/sh-component-library";

import { DeviceTypes } from "components/templates/SHSessionRoom/types";
import { DeviceMenuOptionGroup } from "components/templates/SHSessionRoom/components/VideoCallControls/DeviceMenuOptionGroup";

interface DeviceMenuChevronProps {
  types: DeviceTypes[];
}

export const DeviceMenuChevron = ({ types }: DeviceMenuChevronProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu>
      <MenuButton
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls={`${types}-device-menu`}
        aria-label={`Toggle device options for ${types}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Box pe="v-12">
          <ChevronUpIcon color={"white"} />
        </Box>
      </MenuButton>
      <MenuList>
        {types.map((type, index) => (
          <>
            <DeviceMenuOptionGroup type={type} />
            {index < types.length - 1 && <MenuDivider />}
          </>
        ))}
      </MenuList>
    </Menu>
  );
};

DeviceMenuChevron.displayName = "DeviceMenuChevron";
