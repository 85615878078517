import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { addNotification } from "@spring/smeargle/actions";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  SHStickyFooter,
} from "design-system/components";
import { RightArrow } from "design-system/assets";
import { VerifyPinForm } from "components";
import { mfaCreateAccessToken, mfaSignIn, signIn } from "actions/auth";
import { useRegisterContext } from "context/RegisterContext";
import { trackErrorMsgViewed } from "components/templates/RegisterPage/analytics";
import { useCheckSsoReEnablement } from "hooks";

const MfaSignInForm = ({
  addNotification,
  mfaLoginData,
  initiateMfaLogin,
  routeAlias,
}) => {
  const { t } = useTranslation("limitedLangSettings", {
    keyPrefix: "multiFactorAuthentication",
  });
  const [isMfaBackupCodeForm, setIsMfaBackupCodeForm] = useState(false);
  const [mfaBackUpToken, setMfaBackUpToken] = useState(null);
  const router = useRouter();
  const { checkSsoReEnablement } = useCheckSsoReEnablement();
  const { setNotification } = useRegisterContext();
  // The new registration flow has some different styles and error-handling methods
  // TODO: Might be able to clean this up?
  const isNewRegistrationFlow = /\/sign_in/.test(router?.pathname);

  useEffect(() => {
    // Hitting /access_token will gracefully error, we catch the error for the mfa_token
    if (!mfaBackUpToken && isMfaBackupCodeForm) {
      const { userEmail, userPassword } = mfaLoginData;
      signIn(userEmail, userPassword, null, null, true, {
        mfa_backup_codes: true,
      }).catch((response) => {
        setMfaBackUpToken(response.error_data.mfa_token);
      });
    }
  }, [mfaBackUpToken, isMfaBackupCodeForm]);

  const handleOnSubmit = async (values) => {
    const { code } = values;
    const { userEmail, userPassword, mfaToken } = mfaLoginData;
    const tokenToUseForMfa = isMfaBackupCodeForm ? mfaBackUpToken : mfaToken;

    try {
      await mfaSignIn(
        userEmail,
        userPassword,
        code,
        tokenToUseForMfa,
        routeAlias,
      );
      checkSsoReEnablement(userPassword);
    } catch (err) {
      if (isNewRegistrationFlow) {
        trackErrorMsgViewed("Login");
        setNotification({
          text: "We had a problem signing in",
          status: "error",
        });
      } else {
        if (/incorrect token code provided/i.test(err?.error_description)) {
          return addNotification(
            "Invalid PIN provided. Please try again.",
            "error",
          );
        }

        if (/no mfa device found/i.test(err?.error_description)) {
          return addNotification(
            "Expired PIN provided. Please refresh and try again.",
            "error",
          );
        }

        if (/missing a required parameter/i.test(err?.error_description)) {
          return addNotification(
            "Something went wrong. Please refresh and try again.",
            "error",
          );
        }

        const errorMessage =
          "We had a problem signing in" ||
          err.errors?.[0] ||
          err.error_description ||
          err.message ||
          t("notifications.genericError");
        addNotification(errorMessage, "error");
      }
    }
  };

  const resendAuthorizationCode = async () => {
    const { userEmail, userPassword } = mfaLoginData;

    try {
      await signIn(userEmail, userPassword, null, null, true);
    } catch (err) {
      if (err?.error === "mfa_authentication_required" && err?.error_data) {
        const mfaToken = err.error_data.mfa_token;
        const mfaTypeId = err.error_data.mfa_type_id;

        return mfaCreateAccessToken(mfaToken, () =>
          initiateMfaLogin(
            { email: userEmail, password: userPassword },
            mfaToken,
            mfaTypeId,
          ),
        ).catch((err) => addNotification(err.error_description, "error"));
      }

      if (isNewRegistrationFlow) {
        trackErrorMsgViewed("Login");
        setNotification({
          text: "We had a problem re-sending an authorization code",
          status: "error",
        });
      } else {
        const errorMessage =
          err.errors?.[0] ||
          err.error_description ||
          err.message ||
          t("notifications.genericError");
        addNotification(errorMessage, "error");
      }
    }
  };

  const toggleMfaBackupCodeRequest = async () => {
    setIsMfaBackupCodeForm(!isMfaBackupCodeForm);
  };

  const mfaAuthCodeForm = (isNewRegistrationFlow) => (
    <>
      <Box w={["80%", "100%", "80%"]}>
        <Text variant="body1" color="platform.base" mb={24}>
          {mfaLoginData.mfaType === "phone"
            ? t("signInMfaSubtitlePhone")
            : t("signInMfaSubtitleApp")}
        </Text>
      </Box>
      <VerifyPinForm
        flexDirection="column"
        pinInputWidth={56}
        formLabel={t("signInMfaFormLabel")}
        formSubmit={t("signInMfaFormSubmit")}
        icon={<RightArrow />}
        onSubmit={handleOnSubmit}
        withStickyFooterButton={isNewRegistrationFlow}
      >
        {/* eslint-disable-next-line react/display-name */}
        {({ isLoading, isDisabled }) => (
          <SHStickyFooter width={["100vw", "100vw", "50vw", "50vw", "50vw"]}>
            <Button
              data-cy="mfa-confirm-button"
              type="submit"
              variant="solid"
              colorScheme="primary"
              h={56}
              w="80%"
              mb={28}
              rightIcon={<RightArrow />}
              isLoading={isLoading}
              isDisabled={isDisabled}
            >
              {t("signInMfaFormSubmit")}
            </Button>
            <Flex width="80%">
              <Button
                variant="link"
                colorScheme="primary"
                pb={54}
                mx="auto"
                onClick={toggleMfaBackupCodeRequest}
                data-cy="login-submit"
              >
                {t("signInMfaLostPhone")}
              </Button>
            </Flex>
          </SHStickyFooter>
        )}
      </VerifyPinForm>

      {/* Text immediately under the PIN input */}
      {isNewRegistrationFlow ? (
        <>
          {mfaLoginData.mfaType === "phone" && (
            <Box w="80%" data-testid="mfa-resend-pin" mt={4}>
              <Text variant="body2">
                {t("signInMfaResendHint")}
                <Button
                  data-cy="mfa-resend-code"
                  colorScheme="primary"
                  variant="link"
                  ms={4}
                  onClick={resendAuthorizationCode}
                >
                  <Text variant="body2">{t("signInMfaResendLink")}</Text>
                </Button>
              </Text>
            </Box>
          )}
        </>
      ) : (
        <>
          {mfaLoginData.mfaType === "phone" && (
            <Box data-testid="mfa-resend-pin" mt={16} width={["80%", 390]}>
              <Text variant="body1">{t("signInMfaResendHint")}</Text>
              <Text variant="body1">
                {t("signInMfaResendQuery")}
                <Button
                  colorScheme="primary"
                  variant="link"
                  ms={8}
                  onClick={resendAuthorizationCode}
                >
                  {t("signInMfaResendLink")}
                </Button>
              </Text>
            </Box>
          )}
          <Box mt={16} width={["80%", 390]}>
            <Text variant="body1">{t("signInMfaBackupCodeHint")}</Text>
            <Text variant="body1">
              <Button
                colorScheme="primary"
                variant="link"
                onClick={toggleMfaBackupCodeRequest}
              >
                {t("signInMfaBackupCodeUse")}
              </Button>
            </Text>
          </Box>
        </>
      )}
    </>
  );

  const mfaBackupCodeForm = (isNewRegistrationFlow) => (
    <>
      <Box w="80%">
        <Text variant="body1" color="platform.base" mb={24}>
          {t("signInMfaAlert")}
        </Text>
      </Box>
      <VerifyPinForm
        flexDirection="column"
        pinInputWidth={56}
        formLabel={t("signInMfaFormBackup")}
        formSubmit={t("signInMfaFormSubmit")}
        icon={<RightArrow />}
        onSubmit={handleOnSubmit}
        isDisabled={!mfaBackUpToken}
        withStickyFooterButton={isNewRegistrationFlow}
      >
        {/* eslint-disable-next-line react/display-name */}
        {({ isLoading, isDisabled }) => (
          <SHStickyFooter width={["100vw", "100vw", "50vw", "50vw", "50vw"]}>
            <Button
              data-cy="mfa-confirm-button2"
              type="submit"
              variant="solid"
              colorScheme="primary"
              h={56}
              w="80%"
              mb={54}
              rightIcon={<RightArrow />}
              isLoading={isLoading}
              isDisabled={isDisabled}
            >
              {t("signInMfaFormSubmit")}
            </Button>
          </SHStickyFooter>
        )}
      </VerifyPinForm>

      {/* Text immediately under the PIN input */}
      {isNewRegistrationFlow ? (
        <Box w="80%" mt={4}>
          <Text variant="body2">
            {t("signInMfaBackToAuthText")}
            <Button
              colorScheme="primary"
              variant="link"
              ms={4}
              onClick={toggleMfaBackupCodeRequest}
            >
              <Text variant="body2">{t("signInMfaBackToAuthClick")}</Text>
            </Button>
          </Text>
        </Box>
      ) : (
        <Box w="80%" mt={16} width={380}>
          <Text variant="body1">{t("signInMfaBackToAuthText")}</Text>
          <Text variant="body1">
            <Button
              colorScheme="primary"
              variant="link"
              onClick={toggleMfaBackupCodeRequest}
            >
              {t("signInMfaBackToAuthClick")}
            </Button>
          </Text>
        </Box>
      )}
    </>
  );

  return (
    <Box data-testid="mfa-sign-in-form">
      <Flex flexDirection="column" alignItems="center">
        <Box w={isNewRegistrationFlow && "80%"}>
          <Heading variant="lg" as="h1" mb={8}>
            {t("signInMfaTitle")}
          </Heading>
        </Box>
        {isMfaBackupCodeForm
          ? mfaBackupCodeForm(isNewRegistrationFlow)
          : mfaAuthCodeForm(isNewRegistrationFlow)}
      </Flex>
    </Box>
  );
};

MfaSignInForm.propTypes = {
  addNotification: PropTypes.func,
  mfaLoginData: PropTypes.shape({
    mfaToken: PropTypes.string.isRequired,
    mfaType: PropTypes.oneOf(["phone", "app"]),
    userEmail: PropTypes.string.isRequired,
    userPassword: PropTypes.string.isRequired,
  }).isRequired,
};

export { MfaSignInForm };
export default connect(null, { addNotification })(MfaSignInForm);
