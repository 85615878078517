import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";

export const trackProviderFeedbackSent = (rating: number, comment: string) => {
  TRACK_EVENT.FORM_SUBMITTED(
    routes.Session.as,
    "Livekit Session Provider Feedback",
    {
      rating,
      comment,
    },
  );
};
