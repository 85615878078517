/**
 * NOTE: @param are props
 * @param {string}   lang          User's current language, this comes from either the state.global object of Redux, or coveredLife.country
 * @param {string}   setLang       Changes the current language

 * NOTE: This component has not been modified to be customizable yet. It is likely that
   it should be in the near future but requirements are not yet clear.
*/

import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  HStack,
  Text,
  useMediaQuery,
} from "design-system/components/index";
import { Language, ChevronDown } from "design-system/index";
import {
  SupportedLanguages,
  GlobalOneSupportedLanguages,
} from "@spring/constants";
import { trackButtonClicked } from "components/templates/RegisterPage/analytics";

import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes/index";
import { isT2MemberPreSignupUsingRouter } from "utils/memberHelpers";

export const SHLanguageSelect = ({
  lang = undefined,
  setLang,
  currentView = undefined,
  isOldHeader = false,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { i18n } = useTranslation();
  const [nativeLang, setNativeLang] = useState("English");
  const ref = useRef();

  const isT2 = isT2MemberPreSignupUsingRouter();

  const languageList = isT2
    ? GlobalOneSupportedLanguages.concat(SupportedLanguages)
    : SupportedLanguages;

  function changeLang(lang) {
    setLang(lang.code);
    sessionStorage.setItem("langDropdownTriggered", lang.code);
    i18n.changeLanguage(lang.code);
    setNativeLang(lang.nativeName);
  }

  function clickMethod(language) {
    if (!isOldHeader && currentView !== "LOGIN") {
      trackButtonClicked(
        "Language Selection",
        currentView,
        window.location.pathname === routes.Register.as
          ? "register002"
          : "invite_dependent013",
        "Header",
      );
    } else {
      TRACK_EVENT.BUTTON_CLICKED(
        window.location.pathname,
        "Language Selection",
        {
          location: "Header",
          spring_doc_id: "header_0001",
        },
      );
    }
    changeLang(language);
  }

  let bgColor = "";

  if (isOldHeader) {
    bgColor = "white";
  } else {
    bgColor = `${isMobile ? "platform.50" : "tertiary.50"}`;
  }

  return (
    <Menu isLazy>
      <MenuButton
        w={isMobile ? "90px" : isOldHeader ? "120px" : "fit-content"}
        h={isMobile ? "35px" : isOldHeader ? "53px" : "40px"}
        px={16}
        bg={bgColor}
        p={isMobile ? "5px 12px" : isOldHeader ? "10px 24px" : null}
        borderRadius={isOldHeader ? 50 : 24}
        border={isOldHeader ? "1px #a9a9a9 solid" : "none"}
        _hover={isOldHeader ? { bg: "tertiary.50" } : { bg: "#DCD9D6" }}
        _focus={
          isOldHeader
            ? { border: "1px #73a8a3 solid" }
            : { border: "2px solid #068464" }
        }
        _focusVisible={{ outline: "2px solid black", outlineOffset: "4px" }}
        _active={
          isOldHeader
            ? { border: "1px #73a8a3 solid" }
            : { bg: "tertiary.100", border: "2px solid #068464" }
        }
        data-cy="lang-select"
        aria-label={`Select Language - ${nativeLang} selected`}
        ref={ref}
      >
        <HStack as="span" spacing={4}>
          <Language boxSize={20} aria-hidden={true} />
          <Text
            as="span"
            fontWeight="bold"
            p="0 3px"
            color={isOldHeader ? "#4b4b4b" : "inherit"}
          >
            {lang ? lang.toUpperCase() : "EN"}
          </Text>
          {isOldHeader ? <ChevronDown /> : null}
        </HStack>
      </MenuButton>
      <MenuList
        maxHeight={"60vh"}
        overflowY="scroll"
        // @ts-ignore
        aria-labelledby={ref?.current?.id}
        zIndex={1000}
      >
        <MenuGroup>
          {languageList.map((language) => (
            <MenuItem
              key={language.code}
              data-cy={language.code}
              lang={language.code}
              aria-label={language.nativeName}
              onClick={() => clickMethod(language)}
              _focus={{ bg: "tertiary.400" }}
              _focusVisible={{
                outline: "2px solid black",
                outlineOffset: "-1px",
              }}
              fontWeight="bold"
            >
              {language.nativeName}
            </MenuItem>
          ))}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

SHLanguageSelect.propTypes = {
  lang: PropTypes.string,
  setLang: PropTypes.func.isRequired,
  isOldHeader: PropTypes.bool,
};
