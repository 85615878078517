export const BENEFIT_PREFERENCES = [
  {
    enum: "PHYSICAL_HEALTH",
    label: "Physical Health",
  },
  {
    enum: "EMOTIONAL_WELL_BEING",
    label: "Emotional Well Being",
  },
  {
    enum: "PARENTING_SKILLS",
    label: "Parenting skills",
  },
  {
    enum: "FINANCES",
    label: "Finances",
  },
  {
    enum: "RELATIONSHIPS",
    label: "Relationships",
  },
  {
    enum: "ALCOHOL_OR_SUBSTANCE_USE",
    label: "Alcohol or Substance use",
  },
  {
    enum: "FERTILITY_CHALLENGES",
    label: "Fertility challenges",
  },
];
