import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "design-system/components";

export const NoShowTermsCopy = () => {
  const { t } = useTranslation("careVisits");

  return (
    <Box as="section" my={16}>
      {t("appointmentDetails.noShowTermsCopy")}
    </Box>
  );
};
