import { gql } from "@apollo/client";

import { assessmentToTake } from "operations/fragments/assessment";

const generateAssessment = gql`
  mutation GenerateAssessment(
    $member_id: ID!
    $kind: AssessmentKindEnumType!
    $campaign: CampaignInput
  ) {
    generateAssessment(
      member_id: $member_id
      kind: $kind
      campaign: $campaign
    ) {
      success
      assessment {
        ...assessmentToTake
      }
    }
  }
  ${assessmentToTake}
`;

export default generateAssessment;
