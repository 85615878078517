import { useQuery } from "@apollo/client";
import { DateTime } from "luxon";

import { AppointmentMedium, AppointmentKind } from "@spring/constants";

import Meowth from "@spring/meowth";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

import { getAppointmentSlots } from "operations/queries/appointment";
import { getStartSpan } from "utils/schedulingHelpers";

// NOTE: useAllUpcomingAppointmentSlotsV2.ts replicates this hook but returns a consistent
// NOTE: number of hooks & consistent data structure
// NOTE: see components/molecules/CareTeamList for example usage
export function useAllUpcomingAppointmentSlots(
  careProvider,
  kind,
  increment = 30,
  isCareNavigator = false,
  isCoach = false,
) {
  const shouldShowLeadTime =
    useFeatureFlag(FLAGS.DYNAMIC_LEAD_TIME_RELEASE) &&
    careProvider?.in_house_availability_source_of_truth_at;

  const startSpan = getStartSpan(kind, shouldShowLeadTime);
  const {
    data: virtualAppointmentSlotsData,
    loading: virtualAppointmentSlotsLoading,
  } = useQuery(getAppointmentSlots, {
    variables: {
      user_ids: [Meowth.getUserId(), careProvider?.user_id],
      slot_increment: increment,
      kind: kind, // e.g Therapy, Coaching, etc
      medium: isCareNavigator
        ? AppointmentMedium.Phone
        : AppointmentMedium.Video, // e.g video, in-person
      start_span: startSpan,
      end_span: DateTime.fromISO(startSpan)
        .plus({ days: 29 })
        .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 })
        .toISO(),
      upcoming_slots_only: true,
      upcoming_slots_count: 1,
    },
    skip: !careProvider || !kind,
  });

  const therapyKinds = [
    AppointmentKind.Therapy,
    AppointmentKind.CouplesTherapy,
    AppointmentKind.MinorTherapy,
  ];
  if (
    isCareNavigator ||
    isCoach ||
    (kind && !therapyKinds.includes(kind?.toUpperCase()))
  ) {
    return {
      data: { ...virtualAppointmentSlotsData },
      loading: virtualAppointmentSlotsLoading,
    };
  }

  // Only for Therapy
  const {
    data: inPersonAppointmentSlotsData,
    loading: inPersonAppointmentSlotsLoading,
  } = useQuery(getAppointmentSlots, {
    variables: {
      user_ids: [Meowth.getUserId(), careProvider?.user_id],
      slot_increment: increment,
      kind: kind, // e.g Therapy, Coaching, etc
      medium: AppointmentMedium.InPerson, // e.g video, in-person
      start_span: startSpan,
      end_span: DateTime.fromISO(startSpan)
        .plus({ days: 29 })
        .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 })
        .toISO(),
      upcoming_slots_only: true,
      upcoming_slots_count: 1,
    },
    skip: !careProvider || !kind || !careProvider?.in_person_supported,
  });

  return {
    data: {
      firstVirtualSlot: virtualAppointmentSlotsData,
      firstInPersonSlot: inPersonAppointmentSlotsData,
    },
    loading: virtualAppointmentSlotsLoading || inPersonAppointmentSlotsLoading,
  };
}
