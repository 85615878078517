import { Box, PanelSheet } from "@springcare/sh-component-library";

import { useSessionRoomDrawer } from "context/SessionRoomContext";
import DrawerTabsSection from "./DrawerTabsSection";
import ChatSection from "./ChatSection";
import AttendeeSection from "./AttendeeSection";

import { DrawerTabTypes } from "components/templates/SHSessionRoom/types";

const SessionRoomDrawer = ({ send, chatMessages, isSending, isMobile }) => {
  const { onSessionRoomDrawerClose, drawerWidth, activeDrawerTab } =
    useSessionRoomDrawer();

  return (
    <Box
      w={isMobile ? "100%" : `${drawerWidth}px`}
      h={isMobile ? "100%" : "auto"}
      bg="white"
      mt={!isMobile && 16}
      position={isMobile ? "absolute" : "relative"}
      top={0}
      right={0}
      bottom={0}
      overflow="auto"
      borderRadius={!isMobile && 16}
    >
      <Box w="100%">
        <PanelSheet.Header
          title="Virtual session"
          onClose={onSessionRoomDrawerClose}
        />
      </Box>

      <Box>
        <DrawerTabsSection />
      </Box>

      <Box>
        {activeDrawerTab === DrawerTabTypes.Participants && <AttendeeSection />}

        {activeDrawerTab === DrawerTabTypes.Chat && (
          <ChatSection
            send={send}
            chatMessages={chatMessages}
            isSending={isSending}
          />
        )}
      </Box>
    </Box>
  );
};

export default SessionRoomDrawer;
