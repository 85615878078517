import { addMonths } from "date-fns/addMonths";
import { setDate } from "date-fns/setDate";
import { startOfMonth } from "date-fns/startOfMonth";
import type {
  AppointmentTimeSlotsType,
  GroupedAppointmentsType,
  TimeSlotType,
} from "modules/MemberDashboard/Scheduling/types";
import type { AvailableCnAppointmentTimes } from "modules/shared/graphql-codegen/graphql";

const getTimePeriod = (time: string): string => {
  const localHour = new Date(time).getHours();
  if (localHour < 12) {
    return "morning";
  } else if (localHour >= 12 && localHour < 18) {
    return "afternoon";
  } else {
    return "night";
  }
};

const sortAppointmentsByTime = (
  appointments: AppointmentTimeSlotsType,
): AppointmentTimeSlotsType =>
  appointments.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
  );

export const parseTime = (time: string): string => {
  const localDate = new Date(time);
  const localeString = localDate.toLocaleString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return localeString;
};

export const groupAppointmentsByTimePeriod = (
  appointments: AppointmentTimeSlotsType,
): GroupedAppointmentsType => {
  const groupedAppointments = {
    morning: [],
    afternoon: [],
    night: [],
  };

  if (appointments.length > 0) {
    const sortedAppointments = sortAppointmentsByTime(appointments);

    sortedAppointments.forEach((appointment) => {
      const timePeriod = getTimePeriod(appointment.timestamp);
      groupedAppointments[timePeriod].push(appointment);
    });
  }

  return groupedAppointments;
};

export const formatSelectedDay = (selectedDay: Date): string =>
  selectedDay.toLocaleDateString(undefined, {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

export const getUsersTimezone = (showLong = true): string =>
  new Date()
    .toLocaleDateString(undefined, {
      timeZoneName: showLong ? "long" : "short",
    })
    .split(", ")[1];

export const getIncludedDates = (
  allAvailable: AvailableCnAppointmentTimes[],
  startDate: string,
): Date[] =>
  allAvailable.map((appt) =>
    setDate(new Date(startDate), appt.day_of_the_month),
  );

export const getTimeSlotsForDay = (
  selectedDayAppts: AvailableCnAppointmentTimes,
): TimeSlotType[] =>
  selectedDayAppts.cn_appointments.map((appt) => ({
    timestamp: appt.start_time,
    formattedTime: parseTime(appt.start_time),
    payload: {
      careProviderUserId: appt.care_provider.user_id,
      careProviderId: appt.care_provider.id,
    },
  }));

export const sortAppointmentsByDay = (
  appointments: AvailableCnAppointmentTimes[],
) => {
  const sortedDates = [...appointments].sort(
    (a, b) => a.day_of_the_month - b.day_of_the_month,
  );
  return sortedDates;
};

export const findFirstAvailableDate = (
  allAvailable: AvailableCnAppointmentTimes[],
) => allAvailable[0]?.cn_appointments[0]?.start_time;

export const getStartOfNextMonth = (day: string): Date => {
  const currentStart = startOfMonth(new Date(day));

  return addMonths(new Date(currentStart), 1);
};
