import routes from "routes";

/** *
 * Helper function that returns positions of dynamic values found in alias route
 *
 * Input: /members/care_visits/:id
 * Output: [0: {key: ':id', position: 3}]
 **/
export function findVariablePositions(route) {
  const variablePositions = [];

  route.split("/").forEach((val, i) => {
    if (val.includes(":")) {
      variablePositions.push({ position: i, key: val });
    }
  });

  return variablePositions;
}

/** *
 * Helper function that replaces dynamic values (:id, :user_id) with '.*'
 *
 * Input: /members/care_visits/:id
 * Output: /members/care_visits/.*
 **/
function normalizeRoute(route) {
  return route.as
    .split("/")
    .map((x) => (x.includes(":") ? ".*" : x))
    .join("/");
}

/** *
 * Helper function that matches to route and returns additional route data
 *
 * Input: /members/care_visits/95b2eba3-a33f-4709-9dd6-59e064cf1148
 * Output: {to: "/apps/MemberDashboard/care_visits/:id", as: "/members/care_visits/:id"}
 **/
export function findRouteMatch(route) {
  let target;

  for (const alias in routes) {
    if (routes[alias] && typeof routes[alias] === "object") {
      const normalizedRoute = normalizeRoute(routes[alias]);
      const reg = new RegExp(`^${normalizedRoute}/?$`);

      if (reg.test(route)) {
        target = routes[alias];
        break;
      }
    }
  }
  return target;
}

export function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
