import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";
import Router from "next/router";

export const trackVideoLoaded = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.VIDEO_LOADED(Router.asPath, "Moments: Video Loaded", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackVideoPlayed = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Video Played", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackVideoPaused = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Video Paused", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackVideoEnded = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.VIDEO_ENDED(Router.asPath, "Moments: Video Ended", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackVideoForward10 = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Video Forward 10", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackVideoBackward10 = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Video Backward 10", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackVideoSeek = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Video Seek", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackCaptionChange = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
  captionEnabled,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Video Caption Changed", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
    captionEnabled: String(captionEnabled),
  });
};

export const trackFullScreenChange = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
  fullScreenEnabled,
) => {
  TRACK_EVENT.BUTTON_CLICKED(
    Router.asPath,
    "Moments: Video FullScreen Changed",
    {
      exercise_id: exerciseId,
      exercise_name: exerciseName,
      exercise_category: exerciseCategory,
      media_type: exerciseMediaType,
      fullScreenEnabled: String(fullScreenEnabled),
    },
  );
};

export const trackCloseMoment = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Exit Clicked", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackFinishedExerciseDoneClicked = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Done Clicked", {
    spring_doc_id: "moments002",
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    location: "Moment: Exercise Completed Modal",
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

// AB test Events
export const trackModalOpenedABTestEnd = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.MODAL_OPENED(Router.asPath, "Moments: AB Test Explore More", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackModalClosedABTest = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.MODAL_CLOSED(Router.asPath, "Moments: Modal Closed", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackCompletedExerciseBetter = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Member Feels Better", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackCompletedExerciseNoChange = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Member Feels No Change", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackCompletedExerciseWorse = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Member Feels Worse", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};
// AB test Events end
export const trackScheduleTherapyLinkOnMomentsModal = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.LINK_CLICKED(Router.asPath, "Moments: Schedule Therapy Link", {
    spring_doc_id: "moments100",
    to: Router.asPath,
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    location: "Parenting Moment Completed Modal",
    media_type: exerciseMediaType,
  });
};

export const trackBrowseLinkOnMomentsModal = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.LINK_CLICKED(
    Router.asPath,
    routes.TherapistsBrowse.as,
    "Moments: Browse Family Therapists Link",
    {
      spring_doc_id: "moments101",
      to: Router.asPath,
      exercise_id: exerciseId,
      exercise_name: exerciseName,
      exercise_category: exerciseCategory,
      location: "Parenting Moment Completed Modal",
      media_type: exerciseMediaType,
    },
  );
};

export const trackBrowseRecommendedTherapistsLinkOnMomentsCompletionModal = (
  feeling,
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.LINK_CLICKED(
    Router.asPath,
    routes.RecommendedTherapists.as,
    "Moments: See Recommended Therapists Link",
    {
      spring_doc_id: "moments110",
      feeling,
      exercise_id: exerciseId,
      exercise_name: exerciseName,
      exercise_category: exerciseCategory,
      location: "Moment Completed Modal",
      media_type: exerciseMediaType,
    },
  );
};

export const trackBrowseCoachesLinkOnMomentsCompletionModal = (
  feeling,
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.LINK_CLICKED(
    Router.asPath,
    routes.CoachesBrowse.as,
    "Moments: Browse Coaches Link",
    {
      spring_doc_id: "moments111",
      feeling,
      exercise_id: exerciseId,
      exercise_name: exerciseName,
      exercise_category: exerciseCategory,
      location: "Moment Completed Modal",
      media_type: exerciseMediaType,
    },
  );
};

export const trackMaybeLaterOnMomentsCompletionModal = (
  feeling,
  carePromptType,
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Maybe Later", {
    spring_doc_id: "moments112",
    feeling,
    carePromptType,
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    location: "Moment Completed Modal",
    media_type: exerciseMediaType,
  });
};

export const trackCompletedExerciseFinishedClicked = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Moments: Finish Clicked", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
  });
};

export const trackTeenExerciseClicked = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.CARD_CLICKED(
    Router.asPath,
    "Moment ${exerciseId}",
    "Teen exercise clicked",
    {
      exercise_id: exerciseId,
      card_title: exerciseName,
      exercise_category: exerciseCategory,
      media_type: exerciseMediaType,
      spring_doc_id: "teenhp004",
      location: "Teen Carousel Home Page",
    },
  );
};

export const trackMomentExerciseClicked = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.CARD_CLICKED(
    Router.asPath,
    "Moments: Exercise clicked on Moments Page",
    {
      exerciseId: exerciseId,
      exercise_name: exerciseName,
      exercise_category: exerciseCategory,
      media_type: exerciseMediaType,
    },
  );
};

export const trackFamilyMomentCarouselClicked = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.CARD_CLICKED(Router.asPath, "Moments: Family exercise clicked", {
    exercise_id: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
    spring_doc_id: "famhp005",
    location: "Family Carousel Home Page",
  });
};

export const trackParentingMomentSeeAllLinkClicked = () => {
  TRACK_EVENT.LINK_CLICKED(
    Router.asPath,
    "Moments: Family See All Link on Home Page",
    {
      spring_doc_id: "famhp006",
      location: "Family Carousel See All Link",
    },
  );
};

export const trackVideoMomentExerciseClicked = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
  locationForTracking,
) => {
  TRACK_EVENT.CARD_CLICKED(Router.asPath, "Moments: Video Exercise Clicked", {
    exerciseId: exerciseId,
    exercise_name: exerciseName,
    exercise_category: exerciseCategory,
    media_type: exerciseMediaType,
    location: locationForTracking,
  });
};

export const trackMomentStartClicked = (
  exerciseId,
  exerciseName,
  exerciseCategory,
  exerciseMediaType,
) => {
  TRACK_EVENT.BUTTON_CLICKED(
    Router.asPath,
    "Moments: Get Started Button Clicked",
    {
      exercise_id: exerciseId,
      exercise_name: exerciseName,
      exercise_category: exerciseCategory,
      media_type: exerciseMediaType,
      spring_doc_id: "moments001",
      location: "Get Started Button on Modal",
    },
  );
};

export const trackFamilyMomentsOnHomePageViewed = () => {
  TRACK_EVENT.COMPONENT_VIEWED(
    Router.asPath,
    "Moments: Family Carousel on Home Page",
    {
      location: "Family Carousel on Home Page",
    },
  );
};

export const trackTeenMomentsOnHomePageViewed = () => {
  TRACK_EVENT.COMPONENT_VIEWED(
    Router.asPath,
    "Moments: Teen Carousel on Home Page",
    {
      location: "Teen Carousel on Home Page",
    },
  );
};
