import React, { useState } from "react";
import {
  Box,
  IconButton,
  keyframes,
  Tooltip,
  VFavoriteStarFilledIcon,
  VFavoriteStarIcon,
} from "@springcare/sh-component-library";

interface RatingsProps {
  rating: number;
  setRating: React.Dispatch<React.SetStateAction<number>>;
}

export const RatingTooltipText = [
  "Very dissatisfied",
  "Not satisfied",
  "Neutral",
  "Satisfied",
  "Very satisfied",
];

export const RatingSection = ({ rating, setRating }: RatingsProps) => {
  const [pulsing, setPulsing] = useState(false);
  const [hover, setHover] = useState<number | null>(null);

  const pulse = keyframes`
    from {
      transform: scale(1);
      transform-origin: center center;
      animation-timing-function: ease-in;
    }
    33% {
      transform: scale(0.75);
      animation-timing-function: ease-in-out;
    }
    66% {
      transform: scale(1.25);
      animation-timing-function: ease-out;
    }
    `;

  const onClick = (selectedRating: number) => {
    setPulsing(true);
    setRating(selectedRating);
  };

  return (
    <Box>
      {[...Array(5)].map((_, index) => {
        const currentRating = index + 1;

        return (
          <Tooltip
            bg="background-base"
            color="content-primary"
            key={`rating-${currentRating}`}
            label={RatingTooltipText[index]}
            placement="top"
            size="md"
          >
            <IconButton
              animation={
                pulsing && currentRating <= rating
                  ? `${pulse} 0.5s ${currentRating / 15}s ease-in-out`
                  : ""
              }
              aria-label={`Rating ${currentRating}`}
              bg="transparent !important"
              colorScheme="neutral"
              icon={
                currentRating <= (hover ?? rating)
                  ? VFavoriteStarFilledIcon
                  : VFavoriteStarIcon
              }
              size="sm"
              fontSize={24}
              variant="no-emphasis"
              onAnimationEnd={() =>
                currentRating === rating && setPulsing(false)
              }
              onClick={() => onClick(currentRating)}
              onMouseEnter={() => setHover(currentRating)}
              onMouseLeave={() => setHover(null)}
            />
          </Tooltip>
        );
      })}
    </Box>
  );
};
