import { useContext } from "react";
import {
  Box,
  Text,
  Flex,
  Button,
  Center,
  Divider,
  Heading,
  useDisclosure,
  useMediaQuery,
} from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";
import { HelpFlyout } from "components";
import { TRACK_EVENT } from "utils/mixpanel";
import Router from "next/router";
import { CareContextValues } from "pages/apps/MemberDashboard/care_visits";
import routes from "routes";

const CareTeamFooter = () => {
  const { t } = useTranslation("careVisits");
  const { showEmptyCard, isFindNewCareTab, showSUDCopy } =
    useContext(CareContextValues);
  const {
    isOpen: isHelpFlyoutOpen,
    onOpen: onOpenHelpFlyout,
    onClose: closeHelpFlyout,
  } = useDisclosure();
  const [isMobile, isSidebarOpen] = useMediaQuery([
    "(max-width: 961px)",
    "(min-width: 540px)",
  ]);

  const openHelpFlyout = () => {
    onOpenHelpFlyout();
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Help Button Clicked", {});
  };
  const handleCNScheduleClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.asPath,
      "Care Navigator Schedule Button Clicked",
      {},
    );
    Router.push(routes.CareNavigation.as);
  };

  const CNFooter = () => {
    return (
      <>
        <Box>
          <Heading size="heading-small" mb={2} pr={16}>
            {t("findNewCareTab.footer.careNavigation.title")}
          </Heading>
          <Text size="body-small-regular" color="secondary-content" mb={3}>
            {t("findNewCareTab.footer.careNavigation.subtitle")}
          </Text>
        </Box>
        <Text align="left" mb={5} w={isMobile ? "70%" : null}>
          {t("findNewCareTab.footer.careNavigation.description")}
        </Text>
        <Box>
          <Button
            h={!isMobile && 48}
            py={!isMobile && 12}
            px={!isMobile && 16}
            bg="accent-subtle"
            color="accent-on"
            onClick={handleCNScheduleClick}
            _hover={
              !isMobile && {
                bg: "hover-base-medium-emphasis",
                color: "primary-content",
              }
            }
            tabIndex={0}
          >
            <Text size="body-medium-strong">
              {t("findNewCareTab.footer.careNavigation.buttonText")}
            </Text>
          </Button>
        </Box>
      </>
    );
  };

  return (
    <>
      {isFindNewCareTab && !isMobile && (
        <Flex
          bg="#FBFAF9"
          h={397}
          align="center"
          justifyContent="center"
          w="100%"
          mt={48}
          data-testid="web-footer"
          as="footer"
        >
          <Flex direction="column" w={"100%"} maxW={1024} ml={48} height={300}>
            <Heading
              size="heading-small"
              mb={showEmptyCard ? 25 : 40}
              mt={showEmptyCard ? 25 : 0}
              align="left"
            >
              {t("findNewCareTab.footer.title")}
            </Heading>

            <Flex direction="row" align="center" flexGrow={0}>
              <Flex
                direction="column"
                flex="1"
                justifyContent="space-between"
                height="100%"
              >
                <Box>
                  <Heading size="heading-small" mb={2}>
                    {t("findNewCareTab.footer.careSupport.title")}
                  </Heading>
                  <Text
                    size="body-small-regular"
                    color="secondary-content"
                    mb={3}
                  >
                    {t("findNewCareTab.footer.careSupport.subtitle")}
                  </Text>
                </Box>
                <Text align="left" mb={5}>
                  {t("findNewCareTab.footer.careSupport.description")}
                </Text>
                <Box>
                  <Button
                    h={48}
                    py={12}
                    px={16}
                    bg="accent-subtle"
                    color="accent-on"
                    onClick={openHelpFlyout}
                    _hover={{
                      bg: "hover-base-medium-emphasis",
                      color: "primary-content",
                    }}
                    tabIndex={0}
                  >
                    <Text size="body-medium-strong">
                      {t("findNewCareTab.footer.careSupport.buttonText")}
                    </Text>
                  </Button>
                </Box>
              </Flex>

              <Center>
                {!showSUDCopy && (
                  <Divider
                    orientation="vertical"
                    h={196}
                    color="border-subtle"
                    mx={60}
                  />
                )}
              </Center>

              <Flex
                direction="column"
                flex="1"
                mr={8}
                height="100%"
                justifyContent="space-between"
              >
                {showEmptyCard && (
                  <Button
                    size="heading-small"
                    mb={3.5}
                    w={105}
                    h={32}
                    background="primary-on-subtle"
                    pointerEvents="none"
                  >
                    {t("findNewCareTab.footer.careNavigation.startHere")}
                  </Button>
                )}
                {!showSUDCopy && <CNFooter />}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}

      {isFindNewCareTab && isMobile && (
        <Flex
          bg="#FBFAF9"
          h={711}
          mt={120}
          marginStart={isSidebarOpen ? 84 : 0}
          data-testid="mobile-footer"
        >
          <Flex
            direction="column"
            w={"100%"}
            justify="center"
            ml={isSidebarOpen ? 84 : 48}
          >
            <Heading size="heading-small" mb={50} align="left">
              {t("findNewCareTab.footer.title")}
            </Heading>

            <Flex direction="column" justify="center">
              <Flex direction="column" mb={65}>
                <Heading size="heading-small" mb={2} pr={16}>
                  {t("findNewCareTab.footer.careSupport.title")}
                </Heading>
                <Text
                  size="body-small-regular"
                  color="secondary-content"
                  mb={3}
                >
                  {t("findNewCareTab.footer.careSupport.subtitle")}
                </Text>
                <Text align="left" w="70%" mb={5}>
                  {t("findNewCareTab.footer.careSupport.description")}
                </Text>
                <Box>
                  <Button
                    bg="accent-subtle"
                    color="accent-on"
                    onClick={openHelpFlyout}
                    tabIndex={0}
                  >
                    <Text size="body-medium-strong">
                      {t("findNewCareTab.footer.careSupport.buttonText")}
                    </Text>
                  </Button>
                </Box>
              </Flex>

              <Flex direction="column">
                {showEmptyCard && (
                  <Button
                    size="heading-small"
                    mb={3.5}
                    w={105}
                    h={32}
                    background="primary-on-subtle"
                    pointerEvents="none"
                  >
                    {t("findNewCareTab.footer.careNavigation.startHere")}
                  </Button>
                )}
                {!showSUDCopy && <CNFooter />}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}

      <HelpFlyout
        onOpen={openHelpFlyout}
        isOpen={isHelpFlyoutOpen}
        onClose={closeHelpFlyout}
      />
    </>
  );
};

export default CareTeamFooter;
