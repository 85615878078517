import React, { useEffect, useMemo, useRef, useState } from "react";
import { DateTime } from "luxon";
import { useQuery } from "@apollo/client";
import Meowth from "@spring/meowth";
import { debounce } from "lodash";

import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { Box } from "@springcare/sh-component-library";
import { Spinner, useDisclosure } from "design-system/components";
import {
  AlsoAvailableSection,
  AvailabilityRequestModal,
  CareProviderScheduleModal,
  UpNextSection,
  YourCareSection,
} from "components";
import {
  CareProviderScheduleModalV2,
  MomentsCompleteExerciseModal,
  MomentsExerciseModal,
  MomentsStartExerciseModal,
} from "components/modals";
import { getCareTeam } from "operations/queries/careProvider";
import { getMemberCareItems } from "operations/queries/member";
import { getHomePageMemberInfo } from "./queries/getHomePageMemberInfo";
import { getAssessmentRiskLevels } from "operations/queries/assessment";
import { getAppointments } from "operations/queries/appointment";
import { formatMemberAddress } from "utils/displayHelpers";
import { hasManagedDependents, isMinor } from "utils/memberHelpers";
import { useMemberBenefits } from "hooks/useMemberBenefits";
import { FamilySection } from "./components/FamilySection";
import { JourneySection } from "./components/JourneySection";
import { SUDSignalSection } from "./components/SUDSignalSection";
import { UpdateSettings } from "modules/MemberDashboard/Home/AgingOut/components";
import { useScheduleModalWithProps } from "shared/hooks";
import { SpecializedCareNavigatorAvailabilityModal } from "components/modals/CareNavigatorScheduleModal/CareNavigatorAvailabilityModal";
import { useJourneySection } from "./hooks/useJourneySection";
import { WorkplaceManagerBanners } from "modules/MemberDashboard/Home/components/ManagerExperience/Workplace/components";
import { useWorkplaceManagerBanner } from "./hooks/useWorkplaceManagerBanner";
import { track } from "utils/mixpanel";
import { getInitialAndRecentAssessments } from "utils/assessments/assessmentUtils";
import { AssessmentRisk } from "@spring/constants";
import { upcomingAppointmentsThresholdHours } from "constants/appointments";
import CostEstimateDisclaimer, {
  CostEstimateDisclaimerTypes,
} from "components/molecules/CostEstimateDisclaimer/CostEstimateDisclaimer";
import { isCNScheduling, isGlobalUser } from "utils/global";
import { useShouldShowSUDCNCalendar } from "shared/hooks/useShouldShowSUDCNCalendar";

const HomePage = ({ setHomePageBackgroundCurveHeight }) => {
  const apolloOptionsUserId = Meowth.apolloOptionsUserId();
  const peerRecoverySpecialistAvailable = useFeatureFlag(
    FLAGS.PEER_RECOVERY_SPECIALIST_EXPERIENCE,
  );
  const { data: memberData } = useQuery(
    getHomePageMemberInfo(peerRecoverySpecialistAvailable),
    {
      ...apolloOptionsUserId,
    },
  );
  const memberFinishedAssesments = memberData?.user?.member?.assessments || [];
  const mostRecentAssessment = getInitialAndRecentAssessments(
    memberFinishedAssesments,
  )[1];
  const isAcuityRiskLowOrLowMedium =
    mostRecentAssessment?.risk === AssessmentRisk.Low ||
    mostRecentAssessment?.risk === AssessmentRisk.LowMedium;

  const member = memberData?.user?.member;
  const isAMinor = isMinor(member);
  const isAGlobalMember = isGlobalUser(member?.postal_address?.country);
  const managed_dependents = member?.managed_dependents;
  const address = formatMemberAddress(memberData);
  const getCareTeamVars = address
    ? { id: memberData.user.id, distance_from: { address: address } }
    : { id: apolloOptionsUserId.variables.id };

  const { data: careTeamData } = useQuery(getCareTeam, {
    variables: getCareTeamVars,
    skip: !memberData,
    fetchPolicy: "cache-and-network",
  });

  const coachVisitsCovered = memberData?.user?.member?.visits_covered_coaching;
  const coachVisitsRemaining =
    memberData?.user?.member?.visits_remaining_coaching;
  const redirectTabaccoCare = () => {
    let tobaccoCessationUrl = JSON.parse(
      (
        availableCareItems.find(
          (item) => item.care_item_name === "tobacco_cessation",
        ) ||
        recommendedCareItems.find(
          (item) => item.care_item_name === "tobacco_cessation",
        )
      ).metadata,
    )?.tobacco_cessation_url;

    if (!tobaccoCessationUrl?.startsWith("http")) {
      tobaccoCessationUrl = `https://${tobaccoCessationUrl}`;
    }
    window.open(tobaccoCessationUrl, "_blank");
  };

  const { data: careItemsData } = useQuery(getMemberCareItems, {
    ...apolloOptionsUserId,
    fetchPolicy: "cache-and-network",
  });
  const { data: memberAssessments } = useQuery(getAssessmentRiskLevels, {
    member_id: memberId,
  });

  const cohort = member?.cohort;
  const customerId = cohort?.customer?.id;
  const memberFirstName = memberData?.user?.first_name;
  const memberId = member?.id;
  const memberHasInPersonSupport = cohort?.in_person_supported;
  const memberCountry = member?.postal_address?.country;
  const isADependent = member?.covered_life?.dependent;
  const showGlobalExperience =
    memberCountry && memberCountry.length > 0 && memberCountry !== "US";
  const availableCareItems =
    careItemsData?.user?.member?.care_items?.available_care_items;
  const hasEverCompletedARecommendedCareItem =
    careItemsData &&
    JSON.parse(careItemsData?.user?.member?.care_items?.metadata)
      ?.ever_completed_a_recommended_care_item;
  const momentsAreRecommended =
    careItemsData?.user?.member?.care_items?.recommended_care_items.find(
      (care_item) => care_item.care_item_name === "moments",
    );
  const coach = careTeamData?.user?.member?.care_team?.coach;
  const hasAgedOutRecord = !!member?.aged_out_consent_fields?.created_at;
  const isHealthPlan = member?.cohort?.customer?.is_health_plan;
  const memberShouldSeeManagerExperienceBanners =
    !isAGlobalMember && !isADependent;

  const isSpecializedCNSupported =
    availableCareItems?.some(
      (item) => item.care_item_name === "specialized_care_navigator",
    ) ||
    recommendedCareItems?.some(
      (item) => item.care_item_name === "specialized_care_navigator",
    );

  const {
    shouldShowCoaching,
    allowDirectScheduling,
    showCoachingPaidExperience,
    initialSUDRisk,
    latestSUDRisk,
    isLoading: isBenefitsLoading,
  } = useMemberBenefits(memberId);

  const sudSupported =
    memberData?.user?.member?.cohort?.contract_term?.sud_supported ?? false;
  const { shouldShowSUDCNCalendar } = useShouldShowSUDCNCalendar(
    sudSupported,
    latestSUDRisk,
  );

  // Use memberId in loacalStorage for fetching upcoming appointments. MemberId should always be available, but if not, use the one from the query.
  const localStorageMemberId = localStorage.getItem("member_id") || memberId;

  /* if we try to get the current time inside the hook, it breaks; and if we don't memoize, it breaks */
  const currentDatetime = useMemo(() => DateTime.local(), []);
  const { data: upcomingAppointments, loading: upcomingAppointmentsLoading } =
    useQuery(getAppointments, {
      variables: {
        attended: null,
        limit: 30,
        member_id: localStorageMemberId,
        offset: 0,
        sort_by: [{ column: "appointments.start_at", direction: "asc" }],
        starting_after_timestamp: currentDatetime.minus({ hours: 1 }).toISO(),
        starting_before_timestamp: currentDatetime
          .plus({ hours: upcomingAppointmentsThresholdHours })
          .toISO(),
        status: "Booked" /* ignore cancelled */,
      },
      skip: !localStorageMemberId /* wait till member is available to fetch appointments */,
    });

  const isLoading =
    !memberData ||
    !careItemsData ||
    !careTeamData ||
    !memberAssessments ||
    upcomingAppointmentsLoading;

  const coachingAvailableSectionFlag = useFeatureFlag(
    FLAGS.COACHING_AVAILABLE_SECTION,
  );
  const memberHasManagedDependents = hasManagedDependents(member);
  const showNewCoachingExperience =
    showCoachingPaidExperience && isAcuityRiskLowOrLowMedium;
  const showAgedOutUpdateSettingsSection =
    useFeatureFlag(FLAGS.ENABLE_AGING_OUT_P1) &&
    hasAgedOutRecord &&
    member?.experience_state?.should_update_settings;

  const {
    journeySectionEligible,
    shouldShowJourneySection,
    hasUpcomingAppointments,
  } = useJourneySection(member);

  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose,
    modalProps: scheduleModalProps,
  } = useScheduleModalWithProps();

  const {
    isOpen: isSpecializedCNScheduleModalOpen,
    onOpen: openSpecializedCNScheduleModal,
    onClose: closeSpecializedCNScheduleModal,
  } = useDisclosure();

  const isDirectScheduling = (component) => {
    let qualifiesForCoaching = shouldShowCoaching;
    const paidCoachingExperience =
      coachingAvailableSectionFlag && showCoachingPaidExperience;
    const isDirectSchedulingComponent =
      component === "carePlans"
        ? paidCoachingExperience
        : allowDirectScheduling || paidCoachingExperience;
    qualifiesForCoaching =
      component === "carePlans"
        ? qualifiesForCoaching
        : !!coach || qualifiesForCoaching;

    return isDirectSchedulingComponent && qualifiesForCoaching;
  };

  const isGlobalWithNoCoachingSessions = () => {
    return (
      shouldShowCoaching &&
      showGlobalExperience &&
      coachVisitsRemaining === 0 &&
      coachVisitsCovered > 0
    );
  };

  let recommendedCareItems =
    careItemsData?.user.member.care_items.recommended_care_items;

  if (!isDirectScheduling("carePlans") && !isLoading) {
    recommendedCareItems = recommendedCareItems.filter(function (item) {
      return (
        item.care_item_name !== "coach_assignment" &&
        item.care_item_name !== "coach_follow_up"
      );
    });
  }

  const visitsEligibilityLimitDate = DateTime.fromISO(
    member?.visits_eligibility_limit_date || "",
  );

  const formattedVisitsEligibilityLimitDate = visitsEligibilityLimitDate.isValid
    ? visitsEligibilityLimitDate.toLocaleString(DateTime.DATE_FULL)
    : "";

  const sessionSnapshot = {
    expirationDate: formattedVisitsEligibilityLimitDate,
    totalVisitsCovered: member?.visits_covered_total || 0,
    remainingCovered: member?.visits_remaining_total || 0,
    specialtyVistsRemaining: member?.visits_remaining_specialist || 0,
    visits_covered_coaching: member?.visits_covered_coaching,
  };

  const HOMEPAGE_COLUMNS = [4, 4, 8, 12];
  //Any use of Grid or SimpleGrid should use this HOMEPAGE_COLUMNS variable
  const GRID_SPACING = 24;

  const backgroundCurvePaddingBottomPixels = 180;
  const ref = useRef("0px");
  const [refState, setRefState] = useState(ref);

  const [isFromSUDSignalExperience, setIsFromSUDSignalExperience] =
    useState(false);

  window.addEventListener(
    "resize",
    debounce(() => {
      if (refState.offsetHeight) {
        setHomePageBackgroundCurveHeight(
          refState.offsetHeight + backgroundCurvePaddingBottomPixels + "px",
        );
      }
    }, 100),
  );

  const therapyRecsEligible = !isAMinor && !showGlobalExperience;

  useEffect(() => {
    setHomePageBackgroundCurveHeight(
      refState.offsetHeight + backgroundCurvePaddingBottomPixels + "px",
    );
  }, [refState]);

  const {
    showWorkplaceManagerSelfIdCardOnTop,
    showWorkplaceManagerSelfIdCardOnBottom,
    showExploreCard,
    removeWorkplaceManagerBanner,
  } = useWorkplaceManagerBanner(member);

  useEffect(() => {
    if (journeySectionEligible) {
      track("JourneySection", {
        journey_graph_eligible: journeySectionEligible,
      });
    }
  }, [journeySectionEligible]);

  const isCnScheduling = isCNScheduling(
    member?.cohort?.contract_term?.scheduling_access?.name,
  );

  return (
    <>
      {isLoading ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner speed="1s" size="xl" />
        </Box>
      ) : (
        <>
          <CostEstimateDisclaimer variant={CostEstimateDisclaimerTypes.FULL} />
          {showAgedOutUpdateSettingsSection && (
            <Box mb={["v-20", "40px", "104px"]}>
              <UpdateSettings
                memberId={memberId}
                updateSettingsShownCount={
                  member?.experience_state?.update_settings_shown_count
                }
              />
            </Box>
          )}
          {showWorkplaceManagerSelfIdCardOnTop &&
            memberShouldSeeManagerExperienceBanners && (
              <WorkplaceManagerBanners
                removeWorkplaceManagerBanner={removeWorkplaceManagerBanner}
                memberId={memberId}
              />
            )}
          <section id="upNextSection" ref={(ref) => setRefState(ref)}>
            <UpNextSection
              careTeam={careTeamData}
              hasEverCompletedARecommendedCareItem={
                hasEverCompletedARecommendedCareItem
              }
              memberFirstName={memberFirstName}
              memberId={memberId}
              memberHasInPersonSupport={memberHasInPersonSupport}
              memberCountry={memberCountry}
              recommendedCareItems={recommendedCareItems}
              sessionSnapshot={sessionSnapshot}
              columns={HOMEPAGE_COLUMNS}
              spacing={GRID_SPACING}
              therapyRecsEligible={therapyRecsEligible}
              coachVisitsCovered={coachVisitsCovered}
              tobaccoCtaCallBack={redirectTabaccoCare}
              hasManagedDependents={memberHasManagedDependents}
              openSpecializedCNScheduleModal={openSpecializedCNScheduleModal}
              upcomingAppointments={upcomingAppointments?.appointments?.data}
              showNewCoachingExperience={showNewCoachingExperience}
              initialSUDRisk={initialSUDRisk}
              latestSUDRisk={latestSUDRisk}
              isSUDLoading={isBenefitsLoading}
              shouldShowSUDCNCalendar={shouldShowSUDCNCalendar}
            />
          </section>
          <SUDSignalSection
            sudSupported={sudSupported}
            setIsFromSUDSignalExperience={setIsFromSUDSignalExperience}
            openSpecializedCNScheduleModal={openSpecializedCNScheduleModal}
          />
          {shouldShowJourneySection && (
            <JourneySection
              hasUpcomingAppointments={hasUpcomingAppointments}
              memberId={member?.id}
              trackedGoals={member?.tracked_goals}
            />
          )}
          {!isCnScheduling && (
            <YourCareSection
              careTeam={careTeamData}
              hasEverCompletedARecommendedCareItem={
                hasEverCompletedARecommendedCareItem
              }
              momentsAreRecommended={momentsAreRecommended}
              columns={HOMEPAGE_COLUMNS}
              member={member}
              isDirectScheduling={isDirectScheduling()}
              isGlobalWithNoCoachingSessions={isGlobalWithNoCoachingSessions()}
              spacing={GRID_SPACING}
              memberHasInPersonSupport={memberHasInPersonSupport}
              memberCountry={memberCountry}
              memberAssessments={memberAssessments}
              hasManagedDependents={memberHasManagedDependents}
            />
          )}
          {showWorkplaceManagerSelfIdCardOnBottom &&
            memberShouldSeeManagerExperienceBanners && (
              <WorkplaceManagerBanners
                removeWorkplaceManagerBanner={removeWorkplaceManagerBanner}
                memberId={memberId}
                showExploreCard={showExploreCard}
                showWorkplaceManagerSelfIdCardOnBottom={
                  showWorkplaceManagerSelfIdCardOnBottom
                }
              />
            )}
          {memberHasManagedDependents && (
            <FamilySection
              columns={HOMEPAGE_COLUMNS}
              spacing={GRID_SPACING}
              minors={managed_dependents}
              coach={coach}
              totalVisitsCovered={sessionSnapshot.totalVisitsCovered}
              memberId={memberId}
              onScheduleModalOpen={onScheduleModalOpen}
            />
          )}
          <AlsoAvailableSection
            availableCareItems={availableCareItems}
            careTeam={careTeamData}
            customerId={customerId}
            memberId={memberId}
            sessionSnapshot={sessionSnapshot}
            isDirectScheduling={isDirectScheduling()}
            tobaccoCtaCallBack={redirectTabaccoCare}
            onScheduleModalOpen={onScheduleModalOpen}
            hasManagedDependents={memberHasManagedDependents}
            openSpecializedCNScheduleModal={openSpecializedCNScheduleModal}
            isHealthPlan={isHealthPlan}
          />
          <CareProviderScheduleModal />
          <AvailabilityRequestModal />
          <MomentsStartExerciseModal />
          <MomentsExerciseModal />
          <MomentsCompleteExerciseModal
            onScheduleModalOpen={onScheduleModalOpen}
          />
          <CareProviderScheduleModalV2
            isOpen={isScheduleModalOpen}
            onClose={onScheduleModalClose}
            {...scheduleModalProps}
          />
          <SpecializedCareNavigatorAvailabilityModal
            isOpen={isSpecializedCNScheduleModalOpen}
            isSpecializedCNSupported={isSpecializedCNSupported}
            onClose={() => {
              setIsFromSUDSignalExperience(false);
              closeSpecializedCNScheduleModal();
            }}
            onOpen={openSpecializedCNScheduleModal}
            isFromSUDSignalExperience={isFromSUDSignalExperience}
          />
        </>
      )}
    </>
  );
};

export default HomePage;
