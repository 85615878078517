import React from "react";
import PropTypes from "prop-types";
import { Heading, Box, Text } from "design-system/components";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "components/atoms";
import {
  getInitialCopy,
  formatAppointmentTime,
} from "components/templates/CareVisitDetails/utils";

export const VideoCopy = ({
  duration,
  time,
  provider,
  kind,
  isAMinor,
  isInHouseVideo,
}) => {
  const { t } = useTranslation("careVisits");
  const defaultCopy = t("appointmentDetails.videoCopy.defaultCopy", {
    providerName: provider.name,
    appointmentTime: formatAppointmentTime(time),
    duration: duration,
  });
  const coachingCopy = t("appointmentDetails.videoCopy.coachingCopy", {
    providerName: provider.name,
    appointmentTime: formatAppointmentTime(time),
  });
  const minorCopy = t("appointmentDetails.videoCopy.managedMinorCopy");

  return (
    <>
      <Box as="section">
        <Heading mb={16} as="h2" fontWeight="bold" fontSize={16}>
          {t("appointmentDetails.details")}
        </Heading>
        <Text mb={16} variant="body_v1">
          {getInitialCopy(kind, defaultCopy, coachingCopy, minorCopy, isAMinor)}
        </Text>
        {!isInHouseVideo && (
          <Text mb={16} variant="body_v1">
            <Trans
              ns={"careVisits"}
              i18nKey={
                kind === "COACHING"
                  ? "appointmentDetails.videoCopy.coachingCopyText"
                  : "appointmentDetails.videoCopy.content"
              }
            >
              <Link inlineTextLink to="https://zoom.us/download"></Link>
              <Link
                inlineTextLink
                to="http://itunes.apple.com/us/app/zoom.us-cloud-video-meetings/id546505307"
              ></Link>
              <Link
                inlineTextLink
                to="https://play.google.com/store/apps/details?id=us.zoom.videomeetings"
              ></Link>
            </Trans>
          </Text>
        )}
      </Box>
    </>
  );
};

VideoCopy.propTypes = {
  duration: PropTypes.any,
  isAMinor: PropTypes.any,
  kind: PropTypes.string,
  provider: PropTypes.shape({
    name: PropTypes.any,
  }),
  time: PropTypes.any,
};
