import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useMutation } from "@apollo/client";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import { DateTime } from "luxon";
import { getOr } from "lodash/fp";
import { useTranslation } from "hooks/react-i18next";
import {
  Fab,
  FlexRow,
  InputWrapper,
  Stout,
  Content,
  LoadingCircle,
  Button,
} from "@spring/smeargle";
import { modalIds, AppointmentMedium } from "@spring/constants";
import {
  setField,
  addNotification,
  openModal,
  closeModal,
} from "@spring/smeargle/actions";
import styles from "./styles.module.scss";
import Slot from "components/form/Slot";
import { requestAppointment } from "operations/mutations/appointment";
import { getFirstError } from "utils/apollo/errorHandler";
import {
  trackTimeSlotInModalClicked,
  trackSlotPaginationButtonClicked,
  trackAvailabileAppointmentsViewed,
} from "components/templates/Browse/ProviderBrowsePage/analytics";
import routes from "routes";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

import {
  track,
  makeEventString,
  TRACK_EVENT,
  EVENT_TYPE,
} from "utils/mixpanel";
import { FormControl, FormLabel } from "@springcare/sh-component-library";
import { formatDateWithPascalCaseMonth } from "modules/MemberDashboard/CareVisits/AppointmentDetails/utils/appointmentDetails.util";

const AppointmentTab = ({
  appointments,
  appointmentsLoading,
  queryRequestId,
  ...props
}) => {
  const router = useRouter();
  const currentRoute = router?.asPath;
  const isBrowseOrRecs =
    currentRoute === routes.TherapistsBrowse.as ||
    currentRoute === routes.RecommendedTherapists.as;
  const shouldShowDynamicLeadTime = useFeatureFlag(
    FLAGS.DYNAMIC_LEAD_TIME_RELEASE,
  );

  const [requestAppointmentMutation] = useMutation(requestAppointment, {
    onCompleted: () => {
      props.closeModal(modalIds.careProviderScheduleModal);

      if (props.medium === AppointmentMedium.Video) {
        track(
          'Provider Modal - "Submit request for availability" clicked for virtual',
          {
            deprecated: true,
            type: "Request for Availability",
            page: window.location.pathname,
            replaced_with: makeEventString(EVENT_TYPE.MODAL_OPENED, {
              page: window.location.pathname,
            }),
          },
        );
        TRACK_EVENT.MODAL_OPENED(
          window.location.pathname,
          "Request for Availability virtual",
        );
      } else {
        track(
          'Provider Modal - "Submit request for availability" clicked for in-person',
          {
            deprecated: true,
            replaced_with: makeEventString(EVENT_TYPE.MODAL_OPENED, {
              page: window.location.pathname,
            }),
          },
        );
        TRACK_EVENT.MODAL_OPENED(
          window.location.pathname,
          "Request for Availability In-Person",
        );
      }

      return props.openModal(modalIds.availabilityRequestModal, {
        ...props.provider,
      });
    },
    onError: (err) => props.addNotification(getFirstError(err), "error"),
  });

  useEffect(() => {
    props.appointmentsRefetch();
  }, []);

  const { t } = useTranslation("careProvider");

  const handleChange = (dateTime) => {
    props.setField(
      props.formKey,
      props.fieldKey,
      { selectedDateTime: dateTime, selectedMedium: props.medium },
      true, // sets dirty
    );
  };

  const showValidation = () => {
    return !props.valid && (props.dirty || props.value);
  };

  const slots = (appointments, appointmentsLoading) => {
    const days = {};

    if (appointmentsLoading)
      return (
        <FlexRow>
          <LoadingCircle />
        </FlexRow>
      );

    let display = DateTime.fromISO(props.start).plus({
      days: props.adjustment,
    });

    for (let i = 0; i < props.dayIncrement; i++) {
      const index = `${display.month}/${display.day}`;
      days[index] = {
        display: formatDateWithPascalCaseMonth(
          display.setLocale(props.locale).toFormat("EEE, LLL dd"),
        ),
        days: [],
      };
      display = display.plus({ days: 1 });
    }

    const available = appointments?.appointment_slots?.available;

    if (available) {
      available.forEach((appt, idx) => {
        let firstAndThirdIndex = [0, 2];
        if (
          // Track the first & third available time slot
          firstAndThirdIndex.includes(idx) &&
          props.adjustment === 0 &&
          (props.value.selectedMedium === getOr("", "medium", props) ||
            props.tabIndex >= 0) &&
          !props.isTracked
        ) {
          // Only track from the first 3 days

          const firstAvailableSlot = DateTime.fromISO(appt);
          const currentDateTimeIso = DateTime.local().toUTC();
          const diff = firstAvailableSlot.diff(currentDateTimeIso, "minutes");

          trackAvailabileAppointmentsViewed({
            index: idx,
            providerId: props.providerId,
            kind: props.kind,
            medium: props.medium,
            appointment: appt,
            modalId: props.modal_id,
            timeDifference: diff.minutes,
            queryRequestId: queryRequestId,
            isProfilePage: currentRoute?.includes("/members/providers/"),
          });
          props.setIsTracked(true);
        }

        const dateTime = DateTime.fromISO(appt);
        const localTime = dateTime.toLocal();
        const index = `${localTime.month}/${localTime.day}`;
        const localIso = localTime.toISO();
        const time = dateTime.toFormat("t");

        // If the day is past member termination date, exclude dates
        const terminateAt = props.terminateAt
          ? DateTime.fromISO(props.terminateAt)
          : null;
        if (
          terminateAt &&
          terminateAt.setZone(DateTime.local().zoneName) < dateTime
        ) {
          return;
        }

        // Therapist StartTime Buffer - 24 hrs from current DateTime

        let defaultStartTime;
        if (shouldShowDynamicLeadTime) {
          defaultStartTime = DateTime.local().plus({ hours: 3 }).toUTC();
        } else {
          defaultStartTime = DateTime.local().plus({ hours: 24 }).toUTC();
        }

        // Care Navigator StartTime Buffer - 3 hrs from current DateTime
        if (props.isACareNavigator) {
          defaultStartTime = DateTime.local().plus({ hours: 3 }).toUTC();
        }

        if (days[index]) {
          if (
            (!props.limit || days[index].days.length < props.limit) &&
            DateTime.fromISO(appt) > defaultStartTime
          ) {
            days[index].days.push(
              <Slot
                dataId={localIso}
                dataCy="time-slot"
                key={`${time}-${getOr("", "medium", props)}`}
                active={
                  props.value.selectedDateTime === localIso &&
                  props.value.selectedMedium === getOr("", "medium", props)
                }
                day={days[index].display}
                time={time}
                onClick={(e) => {
                  e.preventDefault();
                  handleChange(localIso); // Convert to ISO to keep the local timezone

                  if (isBrowseOrRecs) {
                    document.getElementById("schedule-button").scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }

                  // directsched029
                  trackTimeSlotInModalClicked({
                    appointmentKind: props?.kind,
                    appointmentMedium: props?.medium,
                    provider: props?.provider,
                    startTime: appt,
                    modalId: props?.modal_id,
                    queryRequestId: queryRequestId,
                    isProfilePage: currentRoute?.includes(
                      "/members/providers/",
                    ),
                  });
                }}
              />,
            );
          }
        }
      });
    }

    const daySlots = [];
    let i = 0;

    // Draw the day header and then the slots
    for (let day in days) {
      if (
        props.limit &&
        props.showMore &&
        days[day].days.length >= props.limit
      ) {
        days[day].days.push(
          <Slot
            dataCy="time-slot"
            key={`${days[day].display}-more-${getOr("", "medium", props)}`}
            day=""
            time="More"
            onClick={(e) => {
              e.preventDefault();
              props.moreAction();
              track("Schedule Therapy Visit -- Clicked Time Slot", {
                deprecated: true,
                replaced_with: "N/A",
              });
            }}
            role="button"
          />,
        );
      }

      const daysDisplay = days[day].days.length ? (
        days[day].days
      ) : (
        <div>{t("scheduleModal.unavailable")}</div>
      );

      daySlots.push(
        <div
          key={day}
          className={classnames(styles.column, {
            [styles.odd]: props.stripe && i % 2,
            [styles.basic]: !props.stripe,
          })}
        >
          <FormControl
            as="fieldset"
            className={styles.dayDisplay}
            aria-labelledby={`time-slot-${day}`}
          >
            <FormLabel
              as="legend"
              color={"#182023"}
              fontSize={"16px"}
              fontWeight={700}
              whiteSpace={"pre-wrap"}
              marginBottom={16}
              marginRight={0}
              textAlign={"center"}
              id={`time-slot-${day}`}
            >
              {days[day].display}
            </FormLabel>

            <div className={styles.dayDisplay}>
              <Stout>{daysDisplay}</Stout>
            </div>
          </FormControl>
        </div>,
      );

      i++;
    }

    return <div className={styles.slotsContainer}>{daySlots}</div>;
  };

  if (!props.available) {
    return (
      <div className={styles.requestAvailabilityContainer}>
        <div className={styles.requestAvailabilityHelperText}>
          <Content dark bold italic>
            Our team will reach out to you with more information about this
            provider's availability
          </Content>
        </div>

        <Button
          text={props.t("scheduleModal.requestInPersonAvailability.buttonText")}
          full
          flat
          transparent
          onClick={() => {
            requestAppointmentMutation({
              variables: {
                input: {
                  availability_days_of_week: ["Unknown"],
                  availability_time_of_day: ["Unknown"],
                  kind: props.kind,
                  medium: props.medium,
                  care_provider_id: props.providerId,
                },
              },
            });
          }}
        />
      </div>
    );
  }

  return (
    <InputWrapper
      valid={showValidation()}
      theme={props.theme}
      for="AppointmentSlots"
    >
      <div className={styles.calendar}>
        <FlexRow justification="space-between" alignment="flex-start">
          <Fab
            size="lg"
            icon="arrow-left"
            ariaLabel={props.t("scheduleModal.seePrevious")}
            onClick={(e) => {
              e.stopPropagation();
              props.setAdjustment(props.adjustment + -props.dayIncrement);

              // Mixpanel Event
              trackSlotPaginationButtonClicked({
                directionName: "Left Arrow",
                providerId: props.providerId,
                appointmentKind: props.kind,
                appointmentMedium: props.medium,
                modalId: props.modal_id,
              });
            }}
            square
            flat
          />
          {slots(appointments, appointmentsLoading)}
          <Fab
            size="lg"
            icon="arrow-right"
            dataCy="schedule-modal-next-page"
            ariaLabel={props.t("scheduleModal.seeNext")}
            onClick={(e) => {
              e.stopPropagation();
              props.setAdjustment(props.adjustment + props.dayIncrement);

              // Mixpanel Event
              trackSlotPaginationButtonClicked({
                directionName: "Right Arrow",
                providerId: props.providerId,
                appointmentKind: props.kind,
                appointmentMedium: props.medium,
                modalId: props.modal_id,
              });
            }}
            square
            flat
          />
        </FlexRow>
      </div>
    </InputWrapper>
  );
};

AppointmentTab.propTypes = {
  appointments: PropTypes.object,
  appointmentsLoading: PropTypes.bool,
  appointmentsRefetch: PropTypes.func,
  dirty: PropTypes.bool,
  fieldKey: PropTypes.string,
  formKey: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  setField: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),
  theme: PropTypes.oneOf(["material", "simple"]),
  valid: PropTypes.bool,
  start: PropTypes.string,
  end: PropTypes.string,
  increment: PropTypes.number,
  bookings: PropTypes.arrayOf(PropTypes.string),
  dayIncrement: PropTypes.number,
  terminateAt: PropTypes.string,
  kind: PropTypes.string,
  medium: PropTypes.string,
  limit: PropTypes.number, // limit results to showing <number> slots per day
  showMore: PropTypes.bool, // if limit is reached, show more slot action
  moreAction: PropTypes.func,
  requestAvailability: PropTypes.func,
  stripe: PropTypes.bool,
  consent: PropTypes.any,
  provider: PropTypes.object,
  providerId: PropTypes.string,
  t: PropTypes.any,
  isACareNavigator: PropTypes.bool,
  addNotification: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  available: PropTypes.arrayOf(PropTypes.string),
  adjustment: PropTypes.number,
  setAdjustment: PropTypes.func,
  tabIndex: PropTypes.number,
  isTracked: PropTypes.bool,
  setIsTracked: PropTypes.func,
  modal_id: PropTypes.string,
};

export { AppointmentTab };
export default compose(
  connect(null, { setField, addNotification, openModal, closeModal }),
)(AppointmentTab);
