import React from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  useDisclosure,
  VFilterIcon,
  VGlobalDuotoneSpot,
  VImprovementDuotoneSpot,
} from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";
import { ResourceCard } from "./components/ResourceCard";
import { useRouter } from "next/router";
import routes from "routes";
import Meowth from "@spring/meowth";
import { useQuery } from "@apollo/client";
import { getAvailableCareItems } from "./helpers";
import { links } from "@spring/constants";
import type { CareItem } from "modules/shared/graphql-codegen/graphql";
import { getMemberCareItems } from "modules/shared/graphql/queries/getMemberCareItems";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import BenefitPreferencesModal from "./components/BenefitPreferencesModal";

interface LoaderProps {
  isLoading: boolean;
  children: React.ReactNode;
}

function Loader({ children, isLoading }: LoaderProps) {
  if (isLoading) {
    return (
      <Center my={60}>
        <Spinner speed="1s" size="xl" />
      </Center>
    );
  }

  return <>{children}</>;
}

export function ResourcesTab() {
  const { t } = useTranslation("careVisits");
  const router = useRouter();
  const showConnectedBenefitsSection = useFeatureFlag(
    FLAGS.SHOW_CONNECTED_BENEFITS,
  );
  const {
    isOpen: isPreferencesModalOpen,
    onOpen: openPreferencesModal,
    onClose: closePreferencesModal,
  } = useDisclosure();

  const { data: careItemsData, loading: isCareItemsDataLoading } = useQuery(
    getMemberCareItems,
    {
      ...Meowth.apolloOptionsUserId(),
    },
  );

  const availableCareItems: CareItem[] | undefined =
    getAvailableCareItems(careItemsData);

  const hasWorkplaceItem = availableCareItems?.some(
    ({ care_item_name }) =>
      care_item_name === "sage_content_to_develop_workplace_skills",
  );

  return (
    <>
      <Box mb="32">
        <Heading size="heading-small" mb="24">
          {t("resourcesTab.fromYourCommunityHeading")}
        </Heading>
        <Flex wrap="wrap" gap="16">
          <ResourceCard
            dataCy="sdoh_local_resources"
            description={t("resourcesTab.sdohLocalResources.description")}
            heading={t("resourcesTab.sdohLocalResources.heading")}
            icon={VGlobalDuotoneSpot}
            onClick={() =>
              router.push(routes.LocalResources.to, routes.LocalResources.as)
            }
            trackingAction={routes.LocalResources.as}
          />
        </Flex>
      </Box>
      <Loader isLoading={isCareItemsDataLoading}>
        {hasWorkplaceItem ? (
          <Box as="section" mb="32">
            <Heading size="heading-small" mb="24">
              {t("resourcesTab.fromSpringHeading")}
            </Heading>
            <Flex wrap="wrap" gap="16">
              <ResourceCard
                dataCy="sage_content_to_develop_workplace_skills"
                description={t("resourcesTab.sageContent.description")}
                heading={t("resourcesTab.sageContent.heading")}
                icon={VImprovementDuotoneSpot}
                isExternal
                onClick={() =>
                  window.open(links.TargetSageContentURL, "_blank")
                }
                trackingAction={"External link"}
              />
            </Flex>
          </Box>
        ) : null}
      </Loader>
      {showConnectedBenefitsSection && (
        <Box as="section" mb="32">
          <Flex justifyContent="space-between" alignItems="center" mb="24">
            {/* Placeholder header until MXB-139 is completed */}
            <Heading size="heading-small">From [Employer/payer]</Heading>
            <Button
              variant="medium-emphasis"
              size="sm"
              icon={VFilterIcon}
              onClick={openPreferencesModal}
            >
              Preferences
            </Button>
          </Flex>
          <BenefitPreferencesModal
            isOpen={isPreferencesModalOpen}
            onClose={closePreferencesModal}
          />
        </Box>
      )}
    </>
  );
}
