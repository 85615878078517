import { Text } from "@springcare/sh-component-library";
import { MenuButton, Box } from "design-system/components";
import { NotificationSvgIcon } from "components/atoms";
import { AdultSvg } from "components/atoms";

export const NameIntials = ({ firstName, lastName }) => {
  if (!firstName || !lastName) {
    return <AdultSvg />;
  }
  return (
    <Text
      width="18px"
      height="16px"
      left="11px"
      fontFamily="HKGroteskRegular"
      fontSize="12px"
      fontWeight="600"
      align="center"
      color={"#482D28"}
    >
      {firstName.charAt(0).toUpperCase()}
      {lastName.charAt(0).toUpperCase()}
    </Text>
  );
};
export const ProfileAvatar = ({
  firstName,
  lastName,
  memberPaymentCollectionLDFlag,
  showBillingNotificationBubble,
}) => {
  return (
    <MenuButton
      data-cy="member-right-dropdown"
      p="12px 11px 12px 11px"
      ms="15px"
      maxHeight="40px"
      h="40px"
      w="40px"
      background="#5F4B191A"
      borderRadius="40px"
      alignContent="center"
      _focus={{ border: "1px #007e5e solid" }}
      _focusVisible={{ outline: "2px solid black", outlineOffset: "4px" }}
      _active={{ border: "1px #007e5e solid" }}
      aria-label={firstName + " " + lastName}
      display="inline-block"
      position="relative"
      whiteSpace="nowrap"
    >
      <Box right={0} top={0} position="absolute">
        {memberPaymentCollectionLDFlag && showBillingNotificationBubble && (
          <NotificationSvgIcon />
        )}
      </Box>
      <NameIntials firstName={firstName} lastName={lastName} />
    </MenuButton>
  );
};
