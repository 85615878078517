import { useMutation } from "@apollo/client";
import React from "react";
import {
  Button,
  Modal,
  FlexCol,
  DangerousHtml,
  MomentsContentIcons,
  Content,
} from "@spring/smeargle";
import {
  openModal,
  closeModal,
  addNotification,
} from "@spring/smeargle/actions";
import { modalIds } from "@spring/constants";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import completeStep from "operations/mutations/exercise/completeStep";

import styles from "./styles.module.scss";

import { getFirstError } from "utils/apollo/errorHandler";
import { toTitleCase } from "utils/mixpanel";
import { Heading } from "design-system/components";
import { trackMomentStartClicked } from "modules/MemberDashboard/Moments/components/analytics";

const MomentsStartExerciseModal = (props) => {
  const { t } = useTranslation(["moments", "a11y"]);

  const [completeCurrentStep] = useMutation(completeStep, {
    onCompleted: ({ completeStep: { next_step, success } }) => {
      if (success) {
        props.closeModal(modalIds.momentsStartExerciseModal);
        props.openModal(modalIds.momentsExerciseModal, {
          exercise: props.exercise,
          current_step: next_step,
          setReturnFocus: props.exercise.setReturnFocus,
        });
      }
    },
    onError: (err) => props.addNotification(getFirstError(err), "error"),
  });

  const completeStartingStep = async () => {
    await completeCurrentStep({
      variables: {
        exercise_id: props.exercise.id,
        current_step_id: props.exercise.starting_step.id,
      },
    });
  };

  const [bookIconProps, headphonesIconProps] = [
    "icons.bookAlt",
    "icons.headphonesAlt",
  ].map((key) => ({
    "aria-label": t(key, { ns: "a11y" }),
  }));
  const ariaLabeledBy = props?.exercise?.title?.replaceAll(" ", "");

  return (
    <Modal
      id={modalIds.momentsStartExerciseModal}
      size="lg"
      ariaLabeledBy={ariaLabeledBy}
    >
      {props.exercise && (
        <FlexCol center justification="space-between">
          <div
            style={{ backgroundImage: `url(${props.exercise.images[0].url})` }}
            className={styles.imageWrapper}
          />
          <Heading
            as={"h1"}
            fontSize={20}
            fontWeight={"bold"}
            margin={"24px 0"}
            data-cy="moments-exercise-modal-sub-title"
            id={ariaLabeledBy}
          >
            {props.exercise.title}
          </Heading>
          <Content>
            {props.exercise.duration
              ? props.exercise.duration + t("startExerciseModal.min")
              : ""}
            <span className={styles.icons}>
              <MomentsContentIcons
                types={props.exercise?.content_formats || []}
                bookIconProps={bookIconProps}
                headphonesIconProps={headphonesIconProps}
              />
            </span>
          </Content>
          <Content center>
            <div className={styles.content}>
              <DangerousHtml>
                {props.exercise.starting_step.elements[0].content}
              </DangerousHtml>
            </div>
          </Content>
          <div className={styles.buttonWrapper}>
            <Button
              full
              flat
              text={t("startExerciseModal.getStarted")}
              dataCy="moments-get-started"
              onClick={() => {
                trackMomentStartClicked(
                  props.exercise.id,
                  props.exercise.title,
                  props.exercise.categories
                    ?.map((item) => toTitleCase(item.category))
                    .join(", "),
                  props.exercise.content_formats?.join(", "),
                );

                completeStartingStep();
              }}
            />
          </div>
        </FlexCol>
      )}
    </Modal>
  );
};

MomentsStartExerciseModal.propTypes = {
  addNotification: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  exercise: PropTypes.shape({
    content_formats: PropTypes.any,
    categories: PropTypes.any,
    duration: PropTypes.func,
    id: PropTypes.any,
    images: PropTypes.any,
    starting_step: PropTypes.shape({
      elements: PropTypes.any,
      id: PropTypes.any,
    }),
    title: PropTypes.any,
  }),
  openModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  exercise: getOr(
    null,
    `modal.${modalIds.momentsStartExerciseModal}.data`,
    state,
  ),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  addNotification,
})(MomentsStartExerciseModal);
