import {
  Flex,
  Box,
  Button,
  VCareNavigatorFlatSpot,
} from "@springcare/sh-component-library";

import SessionDetailsContainer from "components/templates/SHSessionRoom/components/SessionDetailsContainer/SessionDetailsContainer";
import SessionDetailsHeader from "components/templates/SHSessionRoom/components/SessionDetailsContainer/SessionDetailsHeader";
import SessionDetailsBody from "components/templates/SHSessionRoom/components/SessionDetailsContainer/SessionDetailsBody";

import { useSessionViewStatus } from "context/SessionRoomContext";

const SessionUnavailableView = ({ sessionData, isMobile }) => {
  const { sessionViewStatus, SessionViewStatus } = useSessionViewStatus();

  /* NOTE: The copy will change - the idea is a Session will be valid for a week before and a week after the scheduled time */
  const mainHeaderText =
    sessionViewStatus === SessionViewStatus.TooEarly
      ? "Just a bit too early"
      : "Session unavailable";
  const subHeaderText =
    sessionViewStatus === SessionViewStatus.TooEarly
      ? "You can access your therapy session a week before the scheduled time."
      : "Your therapy session is no longer available.";
  {
    /* NOTE: The spot icon will change, design is undecided */
  }
  const Icon =
    sessionViewStatus === SessionViewStatus.TooEarly
      ? VCareNavigatorFlatSpot
      : VCareNavigatorFlatSpot;

  return (
    <SessionDetailsContainer isMobile={isMobile}>
      <Flex
        w={["100%", "100%", 272, 272]}
        flexDir="column"
        alignSelf="center"
        flex="1"
      >
        <SessionDetailsHeader
          mainHeaderText={mainHeaderText}
          subHeaderText={subHeaderText}
          isMobile={isMobile}
        />

        <SessionDetailsBody sessionData={sessionData}>
          <Box>
            <Button
              w="100%"
              variant="high-emphasis"
              // onClick={???}
            >
              Close
            </Button>
          </Box>
        </SessionDetailsBody>
      </Flex>

      <Flex flex="1" display={["none", "none", "block", "block"]}>
        <Icon width={436} height={436} />
      </Flex>
    </SessionDetailsContainer>
  );
};

export default SessionUnavailableView;
