//

import { get } from "lodash/fp";
import { setIn } from "@spring/immutability";

import {
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  TOGGLE_SIDEBAR,
  UPDATE_SEEN_MOBILE_MODAL,
} from "actions/memberDashboard/types";

export const initialState = {
  sidebarOpen: true,
  hasSeenMobileModal: false,
};

const memberDashboard = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return setIn("sidebarOpen", true, state);

    case CLOSE_SIDEBAR:
      return setIn("sidebarOpen", false, state);

    case TOGGLE_SIDEBAR:
      return setIn("sidebarOpen", !get("sidebarOpen", state), state);

    case UPDATE_SEEN_MOBILE_MODAL:
      return setIn("hasSeenMobileModal", true, state);
    default:
      return state;
  }
};

export default memberDashboard;
