import { gql } from "@apollo/client";

const browseCareProviderData = gql`
  fragment browseCareProviderData on BrowseCareProviderResult {
    id
    created_at
    roles
    name
    first_name
    last_name
    avatar
    bio
    licenses
    languages
    on_site
    dedicated_customer {
      id
      name
    }
    cohorts
    dedicated
    states
    tags
    email
    phone
    user_id
    acuity_calendar_id
    scheduling_url
    zoom_url
    in_person_supported
    virtual_supported
    care_provider_tags {
      id
      kind
      name
      created_at
      updated_at
    }
    coordinates {
      latitude
      longitude
    }
    distance_kilometers
    distance_miles
    street_address_1
    street_address_2
    city
    state
    zip_code
    supported_appointment_kinds
    supported_appointment_mediums
    licensed_countries
    categories
    next_available_time_slot
  }
`;

export default browseCareProviderData;
