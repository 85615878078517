import { countries, ProviderRole } from "@spring/constants";
import { useWindowSize } from "@spring/hooks";
import { schedulingAccessName } from "constants/schedulingAccessName";
const unitedStates = countries.find((entry) => entry.iso2 === "us");
const MAX_WIDTH_MOBILE = 960;
//TODO: unify with @spring/constants isUnitedStatesOrCanada and test.
const isUnitedStatesOrCanada = (country) => {
  if (!country) {
    return false;
  }
  const options = [
    "US",
    "USA",
    "UNITED STATES",
    "UNITED STATES OF AMERICA",
    "CA",
    "CAN",
    "CANADA",
  ];
  return options.includes(country.toUpperCase());
};

const normalizeLongNames = (country) => {
  // Sometimes, country names are encoded with "%2B" in the query param, which decodes to "+".
  const searchCountry = country.replace(/\+/g, " ");
  if (searchCountry.includes("united states")) {
    return "united states of america";
  } else if (searchCountry.includes("united kingdom")) {
    return "united kingdom of great britain and northern ireland";
  }
  return searchCountry;
};

const getCountryByKey = (country, format) => {
  return countries.find((entry) => entry[format] === country);
};

const confirmCountryExists = (country, format) => {
  return !!countries.find((entry) => entry[format] === country);
};

const getDesiredFormatByKey = (country, inputFormat, outputFormat) => {
  const entry = getCountryByKey(country, inputFormat);
  if (!entry || !entry[outputFormat]) {
    // Default to United States if given country does not exist
    return unitedStates[outputFormat];
  }

  return entry[outputFormat];
};

const normalizeToISO2 = (country) => {
  if (!country) {
    return "US";
  }

  let searchCountry = country.trim().toLowerCase();

  if (searchCountry.length === 2) {
    // If the given ISO2 value doesn't correspond with a country, return US
    return confirmCountryExists(searchCountry, "iso2")
      ? searchCountry.toUpperCase()
      : "US";
  } else if (searchCountry.length === 3) {
    return getDesiredFormatByKey(searchCountry, "iso3", "iso2").toUpperCase();
  }

  // Catch long name variants and remove '+' characters
  searchCountry = normalizeLongNames(searchCountry);
  return getDesiredFormatByKey(searchCountry, "name", "iso2").toUpperCase();
};

const normalizeToISO3 = (country) => {
  if (!country) {
    return "USA";
  }

  let searchCountry = country.trim().toLowerCase();

  if (searchCountry.length === 2) {
    return getDesiredFormatByKey(searchCountry, "iso2", "iso3").toUpperCase();
  } else if (searchCountry.length === 3) {
    // If the given ISO3 value doesn't correspond with a country, return USA
    return confirmCountryExists(searchCountry, "iso3")
      ? searchCountry.toUpperCase()
      : "USA";
  }

  // Catch long name variants and remove '+' characters
  searchCountry = normalizeLongNames(searchCountry);
  return getDesiredFormatByKey(searchCountry, "name", "iso3").toUpperCase();
};

const normalizeToName = (country) => {
  if (!country) {
    return "United States";
  }

  let searchCountry = country.trim().toLowerCase();

  if (searchCountry.length === 2) {
    return getDesiredFormatByKey(searchCountry, "iso2", "displayName");
  } else if (searchCountry.length === 3) {
    return getDesiredFormatByKey(searchCountry, "iso3", "displayName");
  }

  // Catch long name variants and remove '+' characters
  searchCountry = normalizeLongNames(searchCountry);
  // We want to return display name here as it is correctly capitalized, includes any special characters, and is the official name of the country
  return getDesiredFormatByKey(searchCountry, "name", "displayName");
};

const lookupCountry = (country) => {
  if (!country) {
    return null;
  }

  let searchCountry = country.trim().toLowerCase();

  if (searchCountry.length === 2) {
    return getCountryByKey(searchCountry, "iso2") || null;
  } else if (searchCountry.length === 3) {
    return getCountryByKey(searchCountry, "iso3") || null;
  }

  // Catch long name variants and remove '+' characters
  searchCountry = normalizeLongNames(searchCountry);
  return getCountryByKey(searchCountry, "name") || null;
};

// Format phone numbers, uscode1234 to +1 1234
const formatPhoneForDisplay = (number) => {
  if (!number || !number.includes("code")) {
    return number;
  }
  const phoneArray = number.split("code");
  return "+" + lookupCountry(phoneArray[0])?.dialCode + " " + phoneArray[1];
};

const shouldShowStateField = (inputCountry) => {
  const country = normalizeToISO2(inputCountry);
  if (["AR", "CA", "MX", "US"].includes(country)) {
    return true;
  }
  return false;
};

const isStateMailingEnabled = (inputCountry) => {
  let selectedCountryData = countries.find(
    (entry) => entry["iso2"] === normalizeToISO2(inputCountry)?.toLowerCase(),
  );
  if (selectedCountryData?.stateMailing) return true;

  return false;
};

const mobileAndTabletCheck = () => {
  const currentWindowSize = useWindowSize();
  if (currentWindowSize <= MAX_WIDTH_MOBILE) {
    return true;
  }
  return false;
};

// For CN scheduling and International No Scheduling, we block therapy scheduling on /browse/therapist and show a different
// verbiage for therapists on /members/care_visits
const isCNSchedulingOrNoScheduling = (
  schedulingAccess,
  providerType = "therapist",
) => {
  return (
    (schedulingAccess === schedulingAccessName.InternationalCNScheduling ||
      schedulingAccess === schedulingAccessName.InternationalNoScheduling) &&
    providerType === ProviderRole.Therapist
  );
};

const isCNScheduling = (schedulingAccess) =>
  schedulingAccess === schedulingAccessName.InternationalCNScheduling;

const isGlobalUser = (countryCode) =>
  countryCode && countryCode.length > 0 && countryCode !== "US";

export {
  isUnitedStatesOrCanada,
  normalizeToISO2,
  normalizeToISO3,
  normalizeToName,
  lookupCountry,
  formatPhoneForDisplay,
  shouldShowStateField,
  isStateMailingEnabled,
  mobileAndTabletCheck,
  isCNScheduling,
  isCNSchedulingOrNoScheduling,
  isGlobalUser,
};
