import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router, { useRouter } from "next/router";
import { DateTime } from "luxon";
import { get, getOr } from "lodash/fp";
import {
  FormControl,
  FormLabel,
  Heading,
  Avatar,
  Text,
  Box,
} from "design-system/components";
import { Link } from "@springcare/sh-component-library";
import { Switch } from "@chakra-ui/react";
import {
  Bolded,
  Col,
  Content,
  FlexRow,
  Button,
  Form,
  Grid,
  HorizontalRule,
  Icon,
  Input,
  PhoneInput,
  Section,
  Select,
  Subtitle,
} from "@spring/smeargle";
import Meowth from "@spring/meowth";
import { addNotification } from "@spring/smeargle/actions";
import { Trans, useTranslation } from "react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import styles from "./styles.module.scss";
import DeleteAccount from "./DeleteAccount";
import ConfigureMfa from "./ConfigureMfa";
import {
  MemberAddressForm,
  MemberLanguageForm,
  MemberEmergencyContact,
  MemberEmailNotifications,
} from "components";
import { signIn } from "actions/auth/actions";
import { getUserInfo } from "operations/queries/user";
import { updateUser } from "operations/mutations/user";
import { updateMember } from "operations/mutations/member";
import { getSchema as getPasswordSchema } from "schemas/memberPasswordForm";
import ReminderFrequencySchema from "schemas/reminderFrequencyForm";
import { isMinor, isT2Member, parseBirthday } from "utils/memberHelpers";
import { getFirstError } from "utils/apollo/errorHandler";
import {
  track,
  TRACK_EVENT,
  makeEventString,
  EVENT_TYPE,
} from "utils/mixpanel";
import { isGlobalUser, normalizeToName } from "utils/global";
import { removeCountryCodeFromPhone } from "utils/displayHelpers";
import routes from "routes";
import getSchema from "schemas/memberSettingsForm";
import useSchema from "schemas/use_schema";
import ChangeCountry from "./ChangeCountry";
import { SettingsInsuranceForm } from "./SettingsInsuranceForm";
import { RenderChildAvatar } from "components/templates/ChooseUserPage/utils/RenderChildAvatar";
import { links } from "@spring/constants";
import { ConfigureWorkplaceAccess } from "./ConfigureWorkplaceAccess";
import { FetchResult, useMutation, useQuery } from "@apollo/client";
import { getMemberCheckInFrequency } from "operations/queries/member";

const formKey = "memberSettings";
const mpPrefix = "Member Settings";

const MemberSettingsForm = () => {
  const { showGlobalExperience, country: resCountry } = useSelector(
    (state: any) => state.global,
  );

  const {
    loading: userLoading,
    error: userError,
    data: userInfo,
  } = useQuery(getUserInfo, {
    variables: { id: Meowth.getUserId() },
  });
  const { data: frequency, loading: frequencyLoading } = useQuery(
    getMemberCheckInFrequency,
    { variables: { id: Meowth.getUserId() } },
  );

  const initSmsOptIn = getOr(false, "user.member.sms_opt_in", userInfo);
  const isMemberT2 = isT2Member(getOr({}, "user.member", userInfo));

  const { t } = useTranslation(["limitedLangSettings", "limitedLangAuth"]);
  const [smsOptIn, setSmsOptIn] = useState(initSmsOptIn);

  const canSetWorkplaceAccess = useFeatureFlag(
    FLAGS.ENABLE_WORKPLACE_MANAGER_NAV_LINK,
  );

  const shouldShowSMSOptIn = !isMemberT2; // T2 members don't direct schedule, so SMS reminders don't make sense

  const router = useRouter();
  const insuranceRef = useRef(null);

  const schema = useSchema({
    schemaFunction: getSchema,
    translationNamespace: ["limitedLangSettings", "limitedLangAuth"],
  });
  const passwordSchema = useSchema({
    schemaFunction: getPasswordSchema,
    translationNamespace: "limitedLangValidations",
  });

  useEffect(() => {
    if (router.query.updateInsuranceForm && insuranceRef?.current) {
      window.scrollTo({
        top: insuranceRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [insuranceRef.current]);

  const [doUpdateUser] = useMutation(updateUser, {
    refetchQueries: ["getUserInfo", "getMemberInfo"],
  });
  const [doUpdateMember] = useMutation(updateMember);
  const dispatch = useDispatch();

  const submitBasic = async (formData) => {
    const updateUserPayload = {
      id: userInfo.user.id,
      patch: {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        phone: formData.phone.number,
        country_code: formData.phone.code,
      },
    };
    const updateMemberPayload = {
      variables: {
        input: {
          id: userInfo.user.member.id,
          patch: {
            sms_opt_in: smsOptIn,
          },
        },
      },
    };

    const emailChanged = userInfo.user.email !== formData.email;

    try {
      let updateMemberPromise:
        | Promise<FetchResult<any>>
        | { data: { updateMember: { success: boolean } } } = {
        data: { updateMember: { success: true } },
      };

      // If the value of smsOptIn hasn't changed, do not make a graphql request
      if (initSmsOptIn !== smsOptIn) {
        updateMemberPromise = doUpdateMember(updateMemberPayload);

        TRACK_EVENT.FORM_SUBMITTED(
          routes.MemberSettings.as,
          "Update Sms Opt In",
          {
            location: "SMS Opt In",
            enable_sms: smsOptIn,
          },
        );
      }

      const [updateMemberResponse, updateUserResponse] = await Promise.all([
        updateMemberPromise,
        doUpdateUser({
          variables: { input: updateUserPayload },
        }),
      ]);

      const {
        data: { updateMember },
      } = updateMemberResponse;
      const {
        data: { updateUser },
      } = updateUserResponse;

      if (!updateMember.success || !updateUser.success) {
        dispatch(addNotification(t("notifications.genericError"), "error"));
      }

      const notification = emailChanged
        ? t("notifications.changesSaved") + t("notifications.emailChanged")
        : t("notifications.changesSaved");

      return dispatch(addNotification(notification, "success"));
    } catch (err) {
      // this is the id of the first field in account details form
      dispatch(addNotification(getFirstError(err), "error"));
    }
  };

  const submitPassword = (formData) => {
    const { current_password, password, password_confirmation } = formData;

    const payload = {
      id: userInfo.user.id,
      patch: {
        current_password,
        password,
        password_confirmation,
      },
    };

    doUpdateUser({
      variables: { input: payload },
      onCompleted: (res) => {
        if (!res.updateUser.success) {
          dispatch(addNotification(t("notifications.genericError"), "error"));
        } else {
          dispatch(addNotification(t("notifications.changesSaved"), "success"));

          const { access_token, client, expiry } = getOr(
            {},
            "updateUser.session",
            res,
          );
          Meowth.updateHeaders(access_token, client, expiry);
          signIn(get("user.email", userInfo), password);
        }
      },
      onError: (err) => {
        dispatch(addNotification(getFirstError(err), "error"));
      },
    });
  };

  const initialData = () => {
    const address = getOr({}, "user.member.postal_address", userInfo);
    const { city = "", state = "", country = "" } = address;
    const cityData = [city, state, country].filter(Boolean).join(", ");
    const { phone, date_of_birth, country_code } = userInfo.user;

    const normalizedCode = country_code || "US";

    const phoneInput = {
      number: removeCountryCodeFromPhone(phone, normalizedCode),
      code: normalizedCode.toLowerCase(),
      country: normalizeToName(normalizedCode),
    };

    const dobInput = DateTime.fromObject(parseBirthday(date_of_birth)).toFormat(
      "MMMM dd, yyyy",
    );

    const updatedUserData = {
      ...userInfo.user,
      ...{ phone: phoneInput, date_of_birth: dobInput },
    };

    return {
      ...updatedUserData,
      postal_address: {
        street_address_1: address.street_address_1,
        zip_code: address.zip_code ? address.zip_code : null,
        city: cityData,
        country: resCountry,
      },
    };
  };

  const guardianData = () => {
    const manager = getOr({}, "user.member.manager", userInfo);

    return {
      guardian_first_name: manager?.first_name || "NA",
      guardian_last_name: manager?.last_name || "NA",
      guardian_email: manager?.email || "NA",
    };
  };

  const accountDetails = () => {
    return (
      <Section>
        <Form
          dataCy="accountDetails"
          formKey={formKey}
          theme="simple"
          schema={schema}
          initialData={initialData()}
          unboundSubmit={(formData) => submitBasic(formData)}
          submitIcon="arrow-right"
          submitText={t("common:form.submitText")}
          submitAriaLabel={t("a11y:submit.accountDetails")}
        >
          <h2 className={styles.formHeader}>{t("accountDetails.title")}</h2>
          <fieldset>
            <legend hidden>{t("accountDetails.title")}</legend>
            <Text style={{ marginBottom: 10 }}>
              {t("accountDetails.subheading")}
            </Text>
            <Grid gutter="0 8px">
              <Col sm={6}>
                <Input
                  fieldKey="first_name"
                  label={t("accountDetails.firstName")}
                  dataCy="firstName"
                  autoComplete="given-name"
                  renderLabelFirst={true}
                />
              </Col>
              <Col sm={6}>
                <Input
                  fieldKey="last_name"
                  label={t("accountDetails.lastName")}
                  dataCy="lastName"
                  autoComplete="family-name"
                  renderLabelFirst={true}
                />
              </Col>
              <Col sm={12}>
                <Input
                  fieldKey="date_of_birth"
                  label={t("accountDetails.dateOfBirth")}
                  disabled
                  autoComplete="bday"
                  renderLabelFirst={true}
                />
                <Text color="#6E7072">
                  {
                    <Trans
                      ns={"limitedLangSettings"}
                      i18nKey={t("accountDetails.dobDescription")}
                      components={[
                        <Link
                          href={links.Support}
                          target="_blank"
                          className={styles.inlineTextLink}
                          key="accountDetails.dobDescription"
                        />,
                      ]}
                    />
                  }
                </Text>
              </Col>
              <Col size={12}>
                <Input
                  fieldKey="email"
                  label={t("accountDetails.email")}
                  dataCy="email"
                  autoComplete="email"
                  renderLabelFirst={true}
                />
              </Col>
              <Col size={12}>
                <PhoneInput
                  fieldKey="phone"
                  label={t("accountDetails.phoneNumber")}
                  dataCy="phone"
                />
              </Col>
              {shouldShowSMSOptIn && (
                <Col size={12}>
                  <FormControl
                    isRequired
                    mt={15}
                    display="flex"
                    alignItems="center"
                  >
                    <Switch
                      id="sms-opt-in"
                      colorScheme="primary"
                      sx={{
                        ".chakra-switch__track": {
                          _focus: { boxShadow: "0 0 0 3px black" },
                        },
                      }}
                      _focusVisible={{ boxShadow: "0 0 0 3px black" }}
                      isChecked={smsOptIn}
                      onChange={() => {
                        setSmsOptIn((prevSmsOptIn) => !prevSmsOptIn);
                      }}
                      // @ts-expect-error
                      dataCy="smsOptIn"
                    />
                    <FormLabel
                      color="#6a6a6a"
                      requiredIndicator={<span aria-hidden="true"> *</span>}
                      htmlFor="sms-opt-in"
                      ms="2"
                      mb={0}
                    >
                      {t("accountDetails.enableSMS")}
                    </FormLabel>
                  </FormControl>
                </Col>
              )}
            </Grid>
          </fieldset>
        </Form>
      </Section>
    );
  };

  const addressDetails = () => {
    return (
      <Grid gutter="0 8px">
        <Col size={12}>
          {/* @ts-ignore */}
          <MemberAddressForm formHeader={t("address.title")} />
        </Col>
      </Grid>
    );
  };

  const emergencyContactDetails = () => {
    return (
      <Grid gutter="0 8px">
        <Col size={12}>
          <MemberEmergencyContact member={userInfo.user.member} />
        </Col>
      </Grid>
    );
  };

  // TODO: Update default values to show guardian's first name, last name and dob
  const legalGuardianDetails = () => {
    return (
      <div className={styles.legalGuardianContainer}>
        <Section>
          <Subtitle bold noMargin>
            {t("legalGuardianDetails.title")}
          </Subtitle>
          <Form theme="simple" initialData={guardianData()}>
            <Grid gutter="0 8px">
              <Col sm={6}>
                <Input
                  fieldKey="guardian_first_name"
                  label={t("accountDetails.firstName")}
                  disabled
                />
              </Col>
              <Col sm={6}>
                <Input
                  fieldKey="guardian_last_name"
                  label={t("accountDetails.lastName")}
                  disabled
                  renderLabelFirst={true}
                />
              </Col>
              <Col size={12}>
                <Input
                  fieldKey="guardian_email"
                  label={t("accountDetails.email")}
                  disabled
                  renderLabelFirst={true}
                  description={t("legalGuardianDetails.description")}
                />
              </Col>
            </Grid>
          </Form>
        </Section>
      </div>
    );
  };

  const resetPassword = () => {
    return (
      <Section>
        <Form
          formKey="passwordSettings"
          theme="simple"
          onSubmit={(formData) => submitPassword(formData)}
          submitIcon="arrow-right"
          submitText={t("common:form.submitText")}
          schema={passwordSchema}
          dataCy="reset-password"
          submitAriaLabel={t("a11y:submit.resetPasswordReq")}
        >
          <h2 className={styles.formHeader}>{t("resetPassword.title")}</h2>
          <fieldset>
            <legend hidden>{t("resetPassword.title")}</legend>
            <Input
              type="password"
              fieldKey="current_password"
              label={t("resetPassword.currentPassword")}
              dataCy="current-password"
              autoComplete="current-password"
              renderLabelFirst={true}
            />
            <Input
              type="password"
              fieldKey="password"
              label={t("resetPassword.newPassword")}
              dataCy="new-password"
              renderLabelFirst={true}
            />
            <Input
              type="password"
              fieldKey="password_confirmation"
              label={t("resetPassword.repeatPassword")}
              dataCy="confirm-password"
              renderLabelFirst={true}
            />
          </fieldset>
        </Form>
      </Section>
    );
  };

  const submitCheckInNotifications = (formData) => {
    track("Check-inFrequency Set", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.FORM_SUBMITTED, {
        page: routes.MemberSettings.as,
        type: "Update Check-In Frequency",
        value: +formData.check_in_frequency_days,
        location: "Check-In Notifications",
      }),
    });

    TRACK_EVENT.FORM_SUBMITTED(
      routes.MemberSettings.as,
      "Update Progress Check In Frequency",
      {
        location: "Check In Notifications",
        value: +formData.check_in_frequency_days,
        spring_doc_id: "settings04",
      },
    );

    doUpdateMember({
      variables: {
        input: {
          id: userInfo.user.member.id,
          patch: {
            check_in_frequency_days: +formData.check_in_frequency_days,
          },
        },
      },
      onCompleted: (res) => {
        if (!res.updateMember.success) {
          dispatch(addNotification(t("notifications.genericError"), "error"));
        } else {
          dispatch(addNotification(t("notifications.changesSaved"), "success"));
        }
      },
      onError: (err) => {
        dispatch(addNotification(getFirstError(err), "error"));
      },
    });
  };

  const checkInNotifications = () => {
    const checkInFrequencyDays = get(
      "user.member.check_in_frequency_days",
      frequency,
    );

    return (
      <Section>
        <Heading fontSize={20} fontWeight={"bold"} color="#4b4b4b">
          {t("checkInNotifications.title")}
        </Heading>
        <Section>
          <Content>{t("checkInNotifications.subtitle")}</Content>
        </Section>
        <Form
          formKey="checkInNotifications"
          theme="simple"
          schema={ReminderFrequencySchema}
          onSubmit={(formData) => submitCheckInNotifications(formData)}
          submitIcon="arrow-right"
          submitText={t("common:form.submitText")}
          submitAriaLabel={t("a11y:submit.emailReminderPref")}
          initialData={{
            check_in_frequency_days:
              typeof checkInFrequencyDays === "number"
                ? checkInFrequencyDays.toString()
                : 14,
          }}
        >
          <label className={styles.labelText}>
            <Icon
              type="progress-check"
              className={styles.icon}
              ariaHidden="true"
            />
            {t("checkInNotifications.remindMe")}
          </label>
          <Select
            ariaLabel={t("checkInNotifications.remindMe")}
            fieldKey="check_in_frequency_days"
            placeholder={t("checkInNotifications.placeholder")}
            placeholderPadding="15px"
            options={[
              { label: t("checkInNotifications.fourWeeks"), value: 28 },
              { label: t("checkInNotifications.twoWeeks"), value: 14 },
              { label: t("checkInNotifications.noTracking"), value: 0 },
            ]}
          />
        </Form>
      </Section>
    );
  };

  const FeedbackForm = () => {
    return (
      <>
        <Section>
          <Heading fontSize={20} fontWeight={"bold"} color="#4b4b4b">
            {t("feedback.title")}
          </Heading>
        </Section>
        <Section>
          <Content>
            <Trans
              ns={"limitedLangSettings"}
              i18nKey={"feedback.subtitle"}
              components={[
                <Link
                  target="_blank"
                  colorScheme="base"
                  key={"0"}
                  href={
                    "https://panelfox.io/panel-profile/b4db6070-a695-468c-b4b0-04101108b9f7/776"
                  }
                />,
              ]}
            />
          </Content>
        </Section>
      </>
    );
  };

  const dependentSubtitleText = () => {
    const { dependents_allowed, child_dependents_allowed } = getOr(
      false,
      "user.member.cohort",
      userInfo,
    );
    const companyName = getOr("Your company", "user.customer.name", userInfo);

    if (dependents_allowed && child_dependents_allowed)
      return (
        <Content>
          {t("inviteDependents.subTitle", { companyName: companyName })}
        </Content>
      );
    if (dependents_allowed && !child_dependents_allowed)
      return (
        <Content>
          {t("inviteDependents.subTitleSupportsAdultDependents", {
            companyName: companyName,
          })}
        </Content>
      );
    if (!dependents_allowed && child_dependents_allowed)
      return (
        <Content>
          {t("inviteDependents.subTitleSupportsChildDependents", {
            companyName: companyName,
          })}
        </Content>
      );

    return null;
  };

  const dependentProfiles = () => {
    const { managed_dependents } = getOr({}, "user.member", userInfo);
    const avatarWidth = 25;
    const avatarHeight = 25;
    if (managed_dependents) {
      return managed_dependents.map((dependent) => {
        const ChildAvatar = (dependent) => () =>
          RenderChildAvatar({ dependent, avatarWidth, avatarHeight });
        const avatarBackgroundSize = 50;
        return (
          <li className={styles.dependentContainer} key={dependent.id}>
            <FlexRow alignment="start" justification="space-between">
              <FlexRow alignment="start">
                <Avatar
                  mr={10}
                  aria-hidden="true"
                  icon={ChildAvatar(dependent)}
                  height={avatarBackgroundSize}
                  width={avatarBackgroundSize}
                />
                <Box w={[100, 300]}>
                  <Text className={styles.dependentName}>
                    <Bolded>
                      {dependent.first_name} {dependent.last_name}
                    </Bolded>
                  </Text>
                  <Text className={styles.dependentAccountType}>
                    {dependent.minor
                      ? t("inviteDependents.childAccount")
                      : t("inviteDependents.adultAccount")}
                  </Text>
                  <Text className={styles.dependentEmail}>
                    {dependent.email}
                  </Text>
                </Box>
              </FlexRow>
              <a href={`/members/choose_user/password/${dependent.id}`}>
                {t("inviteDependents.accountLink")}
              </a>
            </FlexRow>
          </li>
        );
      });
    }

    return null;
  };

  const dependentInviteButton = () => {
    const { dependents_allowed } = getOr(false, "user.member.cohort", userInfo);

    if (dependents_allowed) {
      return (
        <>
          <Section size="xlg">
            <HorizontalRule />
          </Section>
          <Section>
            <Heading fontSize={20} fontWeight={"bold"} color="#4b4b4b">
              {t("inviteDependents.title")}
            </Heading>
            <Section>{dependentSubtitleText()}</Section>

            <Section size="lg">
              <Section>
                <ul
                  className={styles.dependentProfilesContainer}
                  style={{ listStyleType: "none" }}
                >
                  {dependentProfiles()}
                </ul>
              </Section>
              <Button
                ariaLabel={"redirect to " + t("inviteDependents.title")}
                text={t("inviteDependents.title")}
                dataCy="invite-dependents"
                onClick={() => {
                  track(`${mpPrefix} -- Invite Dependents Button Clicked`);
                  const { to, as } = routes.InviteDependents;
                  Router.push(
                    {
                      pathname: to,
                      query: { origin: "MemberSettings" },
                    },
                    as,
                  );
                }}
                role="link"
              />
            </Section>
          </Section>
        </>
      );
    }
  };

  if (userLoading || frequencyLoading || userError) {
    return null;
  }

  const { member } = getOr({}, "user", userInfo);
  const isAMinor = isMinor(member);
  const isAGlobalMember = isGlobalUser(
    userInfo.user?.member?.postal_address?.country,
  );
  const isADependent = userInfo.user?.member?.covered_life?.dependent;
  const memberShouldSeeManagerExperienceToggle =
    !isAMinor && !isAGlobalMember && !isADependent;

  return (
    <div role="main">
      <div role="heading" aria-level={1} aria-label="Account Settings">
        <Heading
          as="h1"
          fontSize={48}
          fontWeight={300}
          color="#182023"
          data-cy={"accountSettings"}
        >
          {t("title")}
        </Heading>
      </div>
      <Subtitle noMargin>{t("subTitle")}</Subtitle>
      <Section size="xlg">
        <HorizontalRule />
      </Section>

      {accountDetails()}

      {canSetWorkplaceAccess && memberShouldSeeManagerExperienceToggle && (
        <ConfigureWorkplaceAccess member={userInfo.user.member} />
      )}

      {addressDetails()}

      {emergencyContactDetails()}

      {isAMinor && legalGuardianDetails()}

      {!showGlobalExperience && (
        <div id="insuranceForm" ref={insuranceRef}>
          <SettingsInsuranceForm />
        </div>
      )}

      {resetPassword()}

      <ConfigureMfa />

      <MemberLanguageForm
        doUpdateUser={doUpdateUser}
        userInfo={userInfo}
        isMemberT2={isMemberT2}
      />

      {!isAMinor && (
        <>
          <MemberEmailNotifications data={userInfo} />

          {!isMemberT2 && checkInNotifications()}
          <FeedbackForm />
          {dependentInviteButton()}

          <div className={styles.deleteAccount}>
            <DeleteAccount />
          </div>

          <ChangeCountry data={userInfo} />
        </>
      )}
    </div>
  );
};

export default MemberSettingsForm;
