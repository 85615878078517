//

import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { getOr, get } from "lodash/fp";
import {
  Col,
  Form,
  Checkbox,
  Content,
  Subtitle,
  Input,
  Grid,
  Section,
} from "@spring/smeargle";
import Meowth from "@spring/meowth";
import { addNotification } from "@spring/smeargle/actions";
import { useTranslation, Trans } from "react-i18next";

import { Link } from "components";
import { getFirstError } from "utils/apollo/errorHandler";
import insurancePolicySchema from "schemas/insurancePolicy";
import { getMemberInsurance } from "operations/queries/member";
import {
  updateMember,
  updateMemberInsurance,
} from "operations/mutations/member";

const formKey = "insurance-policy";

const articleQueries = {
  category_slug: "spring-health-faqs",
  article_slug: "billing-and-insurance",
};

const InsuranceForm = (props) => {
  const { t } = useTranslation("limitedLangSettings");
  const disabled = get("formData.data.payment_preference", props);

  const submit = async (formData) => {
    const { payment_preference } = formData;
    const member_id = get("data.user.member.id", props);

    try {
      const insurancePayload = {
        member_id: get("data.user.member.id", props),
        carrier_name: formData.carrier_name?.trim(),
        insurance_group_id: formData.insurance_group_id?.trim(),
        insurance_member_id: formData.insurance_member_id?.trim(),
        plan_name: formData.plan_name?.trim(),
      };

      const {
        data: { updateMember },
      } = await props.updateMember({
        id: member_id,
        patch: {
          payment_preference: payment_preference
            ? "OUT_OF_POCKET"
            : "INSURANCE",
        },
      });

      if (payment_preference) {
        return props.addNotification(
          t("notifications.responsesSubmitted"),
          "success",
        );
      }

      const { error } = await props.submitInsurance(insurancePayload);

      if (error || !updateMember.success) {
        return props.addNotification(t("notifications.genericError"), "error");
      }

      return props.addNotification(
        t("notifications.responsesSubmitted"),
        "success",
      );
    } catch (err) {
      const errorExplanation = get(
        "graphQLErrors[0].problems[0].explanation",
        err,
      );
      errorExplanation
        ? props.addNotification(errorExplanation, "error")
        : props.addNotification(getFirstError(err), "error");
    }
  };

  if (get("data.loading", props)) {
    return null;
  }

  const insurance = getOr({}, "data.user.member.insurance_policy", props);
  const preference = get("data.user.member.payment_preference", props);

  const forceRequired = !props.formData?.data?.payment_preference;
  return (
    <Form
      dataCy="insurance-form"
      formKey={formKey}
      schema={insurancePolicySchema}
      initialData={{
        payment_preference: preference === "OUT_OF_POCKET",
        carrier_name: get("carrier_name", insurance),
        plan_name: get("plan_name", insurance),
        insurance_group_id: get("insurance_group_id", insurance),
        insurance_member_id: get("insurance_member_id", insurance),
      }}
      onSubmit={props.showSubmit ? submit : null}
      submitIcon={props.showSubmit && "arrow-right"}
      submitText={t("common:form.submitText")}
      submitAriaLabel={t("a11y:submit.insuranceDetails")}
    >
      <Subtitle semibold smMargin>
        {props.remainingSessionsCovered > 0
          ? t("insurance.titleWithSessions")
          : t("insurance.titleNoSessions")}
      </Subtitle>
      <Content>
        <Trans
          ns="limitedLangSettings"
          i18nKey="insurance.subTitle"
          components={[
            <Link
              inlineTextLink
              key="0"
              ariaLabel={t("a11y:learnBilling")}
              alias="MemberLearningCenterArticle"
              queries={articleQueries}
            >
              Learning Center page on billing and insurance.
            </Link>,
          ]}
        />
      </Content>

      <Section size="sm">
        <Grid gutter="0 16px">
          <Col sm={6}>
            <Input
              dataCy="carrier-name"
              fieldKey="carrier_name"
              label={t("insurance.carrier")}
              placeholder={t("insurance.carrierPlaceholder")}
              disabled={disabled}
              forceRequired={forceRequired}
            />
          </Col>
          <Col sm={6}>
            <Input
              dataCy="plan-name"
              fieldKey="plan_name"
              label={t("insurance.planName")}
              placeholder={t("insurance.planNamePlaceholder")}
              disabled={disabled}
              forceRequired={forceRequired}
            />
          </Col>
        </Grid>
      </Section>
      <Section size="sm">
        <Grid gutter="0 16px">
          <Col sm={6}>
            <Input
              dataCy="insurance-group-id"
              fieldKey="insurance_group_id"
              label={t("insurance.groupId")}
              disabled={disabled}
              forceRequired={forceRequired}
            />
          </Col>
          <Col sm={6}>
            <Input
              dataCy="insurance-member-id"
              fieldKey="insurance_member_id"
              label={t("insurance.memberId")}
              disabled={disabled}
              forceRequired={forceRequired}
            />
          </Col>
        </Grid>
      </Section>
      <Checkbox
        dataCy="insurance"
        label={t("insurance.selfPaying")}
        fieldKey="payment_preference"
      />
    </Form>
  );
};

InsuranceForm.propTypes = {
  addNotification: PropTypes.func,
  showSubmit: PropTypes.string,
  submitInsurance: PropTypes.func,
  updateMember: PropTypes.func,
  remainingSessionsCovered: PropTypes.number,
};

const mapStateToProps = (state) => ({
  formData: getOr({}, `${formKey}`, state.form),
});

const mapDispatchToProps = { addNotification };

export { InsuranceForm };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(getMemberInsurance, { options: Meowth.apolloOptionsUserId }),
  graphql(updateMember, {
    props: ({ mutate }) => ({
      updateMember: (input) =>
        mutate({
          variables: { input },
        }),
    }),
  }),
  graphql(updateMemberInsurance, {
    props: ({ mutate }) => ({
      submitInsurance: (input) =>
        mutate({
          variables: { ...input },
        }),
    }),
  }),
)(InsuranceForm);
