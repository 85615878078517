import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Text } from "@springcare/sh-component-library";
import {
  curriedGetTips,
  curriedGetHeadline,
} from "modules/MemberDashboard/CareVisits/AppointmentDetails/utils/appointmentDetails.util";
import { NumberedSteps } from "modules/MemberDashboard/CareVisits/AppointmentDetails/components";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

const AppointmentTips = ({
  medium,
  instructions,
  isAMinor,
  kind,
  isInHouseVideo,
}) => {
  const { t } = useTranslation("careVisits");
  const getHeadline = curriedGetHeadline(t);
  const getTips = curriedGetTips(t);
  const couplesTherapyFlag = useFeatureFlag(FLAGS.ENABLE_COUPLES_THERAPY);

  return (
    <Box>
      {getHeadline(medium, kind) && (
        <Text size="body-medium-strong" mb={20}>
          {getHeadline(medium, kind)}
        </Text>
      )}
      <Box>
        <NumberedSteps
          steps={getTips(
            medium,
            instructions,
            isAMinor,
            kind,
            isInHouseVideo,
            couplesTherapyFlag,
          )}
        />
      </Box>
    </Box>
  );
};

AppointmentTips.propTypes = {
  instructions: PropTypes.string.isRequired,
  isAMinor: PropTypes.bool.isRequired,
  kind: PropTypes.string.isRequired,
  medium: PropTypes.string.isRequired,
};

export default AppointmentTips;
