import { gql } from "@apollo/client";

const getMemberDOB = gql`
  query getMemberDOB($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        date_of_birth
      }
    }
  }
`;

export default getMemberDOB;
