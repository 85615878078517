/**
 * Enums for passing in Requestable Appointment Kinds
 * where necessary.
 */

const RequestableAppointmentKind = Object.freeze({
	Therapy                : 'THERAPY',
	CouplesTherapy         : 'COUPLES_THERAPY',
	MinorTherapy           : 'MINOR_THERAPY',
	CareNavigation         : 'CARE_NAVIGATION',
	MedicationManagement   : 'MEDICATION_MANAGEMENT',
	Coaching               : 'COACHING',
	PeerRecoverySpecialist : 'PEER_RECOVERY',
});

module.exports = RequestableAppointmentKind;
