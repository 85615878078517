import { useRef } from "react";
import { camelCase } from "lodash";
import PropTypes from "prop-types";
import {
  CareNavigatorIcon,
  CoachIcon,
  MedicationManagerIcon,
  TherapistIcon,
} from "@spring/smeargle";
import {
  Text,
  Flex,
  SHTooltip as Tooltip,
  useDisclosure,
  Spinner,
} from "design-system/components";
import { ProviderRole } from "@spring/constants";
import { useTranslation } from "react-i18next";
import { getProviderRole } from "utils/memberHelpers";
import { useMemberInfo } from "hooks";
import { getIsUniqueCCACopy } from "utils/employerServicesHelpers";

import styles from "./styles.module.scss";
import { SocialFactors } from "design-system/index";

export const getIcon = (providerRole) => {
  if (!providerRole) {
    return <MedicationManagerIcon width="24" height="24" color="black" />;
  }

  providerRole = providerRole.replace(" ", "_");

  switch (providerRole.toLowerCase()) {
    case ProviderRole.CareNavigator:
      return (
        <CareNavigatorIcon
          svgProps={{ width: "24", height: "24" }}
          color="black"
        />
      );
    case ProviderRole.Therapist:
      return (
        <TherapistIcon svgProps={{ width: "24", height: "24" }} color="black" />
      );
    case ProviderRole.AssociateProvider:
      return (
        <TherapistIcon svgProps={{ width: "24", height: "24" }} color="black" />
      );
    case ProviderRole.Coach:
      return <CoachIcon height="24" width="24" color="black" />;
    case ProviderRole.MedicationManager:
      return <MedicationManagerIcon width="24" height="24" color="black" />;
    case ProviderRole.CareAdvocate:
      return (
        <CareNavigatorIcon
          svgProps={{ width: "24", height: "24" }}
          color="black"
        />
      );
    case ProviderRole.CareConsultant:
      return (
        <CareNavigatorIcon
          svgProps={{ width: "24", height: "24" }}
          color="black"
        />
      );
    case ProviderRole.PeerRecoverySpecialist:
      return <SocialFactors width="24" height="24" color="black" />;
    default:
      return <MedicationManagerIcon width="24" height="24" color="black" />;
  }
};

const CareProviderSubhead = ({
  roles,
  licenses,
  categories,
  associateStatus,
  showNewProfilePage,
  isMobile,
}) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const { t } = useTranslation(["careProvider", "careVisits"]);
  const ref = useRef(null);
  const ASSOCIATE_ACTIVE = "active";
  const { data: memberInfoData, loading: memberDataLoading } = useMemberInfo();
  const customerId = memberInfoData?.user?.member?.cohort?.customer?.id;

  if (memberDataLoading) {
    return <Spinner />;
  }

  const isAssociateProvider = associateStatus === ASSOCIATE_ACTIVE;
  let providerRole = roles && roles[0];
  isAssociateProvider
    ? (providerRole = ProviderRole.AssociateProvider)
    : providerRole;
  providerRole = getProviderRole(providerRole);
  let formattedRole = camelCase(providerRole);

  const subheadLicenses = isAssociateProvider
    ? licenses?.slice(0, 2)?.join(", ")
    : licenses?.join(", ");
  const showLicenses =
    subheadLicenses && providerRole !== ProviderRole.CareAdvocate;

  let subHeadCategories;

  const isUniqueCCACopy = getIsUniqueCCACopy(customerId);

  const getCareAdvocateRole = () => {
    if (isUniqueCCACopy) {
      return t(`careProvider.${formattedRole}.${customerId}.role`, {
        ns: "careVisits",
      });
    } else {
      return t(`careProvider.${formattedRole}.default.role`, {
        ns: "careVisits",
      });
    }
  };

  if (providerRole === "care_advocate") {
    subHeadCategories = providerRole ? getCareAdvocateRole() : "";
  } else {
    subHeadCategories = providerRole
      ? t(`careProvider.${formattedRole}`, { ns: "careVisits" })
      : "";
  }

  if (formattedRole === "coach" && categories && categories?.length > 0) {
    subHeadCategories = categories
      .map((cat) => {
        return t(`coachCategory.${cat}`);
      })
      .join(", ");
  }
  if (providerRole === ProviderRole.PeerRecoverySpecialist) {
    subHeadCategories = "Peer Recovery Specialist";
  }
  // capitalizing each separate word inside of the category
  subHeadCategories =
    subHeadCategories &&
    subHeadCategories
      .split(" ")
      .map((word) => {
        return word[0].toUpperCase() + word.substr(1);
      })
      .join(" ");

  const subheadText = `${subHeadCategories}${showLicenses ? ` • ${subheadLicenses}` : ""}`;

  if (providerRole === ProviderRole.CareNavigator) {
    return (
      <>
        <Text color="platform.base" display="flex" alignItems="center">
          {getIcon(providerRole)}
          {subheadText}
        </Text>
      </>
    );
  }
  return (
    <Flex
      mb={8}
      ref={ref}
      flexWrap="wrap"
      justifyContent={showNewProfilePage ? "start" : "center"}
    >
      {!showNewProfilePage && (
        <span className={styles.providerIcon}>{getIcon(providerRole)}</span>
      )}
      {isAssociateProvider ? (
        <Tooltip
          label={
            <Text size="body-small-regular">
              {t("benefitsSummary.associateProviderTooltip")}
            </Text>
          }
          placement="top"
          role="tooltip"
          borderRadius={8}
          p={8}
          portalProps={{ containerRef: ref }}
          maxWidth="202px"
          isOpen={isOpen}
        >
          <Text
            borderBottom="1px dashed #00131738"
            as="span"
            mr={4}
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            onClick={onToggle}
          >
            {subHeadCategories}
          </Text>
        </Tooltip>
      ) : (
        <Text mr={4}>{subHeadCategories}</Text>
      )}
      {showNewProfilePage ? (
        <>
          <Text
            style={{
              marginLeft: isMobile ? "1px" : "5px",
              marginRight: isMobile ? "3px" : "8px",
              marginTop: "-1px",
            }}
            as="span"
          >
            •
          </Text>
          <Text as="span">{`${subheadLicenses}`}</Text>
        </>
      ) : (
        <Text as="span">{`• ${subheadLicenses}`}</Text>
      )}
    </Flex>
  );
};

CareProviderSubhead.propTypes = {
  licenses: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.arrayOf(PropTypes.string),
  role: PropTypes.any,
  roles: PropTypes.arrayOf(PropTypes.string),
};

CareProviderSubhead.defaultProps = {
  roles: [],
};

export default CareProviderSubhead;
