import { useState, useEffect } from "react";
import Router from "next/router";
import { compose } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import classnames from "classnames";
import Meowth from "@spring/meowth";
import { addNotification } from "@spring/smeargle/actions";
import { useTranslation, withTranslation } from "react-i18next";
import { refreshUserData } from "actions/auth/actions";
import { assessments as assessmentConstants } from "@spring/constants";
import promptStyles from "components/assessments/Prompt/styles.module.scss";
import routes from "routes";
import finishAssessment from "operations/mutations/assessment/finishAssessment";
import { getCompletedInitialAssessment } from "operations/queries/assessment";
import { getUserInfo } from "operations/queries/user";
import { track } from "utils/mixpanel";
import { updateMember } from "operations/mutations/member";
import { getFirstError } from "utils/apollo/errorHandler";
import { Box } from "@springcare/sh-component-library";
import { NicelyDoneScreen } from "./NicelyDoneScreen";
import { getIterableCampaignInfo } from "utils/localStorage";

const SubmitAssessment = (props) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation("assessments");

  useEffect(() => {
    setUser(props.getUserInfo.user);
  }, [props.getUserInfo?.user]);

  const handleSubmit = () => {
    track("Assessment -- Submitted", {
      "Assessment Kind": props.assessmentKind,
      "Assessment Id": props.assessmentId,
      assessment_version: props.assessmentVersion,
    });

    setIsLoading(true);

    props
      .finishAssessment(props.assessmentId)
      .then(async (response) => {
        // wait for user and assessment data refresh for MP tracking
        await refreshUserData();

        track("Assessment -- Finished", {
          "Assessment Kind": props.assessmentKind,
          "Assessment Id": props.assessmentId,
          assessment_version: props.assessmentVersion,
          initial_sud_risk:
            response?.data?.finishAssessment?.assessment?.member
              ?.initial_sud_risk ?? "None",
          latest_sud_risk:
            response?.data?.finishAssessment?.assessment?.member
              ?.latest_sud_risk ?? "None",
        });

        if (
          props.assessmentKind ===
          assessmentConstants.assessmentKinds.INITIAL_ASSESSMENT
        ) {
          props.getCompletedInitialAssessment.refetch().then(() => {
            Router.replace(
              routes.AssessmentResults.to,
              routes.AssessmentResults.as,
            );
          });
        } else if (props.assessmentKind === "GOAL_CHECK_IN") {
          Router.replace(
            routes.MemberProgressDashboard.to,
            routes.MemberProgressDashboard.as,
          );
        } else {
          Router.replace(routes.MemberHome.to, routes.MemberHome.as);
        }
      })
      .catch((err) => {
        props.addNotification(getFirstError(err), "error");

        setIsLoading(false);
      });
  };

  return (
    <Box
      role="main"
      className={classnames(
        promptStyles[props.stackPosition],
        promptStyles[props.direction],
      )}
      maxW="606px"
      m="auto"
    >
      <NicelyDoneScreen
        firstName={user?.first_name}
        loading={isLoading}
        handleSubmit={handleSubmit}
        submitButtonCopy={t("submitAssessment.buttonText")}
      />
    </Box>
  );
};

export { SubmitAssessment };

export default compose(
  connect(
    (state: { form: any }, ownProps: { formKey: any }) => ({
      formData: getOr({}, `${ownProps.formKey}.data`, state.form),
    }),
    { addNotification },
  ),
  graphql(getCompletedInitialAssessment, {
    name: "getCompletedInitialAssessment",
    skip: !Meowth.apolloOptionsUserId,
  }),
  graphql(finishAssessment, {
    props: ({ mutate }) => ({
      finishAssessment: (id) => {
        const input = { id };
        return mutate({
          variables: {
            input: { ...input, campaign: getIterableCampaignInfo() },
          },
          refetchQueries: ["getMemberInfo"],
        });
      },
    }),
  }),
  graphql(getUserInfo, {
    name: "getUserInfo",
    options: Meowth.apolloOptionsUserId,
  }),
  graphql(updateMember, { name: "updateMember" }),
)(withTranslation("assessments")(SubmitAssessment));
