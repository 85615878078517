import { ProviderRole, AppointmentKind } from "@spring/constants";

const {
  Therapy,
  CouplesTherapy,
  MinorTherapy,
  InitialCareNavigation,
  FollowUpCareNavigation,
  InitialMedicationManagement,
  FollowUpMedicationManagement,
  Coaching,
  WellnessAndSocialSupportSession,
  ManagerConsultationAndSupport,
  IndividualSolutionsFocusedConsultation,
  TrainingAndAwarenessEventConsultation,
  InitialPeerRecoverySpecialist,
  FollowUpPeerRecoverySpecialist,
  InitialCoaching,
  FollowUpCoaching,
} = AppointmentKind;
/**
 * @param {string} AppointmentKind enum value
 * @returns {ProviderRole[Key]} returns a string that matches how getCareTeam returns a member's
 * ...care team
 */
// [AppointmentKind.Therapy]                      : 'Therapy',
// [AppointmentKind.CouplesTherapy]               : 'Couples Therapy',
// [AppointmentKind.MinorTherapy]                 : 'Minor Therapy',
// [AppointmentKind.InitialCareNavigation]        : 'Care Navigation',	// These are often used interchangeably
// [AppointmentKind.FollowUpCareNavigation]       : 'Care Navigation', // and in tracking we can differentiate by event order.
// [AppointmentKind.InitialMedicationManagement]  : 'Medication Management',
// [AppointmentKind.FollowUpMedicationManagement] : 'Medication Management',
// [AppointmentKind.Coaching]                     : 'Coaching',
// [AppointmentKind.WellnessAndSocialSupportSession]        : 'Wellness and Social Support Session',
// [AppointmentKind.ManagerConsultationAndSupport]          : 'Manager Consultation and Support',
// [AppointmentKind.IndividualSolutionsFocusedConsultation] : 'Individual Solutions Focused Consultation',
// [AppointmentKind.TrainingAndAwarenessEventConsultation]  : 'Training and Awareness Event Consultation',
// })[appointmentKindEnum];

export const getNormalizedProviderRoleByKind = (kind) => {
  switch (kind) {
    case Therapy:
    case CouplesTherapy:
    case MinorTherapy:
      return ProviderRole.Therapist;
    case InitialCareNavigation:
    case FollowUpCareNavigation:
      return ProviderRole.CareNavigator;
    case InitialMedicationManagement:
    case FollowUpMedicationManagement:
      return ProviderRole.MedicationManager;
    case InitialCoaching:
    case FollowUpCoaching:
    case Coaching:
      return ProviderRole.Coach;
    case WellnessAndSocialSupportSession:
      return ProviderRole.CareAdvocate;
    case ManagerConsultationAndSupport:
    case IndividualSolutionsFocusedConsultation:
    case TrainingAndAwarenessEventConsultation:
      return ProviderRole.CareConsultant;
    case FollowUpPeerRecoverySpecialist:
    case InitialPeerRecoverySpecialist:
      return ProviderRole.PeerRecoverySpecialist;
    default:
      throw new Error(
        "Appointment Kind does not map to an existing provider role, double check your input",
      );
  }
};
