import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import {
  FlexCol,
  FlexRow,
  MomentsAudioStep,
  Content,
  Icon,
  FullScreenModal,
  Col,
  ProgressBar,
  Button,
  DangerousHtml,
  LoadingCircle,
  ExitModal,
} from "@spring/smeargle";
import {
  Flex,
  Button as SHButton,
  SHSubtitleLanguageSelect,
} from "design-system/components";
import { Document } from "design-system";
import { modalIds } from "@spring/constants";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import {
  openModal,
  closeModal,
  addNotification,
} from "@spring/smeargle/actions";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { momentsAudioSubtitlesMap } from "constants/momentsAudioSubtitlesMap";
import completeStep from "operations/mutations/exercise/completeStep";

import styles from "./styles.module.scss";

import { getFirstError } from "utils/apollo/errorHandler";
import { toTitleCase } from "utils/mixpanel";
import {
  trackCloseMoment,
  trackCompletedExerciseFinishedClicked,
} from "modules/MemberDashboard/Moments/components/analytics";

const MomentsExerciseModal = ({
  exercise,
  current_step,
  openModal,
  closeModal,
  addNotification,
  setReturnFocus,
}) => {
  const [showText, setShowText] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [steps, setSteps] = useState([]);
  const [loadingStep, setLoadingStep] = useState(false);
  const [trackLang, setTrackLang] = useState(null);
  const { t } = useTranslation("moments");

  useEffect(() => {
    setShowText(false);
    setCurrentPosition(0);
    setSteps([current_step]);
  }, [current_step]);

  const [completeCurrentStep] = useMutation(completeStep, {
    onCompleted: ({ completeStep: { next_step, success } }) => {
      if (success) {
        if (next_step !== null) {
          setSteps([...steps, next_step]);
          setCurrentPosition(currentPosition + 1);
        } else {
          trackCompletedExerciseFinishedClicked(
            exercise.id,
            exercise.title,
            exercise.categories
              ?.map((item) => toTitleCase(item.category))
              ?.join(", "),
            exercise.content_formats?.join(", "),
          );

          const isParentingMoment =
            exercise.categories.length === 1 &&
            exercise.categories[0].category === "PARENTING";
          closeModal(modalIds.momentsExerciseModal);
          openModal(modalIds.momentsCompleteExerciseModal, {
            title: exercise.title,
            exerciseId: exercise.id,
            exerciseCategory: exercise.categories
              ?.map((item) => toTitleCase(item.category))
              .join(", "),
            exerciseMediaType: exercise.content_formats?.join(", "),
            isParentingMoment: isParentingMoment,
            setReturnFocus: setReturnFocus,
          });
        }
      }
    },
    onError: (err) => addNotification(getFirstError(err), "error"),
  });

  const readStep = (audioText = false) => {
    const textElement = getElement("RICH_TEXT");
    return (
      <div className={styles.readStep}>
        <Col size={7} lg sm={12}>
          <div
            className={styles.subTitle}
            dataCy="moments-exercise-modal-sub-title"
          >
            {!loadingStep && steps[currentPosition]?.title}
          </div>
          <Content>
            <div
              className={classnames(styles.readTextWrapper, {
                [styles.audioText]: audioText,
              })}
            >
              <DangerousHtml>{textElement.content}</DangerousHtml>
            </div>
          </Content>
        </Col>
      </div>
    );
  };

  const getElement = (type) => {
    return steps[currentPosition].elements.find(
      (element) => element.type === type,
    );
  };

  const getSubtitles = () => {
    const currentStep = steps[currentPosition];
    return momentsAudioSubtitlesMap[currentStep?.id];
  };

  const audioStep = () => {
    const audioElement = getElement("AUDIO");
    const textElement = getElement("RICH_TEXT");
    const subtitles = getSubtitles();
    const showTextContent = showText
      ? t("exerciseModal.hideWrittenVersion", { ns: "moments" })
      : t("exerciseModal.showWrittenVersion", { ns: "moments" });

    return (
      <>
        <Flex
          flexDirection="column"
          sx={{
            minWidth: "300px",
          }}
        >
          <MomentsAudioStep
            title={steps[currentPosition]?.title}
            imageUrl={exercise?.images[0]?.url}
            mediaUrl={audioElement?.media_uri}
            subtitles={subtitles}
            hideOnSmallScreen={showText}
            trackLang={trackLang}
          />

          <Flex
            pt={[150, 0, 0, 0, 0]}
            gap="v-24"
            sx={{ button: { textAlign: "left" } }}
          >
            {textElement && (
              <SHButton
                onClick={() => setShowText(!showText)}
                variant="link"
                color="platform.700"
                leftIcon={<Document boxSize={20} color="platform.900" />}
                alignSelf="end"
                _focusVisible={{ boxShadow: "0 0 0 3px black" }}
              >
                {showTextContent}
              </SHButton>
            )}

            <SHSubtitleLanguageSelect
              subtitles={subtitles}
              trackLang={trackLang}
              setTrackLang={setTrackLang}
              alignSelf="end"
            />
          </Flex>
        </Flex>
        {showText && textElement && readStep(true)}
      </>
    );
  };

  const progressBar = () => {
    const getProgressBarStatus = () => {
      // If exercise has only 1 step or we are on last step then
      // instead of returning 0 or 100, return 90 for visual aid
      if (currentPosition + 2 === exercise.total_steps) {
        return 90;
      }
      // Offset to 90% so last step can be shown at 90%
      return parseInt(((currentPosition + 1) / exercise.total_steps) * 90);
    };

    const widthPercentage = `${getProgressBarStatus()}%`;

    return (
      <div className={styles.progressContainer}>
        <div className={styles.progressWrapperContainer}>
          {widthPercentage} {t("progressBar.completed", { ns: "moments" })}
        </div>
        <div className={styles.progressWrapper}>
          <ProgressBar
            size="xlg"
            status="primary"
            hideProgressRadius
            min={1}
            max={100}
            current={getProgressBarStatus()}
          />
        </div>
      </div>
    );
  };

  const prev = () => {
    const handlePrev = () => {
      setCurrentPosition((prevPosition) => prevPosition - 1);
    };

    const backButton = () => {
      return (
        <FlexRow>
          <Icon
            inline
            iconProps={{ "aria-label": null }}
            type="arrow-left"
            className={styles.prevButtonIcon}
            ariaHidden="true"
          />
          <div className={styles.navButtonText}>
            {t("exerciseModal.back", { ns: "moments" })}
          </div>
        </FlexRow>
      );
    };

    return (
      <Button
        text={backButton()}
        reverse
        onClick={handlePrev}
        disabled={currentPosition <= 0 || loadingStep}
        mini
      />
    );
  };

  const next = () => {
    const nextButton = () => {
      return (
        <FlexRow>
          <div className={styles.navButtonText} data-Cy="moments-next-button">
            {currentPosition + 2 === exercise.total_steps
              ? t("exerciseModal.finish", { ns: "moments" })
              : t("exerciseModal.next", { ns: "moments" })}
          </div>
          <Icon
            inline
            iconProps={{ "aria-label": null }}
            type={
              currentPosition + 2 === exercise.total_steps
                ? "check"
                : "arrow-right"
            }
            className={styles.nextButtonIcon}
            ariaHidden="true"
          />
        </FlexRow>
      );
    };

    const handleNext = async () => {
      if (currentPosition === steps.length - 1) {
        setLoadingStep(true);
        await completeCurrentStep({
          variables: {
            exercise_id: exercise.id,
            current_step_id: steps[currentPosition].id,
          },
        });
        setLoadingStep(false);
      } else {
        setCurrentPosition(currentPosition + 1);
      }
    };

    return (
      <Button
        text={nextButton()}
        disabled={loadingStep}
        onClick={handleNext.bind(this)}
        mini
      />
    );
  };

  const progressNavBar = () => {
    return (
      <div className={styles.bottomNav}>
        <div className={styles.navWrapper} data-cy="moments-progress-bar">
          {prev()}
          {progressBar()}
          {next()}
        </div>
      </div>
    );
  };

  const contentFormat = (type) => {
    return (
      steps[currentPosition] && steps[currentPosition].content_format === type
    );
  };

  return (
    exercise && (
      <FullScreenModal
        id={modalIds.momentsExerciseModal}
        title={exercise.title}
        confirmExit
      >
        <FlexCol alignment="center">
          <div className={styles.content}>
            {loadingStep && <LoadingCircle thick />}
            {!loadingStep && contentFormat("AUDIO") && audioStep()}
            {!loadingStep && contentFormat("TEXT") && readStep()}
          </div>
          {progressNavBar()}
        </FlexCol>
        <ExitModal
          message={t("exerciseModal.exitModal.message", { ns: "moments" })}
          confirmationText={t("exerciseModal.exitModal.confirmationText", {
            ns: "moments",
          })}
          declineText={t("exerciseModal.exitModal.declineText", {
            ns: "moments",
          })}
          confirmationAction={() => {
            closeModal(modalIds.exitModal);
          }}
          declineAction={() => {
            trackCloseMoment(
              exercise.id,
              exercise.title,
              exercise.categories
                ?.map((item) => toTitleCase(item.category))
                .join(", "),
              exercise.content_formats?.join(", "),
            );
            closeModal(modalIds.exitModal);
            closeModal(modalIds.momentsExerciseModal);
          }}
        />
      </FullScreenModal>
    )
  );
};

MomentsExerciseModal.propTypes = {
  addNotification: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  current_step: PropTypes.any,
  exercise: PropTypes.shape({
    id: PropTypes.any,
    images: PropTypes.any,
    title: PropTypes.any,
    total_steps: PropTypes.any,
    categories: PropTypes.any,
    content_formats: PropTypes.any,
  }),
  openModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  exercise: getOr(
    null,
    `modal.${modalIds.momentsExerciseModal}.data.exercise`,
    state,
  ),
  current_step: getOr(
    null,
    `modal.${modalIds.momentsExerciseModal}.data.current_step`,
    state,
  ),
  setReturnFocus: getOr(
    null,
    `modal.${modalIds.momentsExerciseModal}.data.setReturnFocus`,
    state,
  ),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  addNotification,
})(MomentsExerciseModal);
