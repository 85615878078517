import { useRef, useState, useEffect } from "react";
import {
  Flex,
  Box,
  Heading,
  AspectRatio,
} from "@springcare/sh-component-library";

import { datadogAddAction } from "lib/datadog-setup";

import CameraPlaceholder from "components/templates/SHSessionRoom/components/ParticipantTile/CameraPlaceholder";
import DeviceMenuSection from "components/templates/SHSessionRoom/components/DeviceMenuSection";
import PreJoinVideoCallControls from "components/templates/SHSessionRoom/components/PreJoinVideoCallControls";

import { useSessionDevices } from "context/SessionRoomContext";

import useMicVolumeLevel from "components/templates/SHSessionRoom/hooks/useMicVolumeLevel";
import { pulseAnimation } from "components/templates/SHSessionRoom/utils/utils";

const VideoPreviewSection = ({ isMobile }) => {
  const videoRef = useRef(null);
  const { sessionDevices, setSessionDevices } = useSessionDevices();

  const [startMicRecording, stopMicRecording, volume] = useMicVolumeLevel();
  const [isSpeaking, setIsSpeaking] = useState(null);
  const [prevVolume, setPrevVolume] = useState(volume);
  if (volume !== prevVolume) {
    setPrevVolume(volume);
    setIsSpeaking(volume > 2);
  }

  const startMedia = async () => {
    const constraints = {
      video: {
        width: isMobile ? { ideal: 1080 } : { ideal: 1920 },
        height: isMobile ? { ideal: 1920 } : { ideal: 1080 },
        aspectRatio: { exact: 1.777 },
      },
      audio: true,
    };
    // called onload and requests access to camera and microphone
    // if given access, sets the stream to the video element and starts audio detection
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      // Check if the audio track is enabled and call startMicRecording
      const audioTracks = stream.getAudioTracks();
      if (audioTracks.length > 0 && audioTracks[0].enabled) {
        startMicRecording();
      } else {
        stopMicRecording();
      }
      setSessionDevices((prevState) => ({
        ...prevState,
        stream,
        isCameraEnabled: constraints.video ? true : prevState.isCameraEnabled,
        isMicrophoneEnabled: constraints.audio
          ? true
          : prevState.isMicrophoneEnabled,
      }));
    } catch (err) {
      datadogAddAction("session_stream_start_error", {
        error: err,
        trackType: "both",
      });
      return;
    }
  };

  const addTrackToStream = async (trackType) => {
    try {
      const constraints =
        trackType === "video"
          ? {
              video: {
                width: isMobile ? { ideal: 1080 } : { ideal: 1920 },
                height: isMobile ? { ideal: 1920 } : { ideal: 1080 },
                aspectRatio: { exact: 1.777 },
              },
            }
          : { audio: true };
      const newStream = await navigator.mediaDevices.getUserMedia(constraints);
      const newTrack = newStream.getTracks()[0];
      sessionDevices.stream.addTrack(newTrack);
    } catch (err) {
      datadogAddAction("session_stream_start_error", {
        error: err,
        trackType: trackType,
      });
      return;
    }
  };

  const toggleVideo = () => {
    if (sessionDevices.stream) {
      const videoTrack = sessionDevices.stream?.getVideoTracks()[0];
      if (sessionDevices.isCameraEnabled && videoTrack) {
        videoTrack.stop();
        sessionDevices.stream?.removeTrack(videoTrack);
      } else {
        addTrackToStream("video"); // Add video track to existing stream
      }
      setSessionDevices((prevState) => ({
        ...prevState,
        isCameraEnabled: !prevState.isCameraEnabled,
      }));
    }
  };

  const toggleAudio = async () => {
    if (sessionDevices.stream) {
      const audioTrack = sessionDevices.stream?.getAudioTracks()[0];
      if (sessionDevices.isMicrophoneEnabled && audioTrack) {
        stopMicRecording();
        audioTrack.stop();
        sessionDevices.stream?.removeTrack(audioTrack);
      } else {
        startMicRecording();
        addTrackToStream("audio"); // Add audio track to existing stream
      }
      setSessionDevices((prevState) => ({
        ...prevState,
        isMicrophoneEnabled: !prevState.isMicrophoneEnabled,
      }));
    }
  };

  useEffect(() => {
    startMedia();
    return () => {
      if (videoRef.current?.srcObject) {
        const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  return (
    <Flex flex={1} mr={[0, 0, 40, 40]} direction="column" alignItems="center">
      <Heading
        size="heading-small"
        mb="v-16"
        hidden={isMobile}
        width="100%"
        aria-label="Media Preview"
        as="h1"
      >
        Audio and Video preview
      </Heading>

      <Box w={["100%", "100%", 688, 688]} mb="v-8">
        <Box pos="relative">
          <Box
            display={sessionDevices.isCameraEnabled ? "none" : "block"}
            borderTopRightRadius={16}
            borderTopLeftRadius={16}
            sx={{ aspectRatio: "16 / 9" }}
            overflow="hidden"
          >
            <CameraPlaceholder borderRadius="v-none" />
          </Box>

          <AspectRatio
            display={sessionDevices.isCameraEnabled ? "block" : "none"}
            ratio={16 / 9}
          >
            <video
              ref={videoRef}
              autoPlay
              muted
              playsInline
              controls={false}
              aria-label="Video preview"
              style={{
                borderTopRightRadius: "16px",
                borderTopLeftRadius: "16px",
                transform: "scaleX(-1)",
              }}
            />
          </AspectRatio>
          {sessionDevices.isMicrophoneEnabled && (
            <Box
              pos="absolute"
              bottom="v-12"
              right="v-12"
              w="v-16"
              h="v-16"
              bg="warning-base"
              borderRadius="50%"
              animation={isSpeaking && pulseAnimation}
              zIndex={1}
            />
          )}
          <PreJoinVideoCallControls
            isMobile={isMobile}
            isCameraEnabled={sessionDevices.isCameraEnabled}
            isMicrophoneEnabled={sessionDevices.isMicrophoneEnabled}
            toggleVideo={toggleVideo}
            toggleAudio={toggleAudio}
          />
        </Box>
      </Box>

      <Flex
        direction="row"
        w="100%"
        py={2}
        justify="space-evenly"
        overflowX="scroll"
        hidden={isMobile}
      >
        <DeviceMenuSection />
      </Flex>
    </Flex>
  );
};

export default VideoPreviewSection;
