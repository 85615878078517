import { AttendedStatusTooltip } from "./apptAttendedStatusTooltip";

export const AttendedStatusDisplayI18nKeyMapping = (attendedStatusTooltipKey) =>
  ({
    [AttendedStatusTooltip.AppointmentKept]: "kept",
    [AttendedStatusTooltip.MemberLateCancel]: "memberLateCancel",
    [AttendedStatusTooltip.MemberNoShow]: "memberNoShow",
    [AttendedStatusTooltip.MemberCancel]: "appointmentsTable.cancelled",
    [AttendedStatusTooltip.CaregiverCancel]: "appointmentsTable.cancelled",
    [AttendedStatusTooltip.CaregiverLateCancel]: "appointmentsTable.cancelled",
    [AttendedStatusTooltip.CaregiverNoShow]: "appointmentsTable.cancelled",
    [AttendedStatusTooltip.SpringCancel]: "appointmentsTable.cancelled",
    [AttendedStatusTooltip.SpringLateCancel]: "appointmentsTable.cancelled",
  })[attendedStatusTooltipKey];

export const AttendedStatusTooltipI18nKeyMapping = (attendedStatusTooltipKey) =>
  ({
    [AttendedStatusTooltip.AppointmentKept]: "",
    [AttendedStatusTooltip.MemberLateCancel]: "memberLateCancelTooltip",
    [AttendedStatusTooltip.MemberNoShow]: "memberNoShowTooltip",
    [AttendedStatusTooltip.MemberCancel]: "",
    [AttendedStatusTooltip.CaregiverCancel]: "",
    [AttendedStatusTooltip.CaregiverLateCancel]: "",
    [AttendedStatusTooltip.CaregiverNoShow]: "",
    [AttendedStatusTooltip.SpringCancel]: "",
    [AttendedStatusTooltip.SpringLateCancel]: "",
  })[attendedStatusTooltipKey];
