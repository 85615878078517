import { theme, extendTheme } from "@springcare/sh-component-library";
import { textStyles } from "./textStyles";

// Reusable Styles
// See https://chakra-ui.com/docs/styled-system/component-style#styling-single-part-components
// ...for more info on sharing base/ other styles for components
/**
 * NOTE: Drawers/ Modals/ Menus are multipart components and thus the rules for setting base styles
 * ...for it is slightly different
 * See:
 * - Github issue with solution: https://github.com/chakra-ui/chakra-ui/discussions/2676#discussioncomment-1773124
 * - Chakra Docs: https://chakra-ui.com/docs/styled-system/component-style#styling-multipart-components
 * - Chakra Repo Anatomy File: https://github.com/chakra-ui/chakra-ui/blob/be24c82948b007a8f6df4652d4ff50a85cd416f9/packages/anatomy/src/index.ts
 */

// buttons
const BTN_SOLID_DISABLED_STYLE = {
  _disabled: {
    color: "platform.on",
    background: "platform.base",
  },
};

const BTN_NOT_SOLID_DISABLED_STYLE = {
  _disabled: {
    color: "platform.base",
    borderColor: "platform.base",
  },
};

export const components = extendTheme({
  Modal: {
    baseStyle: {
      dialogContainer: {
        "@supports(height: -webkit-fill-available)": {},
        height: "100%",
      },
    },
  },
  Heading: {
    baseStyle: {
      width: "fit-content",
      fontWeight: 400,
    },
    variants: {
      // Heading version numbers in this file align with our Figma typography version numbers.
      /***************** VERSION 0 *****************/
      display: textStyles.display,
      lg: textStyles.lg,
      md: textStyles.md,
      sm: textStyles.sm,
      // TODO: Delete exsm once all uses of it are removed. exsm was temporary and never officially part of the design system.
      exsm: textStyles.exsm,
      subtitle1: textStyles.subtitle1,
      subtitle2: textStyles.subtitle2,

      /***************** VERSION 1 *****************/
      display_v1: textStyles.display_v1,
      lg_v1: textStyles.lg_v1,
      md_v1: textStyles.md_v1,
      sm_v1: textStyles.sm_v1,
    },
  },
  Text: {
    baseStyle: {
      width: "fit-content",
      fontWeight: 400,
    },
    variants: {
      // Text version numbers in this file align with our Figma typography version numbers.
      /***************** VERSION 0 *****************/
      subtitle1: textStyles.subtitle1,
      subtitle2: textStyles.subtitle2,
      // TODO: Delete subtitle3 once all uses of it are removed. subtitle3 was temporary and never officially part of the design system.
      subtitle3: textStyles.subtitle3,
      body1: textStyles.body1,
      body2: textStyles.body2,

      /***************** VERSION 1 *****************/
      body_v1: textStyles.body_v1,
      bodyBold_v1: textStyles.bodyBold_v1,
      caption_v1: textStyles.caption_v1,
      captionBold_v1: textStyles.captionBold_v1,
      data1_v1: textStyles.data1_v1,
      data2_v1: textStyles.data2_v1,
    },
  },
  Link: {
    baseStyle: {
      textDecoration: "underline",
      fontSize: 16,
      fontWeight: 700,
    },
    variants: {
      primary: {
        color: "primary.base",
      },
      secondary: {
        "&:hover": { textDecoration: "none" },
      },
    },
  },
  /**
   * NOTE: Drawer is Chakra's name for what we refer to as Flyouts
   * ...and Chakra builds component styles first then React builds the components
   * ... so we style Flyouts using the Drawer name inside this file
   *
   * Flyouts that consume this theme:
   * design-system/components/custom/SHFlyout
   * components/molecules/InformationFlyout
   */
  Drawer: {
    baseStyle: {
      dialog: {},
      body: {
        px: 0,
      },
      header: {},
      footer: {},
    },
    sizes: {},
  },
  Button: {
    // TODO: start using base styles to ensure standard styles
    // ...see below for an example of styles that can go in
    baseStyle: {
      fontSize: 16,
      fontWeight: 700,
      fontFamily: "HKGroteskRegular, sans-serif",
      textDecoration: "none",
      color: "primary.500",
      _focus: {
        color: "primary.500",
        boxShadow: "none",
      },
      _active: {
        color: "primary.500",
      },
      _hover: {
        textDecoration: "none",
        color: "primary.500",
      },
      _focusVisible: {
        boxShadow: "var(--chakra-shadows-outline)",
      },
    },
    defaultProps: {
      width: "fit-content",
      height: "fit-content",
      overflow: "hidden",
    },
    // TODO: double check widths for responsive  breakpoints
    sizes: {
      md: {
        padding: "17.5px",
        w: ["100%", "100%", "auto"],
        fontSize: 16,
      },
      lg: {
        padding: "25.5px",
        w: ["100%", "100%", "auto"],
        fontSize: 16,
      },
    },
    variants: {
      link: (props) => ({
        ...theme.mx.components.Button.variants.link(props),
        color:
          props.colorScheme === "warning"
            ? `${props.colorScheme}.on`
            : `${props.colorScheme}.base`,
        textDecoration: "underline",
        borderWidth: 2,
        borderColor: "platform.on",
        _disabled: {
          ...BTN_NOT_SOLID_DISABLED_STYLE,
        },
        _active: {
          borderColor: "#62bbd3",
        },
      }),
      ghost: (props) => ({
        ...theme.mx.components.Button.variants.ghost(props),
        color:
          props.colorScheme === "warning"
            ? `${props.colorScheme}.on`
            : `${props.colorScheme}.base`,
        _hover: {
          background: `${props.colorScheme}.light`,
        },
        _focus: {
          background: `${props.colorScheme}.light`,
        },
        _active: {
          background: `${props.colorScheme}.light`,
        },
        _disabled: {
          ...BTN_NOT_SOLID_DISABLED_STYLE,
        },
      }),
      outline: (props) => ({
        ...theme.mx.components.Button.variants.outline(props),
        borderWidth: 2,
        borderColor: `${props.colorScheme}.base`,
        color:
          props.colorScheme === "warning"
            ? `${props.colorScheme}.on`
            : `${props.colorScheme}.base`,
        _hover: {
          background: `${props.colorScheme}.light`,
          color:
            props.colorScheme === "warning"
              ? `${props.colorScheme}.on`
              : `${props.colorScheme}.base`,
        },
        _active: {
          background:
            props.colorScheme === "warning"
              ? `${props.colorScheme}.base`
              : `${props.colorScheme}.base`,
          color: "platform.on",
        },
        _focus: {
          color:
            props.colorScheme === "warning"
              ? `${props.colorScheme}.on`
              : `${props.colorScheme}.base`,
        },
        _disabled: {
          ...BTN_NOT_SOLID_DISABLED_STYLE,
        },
      }),
      solid: (props) => ({
        ...theme.mx.components.Button.variants.solid(props),
        background: `${props.colorScheme}.base`,
        color:
          props.colorScheme === "warning" ? `${props.colorScheme}.on` : "white",
        _hover: {
          color:
            props.colorScheme === "warning"
              ? `${props.colorScheme}.on`
              : "white",
          background:
            props.colorScheme === "warning"
              ? `${props.colorScheme}.300`
              : `${props.colorScheme}.600`,
          _disabled: {
            ...BTN_SOLID_DISABLED_STYLE,
          },
        },
        _active: {
          background:
            props.colorScheme === "warning"
              ? `${props.colorScheme}.300`
              : `${props.colorScheme}.600`,
          color:
            props.colorScheme === "warning"
              ? `${props.colorScheme}.on`
              : "white",
        },
        _disabled: {
          ...BTN_SOLID_DISABLED_STYLE,
        },
        _focus: {
          color:
            props.colorScheme === "warning"
              ? `${props.colorScheme}.on`
              : "white",
        },
      }),
    },
  },
  Input: {
    variants: {
      outline: (props) => ({
        ...theme.mx.components.Input.variants.outline(props),
        field: {
          height: 56,
          padding: 16,
          background:
            props.colorScheme === "platform"
              ? `${props.colorScheme}.on`
              : `${props.colorScheme}.light`,
          color:
            props.colorScheme === "platform"
              ? `${props.colorScheme}.base`
              : `${props.colorScheme}.700`,
          borderColor: `${props.colorScheme}.400`,
          borderWidth: 2,
          borderRadius: 8,
          _placeholder: {
            color:
              props.colorScheme === "platform"
                ? `${props.colorScheme}.base`
                : `${props.colorScheme}.700`,
          },
          _hover: {
            background:
              props.colorScheme === "platform"
                ? `${props.colorScheme}.25`
                : `${props.colorScheme}.light`,
            borderColor: `${props.colorScheme}.400`,
          },
          _focus: {
            borderColor:
              props.colorScheme === "platform"
                ? "primary.base"
                : `${props.colorScheme}.base`,
            borderWidth: 4,
            boxShadow: 0,
          },
          _disabled: {
            ...BTN_NOT_SOLID_DISABLED_STYLE,
          },
        },
      }),
      filled: (props) => ({
        ...theme.mx.components.Input.variants.filled(props),
        field: {
          height: 56,
          padding: 16,
          background: `${props.colorScheme}.light`,
          color:
            props.colorScheme === "platform"
              ? `${props.colorScheme}.dark`
              : `${props.colorScheme}.700`,
          borderRadius: 8,
          _placeholder: {
            color:
              props.colorScheme === "platform"
                ? `${props.colorScheme}.base`
                : `${props.colorScheme}.700`,
          },
          _hover: {
            background:
              props.colorScheme === "platform"
                ? `${props.colorScheme}.light`
                : `${props.colorScheme}.light`,
            borderWidth: 2,
            borderColor: "platform.200",
          },
          _focus: {
            borderWidth: 4,
            borderColor:
              props.colorScheme === "platform"
                ? "primary.base"
                : `${props.colorScheme}.base`,
            background: `${props.colorScheme}.light`,
            boxShadow: 0,
          },
        },
      }),
    },
  },
  FormLabel: {
    baseStyle: {
      mt: 0,
      mb: 4,
      fontWeight: "bold",
      color: "platform.900",
    },
  },
  Checkbox: {
    sizes: {
      lg: {
        control: {
          w: 22,
          h: 22,
        },
        icon: {
          fontSize: ".8rem",
        },
      },
    },
    defaultProps: {
      size: "lg",
      variant: "primary",
    },
    baseStyle: {
      control: {
        borderRadius: 4,
        _focus: {
          boxShadow: 0,
        },
      },
    },
    variants: {
      primary: {
        control: {
          borderColor: "platform.base",
          bg: "platform.light",
          _checked: {
            borderColor: "primary.base",
            bg: "primary.light",
            _hover: {
              bg: "primary.light",
            },
            _active: {
              bg: "primary.light",
            },
          },
          _hover: {
            bg: "primary.light",
          },
          _active: {
            bg: "primary.light",
          },
          _focus: {
            borderColor: "primary.base",
            borderWidth: 4,
          },
        },
        icon: {
          color: "primary.base",
          bg: "primary.light",
        },
      },
      circle: {
        control: {
          borderRadius: "99px",
          borderColor: "transparent",
          bg: "transparent",
          h: "24",
          w: "24",
          _checked: {
            bg: "primary.base",
            borderColor: "primary.base",
          },
          _focus: {
            h: "28px",
            w: "28px",
            borderColor: "primary.base",
          },
        },
        icon: {
          color: "primary.light",
          bg: "primary.base",
        },
      },
      error: {
        control: {
          borderColor: "error.base",
          bg: "white",
          _checked: {
            borderColor: "error.base",
            bg: "error.light",
            _hover: {
              bg: "error.light",
            },
            _active: {
              bg: "error.light",
            },
            _focus: {
              borderColor: "error.base",
            },
          },
          _hover: {
            bg: "error.light",
          },
          _active: {
            bg: "error.light",
          },
          _focus: {
            borderColor: "error.base",
            boxShadow: 0,
          },
        },
        icon: {
          color: "error.base",
          bg: "error.light",
        },
      },
      tertiary: {
        control: {
          borderRadius: "3px",
          borderColor: "tertiary.600",
          bg: "tertiary.light",
          _checked: {
            borderColor: "tertiary.600",
            bg: "tertiary.light",
            _hover: {
              bg: "tertiary.light",
            },
            _active: {
              bg: "tertiary.light",
            },
          },
          _hover: {
            bg: "tertiary.light",
          },
          _active: {
            bg: "tertiary.light",
          },
          _focus: {
            borderColor: "tertiary.base",
            borderWidth: 4,
          },
        },
        icon: {
          color: "tertiary.base",
          bg: "tertiary.light",
        },
      },
    },
  },
  Radio: {
    baseStyle: {
      control: {
        color: "platform.base",
        bg: "white",
        _hover: {
          bg: "primary.light",
          borderColor: "primary.base",
        },
        _checked: {
          color: "primary.base",
          bg: "transparent",
          _hover: {
            bg: "primary.light",
            borderColor: "primary.base",
          },
        },
        _focus: {
          boxShadow: 0,
        },
      },
    },
  },
  Select: {
    baseStyle: {
      field: {
        fontWeight: "bold",
        color: "platform.base",
        _hover: {
          borderColor: "primary.base",
          borderWidth: 2,
        },
        _focus: {
          borderColor: "primary.base",
          borderWidth: 2,
          boxShadow: 0,
        },
      },
    },
    variants: {
      outline: (props) => ({
        ...theme.mx.components.Select.variants.outline(props),
        field: {
          height: 56,
          paddingInlineStart: 16,
          background:
            props.colorScheme === "platform"
              ? `${props.colorScheme}.on`
              : `${props.colorScheme}.light`,
          color:
            props.colorScheme === "platform"
              ? `${props.colorScheme}.base`
              : `${props.colorScheme}.700`,
          borderColor: `${props.colorScheme}.400`,
          borderWidth: 2,
          borderRadius: 8,
          _placeholder: {
            color:
              props.colorScheme === "platform"
                ? `${props.colorScheme}.base`
                : `${props.colorScheme}.700`,
          },
          _hover: {
            background:
              props.colorScheme === "platform"
                ? `${props.colorScheme}.25`
                : `${props.colorScheme}.light`,
            borderColor: `${props.colorScheme}.400`,
          },
          _focus: {
            borderColor:
              props.colorScheme === "platform"
                ? "primary.base"
                : `${props.colorScheme}.base`,
            borderWidth: 4,
            boxShadow: 0,
          },
          _disabled: {
            ...BTN_NOT_SOLID_DISABLED_STYLE,
          },
        },
      }),
      filled: (props) => ({
        ...theme.mx.components.Select.variants.filled(props),
        field: {
          height: 56,
          paddingInlineStart: 16,
          background: `${props.colorScheme}.light`,
          color:
            props.colorScheme === "platform"
              ? `${props.colorScheme}.dark`
              : `${props.colorScheme}.700`,
          borderRadius: 8,
          _placeholder: {
            color:
              props.colorScheme === "platform"
                ? `${props.colorScheme}.base`
                : `${props.colorScheme}.700`,
          },
          _hover: {
            background:
              props.colorScheme === "platform"
                ? `${props.colorScheme}.light`
                : `${props.colorScheme}.light`,
            borderWidth: 2,
            borderColor: "platform.200",
          },
          _focus: {
            borderWidth: 4,
            borderColor:
              props.colorScheme === "platform"
                ? "primary.base"
                : `${props.colorScheme}.base`,
            background: `${props.colorScheme}.light`,
            boxShadow: 0,
          },
        },
      }),
    },
  },
  Tooltip: {
    baseStyle: {
      p: 4,
      borderRadius: 3,
      maxW: 200,
      fontSize: 14,
      textAlign: "center",
      zIndex: "0",
    },
  },
  Popover: {
    baseStyle: {
      popper: {
        zIndex: "1002",
      },
    },
  },
});
