import { gql } from "@apollo/client";

const careTeamMember = gql`
  fragment careTeamMember on CareProvider {
    id
    created_at
    roles
    associate_status
    name
    first_name
    last_name
    avatar
    bio
    licenses
    license_number
    languages
    on_site
    dedicated_customer {
      id
      name
    }
    cohorts
    dedicated
    states
    tags
    top_tags
    email
    phone
    user_id
    acuity_calendar_id
    scheduling_url
    zoom_url
    in_person_supported
    virtual_supported
    instructions

    coordinates {
      latitude
      longitude
    }

    street_address_1
    street_address_2
    city
    state
    country
    zip_code
    care_provider_tags {
      id
      kind
      name
      created_at
      updated_at
    }

    status
    supported_appointment_kinds
    supported_appointment_mediums
    distance_kilometers
    distance_miles
    licensed_countries
  }
`;

export default careTeamMember;
