import {
  Box,
  VSelfAwarenessDuotoneSpot as SunOverWaterIcon,
} from "@springcare/sh-component-library";

import NicelyDoneText from "./NicelyDoneText";
import SubmitAssessmentButton from "./SubmitAssessmentButton";

type NicelyDoneScreenProps = {
  firstName: string;
  handleSubmit: () => void;
  loading?: boolean;
  submitButtonCopy: string;
};

export const NicelyDoneScreen = ({
  firstName,
  handleSubmit,
  loading = false,
  submitButtonCopy,
}: NicelyDoneScreenProps) => {
  return (
    <Box mt={144} mx={[24, "auto"]} w={["auto", "400px"]}>
      <SunOverWaterIcon aria-hidden="true" />

      <NicelyDoneText firstName={firstName} />

      <SubmitAssessmentButton
        handleSubmit={handleSubmit}
        loading={loading}
        text={submitButtonCopy}
      />
    </Box>
  );
};
