const routes = {
  "MemberHelp--Home": {
    to: "/apps/MemberDashboard/help",
    as: "/members/help",
  },
  "MemberHelp--Basic_Information": {
    to: "/apps/MemberDashboard/help/basic_information",
    as: "/members/help/basic_information",
  },
  "MemberHelp--Providers": {
    to: "/apps/MemberDashboard/help/providers",
    as: "/members/help/providers",
  },
  "MemberHelp--Recommendations": {
    to: "/apps/MemberDashboard/help/recommendations",
    as: "/members/help/recommendations",
  },
  TakeMemberAssessment: {
    to: "/apps/MemberDashboard/assessments/:id",
    as: "/members/assessments/:id",
  },
  TriggerCheckIn: {
    to: "apps/MemberDashboard/assessments",
    as: "/members/assessments",
  },
  AssessmentResults: {
    to: "/apps/MemberDashboard/your_assessment_results",
    as: "/members/your_assessment_results",
  },
  TeenAssessment: {
    to: "/apps/MemberDashboard/teen/assessment",
    as: "/members/teen/assessment",
  },
  TeenHome: {
    to: "/apps/MemberDashboard/teen/home",
    as: "/members/teen/home",
  },
  TeenCheckIn: {
    to: "/apps/MemberDashboard/teen/check_in",
    as: "/members/teen/check_in",
  },
  MemberDashboard: {
    to: "/apps/MemberDashboard",
    as: "/members",
  },
  Deactivated: {
    to: "/apps/MemberDashboard/deactivated",
    as: "/deactivated",
  },
  TakeInitialAssessment: {
    to: "/apps/MemberDashboard/initial_assessment",
    as: "/members/initial_assessment",
  },
  MemberAppointmentDetail: {
    to: "/apps/MemberDashboard/care_visits/:id",
    as: "/members/care_visits/:id",
  },
  Billing: {
    to: "/apps/MemberDashboard/billing-and-payments",
    as: "/members/billing-and-payments/",
  },
  "Billing--BillingTab": {
    to: "/apps/MemberDashboard/billing-and-payments/billing",
    as: "/members/billing-and-payments/billing",
  },
  "Billing--PaymentHistoryTab": {
    to: "/apps/MemberDashboard/billing-and-payments/payment-history",
    as: "/members/billing-and-payments/payment-history",
  },
  "Billing--WalletTab": {
    to: "/apps/MemberDashboard/billing-and-payments/wallet",
    as: "/members/billing-and-payments/wallet",
  },
  "Billing--Coverage": {
    to: "/apps/MemberDashboard/billing-and-payments/coverage",
    as: "/members/billing-and-payments/coverage",
  },
  ScheduleAppointmentProviderDetail: {
    to: "/apps/MemberDashboard/providers/:id",
    as: "/members/providers/:id",
  },
  ScheduleAppointmentProviderFinishBooking: {
    to: "/apps/MemberDashboard/providers/:id/finish-booking",
    as: "/members/providers/:id/finish-booking",
  },
  MemberCareVisits: {
    to: "/apps/MemberDashboard/care_visits",
    as: "/members/care_visits",
  },
  MemberResults: {
    to: "/apps/MemberDashboard/results",
    as: "/members/results",
  },
  MemberHome: {
    to: "/apps/MemberDashboard/home",
    as: "/members/home",
  },
  PrevisitForm: {
    to: "/apps/MemberDashboard/previsit",
    as: "/members/previsit",
  },
  MemberProgressDashboard: {
    to: "/apps/MemberDashboard/journey",
    as: "/members/journey",
  },
  MomentsOnly: {
    to: "/moments",
    as: "/moments",
  },
  MemberMoments: {
    to: "/apps/MemberDashboard/moments",
    as: "/members/moments",
  },
  MemberMomentsParenting: {
    to: "/apps/MemberDashboard/moments",
    as: "/members/moments",
    query: { category: "PARENTING" },
  },
  MemberSettings: {
    to: "/apps/MemberDashboard/settings",
    as: "/members/settings",
  },
  MemberBenefits: {
    to: "/apps/MemberDashboard/benefits",
    as: "/members/benefits",
  },
  MemberSetGoals: {
    to: "/apps/MemberDashboard/set_goals",
    as: "/members/set_goals",
  },
  MemberUpdateAddress: {
    to: "/apps/MemberDashboard/update_address",
    as: "/members/update_address",
  },
  MemberLearningCenter: {
    to: "/apps/MemberDashboard/learning-center",
    as: "/members/learning-center",
  },
  MemberSetupMfa: {
    to: "/apps/MemberDashboard/mfa",
    as: "/members/mfa",
  },
  MemberSetupMfaBackupCodes: {
    to: "/apps/MemberDashboard/mfa/backup_codes",
    as: "/members/mfa/backup_codes",
  },
  MemberLearningCenterArticles: {
    to: "/apps/MemberDashboard/learning-center/:category_slug/articles",
    as: "/members/learning-center/:category_slug/articles",
  },
  MemberLearningCenterArticle: {
    to: "/apps/MemberDashboard/learning-center/:category_slug/articles/:article_slug",
    as: "/members/learning-center/:category_slug/articles/:article_slug",
  },
  FinishSignUp: {
    to: "/apps/MemberDashboard/finish_signup",
    as: "/members/finish_signup",
  },
  InviteDependents: {
    to: "/apps/MemberDashboard/invite_dependents",
    as: "/members/invite_dependents",
  },
  FamilySignup: {
    to: "/apps/MemberDashboard/family_signup",
    as: "/members/family_signup",
  },
  MemberExpectations: {
    to: "/apps/MemberDashboard/expectations",
    as: "/members/expectations",
  },
  ChooseUser: {
    to: "/apps/MemberDashboard/choose_user",
    as: "/members/choose_user",
  },
  ChooseUserPassword: {
    to: "/apps/MemberDashboard/choose_user/password/:id",
    as: "/members/choose_user/password/:id",
  },
  RecommendedTherapists: {
    to: "/apps/MemberDashboard/recommendations",
    as: "/members/recommendations",
    query: { intro: true },
  },
  SubstanceUseSupport: {
    to: "/apps/MemberDashboard/alcohol-and-substance-use-support",
    as: "/members/alcohol-and-substance-use-support",
  },
  MembersInsurance: {
    to: "/apps/MemberDashboard/insurance",
    as: "/members/insurance",
  },
  CareNavigation: {
    to: "/apps/MemberDashboard/care_navigation",
    as: "/members/care_navigation",
  },
  CareNavigationScheduling: {
    to: "/apps/MemberDashboard/scheduling",
    as: "/members/scheduling",
  },
  SUDCareNavigationScheduling: {
    to: "/apps/MemberDashboard/sud-scheduling",
    as: "/members/sud-scheduling",
  },
  WorkplaceManager: {
    to: "/apps/MemberDashboard/workplace",
    as: "/members/workplace",
  },
  WorkplaceGeneralConsultations: {
    to: "/apps/MemberDashboard/workplace/general_consultations",
    as: "/members/workplace/general_consultations",
  },
  WorkplaceCriticalIncidents: {
    to: "/apps/MemberDashboard/workplace/critical_incidents",
    as: "/members/workplace/critical_incidents",
  },
  WorkplaceCrisisSupport: {
    to: "/apps/MemberDashboard/workplace/crisis_support",
    as: "/members/workplace/crisis_support",
  },
  AccidentsInTheWorkplace: {
    to: "/apps/MemberDashboard/workplace/accidents_in_the_workplace",
    as: "/members/workplace/accidents_in_the_workplace",
  },
  ManageTraumaticEvents: {
    to: "/apps/MemberDashboard/workplace/manage_traumatic_events",
    as: "/members/workplace/manage_traumatic_events",
  },
  SupportingTeamWithChange: {
    to: "/apps/MemberDashboard/workplace/supporting_teams_through_change",
    as: "/members/workplace/supporting_teams_through_change",
  },
  ManagingViolenceInWorkplace: {
    to: "/apps/MemberDashboard/workplace/managing_violence_in_the_workplace",
    as: "/members/workplace/managing_violence_in_the_workplace",
  },
  ManagingTeamsThroughLoss: {
    to: "/apps/MemberDashboard/workplace/managing_teams_through_loss",
    as: "/members/workplace/managing_teams_through_loss",
  },
  SageVideo: {
    to: "/apps/MemberDashboard/workplace/video/sage/:id",
    as: "/members/workplace/video/sage/:id",
  },
  Messages: {
    to: "/apps/MemberDashboard/messages",
    as: "/members/messages",
  },
  SpecialtyCoach: {
    to: "/apps/MemberDashboard/coach/:specialty",
    as: "/members/coach/:specialty",
  },
  LocalResources: {
    to: "/apps/MemberDashboard/local_resources",
    as: "/members/local_resources",
  },
  LocalResourcesDetail: {
    to: "/apps/MemberDashboard/local_resources/:id",
    as: "/members/local_resources/:id",
  },
};

export default routes;
