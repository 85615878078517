//

import React, { Component } from "react";
import { Headline } from "@spring/smeargle";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { LandingPageWrapper } from "components";

import Raven from "utils/raven/getRaven";

export const ErrorBody = ({ heading, status }) => {
  return (
    <LandingPageWrapper>
      <div
        data-cy="error-page"
        style={{
          position: "fixed",
          height: "100px",
          top: "30%",
          left: 0,
          right: 0,
          margin: "auto",
        }}
      >
        <Headline center>
          {heading} | {status}
        </Headline>
      </div>
    </LandingPageWrapper>
  );
};

class ErrorHandler extends Component {
  static getInitialProps(props) {
    const statusCode = props.res
      ? props.res.statusCode
      : props.err
        ? props.err.statusCode
        : null;

    if (props.err && Raven) {
      if (
        !typeof window === "undefined" &&
        window.location.host.includes("localhost")
      ) {
        // do nothing, don't want to capture local host exceptions
      } else {
        Raven.captureException(props.err);
      }
    }

    return { statusCode };
  }

  get status() {
    if (this.props.statusCode) {
      return this.props.statusCode;
    }

    return this.props.t("status");
  }

  render() {
    return <ErrorBody heading={this.props.t("oops")} status={this.status} />;
  }
}

ErrorHandler.propTypes = {
  statusCode: PropTypes.any,
  t: PropTypes.func,
};

ErrorHandler.displayName = "ErrorHandler";

export default withTranslation("error")(ErrorHandler);
