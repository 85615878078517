import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Heading, Text, VStack } from "design-system/components";

type ProviderInstructions = {
  copy: string;
};

export const ProviderInstructions = ({ copy }: ProviderInstructions) => {
  const { t } = useTranslation("careVisits");

  return (
    <VStack
      mt={28}
      mb={12}
      alignItems="flex-start"
      bgColor="platform.25"
      p={24}
      borderRadius={16}
    >
      <Heading size="heading-small" mb="v-8">
        {t("appointmentDetails.providerInstructions")}
      </Heading>
      <Text size="body-medium" mb="v-8">
        {copy}
      </Text>
    </VStack>
  );
};
