import { useQuery } from "@apollo/client";

import { getBenefits } from "operations/queries/benefits";

interface CoachingBenefit {
  unlimitedCoachingCN?: boolean;
  unlimitedCoachingDirectScheduling?: boolean;
  showCoachingPaidExperience?: boolean;
  allowDirectScheduling?: boolean;
  qualifiesForCoachingViaCN?: boolean;
  shouldShowCoaching?: boolean;
  isCoachingRecommended?: boolean;
}

interface SUDBenefit {
  sudEnabled?: boolean;
  isSUDRecommended?: boolean;
}

interface isLoading {
  isLoading?: boolean;
}

type MemberBenefits = CoachingBenefit & SUDBenefit & isLoading;

export const useMemberBenefits = (memberId: string): MemberBenefits => {
  const {
    data: benefitsData,
    loading: isLoading,
    error,
  } = useQuery(getBenefits, {
    variables: { id: memberId },
    skip: !memberId,
  });

  if (!benefitsData && !error) return { isLoading: true } as MemberBenefits;

  if (isLoading) return { isLoading } as MemberBenefits;

  const memberBenefits =
    benefitsData?.benefits?.spring_benefit?.member_benefits;
  if (!memberBenefits || memberBenefits.length === 0)
    return { isLoading } as MemberBenefits;

  let requiredMemberBenefits = {};

  if (memberBenefits.includes("coaching")) {
    const {
      unlimited_coaching_cn,
      unlimited_coaching_direct_scheduling,
      show_coaching_paid_experience,
      allow_direct_scheduling,
      qualifies_for_coaching_via_CN,
      recommended: coaching_recommended,
      enabled: coaching_enabled,
    } = benefitsData.benefits.coaching_benefit;

    requiredMemberBenefits = {
      ...requiredMemberBenefits,
      unlimitedCoachingCN: unlimited_coaching_cn,
      unlimitedCoachingDirectScheduling: unlimited_coaching_direct_scheduling,
      showCoachingPaidExperience: show_coaching_paid_experience,
      allowDirectScheduling: allow_direct_scheduling,
      qualifiesForCoachingViaCN: qualifies_for_coaching_via_CN,
      shouldShowCoaching:
        unlimited_coaching_cn ||
        unlimited_coaching_direct_scheduling ||
        show_coaching_paid_experience,
      isCoachingRecommended: coaching_recommended,
      isCoachingEnabled: coaching_enabled,
    };
  }

  if (memberBenefits.includes("SUD")) {
    const {
      enabled: sud_enabled,
      recommended: sud_recommended,
      initial_sud_risk,
      latest_sud_risk,
      specialized_cn_enabled,
    } = benefitsData.benefits.sud_benefit;

    requiredMemberBenefits = {
      ...requiredMemberBenefits,
      sudEnabled: sud_enabled,
      isSUDRecommended: sud_recommended,
      initialSUDRisk: initial_sud_risk,
      latestSUDRisk: latest_sud_risk,
      specializedCNEnabled: specialized_cn_enabled,
    };
  }

  return { ...requiredMemberBenefits, isLoading };
};
