import React, { useState } from "react";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  VLogOutIcon,
  VArrowRightIcon,
  VXIcon,
  Tooltip,
} from "@springcare/sh-component-library";

import { useSessionConnectStatus } from "context/SessionRoomContext";

export const ExitButton = () => {
  const { handleDisconnect } = useSessionConnectStatus();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu>
      <Tooltip label="Leave" aria-label="Leave">
        <MenuButton
          aria-haspopup="true"
          aria-expanded={isOpen}
          aria-label="Options to leave or end the session"
          onClick={() => setIsOpen(!isOpen)}
        >
          <IconButton
            icon={VLogOutIcon}
            aria-label="Leave Session"
            variant="high-emphasis"
            colorScheme="negative"
          >
            Exit
          </IconButton>
        </MenuButton>
      </Tooltip>
      <MenuList zIndex={10}>
        <MenuItem
          icon={<VArrowRightIcon />}
          onClick={handleDisconnect}
          role="menuitem"
          aria-label="Leave the session"
        >
          Leave session
        </MenuItem>
        <MenuItem
          icon={<VXIcon />}
          onClick={handleDisconnect}
          role="menuitem"
          aria-label="End the session for all participants"
        >
          End session for all
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ExitButton;

ExitButton.displayName = "ExitButton";
