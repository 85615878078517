import {
  FilterChip,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VMicOnIcon,
  VVideoChatIcon,
  VVolumeLowIcon,
} from "@springcare/sh-component-library";

import { useSessionDevices } from "context/SessionRoomContext";
import { DeviceTypes } from "components/templates/SHSessionRoom/types";
import { DeviceMenuOptionGroup } from "components/templates/SHSessionRoom/components/VideoCallControls/DeviceMenuOptionGroup";

const truncateLabel = (label, maxLength = 16) => {
  return label?.length > maxLength ? `${label.slice(0, maxLength)}...` : label;
};

const DeviceMenuSection = () => {
  const { sessionDevices } = useSessionDevices();
  const {
    selectedAudioOutputDevice,
    selectedAudioInputDevice,
    selectedVideoDevice,
  } = sessionDevices;

  const deviceMap = [];

  if (selectedAudioInputDevice.deviceId !== "") {
    deviceMap.push({
      type: DeviceTypes.AudioInput,
      selected: selectedAudioInputDevice,
    });
  }

  if (selectedAudioOutputDevice.deviceId !== "") {
    deviceMap.push({
      type: DeviceTypes.AudioOutput,
      selected: selectedAudioOutputDevice,
    });
  }

  if (selectedVideoDevice.deviceId !== "") {
    deviceMap.push({
      type: DeviceTypes.VideoInput,
      selected: selectedVideoDevice,
    });
  }

  return (
    <Flex gap="v-8" w="100%">
      {deviceMap.map(({ type, selected }, index) => (
        <Menu key={index}>
          <MenuButton
            as={FilterChip}
            /* @ts-ignore -- the 'type' prop for FilterChip from component-library is valid */
            type="menu"
            w="100%"
            justifyContent="start"
            _focus={{ outline: "1px solid grey !important" }}
            _focusVisible={{ outline: "2px solid black !important" }}
          >
            <Flex align="center" gap="v-8">
              {type === DeviceTypes.AudioInput && <VMicOnIcon />}
              {type === DeviceTypes.AudioOutput && <VVolumeLowIcon />}
              {type === DeviceTypes.VideoInput && <VVideoChatIcon />}
              <Text>{truncateLabel(selected.label)}</Text>
            </Flex>
          </MenuButton>
          <MenuList>
            <DeviceMenuOptionGroup type={type} />
          </MenuList>
        </Menu>
      ))}
    </Flex>
  );
};

export default DeviceMenuSection;
