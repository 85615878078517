import React from "react";

const ChildSvg = () => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 22"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 5a3 3 0 1 1 3.019 3h-.038A3 3 0 0 1 9 5Zm-1.962.624a5 5 0 1 1 9.923 0l.917-.917a3 3 0 0 1 4.243 0l.172.172a3 3 0 0 1 0 4.242L19 12.414V21a1 1 0 1 1-2 0v-9a1 1 0 0 1 .293-.707l3.585-3.586a1 1 0 0 0 0-1.414l-.171-.172a1 1 0 0 0-1.414 0l-3.586 3.586A1 1 0 0 1 15 10h-2.976a4.961 4.961 0 0 1-.049 0H9a1 1 0 0 1-.707-.293L4.707 6.121a1 1 0 0 0-1.414 0l-.172.172a1 1 0 0 0 0 1.414l3.586 3.586A1 1 0 0 1 7 12v9a1 1 0 1 1-2 0v-8.586L1.707 9.121a3 3 0 0 1 0-4.242l.171-.172a3 3 0 0 1 4.243 0l.917.917Z"
      fill="#424A4C"
    />
  </svg>
);

export default ChildSvg;
