import {
  Box,
  Flex,
  Text,
  Input,
  IconButton,
  ArrowUpIcon,
} from "@springcare/sh-component-library";
import { useEffect, useRef, useState } from "react";
import { useLocalParticipant } from "@livekit/components-react";

import ChatMessageBubble from "./ChatMessageBubble";

const ChatSection = ({ send, chatMessages, isSending }) => {
  const { localParticipant } = useLocalParticipant();
  const localSid = localParticipant?.sid;

  const [inputMessage, setInputMessage] = useState("");
  const chatRef = useRef(null);

  useEffect(() => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [chatMessages]);

  const handleSend = () => {
    if (!inputMessage) return;
    send(inputMessage);
    setInputMessage("");
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const dateIndicator = new Date().toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });

  return (
    <Flex px="v-24" flexDir="column">
      <Flex
        h="calc(100vh - 316px)"
        flexGrow={1}
        flexDir="column"
        overflowY="auto"
        justifyContent="start"
        ref={chatRef}
        pt="v-16"
        aria-live="polite"
      >
        <Box w="100%" mb="v-16" color="content-primary">
          <Text>
            This is the start of your conversation. Please note that messages
            are not saved.
          </Text>
        </Box>
        <Flex
          w="100%"
          mb="v-16"
          color="content-secondary"
          justifyContent="center"
        >
          <Text size="body-small-regular">{dateIndicator}</Text>
        </Flex>
        {chatMessages.map((chatMessage) => (
          <ChatMessageBubble
            key={chatMessage.id}
            message={chatMessage}
            localSid={localSid}
          />
        ))}
      </Flex>

      <Flex align="center" pt="v-16" pb="v-16" gap="v-8">
        <Input
          type="text"
          name="inputMessage"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={handleEnterKeyPress}
          pr="v-32"
          placeholder="Send message"
          aria-label="Send message"
          autoComplete="off"
        />
        <IconButton
          icon={ArrowUpIcon}
          w={16}
          aria-label="Send Message"
          onClick={handleSend}
          isLoading={isSending}
          disabled={!inputMessage}
        />
      </Flex>
    </Flex>
  );
};

export default ChatSection;
