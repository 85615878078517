// Gets either the browser or the node version of raven.
// Requires `browser` config in `package.json`
// Doesn't use es6 features because it can be used in a node environment
// https://github.com/zeit/next.js/issues/1852#issuecomment-353676513

import { track } from "utils/mixpanel";

let Raven = require("raven");

const SENTRY_APP = "app-7007.on-aptible.com";
const SENTRY_KEY = process.env.SENTRY_KEY;
const SENTRY_PROJECT = process.env.SENTRY_PROJECT;

if (SENTRY_KEY && SENTRY_PROJECT) {
  Raven.config(`https://${SENTRY_KEY}@${SENTRY_APP}/${SENTRY_PROJECT}`, {
    dataCallback: (data) => {
      track("Page Error");
      return data;
    },
  }).install();
} else {
  Raven = null;
}

export default Raven;
