import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "@apollo/client";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import { addNotification } from "@spring/smeargle/actions";
import Meowth from "@spring/meowth";
import { useTranslation } from "react-i18next";
import {
  ActionChip,
  VAssessmentIcon,
  Text,
  IconButton,
  Box,
  useBreakpointValue,
} from "@springcare/sh-component-library";

import routes from "routes";
import { getMemberInfo } from "operations/queries/member";
import { getFirstError } from "utils/apollo/errorHandler";
import { useCreateCheckInMutation } from "hooks/useCreateCheckInMutation";
import { TRACK_EVENT } from "utils/mixpanel";

const TakeCheckinButton = ({ addNotification, isATeen = false }) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { data, loading } = useQuery(getMemberInfo, {
    ...Meowth.apolloOptionsUserId(),
    skip: Meowth.apolloSkipUserId(),
    onError: (err) => addNotification(getFirstError(err), "error"),
  });
  const memberId = data?.user?.member?.id;
  const [createCheckInMutation] = useCreateCheckInMutation(memberId, router);

  const handleTeenCheckInClick = () => {
    router.push(routes.TeenCheckIn.as);
    TRACK_EVENT.BUTTON_CLICKED(router.asPath, "Progress Check-in", {
      spring_doc_id: "checkin002",
    });
  };

  const CircularNoTextButton = ({
    isATeen,
    handleTeenCheckInClick,
    createCheckInMutation,
  }) => {
    return (
      <Box position="relative" marginEnd={["8px", "16px"]} p="2px">
        <IconButton
          variant="low-emphasis"
          size="md"
          colorScheme="positive"
          borderRadius="full"
          borderWidth="1px"
          borderStyle="solid"
          onClick={isATeen ? handleTeenCheckInClick : createCheckInMutation}
          fontSize="lg"
          _focusVisible={{
            outline: "2px solid",
            outlineColor: "black",
            outlineOffset: "4px",
          }}
          icon={VAssessmentIcon}
          px="26px"
          h={["40px", "40px", "48px"]}
          aria-label={t("navigation.checkinBtn")}
          w="50px"
        />
      </Box>
    );
  };

  const FullTextbutton = ({
    isATeen,
    handleTeenCheckInClick,
    createCheckInMutation,
  }) => {
    return (
      <Box position="relative" marginInlineEnd={["8px", "16px"]}>
        <ActionChip
          variant="low-emphasis"
          colorScheme="positive"
          icon={VAssessmentIcon}
          onClick={isATeen ? handleTeenCheckInClick : createCheckInMutation}
          h="full"
        >
          <Text size="body-medium-strong">{t("navigation.checkinBtn")}</Text>
        </ActionChip>
      </Box>
    );
  };

  const shouldHideText = useBreakpointValue({ base: true, xl: false });

  if (loading || !router) {
    return null;
  }

  return shouldHideText ? (
    <CircularNoTextButton
      isATeen={isATeen}
      handleTeenCheckInClick={handleTeenCheckInClick}
      createCheckInMutation={createCheckInMutation}
    />
  ) : (
    <FullTextbutton
      isATeen={isATeen}
      handleTeenCheckInClick={handleTeenCheckInClick}
      createCheckInMutation={createCheckInMutation}
    />
  );
};

TakeCheckinButton.propTypes = {
  addNotification: PropTypes.func,
};

const mapDispatchToProps = { addNotification };
export { TakeCheckinButton };
export default connect(null, mapDispatchToProps)(TakeCheckinButton);
