// TODO: Add provider query if unselectFilter is clicked
import { useLazyQuery } from "@apollo/client";
import { HStack, Flex, Box, Text, IconButton } from "design-system/components";
import { Close } from "design-system/assets";
import { cloneDeep } from "lodash/fp";
import { useTranslation } from "hooks/react-i18next";

import { searchCareProviders } from "operations/queries/careProvider";

import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import {
  prepareQueryFilters,
  minorFilterTag,
  mapCategoryToSingular,
} from "components/templates/Browse/ProviderBrowsePage/utils";
import { trackFilterChangeApplied } from "components/templates/Browse/ProviderBrowsePage/analytics";

import { getQueriedFilters } from "components/templates/Browse/ProviderBrowsePage/utils";

const SelectedFilterChips = () => {
  const {
    queriedFilters,
    setQueriedFilters,
    setSelectedFilters,
    setProviderList,
    setProviderListLoading,
    setPaging,
    memberInfo,
    providerType,
  } = useProviderBrowseContext();

  const [doSearchCareProviders] = useLazyQuery(searchCareProviders, {
    fetchPolicy: "no-cache",
  });

  const { t } = useTranslation(["careProvider", "common"]);

  async function updateFilteredProviders() {
    const preparedFilters = prepareQueryFilters(queriedFilters, providerType);

    if (preparedFilters) {
      preparedFilters["version"] = "v2";
      setProviderListLoading(true);
      doSearchCareProviders({
        variables: preparedFilters,
      })
        .then((data) => {
          const response = data?.data;
          if (response) {
            setProviderList(response?.care_providers_search?.providers);
            setPaging(response?.care_providers_search?.paging);
          }
        })
        .finally(() => {
          setProviderListLoading(false);
        });
    }
  }

  function unselectFilter(filterObj: { type: string; filterLabel: string }) {
    const { type, filterLabel } = filterObj;
    const queriedFiltersCopy = { ...queriedFilters };

    // Removes deselected filter
    queriedFiltersCopy.filters[type] = queriedFiltersCopy.filters[type].filter(
      (item) => {
        return item !== filterLabel;
      },
    );

    // Track tag removed for Mixpanel
    trackFilterChangeApplied(
      filterLabel,
      mapCategoryToSingular(type, t),
      false,
      "Browse Filter Section",
    );

    // Updates state of `searchProvider` payload with removed filters
    setQueriedFilters(cloneDeep(queriedFiltersCopy));

    // Updates flyout "selected" filters to match updated "queried" filters
    setSelectedFilters(cloneDeep(queriedFiltersCopy));

    // Triggers + updates `searchProvider` query with new results
    updateFilteredProviders();
  }

  const queriedFilterObjs = getQueriedFilters(queriedFilters.filters);
  const filterTag = minorFilterTag(memberInfo, t);

  return (
    <>
      {queriedFilterObjs.length > 0 && (
        <Flex wrap="wrap">
          {queriedFilterObjs.map((queriedFilter) => {
            return (
              <Box
                key={queriedFilter.filterLabel}
                w="fit-content"
                px={12}
                py={8}
                mr={8}
                mb={8}
                bg="platform.light"
                borderRadius={4}
                data-cy={`${queriedFilter.filterLabel}-tag`}
              >
                <HStack spacing={4} whiteSpace="nowrap">
                  <Text variant="captionBold_v1">
                    {queriedFilter.filterLabel}
                  </Text>
                  {filterTag !== queriedFilter.filterLabel && (
                    <IconButton
                      onClick={() => unselectFilter(queriedFilter)}
                      aria-label="Close"
                      icon={<Close boxSize={8} />}
                      color="platform.base"
                      _hover={{ color: "platform.base" }}
                    />
                  )}
                </HStack>
              </Box>
            );
          })}
        </Flex>
      )}
    </>
  );
};

export default SelectedFilterChips;
