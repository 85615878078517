//
import PropTypes from "prop-types";
import pichu from "constants/pichu";
import Overlays from "constants/overlayIds";

import React, { Component } from "react";
import { compose } from "redux";
import classnames from "classnames";
import { connect } from "react-redux";
import Router from "next/router";
import { get, getOr } from "lodash/fp";
import Meowth from "@spring/meowth";
import { graphql } from "@apollo/client/react/hoc";
import { SiteLoader } from "@spring/smeargle";
import { addOverlay } from "@spring/smeargle/actions";
import { makeCancelable } from "@spring/smeargle/utils";

import styles from "./styles.module.scss";

import { PreviewDemoDisclaimerModal, SkipToContent } from "components";
import {
  startLoadingProgress,
  endLoadingProgress,
  startRouting,
  endRouting,
} from "actions/app/actions";
import { getUserInfo } from "operations/queries/user";
import { track } from "utils/mixpanel";
import { showGlobalExperience } from "actions/global/actions";
import Snorlax from "utils/snorlax";

/**
 * This is always wrapped by redux
 * Therefore, we can have it always check the token using growlithe
 *
 * Should have a `isLoggedIn` flag that pages can use to restrict acess
 *
 * Should have a `verifiedToken` flag that blocks THIS component from rendering. (show loader)
 *
 * and that we've set isLoggedIn accordingly.
 *
 */

class PageBase extends Component {
  static propTypes = {
    allowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    children: PropTypes.any,
    darkBg: PropTypes.bool,
    overflowVisible: PropTypes.bool,
    endLoadingProgress: PropTypes.func,
    endRouting: PropTypes.func,
    isDeactivated: PropTypes.func,
    loadingActive: PropTypes.any,
    overlays: PropTypes.array,
    progress: PropTypes.any,
    showGlobalExperience: PropTypes.func,
    startLoadingProgress: PropTypes.any,
    startRouting: PropTypes.func,
    className: PropTypes.any,
    removeBasePagePadding: PropTypes.bool,
    removePaddingBottom: PropTypes.bool,
    shouldShowBrowseRecs: PropTypes.bool,
    showT2Background: PropTypes.bool,
  };

  timeout = null;
  refreshTimeout = null;
  globalCheck = false;
  state = {
    allowed: "pending",
    checkAllowedPromise: null,
    pageBaseStyles: "pageBase",
  };

  static defaultProps = {
    allowed: true,
    darkBg: true,
    overflowVisible: false,
  };

  componentDidMount() {
    this.props.endRouting();

    // Allowed logic
    this.checkAllPermissions();

    // 30 seconds after the page mounts, refresh their token
    this.refreshTimeout = setTimeout(() => {
      // If their token expires in under an hour, optimistically refresh
      if (localStorage.access_token_expires_at - 3600000 < Date.now()) {
        pichu.refreshAccessToken();
      }
    }, 30000);

    if (this.props.removeBasePagePadding) {
      this.setState({ pageBaseStyles: "pageBaseNoPadding" });
    }

    Router.onRouteChangeStart = (url) => {
      // skip if it loads instantly
      if (url !== window.location.pathname) {
        track("Route Change", {
          to: url,
          from: window.location.pathname,
        });
        // this.props.routerChange();
        this.props.startRouting();
        this.timeout = window.setTimeout(this.props.startLoadingProgress, 500);
      }
    };

    Router.onRouteChangeComplete = () => {
      if (this.props.loadingActive) {
        this.props.endLoadingProgress();
      }
    };

    Router.onRouteChangeError = () => {
      if (this.props.loadingActive) {
        this.props.endLoadingProgress();
      }
    };
  }

  componentDidUpdate(prevProps) {
    const oldCountry = getOr(
      {},
      "data.user.member.postal_address.country",
      prevProps,
    );
    const newCountry = getOr(
      {},
      "data.user.member.postal_address.country",
      this.props,
    );
    if (
      oldCountry &&
      oldCountry.length > 0 &&
      newCountry &&
      newCountry.length > 0 &&
      newCountry === oldCountry &&
      !this.globalCheck
    ) {
      //check for global experience
      this.shouldShowGlobalExperience();
      // we should change this component to a functional component to utilize the hooks functionality instead for hacking these class lifecycle methods
      this.globalCheck = true;
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
    window.clearTimeout(this.refreshTimeout);
    if (this.state.checkAllowedPromise) {
      this.state.checkAllowedPromise.cancel();
    }
  }

  shouldShowGlobalExperience() {
    const country = getOr(
      {},
      "data.user.member.postal_address.country",
      this.props,
    );
    // if country is US then we show the domestic experience otherwise show the global experience
    if (country && country.length > 0 && country !== "US") {
      this.props.showGlobalExperience({ showGlobalExperience: true });
    } else {
      this.props.showGlobalExperience({ showGlobalExperience: false });
    }
  }

  checkAllPermissions() {
    // isDeactivated is PageBase's custom allowed prop, which is a function that returns a Snorlax.
    // isDeactivated Must be checked for every page, which is why it lives here in PageBase
    const isDeactivatedCheck = this.props.isDeactivated();
    if (isDeactivatedCheck) {
      const checkAllowedPromise = makeCancelable(isDeactivatedCheck.allowed);
      this.setState({ checkAllowedPromise });

      checkAllowedPromise.promise.then((allowed) => {
        if (!allowed) {
          this.setAllowed(false);
          return;
        }

        // allowed technically could also be 'pending', but it doesn't look like that should be possible
        // given that Snorlax's pending property doesn't ever seem to be set to true... so just going to continue
        // whether allowed is true or 'pending'
        this.checkPermission();
      });
    }
  }

  checkPermission() {
    if (this.props.allowed === true) {
      this.setAllowed(true);
      return;
    }

    const check = this.props.allowed();

    if (check) {
      check.allowed.then((allowed) => {
        if (allowed === "pending") {
          // Do nothing
        }

        if (allowed === true) {
          this.setAllowed(true);
        }

        if (allowed === false) {
          this.setAllowed(false);
        }
      });
    }
  }

  setAllowed = (allowed) => {
    this.setState({ allowed });
  };

  get children() {
    if (this.props.allowed) {
      // safeguard against showing page content
      return this.props.children;
    }

    return null;
  }

  get previewDemoDisclaimer() {
    if (process.env.APP_API === "https://preview.api.springhealth.com/") {
      return <PreviewDemoDisclaimerModal />;
    }

    return null;
  }

  render() {
    if (this.state.allowed === "pending" || this.state.allowed === false) {
      return null;
    }

    if (this.props.overlays && this.props.overlays.length) {
      const overlayToRender = Overlays[this.props.overlays[0]];
      return React.createElement(overlayToRender);
    }

    return (
      <div
        className={classnames(
          !this.props.shouldShowBrowseRecs && styles[this.state.pageBaseStyles],
          this.props.className,
          {
            [styles.dark]: this.props.darkBg,
            [styles.darkT2]: this.props.showT2Background,
            [styles.overflowVisible]: this.props.overflowVisible,
            [styles.removePaddingBottom]: this.props.removePaddingBottom,
          },
        )}
        style={{ overflowX: this.props.shouldShowBrowseRecs && "hidden" }}
      >
        <SkipToContent />
        <SiteLoader progress={this.props.progress} />
        {this.children}
        {this.debug}
        {this.previewDemoDisclaimer}
      </div>
    );
  }
}

export default compose(
  graphql(getUserInfo, {
    options: Meowth.apolloOptionsUserId,
    skip: Meowth.apolloSkipUserId,
  }),
  connect(
    (state) => ({
      isLoggedIn: get("auth.isLoggedIn", state),
      progress: getOr(0, "app.loading.progress", state),
      loadingActive: getOr(0, "app.loading.active", state),
      overlays: getOr([], "modal.overlays", state),
      isDeactivated: () => Snorlax(state).isLoggedInAndDeactivated(),
    }),
    {
      startLoadingProgress,
      endLoadingProgress,
      startRouting,
      endRouting,
      addOverlay,
      showGlobalExperience,
    },
  ),
)(PageBase);

export { PageBase };
