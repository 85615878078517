import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  PinInput,
  PinInputField,
} from "design-system/components";

const mfaPinFormSchema = yup.object().shape({
  code: yup.string().length(6).required("A valid PIN is required"),
});

const VerificationCodeInput = ({
  control,
  name,
  pinInputHeight,
  pinInputWidth,
}) => {
  const { field } = useController({ name, control });

  return (
    <PinInput id={name} onChange={field.onChange} type="number" otp>
      <PinInputField h={pinInputHeight} w={pinInputWidth} me={8} />
      <PinInputField h={pinInputHeight} w={pinInputWidth} me={8} />
      <PinInputField h={pinInputHeight} w={pinInputWidth} me={8} />
      <PinInputField h={pinInputHeight} w={pinInputWidth} me={8} />
      <PinInputField h={pinInputHeight} w={pinInputWidth} me={8} />
      <PinInputField h={pinInputHeight} w={pinInputWidth} />
    </PinInput>
  );
};

const VerifyPinForm = ({
  children,
  flexDirection,
  formLabel,
  formSubmit,
  icon,
  isDisabled,
  isRequired,
  onSubmit,
  pinInputHeight,
  pinInputWidth,
  withStickyFooterButton,
  ...rest
}) => {
  const name = "code";
  const { control, formState, handleSubmit, watch } = useForm({
    defaultValues: { [name]: "" },
    resolver: yupResolver(mfaPinFormSchema),
  });
  const { errors, isSubmitting } = formState;
  const isInvalidInput = watch(name).length !== 6;

  return (
    <>
      <Box w={withStickyFooterButton && "80%"} {...rest}>
        <form
          // eslint-disable-next-line no-unused-vars
          onSubmit={handleSubmit((data, _event) => onSubmit(data))}
          aria-label="verify pin form"
        >
          <FormControl isInvalid={errors?.[name]} isRequired={isRequired}>
            <Flex
              gap={24}
              flexDirection={flexDirection}
              alignItems={
                withStickyFooterButton
                  ? "start"
                  : flexDirection === "row"
                    ? "end"
                    : "initial"
              }
            >
              <Flex flexDirection="column">
                <FormLabel htmlFor={name}>{formLabel}</FormLabel>
                <Box>
                  <VerificationCodeInput
                    pinInputWidth={pinInputWidth}
                    pinInputHeight={pinInputHeight}
                    control={control}
                    name={name}
                  />
                  {errors?.[name] && (
                    <FormErrorMessage color="error.base" fontWeight={700}>
                      {errors[name]?.message}
                    </FormErrorMessage>
                  )}
                </Box>
              </Flex>
              {!withStickyFooterButton && (
                <Button
                  type="submit"
                  variant="solid"
                  colorScheme="primary"
                  h={56}
                  rightIcon={icon}
                  isLoading={isSubmitting}
                  isDisabled={isDisabled || isSubmitting || isInvalidInput}
                >
                  {formSubmit}
                </Button>
              )}
            </Flex>
          </FormControl>
          {/**
           * TODO: The below render-props was leveraged to incorporate the redesigns of the MFA
           * login pages, which required use of the SHStickyFooter button AND moving the link to
           * trigger the backup code form under that button. If the old sign in page is ever
           * retired, it may be beneficial to remove the render props and all rendering that is
           * conditioned on withStickyFooterButton.
           */}
          {withStickyFooterButton &&
            children({
              isLoading: isSubmitting,
              isDisabled: isDisabled || isSubmitting || isInvalidInput,
            })}
        </form>
      </Box>
    </>
  );
};

VerifyPinForm.propTypes = {
  children: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  flexDirection: PropTypes.string,
  pinInputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.element,
  isRequired: PropTypes.bool,
  withStickyFooterButton: PropTypes.bool,
};

VerifyPinForm.defaultProps = {
  children: () => {},
  flexDirection: "row",
  pinInputWidth: 56,
  pinInputHeight: 56,
  isRequired: true,
  isDisabled: false,
  withStickyFooterButton: false,
};

export default VerifyPinForm;
