//

import React from 'react';
import PropTypes from 'prop-types';

const ZoomIcon = () => (
	<svg aria-hidden="true" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M7 4C3.13401 4 0 7.13401 0 11V17C0 18.6569 1.34315 20 3 20H11C14.0741 20 16.6854 18.0184 17.6262 15.2628L21.3675 16.5099C22.6626 16.9416 24 15.9777 24 14.6126V9.38743C24 8.02231 22.6626 7.05837 21.3675 7.49006L18 8.61257V7C18 5.34315 16.6569 4 15 4H7ZM18 10.7208L22 9.38743V14.6126L18 13.2792V10.7208ZM16 7V13C16 15.7614 13.7614 18 11 18H3C2.44772 18 2 17.5523 2 17V11C2 8.23858 4.23858 6 7 6H15C15.5523 6 16 6.44772 16 7Z" />
	</svg>
);



export default ZoomIcon;
