import { Link } from "@springcare/sh-component-library";
import { Trans } from "react-i18next";
import { AppointmentKind, AppointmentMedium } from "@spring/constants";

const downloadZoom = (t) => {
  return {
    headline: t("appointmentTips.downloadZoom.headline"),
    text: (
      <>
        <Trans
          t={t}
          i18nKey="appointmentDetails.videoCopy.content"
          components={[
            <Link key="zoom-link" href="https://zoom.us/download" />,
            <Link
              key="ios-store-link"
              href="http://itunes.apple.com/us/app/zoom.us-cloud-video-meetings/id546505307"
            />,
            <Link
              key="android-store-link"
              href="https://play.google.com/store/apps/details?id=us.zoom.videomeetings"
              target="_blank"
            />,
          ]}
        />
      </>
    ),
  };
};

export const curriedGetTips =
  (t) =>
  (
    medium,
    instructions,
    isAMinor,
    kind,
    isInHouseVideo,
    couplesTherapyFlag,
  ) => {
    if (isAMinor) {
      if (medium === AppointmentMedium.InPerson && instructions) {
        return t("appointmentTips.inPerson", { returnObjects: true });
      }

      switch (medium) {
        case AppointmentMedium.Video:
          return [
            ...(!isInHouseVideo ? [downloadZoom(t)] : []),
            ...t("appointmentTips.video.minor", { returnObjects: true }),
          ];
        case AppointmentMedium.InPerson:
          return t("appointmentTips.inPerson", { returnObjects: true });

        case AppointmentMedium.Phone:
          return t("appointmentTips.phone.minor", { returnObjects: true });
      }
    } else {
      const isCouplesTherapy = kind === AppointmentKind.CouplesTherapy;

      if (medium === AppointmentMedium.InPerson && instructions) {
        return t("appointmentTips.inPerson", { returnObjects: true }).slice(1);
      }

      switch (medium) {
        case AppointmentMedium.InPerson:
          if (isCouplesTherapy && couplesTherapyFlag) {
            return [
              t("appointmentTips.inPerson", { returnObjects: true })[1],
              ...t("appointmentTips.inPersonCouples", { returnObjects: true }),
              t("appointmentTips.inPerson", { returnObjects: true })[3],
            ];
          }
          return t("appointmentTips.inPerson", { returnObjects: true }).slice(
            1,
          );

        case AppointmentMedium.Video:
          if (kind === "COACHING")
            return [
              ...(!isInHouseVideo ? [downloadZoom(t)] : []),
              ...t("appointmentTips.video.nonMinor", { returnObjects: true }),
              {
                headline: t(
                  "appointmentTips.video.interpolatedTips.comePreparedForCoachingHeadline",
                ),
                text: (
                  <span>
                    {t(
                      "appointmentTips.video.interpolatedTips.comePreparedForCoachingIntroText",
                    )}
                    <ul>
                      <li>
                        <Trans
                          t={t}
                          i18nKey="appointmentTips.video.interpolatedTips.comePreparedForCoachingListItem1Text"
                          components={[<b key="bold" />]}
                        />
                      </li>
                      <li>
                        <Trans
                          t={t}
                          i18nKey="appointmentTips.video.interpolatedTips.comePreparedForCoachingListItem2Text"
                          components={[<b key="bold" />]}
                        />
                      </li>
                    </ul>
                  </span>
                ),
              },
            ];

          return [
            ...(!isInHouseVideo ? [downloadZoom(t)] : []),
            ...t("appointmentTips.video.nonMinor", { returnObjects: true }),
            ...(!isInHouseVideo
              ? [
                  {
                    headline: t(
                      "appointmentTips.video.interpolatedTips.showFaceClearlyHeadline",
                    ),
                    text: t(
                      "appointmentTips.video.interpolatedTips.showFaceClearlyText",
                    ),
                  },
                ]
              : []),
            {
              headline: t(
                "appointmentTips.video.interpolatedTips.knowWhatToExpectHeadline",
              ),
              text:
                isCouplesTherapy && couplesTherapyFlag
                  ? t(
                      "appointmentTips.video.interpolatedTips.knowWhatToExpectCouplesText",
                    )
                  : t(
                      "appointmentTips.video.interpolatedTips.knowWhatToExpectText",
                    ),
            },
          ];

        case AppointmentMedium.Phone:
          return t("appointmentTips.phone.nonMinor", { returnObjects: true });
      }
    }
  };

export const curriedGetHeadline = (t) => (medium, kind) => {
  switch (medium) {
    case AppointmentMedium.Video:
      if (kind === "COACHING") return t("appointmentTips.headline.coaching");
      return t("appointmentTips.headline.video");

    case AppointmentMedium.Phone:
      return t("appointmentTips.headline.phone");
    default:
      break;
  }
};

export const formatDateWithPascalCaseMonth = (date) => {
  if (typeof date !== "string" || date.length === 0) {
    return date;
  }
  const dateSegments = date
    .split(",")
    .map(
      (segment) =>
        segment.trim().charAt(0).toUpperCase() + segment.trim().slice(1),
    );
  return dateSegments.join(", ");
};
