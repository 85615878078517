import Meowth from "@spring/meowth";
import Router, { withRouter } from "next/router";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addNotification } from "@spring/smeargle/actions";
import {
  Heading,
  Text,
  Button,
  SimpleGrid,
  Box,
  Flex,
  SHStickyFooter,
  Center,
  Spinner,
} from "design-system/components";
import { Link } from "components/atoms";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  EVENT_TYPE,
  makeEventString,
  TRACK_EVENT,
  track,
} from "utils/mixpanel";
import { SHInputWithLoadingState } from "design-system/components";

import { retryLogin } from "./retryLogin";

import { getUserInfo } from "operations/queries/user";
import {
  updateFamilySignupModel,
  updateFindMyMembershipModel,
  resetFamilySignupModel,
  resetFindMyMembershipModel,
  resetVerificationModel,
} from "actions/signUp";
import { resendConfirmationEmail, verifyConfirmationCode } from "actions/auth";
import routes from "routes";
import { redirectToRegister, redirectToSignIn } from "lib/redirects";
import { isUserLoggedIn } from "lib/isUserLoggedIn";
import { useMemberInfo } from "hooks";
import { getHomeRoute } from "shared/utils/getHomeRoute";

function getEmailFromUrlParams() {
  return window
    ? new URL(window.location.href).searchParams.get("email")
    : null;
}

const LoadingSpinner = () => {
  return (
    <Center>
      <Spinner speed="1s" size="xl" data-testid="loading-spinner" />
    </Center>
  );
};

function updateInitialTarget() {
  // make sure it doesn't redirect back to code confirmation
  if (
    localStorage.getItem("initialTarget") === routes.CodeConfirmationEmail.as
  ) {
    localStorage.removeItem("initialTarget");
  }

  const left = localStorage.getItem("nextTarget");

  if (left) {
    localStorage.setItem("initialTarget", left);
  }

  return left;
}

const REMIND_ME_LATER = "Remind Me Later";

function redirectToWhereLeftOrDashboard(member) {
  const left = updateInitialTarget();

  if (left) {
    TRACK_EVENT.LINK_CLICKED(
      routes.CodeConfirmationEmail.as,
      localStorage.getItem("nextTarget"),
      REMIND_ME_LATER,
    );
    Router.push(localStorage.getItem("nextTarget"));
  } else {
    TRACK_EVENT.LINK_CLICKED(
      routes.CodeConfirmationEmail.as,
      routes.MemberHome.as,
      REMIND_ME_LATER,
    );
  }

  if (member?.managed_dependents.length > 0) {
    Router.push(routes.ChooseUser.to, routes.ChooseUser.as);
    return;
  }

  Router.push(routes.MemberHome.to, routes.MemberHome.as);
}

const CodeConfirmationEmailForm = (props) => {
  const userIsLoggedIn = isUserLoggedIn();
  if (!userIsLoggedIn) {
    redirectToSignIn();
    return <LoadingSpinner />;
  }

  const { data: memberData, loading } = useMemberInfo();
  const hasCompletedAddress =
    !!memberData?.user?.member?.postal_address?.street_address_1;
  const member = memberData?.user?.member;
  const { t } = useTranslation("emailContent");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: "onTouched",
  });

  if (loading) return <LoadingSpinner />;

  localStorage.removeItem("fromSignIn");
  const email = getEmailFromUrlParams() || memberData?.user?.member?.email;

  if (!userIsLoggedIn) {
    redirectToSignIn();
    return <LoadingSpinner />;
  }

  if (!hasCompletedAddress) {
    redirectToRegister();
    return <LoadingSpinner />;
  }

  const { addNotification } = props;

  async function resendCode() {
    track("Auth -- Resend Confirm Email", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.BUTTON_CLICKED, {
        page: routes.CodeConfirmationEmail.as,
        type: "Resend Confirmation Email",
      }),
    });
    TRACK_EVENT.BUTTON_CLICKED(
      routes.CodeConfirmationEmail.as,
      "Resend Confirmation Email",
    );
    try {
      await resendConfirmationEmail({ email });
    } catch (error) {
      addNotification(error?.error_data?.failures?.email[0], "error");
    }
  }

  const codeLengthValidation = {
    required: "Code is required",
    pattern: {
      value: /^[0-9]+$/,
      message: "Numbers only",
    },
    minLength: { value: 6, message: "Code must be 6 characters" },
    maxLength: { value: 6, message: "Code must be 6 characters" },
  };

  const updateStepState = (step) => {
    if (step === "GUARDIAN") {
      props.updateFamilySignupModel({
        fieldKey: "family_signup_step",
        value: "CHILD",
      });
      props.updateFindMyMembershipModel({ fieldKey: "first_name", value: "" });
      props.updateFindMyMembershipModel({ fieldKey: "last_name", value: "" });
      props.updateFindMyMembershipModel({
        fieldKey: "date_of_birth",
        value: "",
      });
      props.updateFindMyMembershipModel({
        fieldKey: "email_address",
        value: "",
      });
      props.updateFindMyMembershipModel({
        fieldKey: "potential_member_type",
        value: "DEPENDENT",
      });
      return;
    } else if (step === "CHILD") {
      props.resetFamilySignupModel();
      props.resetFindMyMembershipModel();
      props.resetVerificationModel();
      return;
    }
  };

  async function verifyFamilySignupCode(confirmation_code) {
    const { family_signup_step } = props.familySignupModel;

    if (family_signup_step === "GUARDIAN") {
      try {
        await verifyConfirmationCode({ confirmation_code, email });
        addNotification("Code Validated!", "success");
        if (isUserLoggedIn()) {
          updateStepState("GUARDIAN");
          const { to, as } = routes.FamilySignup;
          props.router.push(to, as);
        } else {
          // handle this issue
          updateInitialTarget();
          // if the password wasn't available (in case of page refresh),
          if (!(await retryLogin(email))) {
            // redirets to SignIn
            redirectToWhereLeftOrDashboard(member);
          }
        }
      } catch (_e) {
        setError("code", {
          type: "manual",
          message: `${t("notification.codeInvalidated")}`,
        });
      }
    } else if (family_signup_step === "CHILD") {
      try {
        await verifyConfirmationCode({ confirmation_code, email });
        addNotification("Code Validated!", "success");
        // clear out redux state
        updateStepState("CHILD");
        // go to choose user page
        const { to, as } = routes.ChooseUser;
        props.router.push(to, as);
      } catch (_e) {
        setError("code", {
          type: "manual",
          message: `${t("notification.codeInvalidated")}`,
        });
      }
    }
  }

  function remindMeHandler() {
    const dependentsVisitedStatus = localStorage.getItem(
      "hasVisitedDependents",
    );
    track("Auth -- Confirm Email Remind Later", {
      deprecated: true,
      replaced_with: makeEventString(EVENT_TYPE.LINK_CLICKED, {
        page: routes.CodeConfirmationEmail.as,
        to:
          routes.FamilySignup.as +
          " OR " +
          routes.ChooseUser.as +
          " OR " +
          routes.InviteDependents.as,
        as: REMIND_ME_LATER,
      }),
    });
    const { is_family_signup, family_signup_step } = props.familySignupModel;
    const { child_dependents_allowed } =
      props.data?.user?.member?.cohort || false;

    if (is_family_signup && family_signup_step === "GUARDIAN") {
      TRACK_EVENT.LINK_CLICKED(
        routes.CodeConfirmationEmail.as,
        routes.FamilySignup.as,
        REMIND_ME_LATER,
      );
      updateStepState("GUARDIAN");
      const { to, as } = routes.FamilySignup;
      props.router.push(to, as);
      return;
    }
    if (is_family_signup && family_signup_step === "CHILD") {
      TRACK_EVENT.LINK_CLICKED(
        routes.CodeConfirmationEmail.as,
        routes.ChooseUser.as,
        REMIND_ME_LATER,
      );
      updateStepState("CHILD");
      const { to, as } = routes.ChooseUser;
      props.router.push(to, as);
      return;
    }
    if (child_dependents_allowed && !dependentsVisitedStatus) {
      TRACK_EVENT.LINK_CLICKED(
        routes.CodeConfirmationEmail.as,
        routes.InviteDependents.as,
        REMIND_ME_LATER,
      );
      const { to, as } = routes.InviteDependents;
      props.router.push(
        {
          pathname: to,
          query: { referrer: routes.CodeConfirmationEmail.as },
        },
        as,
      );
      return;
    }
    redirectToWhereLeftOrDashboard(member);
  }

  function sendToNextRoute() {
    // check if dependents allowed
    const { dependents_allowed } = props.data?.user?.member?.cohort || false;
    const dependentsVisitedStatus = localStorage.getItem(
      "hasVisitedDependents",
    );
    if (member?.minor && member?.manager?.email && member?.date_of_birth) {
      const memberHomeRoute = getHomeRoute(member.date_of_birth);
      props.router.push(memberHomeRoute.to, memberHomeRoute.as);
      return;
    }

    // if so - send to InviteDependentsPage Screen
    if (dependents_allowed && !dependentsVisitedStatus) {
      const { to, as } = routes.InviteDependents;
      props.router.push(
        {
          pathname: to,
          query: { referrer: routes.CodeConfirmationEmail.as },
        },
        as,
      );
      return;
    }
    // if not, go to expectations page
    redirectToWhereLeftOrDashboard(member);
    return;
  }

  async function onSubmit({ code: confirmation_code }) {
    TRACK_EVENT.BUTTON_CLICKED(
      routes.CodeConfirmationEmail.as,
      "Confirm email code",
    );

    if (props.familySignupModel.is_family_signup) {
      await verifyFamilySignupCode(confirmation_code);
      return;
    }
    try {
      await verifyConfirmationCode({ confirmation_code, email });
      track("Auth -- Confirm Email", {
        deprecated: true,
        replaced_with: makeEventString(EVENT_TYPE.FORM_SUBMITTED, {
          page: routes.CodeConfirmationEmail.as,
          type: "Code Confirmation",
        }),
      });
      TRACK_EVENT.FORM_SUBMITTED(
        routes.CodeConfirmationEmail.as,
        "Code Confirmation",
      );
      addNotification(`${t("notification.codeValidated")}`, "success");
      if (isUserLoggedIn()) {
        sendToNextRoute();
      } else {
        updateInitialTarget();
        // if the password wasn't available (in case of page refresh),
        if (!(await retryLogin(email))) {
          // redirects to SignIn
          redirectToWhereLeftOrDashboard(member);
        }
      }
    } catch (_error) {
      setError("code", {
        type: "manual",
        message: `${t("notification.codeInvalidated")}`,
      });
    }
  }

  const UPDATE_CONFIRMATION_EMAIL = "Update Confirmation Email";

  return (
    <>
      <Box
        m="0 auto"
        pb={[100, 100, 75, 75, 75]}
        w={[
          "calc(100% - 48px)",
          "calc(100% - 48px)",
          "calc(100% - 48px)",
          "80%",
        ]}
      >
        <Heading
          variant="md_v1"
          mb={8}
          lineHeight="37px"
          data-cy="code-confirmation-heading"
        >
          {t("codeConfirmation.subheading")}
        </Heading>
        <Text
          role="alert"
          aria-live="polite"
          variant="body_v1"
          lineHeight="24px"
          mb="24px"
        >
          {t("codeConfirmation.sendConfirmationCode")}
          {` ${email}. `}
          {userIsLoggedIn && (
            <Link
              inlineTextLinkDark
              alias="UpdateEmail"
              mpTracking={{
                page: routes.CodeConfirmationEmail.as,
                to: routes.UpdateEmail.as,
                type: UPDATE_CONFIRMATION_EMAIL,
              }}
              ariaLabel={t("updateEmail.submitText")}
              bold
            >
              {t("codeConfirmation.changeEmail")}
            </Link>
          )}
        </Text>
        <form id="confirm-code" onSubmit={handleSubmit(onSubmit)}>
          <SimpleGrid columns={1} spacing="10px">
            <SHInputWithLoadingState
              required
              name="code"
              label={t("codeConfirmation.confirmationMessage")}
              register={register}
              inputType="text"
              validation={codeLengthValidation}
              errors={errors?.code}
              dataCy="code"
              autoComplete="off"
              ariaLabel={t("codeConfirmation.confirmationMessage")}
              inputMode="numeric"
            />
            <Text variant="body_v1" mb="20px">
              {t("codeConfirmation.haveNotReceivedIt")}
              <Button
                w="fit-content"
                variant="link"
                onClick={resendCode}
                tabIndex="0"
                data-cy="resend-email"
                _focusVisible={{ boxShadow: "0 0 0 3px black" }}
              >
                {t("codeConfirmation.resendCode")}
              </Button>
            </Text>
          </SimpleGrid>
        </form>
      </Box>
      <SHStickyFooter
        width={["100vw", "100vw", "50vw", "50vw", "50vw"]}
        hasShadow
      >
        <Flex
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
          height={150}
          mb="8"
        >
          <Button
            w={[
              "calc(100% - 48px)",
              "calc(100% - 48px)",
              "calc(100% - 48px)",
              "80%",
            ]}
            colorScheme="primary"
            size="lg"
            variant="solid"
            type="submit"
            form="confirm-code"
            data-cy="confirm-email-button"
            _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          >
            {t("codeConfirmation.submitText")}
          </Button>
          <Button
            w={[
              "calc(100% - 48px)",
              "calc(100% - 48px)",
              "calc(100% - 48px)",
              "80%",
            ]}
            bgColor="tertiary.50"
            color="platform"
            _hover={{
              bgColor: "tertiary.100",
              color: "platform",
            }}
            _focus={{
              bgColor: "tertiary.100",
              color: "platform",
            }}
            _focusVisible={{ boxShadow: "0 0 0 3px black" }}
            size="lg"
            variant="solid"
            onClick={remindMeHandler}
            data-cy="remind-me-later-button"
          >
            <b>{t("codeConfirmation.remindMessage")}</b>
          </Button>
        </Flex>
      </SHStickyFooter>
    </>
  );
};

const mapStateToProps = (state) => ({
  findMyMembershipModel: state.signUp.findMyMembershipModel,
  familySignupModel: state.signUp.familySignupModel,
});

export default compose(
  withRouter,
  connect(
    (state) => (
      mapStateToProps,
      {
        findMyMembershipModel: state.signUp.findMyMembershipModel,
        familySignupModel: state.signUp.familySignupModel,
      }
    ),
    {
      addNotification,
      updateFindMyMembershipModel,
      updateFamilySignupModel,
      resetFamilySignupModel,
      resetFindMyMembershipModel,
      resetVerificationModel,
    },
  ),
  graphql(getUserInfo, {
    options: Meowth.apolloOptionsUserId,
    skip: Meowth.apolloSkipUserId,
  }),
)(CodeConfirmationEmailForm);

CodeConfirmationEmailForm.propTypes = {
  addNotification: PropTypes.func,
  data: PropTypes.shape({
    user: PropTypes.shape({
      member: PropTypes.shape({
        cohort: PropTypes.shape({
          child_dependents_allowed: PropTypes.any,
          dependents_allowed: PropTypes.any,
        }),
      }),
    }),
  }),
  familySignupModel: PropTypes.object,
  findMyMembershipModel: PropTypes.object,
  getUserInfo: PropTypes.func,
  onSubmit: PropTypes.func,
  resetFamilySignupModel: PropTypes.func,
  resetFindMyMembershipModel: PropTypes.func,
  resetVerificationModel: PropTypes.func,
  router: PropTypes.object,
  updateFamilySignupModel: PropTypes.func,
  updateFindMyMembershipModel: PropTypes.func,
  isMobile: PropTypes.bool,
};
