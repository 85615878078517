// used for defining the type of media
export enum MediaType {
  Microphone = "microphone",
  Camera = "camera",
}

export enum ParticipantType {
  Member = "member",
  Provider = "provider",
}

// used for defining the type of LiveKit's VideoTrack
export interface TrackType {
  participant: {
    isLocal: boolean;
    sid: string;
  };
}

export enum DeviceTypes {
  AudioOutput = "audiooutput",
  AudioInput = "audioinput",
  VideoInput = "videoinput",
}

export enum ToggleButtonTypes {
  Camera = "camera",
  Chat = "chat",
  Microphone = "microphone",
  Participants = "participants",
  Screensharing = "screensharing",
}

export enum DrawerTabTypes {
  Participants = "participants",
  Chat = "chat",
}
