import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import {
  InputWrapper,
  LoadingCircle,
  Tabs,
  Tab,
  ZoomIcon,
  LocationPin,
  Button,
  FlexRow,
} from "@spring/smeargle";
import {
  AppointmentMedium,
  modalIds,
  getAppointmentKindForTracking,
  getAppointmentMediumForTracking,
  getModalNameForTracking,
  AppointmentKind,
  RequestableAppointmentKind,
} from "@spring/constants";

import {
  setField,
  addNotification,
  openModal,
  closeModal,
} from "@spring/smeargle/actions";
import AppointmentTab from "./AppointmentTab";

import { formFieldSelector } from "selectors/form";
import { getAppointmentSlots } from "operations/queries/appointment";
import { requestAppointment } from "operations/mutations/appointment";
import { getFirstError } from "utils/apollo/errorHandler";
import { useTranslation } from "react-i18next";
import { TRACK_EVENT } from "utils/mixpanel";
import { trackProviderProfileNoAvailability } from "components/templates/Browse/ProviderBrowsePage/analytics";
import routes from "routes";
import { Box } from "design-system/components";
import ContactForAvailability from "components/form/NewAppointmentSlots/ContactForAvailability";

const AppointmentTabs = (props) => {
  const {
    supportsVirtual,
    supportsInPerson,
    isALegacyMinor,
    bookings,
    start,
    increment,
    kind,
    dayIncrement,
    value,
    dirty,
    valid,
    medium,
    providerId,
    theme,
    queryRequestId,
    setShowComingSoonBanner,
    defaultTab,
  } = props;
  const { t } = useTranslation("careProvider");

  const isProviderPage =
    window.location.pathname ===
    routes["ScheduleAppointmentProviderDetail"].as.replace(":id", providerId);

  const virtualLabel = t("scheduleModal.virtual");
  const inPersonLabel = t("scheduleModal.inPerson");

  const [adjustment, setAdjustment] = useState(0);
  const [tabCount, setTabCount] = useState(0);
  const [isVirtualTracked, setIsVirtualTracked] = useState(false);
  const [isInPersonTracked, setIsInPersonTracked] = useState(false);
  const [lastTabOpen, setLastTabOpen] = useState();

  const [requestAppointmentMutation] = useMutation(requestAppointment, {
    onCompleted: () => {
      props.closeModal(modalIds.careProviderScheduleModal);

      return props.openModal(modalIds.availabilityRequestModal, {
        ...props.provider,
      });
    },
    onError: (err) => props.addNotification(getFirstError(err), "error"),
  });

  useEffect(() => {
    if (supportsVirtual) setTabCount((tabCount) => tabCount + 1);
    if (supportsInPerson && !isALegacyMinor)
      setTabCount((tabCount) => tabCount + 1);
  }, []);

  let appointmentKind = kind;
  if (
    [
      "FOLLOW_UP_MEDICATION_MANAGEMENT",
      "INITIAL_MEDICATION_MANAGEMENT",
    ].includes(kind)
  ) {
    appointmentKind = RequestableAppointmentKind.MedicationManagement;
  }
  if (["INITIAL_PEER_RECOVERY", "FOLLOW_UP_PEER_RECOVERY"].includes(kind)) {
    appointmentKind = RequestableAppointmentKind.PeerRecoverySpecialist;
  }

  const requestAvailabilityButton = () => (
    <Button
      text={props.t("scheduleModal.requestInPersonAvailability.buttonText")}
      full
      flat
      transparent
      dataCy="request-in-availability"
      onClick={() => {
        requestAppointmentMutation({
          variables: {
            input: {
              availability_days_of_week: ["Unknown"],
              availability_time_of_day: ["Unknown"],
              kind: appointmentKind,
              medium: medium,
              care_provider_id: providerId,
            },
          },
        });
      }}
    />
  );

  const useAppointmentSlotsByMedium = (medium) => {
    const {
      data: appointments,
      loading: appointmentsLoading,
      refetch: appointmentsRefetch,
    } = useQuery(getAppointmentSlots, {
      variables: {
        user_ids: bookings,
        start_span: start,
        slot_increment: increment,
        kind: kind,
        medium: medium,
        end_span: DateTime.fromISO(start) // Set the end span based off how many days the dev wants to show
          .plus({ days: dayIncrement })
          .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 })
          .toISO(),
      },
      fetchPolicy: "network-only",
    });

    return {
      appointments,
      appointmentsLoading,
      appointmentsRefetch,
    };
  };

  const {
    appointments: virtualAppointments,
    appointmentsLoading: virtualLoading,
    appointmentsRefetch: virtualRefetch,
  } = useAppointmentSlotsByMedium(AppointmentMedium.Video);

  let inPersonLoading = false;
  let inPersonAppointments = [];
  let inPersonRefetch;
  if (props.provider.in_person_supported) {
    ({
      appointments: inPersonAppointments,
      appointmentsLoading: inPersonLoading,
      appointmentsRefetch: inPersonRefetch,
    } = useAppointmentSlotsByMedium(AppointmentMedium.InPerson));
  }

  /*
   * This is just a temporary solution for the Target SDOH MVP launch
   * Members get access 4/1 but TMCAs are not onboarded until 4/7
   * So we need to show a "Coming Soon" banner while the TMCA has no availability
   * The slots are loaded here, but the banner lives in the parent component
   * So we're passing a temporary set-state function to show/hide the banner
   */
  useEffect(() => {
    const hasAvailableSlots =
      inPersonAppointments?.appointment_slots?.available?.length > 0 ||
      virtualAppointments?.appointment_slots?.available?.length > 0;
    const isLoading = inPersonLoading || virtualLoading;
    const isBeforeApril7th = DateTime.now() < DateTime.fromISO("2024-04-07");

    // Wait until data is loaded, then show the banner if there are no slots
    setShowComingSoonBanner(
      !isLoading && !hasAvailableSlots && isBeforeApril7th,
    );

    if (
      !isLoading &&
      !hasAvailableSlots &&
      window.location.pathname.includes("/members/providers/")
    ) {
      trackProviderProfileNoAvailability(
        providerId,
        appointmentKind,
        queryRequestId,
      );
    }
  }, [
    inPersonAppointments,
    inPersonLoading,
    virtualAppointments,
    virtualLoading,
  ]);

  const refetchAppointments = (labelCheck) => {
    const refetchStart = DateTime.fromISO(start)
      .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 1 })
      .plus({ days: adjustment });
    const end = DateTime.fromISO(start)
      .plus({ days: adjustment + dayIncrement - 1 })
      .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 });
    if (!virtualLoading && labelCheck === virtualLabel) {
      virtualRefetch({
        start_span: refetchStart.toISO(),
        end_span: end.toISO(),
      });
    }
    if (!inPersonLoading && labelCheck === inPersonLabel) {
      inPersonRefetch({
        start_span: refetchStart.toISO(),
        end_span: end.toISO(),
      });
    }
  };

  useEffect(() => {
    refetchAppointments(lastTabOpen);
  }, [adjustment]);

  const triggerRefetch = (label) => {
    if (label === lastTabOpen || !lastTabOpen) {
      setLastTabOpen(label);
      return;
    }
    setLastTabOpen(label);
    refetchAppointments(label);
  };

  const showValidation = () => {
    return !valid && (dirty || value);
  };

  const getVirtualTab = (
    virtualAppointments,
    virtualAppointmentsLoading,
    tabIndex,
  ) => {
    return (
      <AppointmentTab
        tabIndex={tabIndex}
        available={virtualAppointments.appointment_slots.available}
        appointments={virtualAppointments}
        appointmentsLoading={virtualAppointmentsLoading}
        appointmentsRefetch={() => triggerRefetch(virtualLabel)}
        adjustment={adjustment}
        setAdjustment={setAdjustment}
        isTracked={isVirtualTracked}
        setIsTracked={setIsVirtualTracked}
        {...props}
        medium={AppointmentMedium.Video}
        queryRequestId={queryRequestId}
      />
    );
  };

  const getInPersonTab = (
    inPersonAppointments,
    inPersonAppointmentsLoading,
    tabIndex,
  ) => {
    return (
      <AppointmentTab
        tabIndex={tabIndex}
        available={inPersonAppointments?.appointment_slots?.available}
        appointments={inPersonAppointments}
        appointmentsLoading={inPersonAppointmentsLoading}
        appointmentsRefetch={() => triggerRefetch(inPersonLabel)}
        adjustment={adjustment}
        setAdjustment={setAdjustment}
        isTracked={isInPersonTracked}
        setIsTracked={setIsInPersonTracked}
        {...props}
        medium={AppointmentMedium.InPerson}
        queryRequestId={queryRequestId}
      />
    );
  };

  const getActiveTab = (virtualAppointments, inPersonAppointments) => {
    if (tabCount === 1) return 0;

    if (defaultTab) return defaultTab;

    if (medium === AppointmentMedium.Video) return 0;
    if (medium === AppointmentMedium.InPerson) return 1;

    if (value.selectedMedium === AppointmentMedium.Video) return 0;
    if (value.selectedMedium === AppointmentMedium.InPerson) return 1;

    const virtualFirstSlot =
      virtualAppointments && virtualAppointments.appointment_slots.available[0];
    const inPersonFirstSlot =
      inPersonAppointments?.appointment_slots?.available[0];

    if (virtualFirstSlot < inPersonFirstSlot) return 0;
    if (virtualFirstSlot > inPersonFirstSlot) return 1;
    if (virtualFirstSlot === inPersonFirstSlot) return 0;
  };

  const getTabs = (
    virtualAppointments,
    virtualAppointmentsLoading,
    inPersonAppointments,
    inPersonAppointmentsLoading,
  ) => {
    const tabs = [];
    let tabIndex = 0;

    if (virtualAppointments && supportsVirtual) {
      tabs.push({
        label: virtualLabel,
        child: getVirtualTab(
          virtualAppointments,
          virtualAppointmentsLoading,
          tabIndex,
        ),
      });

      tabIndex += 1;
    }

    {
      /* We only show the in-person section when the following conditions are met:
      - the member is *not* a legacy minor; managed minors *can* request in-person appointments
      - the member is *not* viewing the global experience
      - the `supportsInPerson` boolean, which is passed from `Listing`, is `true`
      - the provider's office address is 35 miles or less away from the member's address
    */
    }
    if (inPersonAppointments && !isALegacyMinor && supportsInPerson) {
      tabs.push({
        label: inPersonLabel,
        child: getInPersonTab(
          inPersonAppointments,
          inPersonAppointmentsLoading,
          tabIndex,
        ),
      });
    }

    const appointmentMediumType = (label) => {
      if (label === virtualLabel) {
        return getAppointmentMediumForTracking(AppointmentMedium.Video);
      } else if (label === inPersonLabel) {
        return getAppointmentMediumForTracking(AppointmentMedium.InPerson);
      }
      return undefined;
    };

    return tabs.map((tab) => {
      const tracking = () => {
        TRACK_EVENT.BUTTON_CLICKED(
          routes.TherapistsBrowse.as,
          "Appointment Medium Tab",
          {
            spring_doc_id: "directsched04",
            location: getModalNameForTracking(
              modalIds.careProviderScheduleModal,
            ),
            provider_id: props.provider.id,
            appointment_type: getAppointmentKindForTracking(
              AppointmentKind.Therapy,
            ),
            appointment_medium: appointmentMediumType(tab.label),
          },
        );
      };
      return (
        <Tab
          key={`tab_${tab.label}_${providerId}`}
          label={tab.label}
          tracking={tracking}
          transparent
          width="100"
          icon={tab.label === virtualLabel ? <ZoomIcon /> : <LocationPin />}
        >
          {tab.child}
        </Tab>
      );
    });
  };

  if (virtualLoading || inPersonLoading) {
    return (
      <FlexRow>
        <LoadingCircle />
      </FlexRow>
    );
  }

  const tabs = getTabs(
    virtualAppointments,
    virtualLoading,
    inPersonAppointments,
    inPersonLoading,
  );

  return (
    <InputWrapper valid={showValidation()} theme={theme} for="AppointmentSlots">
      {tabs.length !== 0 ? (
        <Tabs
          key={`tab_${providerId}`}
          activeTab={getActiveTab(virtualAppointments, inPersonAppointments)}
          justification="flex-start"
          fixedTabWidth
        >
          {tabs}
        </Tabs>
      ) : isProviderPage ? (
        <Box w={{ base: "100%", md: "60%" }}>
          <ContactForAvailability inPersonMedium={false} {...props} />
        </Box>
      ) : (
        requestAvailabilityButton()
      )}
    </InputWrapper>
  );
};

AppointmentTabs.propTypes = {
  addNotification: PropTypes.func,
  bookings: PropTypes.arrayOf(PropTypes.string),
  careTeamPage: PropTypes.bool,
  clientValidation: PropTypes.shape({
    message: PropTypes.string,
    valid: PropTypes.bool,
  }),
  close: PropTypes.func,
  closeModal: PropTypes.func,
  consent: PropTypes.any,
  dayIncrement: PropTypes.number,
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  end: PropTypes.string,
  fieldKey: PropTypes.string,
  formKey: PropTypes.string,
  increment: PropTypes.number,
  isACareNavigator: PropTypes.bool,
  isALegacyMinor: PropTypes.bool,
  kind: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  limit: PropTypes.number, // limit results to showing <number> slots per day
  medium: PropTypes.string,
  moreAction: PropTypes.func,
  openModal: PropTypes.func,
  placeholder: PropTypes.string,
  provider: PropTypes.object,
  providerId: PropTypes.string,
  queryRequestId: PropTypes.string,
  requestAvailability: PropTypes.func,
  setField: PropTypes.func,
  setShowComingSoonBanner: PropTypes.func,
  showMore: PropTypes.bool, // if limit is reached, show more slot action
  start: PropTypes.string,
  stripe: PropTypes.bool,
  supportsInPerson: PropTypes.bool,
  supportsVirtual: PropTypes.bool,
  t: PropTypes.func,
  terminateAt: PropTypes.string,
  theme: PropTypes.oneOf(["material", "simple"]),
  type: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export { AppointmentTabs };
export default compose(
  connect((state, ownProps) => formFieldSelector(state, ownProps), {
    setField,
    addNotification,
    openModal,
    closeModal,
  }),
)(AppointmentTabs);
