import React, { FC } from "react";
import { RenderChildAvatar } from "./utils/RenderChildAvatar";
import routes from "routes/index";
import { useTranslation } from "react-i18next";
import { ChooseUserCard } from "./ChooseUserCard";
import { formatMemberExactAge } from "utils/displayHelpers";
import { VAvatarDuotoneSpot, Flex } from "@springcare/sh-component-library";
import { trackBadgeViewedFactory } from "./utils/trackBadgeViewedFactory.util";
import { getHomeRoute } from "shared/utils/getHomeRoute";
import { useCanChangeUser } from "lib/rememberUser";
import { WITHIN_AGING_OUT_VALUES } from "modules/shared/components/Notifications/constants/AccountTransitions.constants";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

interface ChooseUserDisplayProps {
  currentUser: {
    id: string;
    first_name: string;
    date_of_birth: string;
    member: {
      id: string;
      choose_user_cta_tag: string;
      minor: boolean;
      within_aging_out?: string;
      manager: {
        id: string;
        first_name: string;
        last_name: string;
        email: string;
      } | null;
      managed_dependents?: Array<{
        id: string;
        first_name: string;
        date_of_birth: string;
        choose_user_cta_tag: string;
        within_aging_out?: string;
      }> | null;
      cohort: {
        id: string;
        dependents_allowed?: boolean;
      };
    };
  };
}

export const ChooseUserDisplay: FC<ChooseUserDisplayProps> = ({
  currentUser,
}) => {
  const { t } = useTranslation("chooseUser");
  const agingOutFlagEnabled = useFeatureFlag(FLAGS.ENABLE_AGING_OUT_P1);
  const canChangeUser = useCanChangeUser();
  const parentName = currentUser?.first_name;
  const parentMemberId = currentUser?.member?.id;
  const dependents = currentUser?.member?.managed_dependents || [];
  const dependentsAllowed = currentUser?.member?.cohort?.dependents_allowed;
  const avatarWidth = 58;
  const avatarHeight = 59;

  const ChildAvatar = (dependent) => () =>
    RenderChildAvatar({ dependent, avatarWidth, avatarHeight });

  const isInDependentAccount = currentUser?.member?.minor;

  const supportedChooseUserCtaTags = {
    // This mapping of supported tags to translated strings guards against the BE returning a tag type that is not yet implemented on the FE.
    finish_assessment: t("chooseUserCtaTag.finish_assessment"),
    schedule_initial_appointment: t(
      "chooseUserCtaTag.schedule_initial_appointment",
    ),
    schedule_follow_up_appointment: t(
      "chooseUserCtaTag.schedule_follow_up_appointment",
    ),
    upcoming_changes: t("chooseUserCtaTag.upcoming_changes"),
  };
  const parentBadgeKey = currentUser?.member?.choose_user_cta_tag;
  const parentBadgeText = supportedChooseUserCtaTags[parentBadgeKey];
  const trackParentBadgeViewed = trackBadgeViewedFactory(
    parentBadgeKey,
    parentMemberId,
    null,
  );

  const getDependentBadgeText = (dependent) => {
    const dependentIsTurningEighteen =
      dependent.within_aging_out ===
      WITHIN_AGING_OUT_VALUES.seventeen_to_eighteen;
    if (dependentIsTurningEighteen && agingOutFlagEnabled) {
      return WITHIN_AGING_OUT_VALUES.seventeen_to_eighteen;
    }
    return supportedChooseUserCtaTags[dependent.choose_user_cta_tag];
  };

  if (isInDependentAccount) {
    const manager = currentUser?.member.manager;
    const dependent = currentUser;
    const age = formatMemberExactAge(dependent.date_of_birth);
    const dependentBadgeText = getDependentBadgeText(dependent.member);
    const trackDependentBadgeViewed = trackBadgeViewedFactory(
      dependent.member.choose_user_cta_tag,
      parentMemberId,
      dependent.id,
    );
    const memberHomeRoute = getHomeRoute(dependent.date_of_birth);

    return (
      <Flex
        gap="v-24"
        flexWrap="wrap"
        flexDirection={["row"]}
        justifyContent={["flex-start", "center"]}
        ml={[20, 0]}
      >
        <ChooseUserCard
          key={dependent.id}
          heading={dependent.first_name}
          subheading={`${t("type.child")} - ${age} ${t("years")}`}
          to={memberHomeRoute.as}
          badge={dependentBadgeText}
          dependent={dependent}
          Icon={ChildAvatar(dependent)}
          dataCy={`${dependent.first_name}-child-member-cta`}
          handleBadgeViewedTracking={trackDependentBadgeViewed}
        />
        {/* When canChangeUser is false, member should not be able to access this choose user page at all.
         * As an additional safeguard to protect the manager's PHI, if the member does somehow access this page,
         * they will not see the manager's card.
         */}
        {canChangeUser && (
          <ChooseUserCard
            heading={manager?.first_name}
            subheading={t("type.guardian")}
            to={routes["ChooseUserPassword"].as.replace(":id", manager?.id)}
            badge={parentBadgeText}
            Icon={ParentAvatar}
            dataCy="parent-member-cta"
            handleBadgeViewedTracking={trackParentBadgeViewed}
          />
        )}
      </Flex>
    );
  }

  return (
    <Flex
      gap="v-24"
      flexWrap="wrap"
      flexDirection="row"
      justifyContent={["flex-start", "center"]}
      ml={[20, 0]}
    >
      <ChooseUserCard
        heading={parentName}
        subheading={t("type.guardian")}
        to={routes.MemberHome.as}
        badge={parentBadgeText}
        Icon={ParentAvatar}
        dataCy="parent-member-cta"
        handleBadgeViewedTracking={trackParentBadgeViewed}
      />
      {dependents.map((dep) => {
        const age = formatMemberExactAge(dep.date_of_birth);
        const dependentBadgeText = getDependentBadgeText(dep);
        const trackDependentBadgeViewed = trackBadgeViewedFactory(
          dep.choose_user_cta_tag,
          parentMemberId,
          dep.id,
        );

        return (
          <ChooseUserCard
            key={dep.id}
            heading={dep.first_name}
            subheading={`${t("type.child")} - ${age} ${t("years")}`}
            to={routes["ChooseUserPassword"].as.replace(":id", dep.id)}
            badge={dependentBadgeText}
            dependent={dep}
            Icon={ChildAvatar(dep)}
            dataCy={`${dep.first_name}-child-member-cta`}
            handleBadgeViewedTracking={trackDependentBadgeViewed}
          />
        );
      })}
      {dependentsAllowed && (
        <ChooseUserCard
          heading={t("addDependent")}
          to={routes.InviteDependents.as}
          dataCy="add-dependent-button"
        />
      )}
    </Flex>
  );
};

const ParentAvatar = () => (
  <VAvatarDuotoneSpot
    data-testid="guardian-avatar"
    aria-hidden={true}
    width={58}
    height={59}
  />
);
