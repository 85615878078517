import {
  Box,
  Flex,
  Heading,
  Text,
  Avatar,
  Divider,
} from "@springcare/sh-component-library";

import { useSessionViewStatus } from "context/SessionRoomContext";

import { formatPreSessionDateString } from "components/templates/SHSessionRoom/utils/utils";

const SessionDetailsBody = ({ sessionData, children }) => {
  const { sessionViewStatus, SessionViewStatus } = useSessionViewStatus();
  const { participant_type, provider_name, member_name, start_at } =
    sessionData;
  const { sessionDateString, sessionTimeString } =
    formatPreSessionDateString(start_at);

  const showParticipantDetails =
    sessionViewStatus !== SessionViewStatus.ProviderNoShow;

  const showExtraSubheader =
    sessionViewStatus === SessionViewStatus.TooEarly ||
    sessionViewStatus === SessionViewStatus.TooLate;

  const showAppointmentTime =
    sessionViewStatus === SessionViewStatus.TooEarly ||
    sessionViewStatus === SessionViewStatus.NotStarted ||
    sessionViewStatus === SessionViewStatus.MemberIsWaiting ||
    sessionViewStatus === SessionViewStatus.Connecting;

  const participantNameCopy =
    participant_type === "provider" ? member_name : provider_name;

  return (
    <>
      <Flex
        py={showParticipantDetails && "v-16"}
        w="100%"
        justify="flex-start"
        align="center"
      >
        {showParticipantDetails && (
          <Box>
            <Avatar size="2xl" name={participantNameCopy} />
          </Box>
        )}

        <Box>
          {showExtraSubheader && (
            <Text size="body-small-strong" color="accent-base" ml="v-8">
              {sessionViewStatus === SessionViewStatus.TooEarly &&
                "Upcoming Appointment"}
              {sessionViewStatus === SessionViewStatus.TooLate &&
                "Past Appointment"}
            </Text>
          )}
          {showParticipantDetails && (
            <Heading size="heading-small" ml="v-8">
              {participantNameCopy}
            </Heading>
          )}
        </Box>
      </Flex>

      {showParticipantDetails && <Divider w="100%" mb="v-16" />}

      <Box pb="v-8">
        {showAppointmentTime && (
          <Flex justify="space-between">
            <Text size="body-medium-strong" color="content-primary">
              {sessionDateString}
            </Text>
            <Text size="body-medium-strong" color="content-primary">
              {sessionTimeString}
            </Text>
          </Flex>
        )}
      </Box>
      {/* children is an optional button */}
      {children}
    </>
  );
};

export default SessionDetailsBody;
