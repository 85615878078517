import { Box, Flex, Stack } from "@springcare/sh-component-library";

const SessionDetailsContainer = ({ isMobile, children }) => (
  <Flex
    w="100vw"
    h="100vh"
    justify="center"
    align={!isMobile ? "center" : "flex-start"}
    as="main"
  >
    <Stack direction={["column", "column", "row", "row"]}>{children}</Stack>
  </Flex>
);

export default SessionDetailsContainer;
