/**
 * Enums for provider roles.
 */

const ProviderRole = Object.freeze({
	CareNavigator       			: 'care_navigator',
	Therapist          				: 'therapist',
	MedicationManager   			: 'medication_manager',
	Coach              				: 'coach',
	CareAdvocate       				: 'care_advocate',
	CareConsultant     				: 'care_consultant',
	AssociateProvider  				: 'associate_provider',
	PeerRecoverySpecialist	: 'peer_recovery_specialist',
});

/**
 * Enums for Medication Manager specific roles.
 */

const MedicationManagerRole = Object.freeze({
	Psychiatrist                 : 'Psychiatrist', 
	Internist                    : 'Internist',
	FamilyPractitioner           : 'Family Practitioner', 
	PsychiatricNursePractitioner : 'Psychiatric Nurse Practitioner',
	Physician                    : 'Physician', 
});

const isMedicationManagerRole = (roles) => {
	for (const role of roles){
		if (Object.values(MedicationManagerRole).includes(role)) return true;
		return false;
	}
};

/**
 * 
 * @param {string} providerRole enums/ProviderRole, eg ProviderRole.CareNavigator
 * @returns {boolean}
 */
const isStateLicensedProvider = (providerRole) => [
	ProviderRole.Therapist,
	ProviderRole.MedicationManager,
].includes(providerRole);

/**
 * This is a layer of abstraction to ensure that the name of these provider roles
 * that is sent to immutable tracking systems is never changed.
 * The values below should not be modified without lots of communication and consideration.
 * @param {string}  
 * @returns {string}
 */
const getProviderRoleForTracking = (providerRoleEnum) => ({
	[ProviderRole.CareNavigator]          : 'Care Navigator',
	[ProviderRole.Therapist]              : 'Therapist',
	[ProviderRole.MedicationManager]      : 'Medication Manager',
	[ProviderRole.Coach]                  : 'Coach',
	[ProviderRole.CareAdvocate]           : 'Care Advocate',
	[ProviderRole.CareConsultant]         : 'Care Consultant',
	[ProviderRole.PeerRecoverySpecialist] : 'Peer Recovery Specialist',
})[providerRoleEnum];

module.exports = {
	ProviderRole,
	MedicationManagerRole,
	isMedicationManagerRole,
	isStateLicensedProvider,
	getProviderRoleForTracking, 
};
