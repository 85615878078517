import { useEffect, useCallback } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Spinner,
  VStack,
} from "design-system/components";
import {
  BookSession,
  CareNavigator,
  WateringPlant,
  WelcomeHand,
} from "design-system/assets";
import { Link } from "@springcare/sh-component-library";
import { TermsAndPolicyFooter } from "components";
import { BoeingCustomerID } from "constants/customers";
import { BoeingSDOHZendeskForm } from "constants/links";
import PropTypes from "prop-types";
import routes from "routes";
import { useTranslation } from "hooks/react-i18next";
import { Trans } from "react-i18next";
import { useRegisterContext } from "context/RegisterContext";
import { register, TRACK_EVENT } from "utils/mixpanel";

const PROMO_CARD_CONFIGS = {
  [BoeingCustomerID]: [
    { transKey: "Two" },
    { transKey: "Three" },
    { transKey: "FourBoeing", subtitleLink: BoeingSDOHZendeskForm },
  ],
  default: [{ transKey: "Two" }, { transKey: "Three" }, { transKey: "Four" }],
};

const PromoDesktop = ({ isWarmSignup = false, isWarmLoading = false }) => {
  const { t } = useTranslation("limitedLangRegister");
  const coveredLife = useRegisterContext().coveredLife;
  const coveredLifeName = coveredLife?.first_name
    ? coveredLife?.first_name
    : null;
  const customerName = coveredLife?.customer_name || null;
  const customerId = coveredLife?.customer_id || null;
  const remainingCovered = coveredLife?.total_visits_covered || null;
  const headingStr = t("promo.itemOne.titleWarm", { coveredLifeName });
  const titleStr = t("promo.itemTwo.titleWarm", { remainingCovered });

  const headingContentLoaded = () => {
    if (coveredLifeName && !headingStr.includes(coveredLifeName)) {
      return false;
    }

    if (remainingCovered && !titleStr.includes(remainingCovered)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (customerName) {
      register({ customer_name: customerName });
    }
  }, [customerName]);

  const PROMO_CARDS =
    PROMO_CARD_CONFIGS[customerId] || PROMO_CARD_CONFIGS["default"];

  const handleClick = useCallback((href) => {
    TRACK_EVENT.LINK_CLICKED(routes.Register.as, href, "Promo Desktop", {});
  }, []);

  const Icon = ({ index }) => {
    if (index === 0) {
      return <WateringPlant width="40px" height="40px" />;
    }
    if (index === 1) {
      return <BookSession width="40px" height="40px" />;
    }
    if (index === 2) {
      return <CareNavigator width="40px" height="40px" />;
    }
    return null;
  };

  const CopySection = ({ card, index }) => (
    <Flex pb={24} alignItems="flex-start" w="80%">
      <Icon index={index} />
      <Box ps={20}>
        {remainingCovered && (
          <Heading
            variant="md"
            fontSize={22}
            pb={8}
            data-cy="cl-remaining-sessions"
          >
            {card.transKey === "Two"
              ? titleStr
              : t(`promo.item${card.transKey}.title`)}
          </Heading>
        )}
        {!remainingCovered && (
          <Heading variant="md" fontSize={22} pb={8}>
            {t(`promo.item${card.transKey}.title`)}
          </Heading>
        )}
        <Text variant="body1">
          {card.subtitleLink ? (
            <Trans
              ns="limitedLangRegister"
              t={t}
              i18nKey={`promo.item${card.transKey}.subtitle`}
              components={[
                <Link
                  key="0"
                  href={card.subtitleLink}
                  onClick={() => handleClick(card.subtitleLink)}
                  target="_blank"
                />,
              ]}
            />
          ) : (
            t(`promo.item${card.transKey}.subtitle`)
          )}
        </Text>
      </Box>
    </Flex>
  );

  if (isWarmSignup && (isWarmLoading || !headingContentLoaded())) {
    return (
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex={9999}
        bg="rgba(255, 255, 255)"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="primary.base"
          size="xl"
        />
      </Box>
    );
  }

  return (
    <Box bg="tertiary.25">
      <VStack height="calc(100vh - 95px)" pt={"5%"}>
        <Flex
          align="left"
          direction="column"
          justifyContent={"center"}
          mb={48}
          width="80%"
        >
          <Box pb={"11px"}>
            <WelcomeHand width="72px" height="72px" />
          </Box>
          {coveredLifeName && (
            <Heading
              as="h1"
              pb={8}
              variant="display"
              letterSpacing={[0, 0, 1, 1.5]}
              data-cy="welcome-to-spring-health"
            >
              {headingStr}
            </Heading>
          )}
          {!coveredLifeName && (
            <Heading
              as="h1"
              pb={8}
              variant="display"
              letterSpacing={[0, 0, 1, 1.5]}
              data-cy="welcome-to-spring-health"
            >
              {t("promo.itemOne.title")}
            </Heading>
          )}
          <Text variant="body1">{t("promo.itemOne.subtitle")}</Text>
        </Flex>
        <Flex
          align="left"
          justify="center"
          direction="column"
          pb="95px"
          width="80%"
        >
          {PROMO_CARDS.map((card, index) => (
            <CopySection card={card} index={index} key={index} />
          ))}
        </Flex>
        <TermsAndPolicyFooter noBackground={true} />
      </VStack>
    </Box>
  );
};

PromoDesktop.propTypes = {
  card: PropTypes.string,
  index: PropTypes.number,
};

export default PromoDesktop;
