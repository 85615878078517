import React from "react";
import { Box, Heading } from "@springcare/sh-component-library";

export const MemberGreeting = ({
  memberFirstName,
  memberGreeting,
  size,
  as = "h2",
}) => (
  <Box w="100%" pb="24">
    <Heading as={as as any} data-testid="up-next-section-greeting" size={size}>
      {memberGreeting} {memberFirstName}
    </Heading>
  </Box>
);
