import React from "react";
import { Text, Box, Flex } from "design-system/components";
import { useTranslation, Trans } from "react-i18next";
import { Dollar, Link, useDisclosure } from "design-system/index";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { CancellationDetailsModal } from "components/modals";

export const CancellationPolicy = ({
  defaultCopy = "",
  showDollarIcon = true,
  showViewPolicyCTA = false,
}) => {
  const { t } = useTranslation("careVisits");
  const shouldShowDeltaCopy = useFeatureFlag(
    FLAGS.DELTA_CANCELATION_POLICY_COPY,
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Flex gap={8}>
        {showDollarIcon && (shouldShowDeltaCopy || defaultCopy) && (
          <Box>
            <Dollar width={25} height={24} />
          </Box>
        )}
        <Box color={"black"}>
          {(shouldShowDeltaCopy || defaultCopy) && (
            <Text fontWeight={"bold"} display={"inline"}>
              {t("cancelAppointment.title")}{" "}
            </Text>
          )}
          {shouldShowDeltaCopy ? (
            <Text display={"inline"}>
              <Trans
                ns="careVisits"
                i18nKey="cancelAppointment.customerSpecific"
                values={{
                  customerName: "Delta",
                  noShowCharge: 215,
                }}
              />
            </Text>
          ) : (
            defaultCopy
          )}
          {showViewPolicyCTA && (
            <>
              {" "}
              <Link
                display={"inline"}
                color={"primary-base"}
                cursor={"pointer"}
                onClick={onOpen}
              >
                {t("cancelAppointment.viewPolicy")}
              </Link>
              <CancellationDetailsModal isOpen={isOpen} onClose={onClose} />
            </>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
