import { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Button,
} from "@springcare/sh-component-library";
import { links } from "@spring/constants";
import { RatingSection } from "components/templates/SHSessionRoom/components/RatingSection";
import { trackProviderFeedbackSent } from "components/templates/SHSessionRoom/telemetry/analytics";

const SessionEndedProvider = () => {
  const [rating, setRating] = useState(0);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const handleSubmit = () => {
    trackProviderFeedbackSent(rating, feedbackComment);
    setFeedbackSubmitted(true);
  };

  const redirectToCompass = () => {
    window.open(links.CompassURL, "_self");
  };

  return (
    <Flex h="100vh" w="100vw" as="section">
      <Flex flex="1" justify="center" flexDir="column" px={16}>
        <Box m="0 auto">
          {!feedbackSubmitted ? (
            <>
              <Box mb="v-24" as="section">
                <Heading size="heading-medium" mb="v-8" as="h1">
                  Session complete
                </Heading>
                <Text size="body-medium">
                  Tell us about your experience using the video chat in Spring
                  Health.
                </Text>
              </Box>

              <Box mb="v-24" as="section">
                <RatingSection rating={rating} setRating={setRating} />
              </Box>

              <Box mb="v-24" as="section">
                <Input
                  id="comment"
                  name="comment"
                  label="Additional feedback"
                  aria-label="Additional feedback"
                  optionalText="Optional"
                  placeholder="Type your feedback here"
                  onChange={(e) => setFeedbackComment(e.target.value)}
                  autoComplete="off"
                />
              </Box>
              <Box>
                <Button onClick={handleSubmit} isDisabled={rating === 0}>
                  Submit
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box mb="v-24" as="section" aria-live="polite">
                <Heading size="heading-medium" mb="v-8" as="h1">
                  Thanks for your feedback!
                </Heading>
                <Text size="body-medium" mb="v-8">
                  Your input helps us improve your experience.
                </Text>
              </Box>
              <Box>
                <Button role="link" onClick={redirectToCompass}>
                  Back to Compass
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default SessionEndedProvider;
