import { gql } from "@apollo/client";

import { careTeamMember } from "operations/fragments/careProvider";
import { postalAddress } from "operations/fragments/member";

const getCareTeam = gql`
  query getCareTeam($id: ID!, $distance_from: DistanceFromSearchInput) {
    user(id: $id, distance_from: $distance_from) {
      id
      member {
        id
        is_peer_recovery_specialist_supported
        postal_address {
          ...postalAddress
        }
        care_team {
          id
          care_navigator {
            ...careTeamMember
          }
          medication_manager {
            ...careTeamMember
          }
          therapist {
            ...careTeamMember
          }
          coach {
            ...careTeamMember
          }
          peer_recovery_specialist {
            ...careTeamMember
          }
        }
        previsit {
          id
          can_schedule
        }
      }
    }
  }
  ${careTeamMember}
  ${postalAddress}
`;

export default getCareTeam;
