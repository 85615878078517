import {
  Button,
  ModalSheet,
  ModalProps,
  useCustomToast,
  Spinner,
} from "@springcare/sh-component-library";
import { SHOutlinedCircleCheckBox } from "design-system/components";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "hooks/react-i18next";
import React, { useState } from "react";
import { useMemberInfo } from "hooks";
import { BENEFIT_PREFERENCES } from "components/templates/CareVisitsPage/components/Tabs/constants";
import getMemberBenefitPreferences from "operations/queries/connectedBenefits/getMemberBenefitPreferences";
import updateMemberBenefitPreferences from "operations/mutations/connectedBenefits/updateMemberPreferences";

type CustomModalProps = Omit<ModalProps, "children">;

const BenefitPreferencesModal = ({ onClose, isOpen }: CustomModalProps) => {
  const { t } = useTranslation("careVisits");
  const [selectedPreferences, setSelectedPreferences] = useState(null);

  const { data: memberData } = useMemberInfo();
  const { loading: preferencesLoading } = useQuery(
    getMemberBenefitPreferences,
    {
      variables: {
        member_id: memberData?.user?.member.id,
      },
      onCompleted: (data) => {
        setSelectedPreferences(data?.member_preferences?.names || []);
      },
      onError: () => {
        setSelectedPreferences([]);
      },
    },
  );

  const handleSelect = (preference) => {
    if (!selectedPreferences.includes(preference.enum)) {
      setSelectedPreferences((prevState) => [...prevState, preference.enum]);
    } else {
      setSelectedPreferences((prevState) =>
        prevState.filter((pref) => pref !== preference.enum),
      );
    }
  };

  const successToast = useCustomToast({
    type: "success",
    message: t("resourcesTab.benefits.preferencesModal.successMessage"),
    layout: "fit-content",
    duration: 5000,
  });

  const errorToast = useCustomToast({
    type: "error",
    message: t("resourcesTab.benefits.preferencesModal.errorMessage"),
    layout: "fit-content",
    duration: 5000,
  });

  const [updatePreferencesMutation, { loading: updateLoading }] = useMutation(
    updateMemberBenefitPreferences,
    {
      awaitRefetchQueries: true,
      refetchQueries: ["getMemberBenefitPreferences"],
      onCompleted: () => {
        successToast();
        onClose();
      },
      onError: () => {
        errorToast();
        onClose();
      },
    },
  );

  const handleSave = () => {
    updatePreferencesMutation({
      variables: {
        member_id: memberData?.user?.member.id,
        preferences: selectedPreferences,
      },
    });
  };

  return (
    <ModalSheet
      title={t("resourcesTab.benefits.preferencesModal.title")}
      caption={t("resourcesTab.benefits.preferencesModal.caption")}
      size={"md"}
      isOpen={isOpen}
      onClose={onClose}
      footerChildren={
        <Button
          onClick={handleSave}
          isLoading={updateLoading}
          data-testid="saveButton"
        >
          {t("resourcesTab.benefits.preferencesModal.buttonText")}
        </Button>
      }
    >
      <>
        {preferencesLoading || !selectedPreferences ? (
          <Spinner />
        ) : (
          BENEFIT_PREFERENCES.map((preference) => (
            <SHOutlinedCircleCheckBox
              checked={selectedPreferences.includes(preference.enum)}
              label={preference.label}
              key={preference.enum}
              fieldKey={preference.enum}
              onChange={() => handleSelect(preference)}
            />
          ))
        )}
      </>
    </ModalSheet>
  );
};

export default BenefitPreferencesModal;
