import {
  Box,
  Flex,
  Text,
  VPrivateSpringIcon,
} from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";

const ConfidentialityBanner = () => {
  const { t } = useTranslation("goals");

  return (
    <Box color="content-secondary">
      <Flex alignItems="center" mb={50}>
        <VPrivateSpringIcon />
        <Text fontSize="12px" pl="8px">
          {t("memberGoalForm.confidentiality")}
        </Text>
      </Flex>
    </Box>
  );
};

export { ConfidentialityBanner };
