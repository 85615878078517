import routes from "routes";
import PropTypes from "prop-types";
import Snorlax from "utils/snorlax";
import Meowth from "@spring/meowth";

import { connect } from "react-redux";

import { useEffect } from "react";
import { useRouter } from "next/router";
import { useMutation } from "@apollo/client";
import { useTranslation, Trans } from "react-i18next";

import { PageBase } from "components/layout";
import { updateUser } from "operations/mutations/user";
import {
  ListItem,
  OrderedList,
  Button,
  Box,
} from "@springcare/sh-component-library";

import { VanillaCelebration } from "design-system/assets";
import { Avatar, Badge, Flex, Heading, Text } from "design-system/components";
import Header from "components/organisms/MemberExpectations/components/Header";
import styles from "components/organisms/MemberExpectations/styles.module.scss";
import { TRACK_EVENT } from "utils/mixpanel/events";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

const EnhancedMemberExpectations = (props) => {
  const { allowed, agedOutConsentRecord, lang, memberName } = props;
  const { t } = useTranslation("membership");
  const router = useRouter();
  const showPersonalizedTitle = memberName && lang === "en";

  const [updateUserLang, { loading: updateUserLangLoading }] =
    useMutation(updateUser);

  const handleClick = () => {
    TRACK_EVENT.BUTTON_CLICKED(
      routes.MemberExpectations.as,
      "Enhanced Member Expectations Begin",
      {
        is_aged_out: hasAgedOutRecord,
      },
    );
    return router.push(
      routes.TakeInitialAssessment.to,
      routes.TakeInitialAssessment.as,
    );
  };

  useEffect(() => {
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      routes.MemberExpectations.as,
      "Enhanced Member Expectations",
      {
        page_version: "Enhanced Member Expectations",
        is_aged_out: hasAgedOutRecord,
      },
    );
  }, []);

  const isAgeOutEnabled = useFeatureFlag(FLAGS.ENABLE_AGING_OUT_P1);
  // If a member has an agedOutRecord and not taken an adult IA, then we display different prompt text
  const hasAgedOutRecord =
    isAgeOutEnabled && !!agedOutConsentRecord?.created_at;

  return (
    <PageBase
      className={styles.pageBase}
      darkBg={false}
      allowed={allowed}
      redirect={routes.SignIn}
    >
      <Box marginRight={["24", "initial"]}>
        <Header
          alwaysWhite={true}
          isLoggedIn={true}
          updateUserLang={updateUserLang}
        />
      </Box>

      <Flex
        as="main"
        mt={65}
        maxW={["95%", "85%", "70%", "55%", "35%"]}
        justifyContent="center"
        marginTop={[10, "initial"]}
      >
        <Flex direction="column" w="100%">
          <VanillaCelebration width={80} />

          <Heading
            data-cy="begin-assessment-heading"
            data-testid="enhanced-member-expectations-heading"
            variant="md_v1"
            as="h1"
            mt={18}
            mb={16}
          >
            {hasAgedOutRecord
              ? t("enhancedExpectations.agingOut.heading")
              : showPersonalizedTitle
                ? t("enhancedExpectations.personalizedTitle", {
                    name: memberName,
                  })
                : t("enhancedExpectations.title")}
          </Heading>

          {hasAgedOutRecord && (
            <Text size="body-medium-regular" mb={8}>
              {t("enhancedExpectations.agingOut.subHeading")}
            </Text>
          )}

          <Badge pl={8} pr={8} bg="platform.light" w={85} mb={16}>
            {t("enhancedExpectations.completionEstimate")}
          </Badge>

          <OrderedList styleType={"none"} marginLeft={[0, "initial"]}>
            <ListItem>
              <Flex py={[0, 12]} alignItems="center">
                <Avatar name="1" />
                <Trans i18nKey="enhancedExpectations.subtitle.one">
                  <Text
                    ml={16}
                    letterSpacing={"-0.2px"}
                    textAlign={["left", "justify"]}
                    lineHeight={["24px", "initial"]}
                    fontSize={["16px", "initial"]}
                  >
                    {hasAgedOutRecord
                      ? t("enhancedExpectations.agingOut.bulletPointOne")
                      : t("enhancedExpectations.subtitle.one")}
                  </Text>
                </Trans>
              </Flex>
            </ListItem>

            <ListItem>
              <Flex py={12} alignItems="center">
                <Avatar name="2" />
                <Trans i18nKey="enhancedExpectations.subtitle.two">
                  <Text
                    ml={16}
                    letterSpacing={"-0.2px"}
                    textAlign={["left", "justify"]}
                    lineHeight={["24px", "initial"]}
                    fontSize={["16px", "initial"]}
                  >
                    {hasAgedOutRecord
                      ? t("enhancedExpectations.agingOut.bulletPointTwo")
                      : t("enhancedExpectations.subtitle.two")}
                  </Text>
                </Trans>
              </Flex>
            </ListItem>

            <ListItem>
              <Flex pt={12} alignItems="center">
                <Avatar name="3" />
                <Trans i18nKey="enhancedExpectations.subtitle.three">
                  <Text
                    ml={16}
                    letterSpacing={"-0.2px"}
                    textAlign={["left", "justify"]}
                    lineHeight={["24px", "initial"]}
                    fontSize={["16px", "initial"]}
                  >
                    {hasAgedOutRecord
                      ? t("enhancedExpectations.agingOut.bulletPointThree")
                      : t("enhancedExpectations.subtitle.three")}
                  </Text>
                </Trans>
              </Flex>
            </ListItem>
          </OrderedList>

          <Flex
            p="32px 0"
            width="100%"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            alignSelf="stretch"
            bottom="0px"
            left="0px"
          >
            <Flex
              height="48px"
              width="100%"
              flexDir="row"
              justifyContent="flex-start"
              alignItems="center"
              gap="8px"
              flex="1 0 0"
              mb={["100px", "initial"]}
            ></Flex>
            <Flex
              justifyContent={["center", "left"]}
              w={"full"}
              backgroundColor={"background-base"}
              position={["fixed", "initial"]}
              bottom={[0, "initial"]}
            >
              <Button
                variant="high-emphasis" //this is a temp attribute. the medium and low emphasis variants have transparency, which causes accessibility issues if the background is scrollable. change this when the tokens are no longer a transparent color
                onClick={handleClick}
                data-cy="expectations-begin"
                isDisabled={updateUserLangLoading}
                w={["343px", "initial"]}
                m={["24px 0px", "initial"]}
              >
                {t("enhancedExpectations.buttonText")}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </PageBase>
  );
};

EnhancedMemberExpectations.propTypes = {
  allowed: PropTypes.func,
  lang: PropTypes.string,
  memberName: PropTypes.string,
};

EnhancedMemberExpectations.defaultProps = {
  allowed: true,
  lang: "en",
  memberName: null,
};

const mapStateToProps = (state) => ({
  allowed: () =>
    Snorlax(state)
      .loggedIn.to(routes.SignIn)
      .isMember(routes["Logout"])
      .customerIsLaunched(Meowth.getCustomerId(), routes.ComingSoon)
      .hasNotCompletedInitialAssessment(routes.MemberHome),
  lang: state?.global?.lang,
  memberName: state?.auth?.userIdentifiers?.user?.first_name,
  agedOutConsentRecord:
    state?.auth?.userIdentifiers?.user?.member?.aged_out_consent_fields,
});

export { EnhancedMemberExpectations };
export default connect(mapStateToProps)(EnhancedMemberExpectations);
