import React from "react";
import {
  Breakpoint,
  Card,
  Content,
  FlexCol,
  FlexRow,
  Subtitle,
} from "@spring/smeargle";

import { Link, NoProvidersChat, NoProviderEmail } from "components/atoms";

import styles from "./styles.module.scss";
import { openZendeskChat } from "utils/zendesk";

const NoProvidersFound = () => {
  return (
    <div>
      <Breakpoint md andUp>
        <Card padding="find-provider-lg" color="GREY_150">
          <Subtitle>Contact Us</Subtitle>
          <FlexRow justification="space-between">
            <div className={styles.contactCardContainer}>
              <Card>
                <FlexRow>
                  <FlexCol>
                    <div className={styles.email}>
                      <NoProviderEmail />
                    </div>
                  </FlexCol>
                  <FlexCol justification="space-around">
                    <Content>Email</Content>
                    <Link
                      className={styles.contactLinks}
                      href="mailto:support@resourcesforyourlife.com"
                      to={"mailto:support@resourcesforyourlife.com"}
                    >
                      support@resourcesforyourlife.com
                    </Link>
                    <Content>Available 24/7</Content>
                  </FlexCol>
                </FlexRow>
              </Card>
            </div>
            <div className={styles.contactCardContainer}>
              <Card>
                <FlexRow>
                  <FlexCol>
                    <div className={styles.chat}>
                      <NoProvidersChat />
                    </div>
                  </FlexCol>
                  <FlexCol justification="space-around">
                    <Content>Chat with us</Content>
                    <a
                      className={styles.contactLinks}
                      onClick={openZendeskChat}
                    >
                      Send a message
                    </a>
                    <Content>Available: 8am - 8pm ET Monday - Friday</Content>
                  </FlexCol>
                </FlexRow>
              </Card>
            </div>
          </FlexRow>
        </Card>
      </Breakpoint>
    </div>
  );
};

export default NoProvidersFound;
