import {
  Box,
  Flex,
  Toggle,
  VIndividualIcon,
  VSmsIcon,
} from "@springcare/sh-component-library";

import { useSessionRoomDrawer } from "context/SessionRoomContext";

const DrawerTabsSection = () => {
  const { activeDrawerTab, setActiveDrawerTab } = useSessionRoomDrawer();

  return (
    <Box px={24}>
      <Toggle.Group
        selected={activeDrawerTab}
        role="tablist"
        aria-label="Drawer Tab Options"
        bg="accent-subtle"
        onToggle={(selected) => setActiveDrawerTab(selected)}
        width="fill"
      >
        <Flex>
          <Toggle.Button
            value="participants"
            icon={VIndividualIcon}
            aria-label="Participants tab"
            role="tab"
            height="v-lg"
          >
            Attendees
          </Toggle.Button>

          <Toggle.Button
            value="chat"
            icon={VSmsIcon}
            aria-label="Chat tab"
            role="tab"
            height="v-lg"
          >
            Chat
          </Toggle.Button>
        </Flex>
      </Toggle.Group>
    </Box>
  );
};

export default DrawerTabsSection;
