import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "hooks/react-i18next";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useMediaQuery,
} from "@springcare/sh-component-library";
import classnames from "classnames";
import type {
  CareProvider,
  CareProviderTag,
  Member,
} from "modules/shared/graphql-codegen/graphql";
import { TRACK_EVENT } from "utils/mixpanel";
import InPersonMap from "./InPersonMap";

const COLLAPSED_HEIGHT = 182;
const BORDER_STYLE = "1px solid rgba(239, 237, 232, 1)";

interface ProviderDetailsV2Props {
  careProvider: CareProvider;
  showLicensedInStates: boolean;
  licensedStatesToShow: string[];
  showTags: boolean;
  member: Member;
  supportsInPerson: boolean;
}

const ProviderDetailsV2 = ({
  careProvider,
  showLicensedInStates = true,
  licensedStatesToShow,
  showTags = true,
  member,
  supportsInPerson,
}: ProviderDetailsV2Props) => {
  const { t } = useTranslation("careProvider");
  const [isMobile] = useMediaQuery("(max-width: 450px)");
  const [hideButton, setHideButton] = React.useState<boolean>(false);
  const [maxHeight, setMaxHeight] = React.useState<number>(COLLAPSED_HEIGHT);
  const divRef = React.createRef<HTMLDivElement>();

  const specializingInTags = careProvider?.care_provider_tags?.filter(
    (tag: CareProviderTag) => tag.kind === "CONDITION",
  );

  const worksWithTags = careProvider?.care_provider_tags?.filter(
    (tag: CareProviderTag) => tag.kind === "SPECIALTY",
  );

  const languageTags = careProvider?.care_provider_tags?.filter(
    (tag: CareProviderTag) => tag.kind === "LANGUAGE",
  );

  const getCollapsedListItems = (
    list: string[] | CareProviderTag[],
    isObject = true,
  ) => {
    return list.map((tag, i) => {
      const innerText = isObject ? tag.name : tag;
      const text =
        i === 5 && list.length > 6 && maxHeight === COLLAPSED_HEIGHT
          ? `${innerText}...`
          : innerText;
      return <ListItem key={isObject ? tag.id : tag} name={text} />;
    });
  };

  const ListItem = ({ name }: { name: string }) => {
    return (
      <Text>
        <span style={{ margin: "0 8px" }}>▪</span> {name}
      </Text>
    );
  };

  const ListHeading = ({ title }: { title: string }) => (
    <Heading fontWeight={"600"} fontSize={"21"} marginBottom="16px">
      {title}
    </Heading>
  );

  const handleButtonClick = () => {
    setMaxHeight(divRef?.current?.scrollHeight);
    setTimeout(() => setHideButton(true), 100);
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Clicked show more button on provider profile",
    );
  };

  return (
    <Box borderBottom={BORDER_STYLE}>
      <Flex
        ref={divRef}
        paddingTop="16px"
        borderTop={BORDER_STYLE}
        maxHeight={maxHeight}
        marginTop="20px"
        justifyContent="center"
        flexWrap="wrap"
        overflow="hidden"
        transition="max-height 0.5s"
        className={classnames(styles.detailsContainerV2, {
          [styles.detailsContainerV2Open]: hideButton,
        })}
      >
        {showTags && specializingInTags?.length > 0 && (
          <Box flex={isMobile ? "100%" : "50%"} margin="0 auto 20px">
            <ListHeading title={t("specializingIn")} />
            {getCollapsedListItems(specializingInTags)}
          </Box>
        )}

        {showTags && worksWithTags?.length > 0 && (
          <Box flex={isMobile ? "100%" : "50%"} margin="0 auto 20px">
            <ListHeading title={t("worksWith")} />
            {getCollapsedListItems(worksWithTags)}
          </Box>
        )}

        {languageTags?.length > 0 && (
          <Box flex={isMobile ? "100%" : "50%"} margin="0 auto 20px">
            <ListHeading title={t("language")} />
            {getCollapsedListItems(languageTags)}
          </Box>
        )}

        {showLicensedInStates && (
          <Box flex={isMobile ? "100%" : "50%"} margin="0 auto 20px">
            <ListHeading title={t("detailModal.licensedIn")} />
            {getCollapsedListItems(licensedStatesToShow, false)}
          </Box>
        )}
        {supportsInPerson && (
          <InPersonMap
            borderStyle={BORDER_STYLE}
            careProvider={careProvider}
            member={member}
          />
        )}
      </Flex>
      <Button
        variant="medium-emphasis"
        visibility={hideButton ? "hidden" : "visible"}
        position={hideButton ? "absolute" : "relative"}
        width={isMobile ? "100%" : "120px"}
        margin={isMobile ? "10px 0" : "20px 0"}
        height="40px"
        data-testid={"showMoreButton"}
        onClick={handleButtonClick}
      >
        {t("showMore")}
      </Button>
    </Box>
  );
};

export default ProviderDetailsV2;
