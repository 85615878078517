import React from "react";
import { Text } from "design-system/components";
import { Trans } from "react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const ConfirmOrCancelPolicy = ({ defaultCopy = "" }) => {
  const shouldShowDeltaCopy = useFeatureFlag(
    FLAGS.DELTA_CANCELATION_POLICY_COPY,
  );

  if (shouldShowDeltaCopy === false) {
    return <>{defaultCopy}</>;
  }

  return (
    <Text display="inline">
      <Trans
        ns="careVisits"
        i18nKey="cancelAppointment.confirmOrCancel"
        values={{
          customerName: "Delta",
          noShowCharge: 215,
        }}
      />
    </Text>
  );
};
