import { useEffect } from "react";
import { useLocalParticipantPermissions } from "@livekit/components-react";

import { useSessionViewStatus } from "context/SessionRoomContext";
// NOTE: This component checks the local participant's permissions using
// Livekit's useLocalParticipantPermissions() hook and routes them accordingly.
// Providers have all permissions set to 'true' and can join a room immediately
// and admit Members. Members have permissions 'false' by default, which change
// upon Provider approval. The useEffect() detects this change and updates the
// session view status (see setSessionViewStatus(SessionViewStatus.InProgress).
// This component is necessary b/c Livekit hooks must be used within React
// components.

const ParticipantPermissionsChecker = () => {
  const localParticipantPermissions = useLocalParticipantPermissions();
  const { canPublish, canSubscribe, canPublishData } =
    localParticipantPermissions || {};
  const { sessionViewStatus, setSessionViewStatus, SessionViewStatus } =
    useSessionViewStatus();

  const memberShouldWait = ![
    SessionViewStatus.NotStarted,
    SessionViewStatus.TooEarly,
    SessionViewStatus.TooLate,
  ].includes(sessionViewStatus);

  useEffect(() => {
    if (canPublish && canSubscribe && canPublishData) {
      setSessionViewStatus(SessionViewStatus.InProgress);
    } else if (memberShouldWait) {
      setSessionViewStatus(SessionViewStatus.MemberIsWaiting);
    }
  }, [localParticipantPermissions]);

  return null;
};

export default ParticipantPermissionsChecker;
