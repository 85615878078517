import React, { useRef, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

import SkipBackwardIcon from "../Assets/svgs/SkipBackwardIcon";
import SkipForwardIcon from "../Assets/svgs/SkipForwardIcon";
import PlayIcon from "../../atoms/Assets/svgs/PlayIcon";
import PauseIcon from "../../atoms/Assets/svgs/PauseIcon";

import styles from "./styles.module.scss";

import { FlexRow } from "../../../components";
import Duration from "../../../components/atoms/Duration/Duration";

const AudioPlayer = (props) => {
  const { trackLang, url, subtitles, autoPlay, skipInterval } = props;

  const [playing, setPlaying] = useState(autoPlay);
  const [duration, setDuration] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);

  let player = useRef(null);
  const videoElement = document.querySelector("video");

  useEffect(() => {
    if (videoElement) {
      videoElement.setAttribute("aria-label", `${props.title}`);
    }
  }, [trackLang, videoElement]);

  useEffect(() => {
    const textTracks = player.current.getInternalPlayer()?.textTracks;

    for (let i = 0; textTracks?.length && i < textTracks.length; i++) {
      if (textTracks[i].language === trackLang) {
        textTracks[i].mode = "showing";
      } else {
        textTracks[i].mode = "hidden";
      }
    }
  }, [trackLang]);

  const handleProgress = (state) => {
    setPlayedSeconds(state.playedSeconds);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handlePlay = () => {
    setPlaying(!playing);
  };

  const handleKeypress = (event, callback) => {
    if (event.code === "Space") {
      callback();
    }
  };

  const handleSeekRewind = () => {
    let seekTo = Math.max(0, Math.round(playedSeconds - skipInterval));
    setPlayedSeconds(seekTo);
    player.current.seekTo(seekTo);
  };

  const handleSeekForward = () => {
    let seekTo = Math.min(duration, Math.round(playedSeconds + skipInterval));
    setPlayedSeconds(seekTo);
    player.current.seekTo(seekTo);
  };

  const handleEnded = () => {
    setPlaying(false);
  };

  const getTracks = useMemo(
    () =>
      Object.keys(subtitles).map((lang) => {
        return {
          kind: "captions",
          src: subtitles[lang],
          srcLang: lang,
          default: false,
          mode: trackLang === lang ? "showing" : "hidden",
        };
      }),
    [trackLang, subtitles],
  );

  return (
    <div className={styles.audioPlayerContainer}>
      <FlexRow center justification="space-evenly">
        <div
          role="button"
          className={styles.cursorPointer}
          onClick={handleSeekRewind}
          onKeyUp={(event) => handleKeypress(event, handleSeekRewind)}
          tabIndex="0"
          aria-label="Skip back 10 seconds - Use spacebar to skip back"
        >
          <SkipBackwardIcon />
        </div>
        <div className={styles.duration}>
          <Duration duration={Math.round(playedSeconds)} />
        </div>
        <div
          role="button"
          className={styles.playPauseIcon}
          onClick={handlePlay}
          onKeyUp={(event) => handleKeypress(event, handlePlay)}
          aria-label={
            playing
              ? "Pause - Use spacebar to pause"
              : "Play - Use spacebar to play"
          }
          id={`Play ${props.title} Moment`}
          tabIndex="0"
        >
          {playing ? <PauseIcon /> : <PlayIcon />}
        </div>
        <div className={styles.duration}>
          <Duration duration={Math.round(duration - playedSeconds)} />
        </div>
        <div
          role="button"
          className={styles.cursorPointer}
          onClick={handleSeekForward}
          onKeyUp={(event) => handleKeypress(event, handleSeekForward)}
          tabIndex="0"
          aria-label="Skip forward 10 seconds - Use spacebar to skip forward"
        >
          <SkipForwardIcon />
        </div>
      </FlexRow>
      <ReactPlayer
        ref={player}
        url={url}
        onDuration={handleDuration}
        onProgress={handleProgress}
        height={props.height}
        width={"100%"}
        onEnded={handleEnded}
        playing={playing}
        style={{ position: "relative", justifySelf: "center" }}
        config={{
          file: {
            forceVideo: true,
            tracks: getTracks,
          },
        }}
      />
    </div>
  );
};

AudioPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  subtitles: PropTypes.array,
  autoPlay: PropTypes.bool,
  skipInterval: PropTypes.number,
  trackLang: PropTypes.string,
  height: PropTypes.string,
};

AudioPlayer.defaultProps = {
  subtitles: [],
  autoPlay: false,
  skipInterval: 10,
};

export default AudioPlayer;
