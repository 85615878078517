import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getHelpInfo } from "operations/queries/member";

type CountryType = {
  global: {
    country: string;
  };
};

export function useHelpInfo() {
  const { country: country } = useSelector(
    (state: CountryType) => state.global,
  );

  const [selectedCountry, setSelectedCountry] = useState(country || "US");
  const { i18n } = useTranslation();
  const { data, loading } = useQuery(getHelpInfo, {
    variables: { country: selectedCountry, locale: i18n.language },
    fetchPolicy: "network-only",
  });
  const helpInfo = data?.help_info;

  return { helpInfo, loading, selectedCountry, setSelectedCountry };
}
