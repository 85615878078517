//
import PropTypes from "prop-types";
import React, { Component } from "react";
import classnames from "classnames";
import { Floaty, Stout } from "@spring/smeargle";

import styles from "./styles.module.scss";
import { clickableDivProps } from "lib/accessibility_helpers";

class Slot extends Component {
  static propTypes = {
    dataId: PropTypes.string,
    active: PropTypes.any,
    dataCy: PropTypes.any,
    onClick: PropTypes.any,
    time: PropTypes.any,
    day: PropTypes.any,
  };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.active !== nextProps.active ||
      this.props.time !== nextProps.time
    );
  }

  render() {
    return (
      <Floaty radius={3}>
        <div
          data-id={this.props.dataId}
          data-cy={this.props.dataCy ? `${this.props.dataCy}-click` : "click"}
          className={classnames(styles.slot, {
            [styles.active]: this.props.active,
          })}
          onClick={this.props.onClick}
          aria-label={`${this.props.day} - ${this.props.time}`}
          {...clickableDivProps({
            onClick: this.props.onClick,
            pressed: this.props.active,
          })}
        >
          <Stout dark>{this.props.time}</Stout>
        </div>
      </Floaty>
    );
  }
}

export default Slot;
