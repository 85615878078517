import { gql } from "@apollo/client";

export const getMemberAppointmentAndAssessmentData = gql`
  query getMemberAppointmentAndAssessmentData($id: ID!) {
    user(id: $id) {
      id
      member {
        id
        minor
        has_received_care
        most_recent_assessment {
          id
          status
          kind
          risk
          ended_at
        }
        attended_appointment_within_last_180_days
        visits_remaining_basic
        visits_remaining_coaching
      }
    }
  }
`;
