import { Box, Heading, Text } from "@springcare/sh-component-library";

const SessionDetailsHeader = ({ mainHeaderText, subHeaderText, isMobile }) => (
  <>
    <Box>
      <Heading
        size={isMobile ? "heading-small" : "heading-medium"}
        mb="v-8"
        as="h2"
      >
        {mainHeaderText}
      </Heading>
    </Box>

    <Box mb="v-8">
      <Text size="body-medium" color="content-secondary">
        {subHeaderText}
      </Text>
    </Box>
  </>
);

export default SessionDetailsHeader;
