import { Grid } from "@spring/smeargle";
import React, { useCallback, useEffect, useState } from "react";
import { CareTeamCard } from "components/molecules";
import { useMutation, useQuery } from "@apollo/client";
import { searchCareProviders } from "operations/queries/careProvider";
import { AppointmentKind, ProviderRole } from "@spring/constants";
import { PlantInHand } from "design-system/assets";
import { useRouter } from "next/router";
import routes from "routes";
import {
  Box,
  Button,
  Center,
  CloseButton,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useMediaQuery,
} from "design-system/components";
import { requestCareTeamChange } from "operations/mutations/member";
import { getFirstError } from "utils/apollo/errorHandler";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "@spring/smeargle/actions";
import { useTranslation } from "react-i18next";
import { useCareTeam, useMemberInfo } from "hooks";
import { TRACK_EVENT } from "utils/mixpanel";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

interface ModalProps {
  isOpen: boolean;
  preventAutoOpen?: boolean;
  onClose: () => void;
  onOpen: () => void;
  showSpecializedCN?: boolean;
  isFromSUDSignalExperience?: boolean;
  isSpecializedCNSupported?: boolean;
}

type GlobalExperienceType = {
  global: {
    showGlobalExperience: boolean;
  };
};

const providerAttributes = (t: (_: string) => string) => ({
  role: t("careProvider.careNavigator"),
  description: "",
  smallIcon: (
    <PlantInHand aria-hidden="true" color="#424A4C" width="24" height="24" />
  ),
});

const RequestAdditionalAvailabilityForm = ({
  t,
  memberId,
  assignedCNId,
  onClose,
  showSpecializedCN,
}) => {
  const dispatch = useDispatch();

  const [requestCareTeamChangeMutation] = useMutation(requestCareTeamChange, {
    onCompleted: () => {
      dispatch(
        addNotification(
          t(
            "careNavigatorScheduleModal.requestAdditionalAvailability.notificationMessage.success",
          ),
          "success",
        ),
      );
      onClose();
    },
    onError: (err) => dispatch(addNotification(getFirstError(err), "error")),
  });

  const [reason, setReason] = useState("");

  const handleReasonInput = (e) => {
    setReason(e.target.value);
  };

  return (
    <Box pt={2}>
      <Divider aria-hidden="true" borderColor="gray.300" mb={6} w="100%" />

      <Heading as="h2" fontSize={16} fontWeight={600}>
        {t("careNavigatorScheduleModal.requestAdditionalAvailability.subtitle")}
      </Heading>
      <Text fontSize={16} fontWeight={400} mt={8}>
        {t(
          "careNavigatorScheduleModal.requestAdditionalAvailability.description",
        )}
      </Text>

      <FormControl mt={16}>
        <FormLabel htmlFor="availability-reason-input">
          <Flex justify="space-between" mx={8}>
            <Text fontSize={16} fontWeight={400}>
              {t(
                "careNavigatorScheduleModal.requestAdditionalAvailability.reason",
              )}
            </Text>
            <Text fontSize={16} fontWeight={400}>
              {t(
                "careNavigatorScheduleModal.requestAdditionalAvailability.optional",
              )}
            </Text>
          </Flex>
        </FormLabel>
        <Input
          height={"48px"}
          name={t(
            "careNavigatorScheduleModal.requestAdditionalAvailability.reason",
          )}
          id="availability-reason-input"
          backgroundColor="#5F4B191A"
          border="none"
          onChange={handleReasonInput}
        />
        <Flex justify="flex-end" mt={15.5} mb={20.5}>
          <Button
            size="lg"
            variant="solid"
            colorScheme="primary"
            onClick={() => {
              TRACK_EVENT.FORM_SUBMITTED(
                window.location.pathname,
                "Requested Provider",
                {
                  location: showSpecializedCN
                    ? "Specialized Care Navigator Availability Modal"
                    : "Care Navigator Availability Modal",
                  provider_role: "Care Navigation",
                  additional_note: reason,
                },
              );
              requestCareTeamChangeMutation({
                variables: {
                  input: {
                    member_id: memberId,
                    role: ProviderRole.CareNavigator,
                    care_provider_id: assignedCNId,
                    reason: reason,
                  },
                },
              });
            }}
          >
            {t(
              "careNavigatorScheduleModal.requestAdditionalAvailability.submit",
            )}
          </Button>
        </Flex>
      </FormControl>
    </Box>
  );
};

const CareNavigatorCard = ({
  isMobile,
  t,
  cn,
  router,
  showGlobalExperience,
  memberData,
  isWidthSmall,
  providerOrder,
  onNoPhoneAppointments = () => {},
  isAssignedCN = false,
  providerRole,
  showSpecializedCN = false,
  isFromSUDSignalExperience,
  sudSignalExperimentValue,
}) => (
  <Box
    border="1px solid #00131738; !important"
    borderRadius={8}
    px={isMobile ? "1em" : "16px"}
    py={isMobile ? "0" : "16px"}
    pt={isMobile ? "1em" : "16px"}
    mb={16}
  >
    <Grid gutter="16px" alignment="center">
      <CareTeamCard
        onNoPhoneAppointments={onNoPhoneAppointments}
        providerRole={providerRole}
        providerAttributes={providerAttributes(t)}
        showChange={false}
        scheduleAction={() => {}}
        infoAction={() => {}}
        changeAction={() => {}}
        providerData={cn}
        onSchedule={(selectedDateTime: string | null) => {
          const url = routes["ScheduleAppointmentProviderDetail"].as.replace(
            ":id",
            cn.id,
          );

          const query = {
            returnPath: router.asPath.split("?")[0],
            showSpecializedCN: showSpecializedCN,
            isFromSUDSignalExperience:
              isFromSUDSignalExperience ||
              router.query.isFromSUDSignalExperience === "true",
            sudSignalExperimentValue: sudSignalExperimentValue,
          };

          if (selectedDateTime) {
            query["selectedDateTime"] = selectedDateTime;
          }

          router.push({
            pathname: url,
            query: query,
          });
        }}
        showGlobalExperience={showGlobalExperience}
        appointmentKind={AppointmentKind.FollowUpCareNavigation}
        memberData={memberData}
        isCNAvailabilityModal={true}
        scheduleConfirmation=""
        scheduleCoachWithCN={null}
        browseOthers=""
        isAssignedCN={isAssignedCN}
        slotsWidth={isWidthSmall ? "100%" : "max-content"}
        nameFontSize={16}
        avatarWidth={[52, 60, 70, 80]}
        avatarNoExtraSpace={true}
        slotsMaxWidth={isWidthSmall ? "100%" : 280}
        singleTabTitle={t("careNavigatorScheduleModal.upcomingAvailability")}
        providerOrder={providerOrder}
        onScheduleModalOpen={() => {}}
      />
    </Grid>
  </Box>
);

const CareNavigatorAvailabilityModal = ({
  isOpen,
  preventAutoOpen,
  onClose,
  onOpen,
  showSpecializedCN,
  isFromSUDSignalExperience,
  isSpecializedCNSupported,
}: ModalProps) => {
  const sudSignalExperimentValue: string = useFeatureFlag(
    FLAGS.SUD_SIGNAL_EXPERIMENT,
  );
  const { t } = useTranslation("careVisits");
  const { showGlobalExperience } = useSelector(
    (state: GlobalExperienceType) => state.global,
  );
  const { data: memberData, loading: memberDataLoading } = useMemberInfo();

  const { data: careTeam, loading: careTeamLoading } = useCareTeam();

  const { data: cns, loading: cnLoading } = useQuery(searchCareProviders, {
    fetchPolicy: "network-only",
    skip: !isOpen,
    variables: showSpecializedCN
      ? {
          roles: ["CARE_NAVIGATOR"],
          filters: {
            specialties: ["Substance Use Disorder"],
          },
        }
      : {
          roles: ["CARE_NAVIGATOR"],
        },
  });

  const [isMobile, isWidthSmall] = useMediaQuery([
    "(max-width: 450px)",
    "(max-width: 960px)",
  ]);
  const [unavailableCNs, setUnavailableCNs] = useState([]);
  const unavailableCNCount = new Set(unavailableCNs).size;

  const router = useRouter();

  const providerRole = showSpecializedCN
    ? "specialized_care_navigator"
    : "care_navigator";

  useEffect(() => {
    const showCNScheduleModal = router?.query?.showCNScheduleModal;
    // preventAutoOpen used for when multiple CareNavigatorAvailabilityModals are on the same page
    if (
      showCNScheduleModal === "true" &&
      !showSpecializedCN &&
      !preventAutoOpen &&
      isSpecializedCNSupported
    ) {
      onOpen();
    }
  }, [router?.query]);

  useEffect(() => {
    if (isOpen) {
      TRACK_EVENT.MODAL_OPENED(
        window.location.pathname,
        showSpecializedCN
          ? "Specialized Care Navigator Availability Modal"
          : "Care Navigator Availability Modal",
        {
          ...(isFromSUDSignalExperience && {
            location: "SUD Signal Experience",
            experience_type: sudSignalExperimentValue,
          }),
        },
      );
    }
  }, [isOpen]);

  const onModalClose = useCallback(() => {
    const { showCNScheduleModal, showSpecializedCNScheduleModal, ...query } =
      router.query;
    router.replace(
      {
        pathname: router.asPath.split("?")[0],
        query,
      },
      undefined,
      { scroll: false },
    );
    setUnavailableCNs([]);
    onClose();
  }, [router]);

  const careNavigators = cns?.care_providers_search?.providers;
  const assignedCN = careTeam?.user?.member?.care_team?.care_navigator;
  const assignedCNIsSudCN = assignedCN?.care_provider_tags
    ?.map((tag) => tag.name)
    .includes("Substance Use Disorder");

  let showAdditionalAvailabilityTitle =
    cnLoading ||
    (careNavigators && careNavigators.length !== 0 && unavailableCNCount < 2);

  if (
    careNavigators &&
    careNavigators.length === 1 &&
    careNavigators[0]?.id === assignedCN?.id
  ) {
    showAdditionalAvailabilityTitle = false;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      scrollBehavior={"inside"}
      isCentered
      size={isMobile ? "full" : "xl"}
    >
      <ModalOverlay />
      <ModalContent maxW={700}>
        <Flex p={6} justify="space-between" alignItems="center">
          <Box>
            <ModalHeader p={0}>
              {t("careNavigatorScheduleModal.title")}
            </ModalHeader>
          </Box>
          <CloseButton size="lg" bg="#5F4B191A" onClick={onClose} />
        </Flex>
        <ModalBody p={0}>
          <Box mx={24}>
            {(!showSpecializedCN || assignedCNIsSudCN) && (
              <Box mt={32}>
                <Heading as="h2" fontSize={16} fontWeight={600}>
                  {t("careNavigatorScheduleModal.yourCareNavigator")}
                </Heading>
                <Text fontSize={12} fontWeight={400} mt={8} mb={16}>
                  {t("careNavigatorScheduleModal.subtitle")}
                </Text>
              </Box>
            )}
            {careTeamLoading && (
              <Center py={20}>
                <Spinner speed="1s" size="xl" />
              </Center>
            )}
            {!careTeamLoading &&
              careTeam &&
              (!showSpecializedCN || assignedCNIsSudCN) && (
                <>
                  <CareNavigatorCard
                    isMobile={isMobile}
                    t={t}
                    cn={assignedCN}
                    router={router}
                    showGlobalExperience={showGlobalExperience}
                    memberData={memberData}
                    isAssignedCN={true}
                    isWidthSmall={isWidthSmall}
                    providerOrder={1}
                    providerRole={providerRole}
                    showSpecializedCN={showSpecializedCN}
                    isFromSUDSignalExperience={isFromSUDSignalExperience}
                    sudSignalExperimentValue={sudSignalExperimentValue}
                  />
                </>
              )}

            {showAdditionalAvailabilityTitle && (
              <Box mt={(!showSpecializedCN || assignedCNIsSudCN) && 24} mb={16}>
                <Heading as="h2" fontSize={16} fontWeight={600}>
                  {t("careNavigatorScheduleModal.additionalAvailability")}
                </Heading>
                {showSpecializedCN && !assignedCNIsSudCN && (
                  <Text fontSize={12} fontWeight={400} mt={8} mb={16}>
                    {t("careNavigatorScheduleModal.subtitle")}
                  </Text>
                )}
              </Box>
            )}

            {(cnLoading || memberDataLoading) && (
              <Center py={20}>
                <Spinner speed="1s" size="xl" />
              </Center>
            )}
            {!cnLoading && !memberDataLoading && unavailableCNCount < 2 && (
              <>
                {careNavigators?.map((cn, index) => {
                  if (index === 0 || unavailableCNs.includes(cn.id)) {
                    return <React.Fragment key={cn.id} />;
                  }
                  return (
                    <CareNavigatorCard
                      isMobile={isMobile}
                      t={t}
                      cn={cn}
                      router={router}
                      showGlobalExperience={showGlobalExperience}
                      memberData={memberData}
                      isWidthSmall={isWidthSmall}
                      onNoPhoneAppointments={() => {
                        setUnavailableCNs([...unavailableCNs, cn.id]);
                      }}
                      providerOrder={index + 1}
                      providerRole={providerRole}
                      showSpecializedCN={showSpecializedCN}
                      key={cn.id}
                      isFromSUDSignalExperience={isFromSUDSignalExperience}
                      sudSignalExperimentValue={sudSignalExperimentValue}
                    />
                  );
                })}
              </>
            )}
            {!careTeamLoading && (
              <RequestAdditionalAvailabilityForm
                t={t}
                memberId={memberData?.user?.member?.id}
                assignedCNId={assignedCN?.id}
                onClose={onModalClose}
                showSpecializedCN={showSpecializedCN}
              />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CareNavigatorAvailabilityModal;

export const SpecializedCareNavigatorAvailabilityModal = ({
  isOpen,
  preventAutoOpen,
  onClose,
  onOpen,
  isFromSUDSignalExperience,
  isSpecializedCNSupported,
}: ModalProps) => {
  const router = useRouter();

  useEffect(() => {
    const showSpecializedCNScheduleModal =
      router?.query?.showSpecializedCNScheduleModal;
    // preventAutoOpen used for when multiple CareNavigatorAvailabilityModals are on the same page
    if (
      showSpecializedCNScheduleModal === "true" &&
      !preventAutoOpen &&
      isSpecializedCNSupported
    ) {
      onOpen();
    }
  }, [router?.query]);

  return (
    <CareNavigatorAvailabilityModal
      isOpen={isOpen}
      preventAutoOpen={preventAutoOpen}
      onClose={onClose}
      onOpen={onOpen}
      showSpecializedCN={true}
      isFromSUDSignalExperience={isFromSUDSignalExperience}
      isSpecializedCNSupported={isSpecializedCNSupported}
    />
  );
};
