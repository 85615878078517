import { Box, Flex, Text } from "@springcare/sh-component-library";
import { PlantLeaves, WateringCan } from "design-system/assets";
import { Trans } from "react-i18next";

export const VisitsCoveredCard = ({
  memberData,
  item,
  isBetweenBaseToSm,
  isWpoCoaching = false,
}) => {
  const customer = memberData?.user?.member?.cohort?.customer?.name;
  const coveredSessions = ["Therapy", "DependentsSection"].includes(item)
    ? memberData?.user?.member?.visits_covered_total
    : memberData?.user?.member?.visits_covered_coaching;

  if (["Therapy", "DependentsSection", "Coaching"].includes(item)) {
    return (
      <Flex
        alignItems="flex-start"
        maxWidth={isBetweenBaseToSm ? "100%" : "297px"}
      >
        <Box
          w="100%"
          p={16}
          borderWidth="2px"
          borderRadius="12px"
          borderColor={"border-subtle"}
          display="flex"
          flexDirection="column"
          background="background-subtle"
        >
          <Flex
            position="relative"
            borderRadius={50}
            textAlign="center"
            alignItems="center"
            justifyContent="center"
            width={40}
            height={40}
            backgroundColor="secondary.100"
          >
            <Text p="6 4 6 4" fontSize={21} fontWeight={600}>
              {coveredSessions}
            </Text>
            <Flex
              position="absolute"
              top="-5px"
              right="-10px"
              borderRadius={50}
              justifyContent="center"
              alignItems="center"
              backgroundColor="tertiary.25"
            >
              {["Therapy", "DependentsSection"].includes(item) && (
                <PlantLeaves
                  color="platform.900"
                  width="18px"
                  height="18px"
                  aria-hidden="true"
                />
              )}
              {item === "Coaching" && (
                <WateringCan
                  color="platform.900"
                  width="18px"
                  height="18px"
                  aria-hidden="true"
                />
              )}
            </Flex>
          </Flex>
          <Text pt={10} size="body-medium-regular">
            <Trans
              ns={isWpoCoaching ? "benefits" : "t2Benefits"}
              i18nKey={
                isWpoCoaching
                  ? "customerWPOCoachingCoveredInfo"
                  : ["Therapy", "DependentsSection"].includes(item)
                    ? "customerTherapyCoveredInfo"
                    : "customerCoachingCoveredInfo"
              }
              values={{
                customerName: customer,
                sessionCount: coveredSessions,
              }}
              components={[<strong key="0" />]}
            />
          </Text>
        </Box>
      </Flex>
    );
  }
  return null;
};
