import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import Meowth from "@spring/meowth";
import { NavItem, Icon } from "@spring/smeargle";
import { SpringTextLogo } from "design-system/index";
import {
  Box,
  Button,
  Center,
  IconButton,
  VAssessmentIcon,
} from "@springcare/sh-component-library";

import { getMemberInfo } from "operations/queries/member";
import { toggleSidebar } from "actions/memberDashboard/actions";
import { mobileAndTabletCheck } from "utils/global";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useCreateCheckInMutation } from "hooks/useCreateCheckInMutation";
import { TRACK_EVENT } from "utils/mixpanel";
import routes from "routes";

import {
  teenWebLeftLinks,
  adultWebLeftLinks,
  adultWebWorkplaceManagerLeftLinks,
  minorWebLeftLinks,
  adultMobileSidebarLinks,
  adultMobileWorkplaceManagerSidebarLinks,
  minorMobileSidebarLinks,
  t2MemberMobileSidebarLinks,
  teenMobileSidebarLinks,
  t2MemberWebLeftLinks,
  globalAdultMobileSidebarLinks,
} from "./config/navConfig";
import LinkItem from "./LinkItem";

import LinkItemNav from "./LinkItemNav";
import styles from "./styles.module.scss";

interface MobileMenuNavLink {
  alias: string;
  icon: ReactNode;
  linkname?: string;
  text: string;
  trackingText: string;
}

interface MobileNavLinkToggles {
  isAT2Member: boolean;
  isATeen: boolean;
  isAMinor: boolean;
  canSeeWorkplaceLink: boolean;
  isAWorkplaceManager: boolean;
  isAGlobalMember: boolean;
  isADependent: boolean;
}

const getMobileNavLinks = ({
  isAT2Member,
  isATeen,
  isAMinor,
  canSeeWorkplaceLink,
  isAWorkplaceManager,
  isAGlobalMember,
  isADependent,
}: MobileNavLinkToggles): MobileMenuNavLink[] => {
  if (isAT2Member) return t2MemberMobileSidebarLinks;
  if (isATeen) return teenMobileSidebarLinks;
  if (isAMinor) return minorMobileSidebarLinks;
  if (
    canSeeWorkplaceLink &&
    isAWorkplaceManager &&
    !isAGlobalMember &&
    !isADependent
  )
    return adultMobileWorkplaceManagerSidebarLinks;
  return adultMobileSidebarLinks;
};

interface MobileMenuProps {
  isATeen: boolean;
  isAMinor: boolean;
  isAT2Member: boolean;
  isAWorkplaceManager: boolean;
  isAnAdultMember: boolean;
  isAGlobalMember: boolean;
  isADependent: boolean;
  showCheckInButton: boolean;
  createCheckInMutation: () => void;
}

export const MobileMenu = ({
  isATeen,
  isAMinor,
  isAT2Member,
  isAWorkplaceManager,
  isAnAdultMember,
  isAGlobalMember,
  isADependent,
  showCheckInButton,
  createCheckInMutation,
}: MobileMenuProps) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const canSeeWorkplaceLink = useFeatureFlag(
    FLAGS.ENABLE_WORKPLACE_MANAGER_NAV_LINK,
  );

  const isSidebarOpen = useSelector((state: RootState) =>
    Boolean(state.memberDashboard.sidebarOpen),
  );
  const dispatch = useDispatch();
  const toggleSidebarAction = () => dispatch(toggleSidebar());

  const mobileNavLinkToggles: MobileNavLinkToggles = {
    isAT2Member,
    isATeen,
    isAMinor,
    canSeeWorkplaceLink,
    isAWorkplaceManager,
    isAGlobalMember,
    isADependent,
  };

  const mobileNavLinks = getMobileNavLinks(mobileNavLinkToggles);

  const handleTeenCheckInClick = () => {
    router.push(routes.TeenCheckIn.as);
    TRACK_EVENT.BUTTON_CLICKED(router.asPath, "Check-in mobile menu", {
      spring_doc_id: "checkin003",
    });
  };

  const handleCheckInClick = isAnAdultMember
    ? createCheckInMutation
    : handleTeenCheckInClick;

  const shouldShowMobileCheckInButton =
    showCheckInButton && isSidebarOpen && !isAT2Member;
  const showMobileCheckInIconButton =
    showCheckInButton && !isSidebarOpen && !isAT2Member;

  const sidebarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      isSidebarOpen &&
      sidebarRef?.current &&
      !sidebarRef?.current?.contains(event.target)
    ) {
      toggleSidebarAction();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <div
      id="MemberDashboardSidebar"
      className={classnames(styles.membersSidebar, {
        [styles.open]: isSidebarOpen,
      })}
      ref={sidebarRef}
    >
      <div className={styles.logoArea}>
        <span className={styles.logo}>
          <SpringTextLogo />
        </span>
        <Icon
          type="menu"
          onClick={toggleSidebarAction}
          expanded={isSidebarOpen}
        />
      </div>
      {shouldShowMobileCheckInButton && (
        <Center>
          <Button
            variant="medium-emphasis"
            icon={VAssessmentIcon}
            onClick={handleCheckInClick}
            role="link"
          >
            {t("navigation.mobileCheckInBtn")}
          </Button>
        </Center>
      )}
      <Box className={styles.links}>
        {showMobileCheckInIconButton && (
          <Center marginLeft={10}>
            <IconButton
              aria-label="Check-in on your progress"
              variant="medium-emphasis"
              colorScheme="base"
              size="md"
              role="link"
              icon={VAssessmentIcon}
              onClick={handleCheckInClick}
            />
          </Center>
        )}
        {mobileNavLinks.map((sideBarData, index) => (
          <LinkItemNav data={sideBarData} key={index} />
        ))}
      </Box>
    </div>
  );
};

interface RootState {
  memberDashboard: {
    sidebarOpen: boolean;
  };
}

const PAGE_NAME = "Top Navigation";

interface MenuNavLink {
  alias: string;
  linkName: string;
  navText: string;
  new?: boolean;
  oldNav?: boolean;
  path: string;
  trackingText: string;
  translatedText: string;
}

interface WebNavLinkToggles {
  isAT2Member: boolean;
  isATeen: boolean;
  isAMinor: boolean;
  canSeeWorkplaceLink: boolean;
  isAWorkplaceManager: boolean;
  isAnAdultMember: boolean;
  isAGlobalMember: boolean;
  isADependent: boolean;
}

const getWebNavLinks = ({
  isAT2Member,
  isATeen,
  isAMinor,
  canSeeWorkplaceLink,
  isAWorkplaceManager,
  isAGlobalMember,
  isADependent,
}: WebNavLinkToggles): MenuNavLink[] => {
  if (isAT2Member) return t2MemberWebLeftLinks;
  if (isATeen) return teenWebLeftLinks;
  if (isAMinor) return minorWebLeftLinks;
  if (
    canSeeWorkplaceLink &&
    isAWorkplaceManager &&
    !isAGlobalMember &&
    !isADependent
  )
    return adultWebWorkplaceManagerLeftLinks;
  return adultWebLeftLinks;
};

interface MenuProps {
  isATeen: boolean;
  isAMinor: boolean;
  isAT2Member: boolean;
  isAnAdultMember: boolean;
  isAWorkplaceManager: boolean;
  showCheckInButton: boolean;
  isAGlobalMember: boolean;
  isADependent: boolean;
}

export const Menu = ({
  isATeen,
  isAMinor,
  isAT2Member,
  isAnAdultMember,
  isAWorkplaceManager,
  showCheckInButton,
  isAGlobalMember,
  isADependent,
}: MenuProps) => {
  const canSeeWorkplaceLink = useFeatureFlag(
    FLAGS.ENABLE_WORKPLACE_MANAGER_NAV_LINK,
  );
  const router = useRouter();
  const { data } = useQuery(getMemberInfo, {
    ...Meowth.apolloOptionsUserId(),
  });

  const isWorkplacePageViewed =
    data?.user?.member?.experience_state?.workplace_page_viewed;
  const memberId = data?.user?.member?.id;

  const [createCheckInMutation] = useCreateCheckInMutation(memberId, router);

  const webNavLinkToggles: WebNavLinkToggles = {
    isAT2Member,
    isATeen,
    isAMinor,
    canSeeWorkplaceLink,
    isAWorkplaceManager,
    isAnAdultMember,
    isAGlobalMember,
    isADependent,
  };

  const webNavLinks = getWebNavLinks(webNavLinkToggles);

  if (!router) {
    return null;
  }

  return (
    <>
      {mobileAndTabletCheck() ? (
        <MobileMenu
          isAMinor={isAMinor}
          isATeen={isATeen}
          isAT2Member={isAT2Member}
          isAnAdultMember={isAnAdultMember}
          isAGlobalMember={isAGlobalMember}
          isADependent={isADependent}
          isAWorkplaceManager={isAWorkplaceManager}
          showCheckInButton={showCheckInButton}
          createCheckInMutation={createCheckInMutation}
        />
      ) : (
        webNavLinks.map((link) => (
          <div
            key={link.navText}
            className={classnames(styles.breakpoint, styles.link)}
            data-testid="Menu"
          >
            <NavItem
              isRouterPath={router.asPath === link.path}
              active={router.asPath.includes(link.path)}
            >
              <LinkItem
                data={link}
                PAGE_NAME={PAGE_NAME}
                isWorkplacePageViewed={isWorkplacePageViewed}
              />
            </NavItem>
          </div>
        ))
      )}
    </>
  );
};

export default Menu;
