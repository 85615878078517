import { useTranslation } from "react-i18next";
import { smeargleMaxTabletSize } from "constants/smeargle";
import {
  Flex,
  Box,
  Text,
  Heading,
  VInsuranceIcon,
  VFinancialHelpIcon,
  useMediaQuery,
} from "@springcare/sh-component-library";

import { InteractableCard } from "./shared";
import { TRACK_EVENT } from "utils/mixpanel";
import { useEffect, useRef, useState } from "react";
import { useInViewportOnce } from "hooks";

export const InitialDisplay = ({
  trackingProperties,
  changeToInsuranceFormDisplayCallback,
  changeToSelfPayDisplayCallback,
}) => {
  const { t } = useTranslation("costEstimate");
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);
  const [isUnderSmeargleTabletSize] = useMediaQuery(
    `(max-width: ${smeargleMaxTabletSize}px)`,
  );
  const widthValueToUse = isUnderSmeargleTabletSize ? "100%" : "724px";

  useEffect(() => {
    if (inViewport && !trackedOnce) {
      TRACK_EVENT.PAGE_VERSION_VIEWED(
        window.location.pathname,
        "Request cost estimate",
        trackingProperties,
      );
      setTrackedOnce(true);
    }
  });

  return (
    <Flex
      ref={ref}
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Heading as="h1" width={widthValueToUse} size="heading-medium" mb={24}>
        {t("requestCostEstimateHeading")}
      </Heading>
      <Box as="section" width={widthValueToUse}>
        <Text>{t("requestForInfoDescription")}</Text>
        <Text mb={16}>{t("requestForInfoPrompt")}</Text>
        <InteractableCard
          data-cy="request-cost-estimate-insurance-card"
          cardText={t("addInsuranceCtaText")}
          cardSubtext={t("addInsuranceCtaSubtext")}
          primaryIcon={VInsuranceIcon}
          handleClickCallback={changeToInsuranceFormDisplayCallback}
        />
        <InteractableCard
          cardText={t("selfPayCtaText")}
          cardSubtext={t("selfPayCtaSubtext")}
          primaryIcon={VFinancialHelpIcon}
          handleClickCallback={changeToSelfPayDisplayCallback}
        />
      </Box>
    </Flex>
  );
};
