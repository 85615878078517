import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { DateTime } from "luxon";
import { format } from "date-fns/format";
import { startOfMonth } from "date-fns/startOfMonth";
import { isSameMonth } from "date-fns/isSameMonth";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { RequestableAppointmentKind } from "@spring/constants";
import Meowth from "@spring/meowth";
import { useCustomToast } from "@springcare/sh-component-library";
import { useMemberInfo } from "hooks/useMemberInfo";
import type {
  AvailableCnAppointmentTimes,
  GetCNsByStartTimesQuery,
  GetCNsByStartTimesQueryVariables,
} from "modules/shared/graphql-codegen/graphql";
import { getCNsByStartTimes } from "modules/MemberDashboard/Scheduling/graphql/getCNsByStartTimes";
import { getCareProvider } from "operations/queries/careProvider";
import {
  findFirstAvailableDate,
  formatSelectedDay,
  getIncludedDates,
  getStartOfNextMonth,
  getTimeSlotsForDay,
  sortAppointmentsByDay,
} from "modules/MemberDashboard/Scheduling/utils";
import type { TimeSlotType } from "modules/MemberDashboard/Scheduling/types";
import { createAppointment } from "operations/mutations/appointment";
import { TRACK_EVENT } from "utils/mixpanel";
import { getIterableCampaignInfo } from "utils/localStorage";

export const useCNScheduling = (showSUDCN: boolean) => {
  const { t } = useTranslation("scheduling");
  const today = useMemo(() => new Date().toISOString(), []);
  const [availableAppointments, setAvailableAppointments] = useState<
    AvailableCnAppointmentTimes[]
  >([]);
  const [startDate, setStartDate] = useState(today);
  const [selectedDateTimeSlots, setSelectedDateTimeSlots] = useState([]);
  const [includedDates, setIncludedDates] = useState([]);
  const [selectedDayString, setSelectedDayString] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlotType>(null);
  const [bookedAppointment, setBookedAppointment] = useState(null);
  const [firstAvailableDate, setFirstAvailableDate] = useState(null);
  const [isManuallyFetchingNextMonth, setIsManuallyFetchingNextMonth] =
    useState(false);
  const [shouldShowReviewScreen, setShouldShowReviewScreen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const loadingErrorToast = useCustomToast({
    type: "error",
    message: t("errorLoading"),
    layout: "fit-content",
    action: "on",
  });

  const bookingErrorToast = useCustomToast({
    type: "error",
    message: t("bookingReview.errorBooking"),
    layout: "fit-content",
    action: "on",
  });

  const { data: memberData, loading: isMemberDataLoading } = useMemberInfo();
  const member = memberData?.user?.member;
  const {
    loading: isAvailableAppointmentsLoading,
    previousData: previousAppointmentData,
  } = useQuery<GetCNsByStartTimesQuery, GetCNsByStartTimesQueryVariables>(
    getCNsByStartTimes,
    {
      variables: {
        member_id: member?.id,
        start_date: startDate,
        utc_offset: format(new Date(), "xxx"),
        sud_only: showSUDCN,
      },
      skip: !memberData || !startDate,
      onError: loadingErrorToast,
      onCompleted: ({
        all_available_cn_appointment_times: allAppointments,
      }: {
        all_available_cn_appointment_times: AvailableCnAppointmentTimes[];
      }) => {
        if (allAppointments?.length > 0) {
          const sortedAppointments = sortAppointmentsByDay(allAppointments);
          const allIncludedDates = getIncludedDates(
            sortedAppointments,
            startDate,
          );

          setAvailableAppointments(sortedAppointments);
          setIncludedDates(allIncludedDates);

          if (!firstAvailableDate) {
            setFirstAvailableAppointment(sortedAppointments);
          }
        } else if (!firstAvailableDate) {
          // The original fetched month does not have any appointments
          // so we need to manually fetch the next month to find the first available appointment
          manuallyFetchNextMonth();
        }
      },
    },
  );

  const [
    doGetCareProvider,
    { data: careProviderData, loading: careProviderLoading },
  ] = useLazyQuery(getCareProvider);

  const [
    bookAppointmentMutation,
    { loading: isBookingAppointment, reset: resetBookAppointment },
  ] = useMutation(createAppointment, {
    onError: () => {
      TRACK_EVENT.ERROR_MESSAGE_VIEWED(
        window.location.pathname,
        "CN appointment booking failed",
        {
          appointment_time_utc: selectedTimeSlot.timestamp,
          message: t("errorBooking"),
        },
      );
      bookingErrorToast();
    },
    onCompleted: ({ createAppointment }) => {
      const appointmentId = createAppointment?.appointment.id;

      if (createAppointment.success) {
        TRACK_EVENT.BUTTON_CLICKED(
          window.location.pathname,
          "CN appointment successfully scheduled",
          {
            appointment_time_utc: selectedTimeSlot.timestamp,
          },
        );
        setBookedAppointment(appointmentId);
      }
    },
  });

  useEffect(() => {
    if (isAvailableAppointmentsLoading && previousAppointmentData) {
      setIncludedDates([]);
    }
  }, [isAvailableAppointmentsLoading, previousAppointmentData]);

  const setSelectedDateAndTimeSlots = (
    selected: Date,
    allAppointments: AvailableCnAppointmentTimes[],
  ) => {
    const selectedDayOfMonth = selected.getDate();
    const selectedDayAppts = allAppointments.find(
      (appt) => appt.day_of_the_month === selectedDayOfMonth,
    );
    const timeSlots = getTimeSlotsForDay(selectedDayAppts);

    setSelectedDate(selected);
    setSelectedDateTimeSlots(timeSlots);
    setSelectedDayString(formatSelectedDay(selected));
  };

  const manuallyFetchNextMonth = () => {
    setIsManuallyFetchingNextMonth(true);

    const startOfNextMonth = getStartOfNextMonth(startDate);

    handleNewMonth(startOfNextMonth);
  };

  const setFirstAvailableAppointment = (
    allAppointments: AvailableCnAppointmentTimes[],
  ) => {
    const firstAppointmentDay = findFirstAvailableDate(allAppointments);
    const firstAppointmentDate = new Date(firstAppointmentDay);

    setFirstAvailableDate(firstAppointmentDate);
    setSelectedDateAndTimeSlots(firstAppointmentDate, allAppointments);
    setIsManuallyFetchingNextMonth(false);
  };

  const handleSelectedDay = useCallback(
    (selectedDay: Date) => {
      setSelectedTimeSlot(null);
      setSelectedDateAndTimeSlots(selectedDay, availableAppointments);
    },
    [availableAppointments],
  );

  const handleNewMonth = useCallback(
    (startOfNewMonth: Date) => {
      const maxDate = new Date();
      maxDate.setDate(maxDate.getDate() + 60);

      if (startOfNewMonth < new Date()) {
        setStartDate(today);
      } else if (startOfNewMonth <= maxDate) {
        setStartDate(startOfNewMonth.toISOString());
      }
    },
    [today],
  );

  const handleNextClick = useCallback(() => {
    TRACK_EVENT.BUTTON_CLICKED(window.location.pathname, "Next button clicked");
    setShouldShowReviewScreen(true);
    doGetCareProvider({
      variables: {
        id: selectedTimeSlot?.payload?.careProviderId,
      },
    });
  }, [doGetCareProvider, selectedTimeSlot?.payload?.careProviderId]);

  const handleBackToCalendar = useCallback(() => {
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Back to availability clicked",
    );

    if (!isSameMonth(selectedTimeSlot?.timestamp, startDate)) {
      const startOfSelectedMonth = startOfMonth(selectedTimeSlot?.timestamp);
      handleNewMonth(startOfSelectedMonth);
    }

    resetBookAppointment();
    setShouldShowReviewScreen(false);
  }, [
    handleNewMonth,
    resetBookAppointment,
    selectedTimeSlot?.timestamp,
    startDate,
  ]);

  const handleBookAppointment = useCallback(() => {
    resetBookAppointment();
    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Book appointment button clicked",
      {
        provider_id: selectedTimeSlot?.payload?.careProviderId,
        start_at: selectedTimeSlot?.timestamp,
      },
    );
    const {
      timestamp: start_at,
      payload: { careProviderUserId },
    } = selectedTimeSlot;

    const provider = {
      user_id: careProviderUserId,
      role: "Care Navigator",
    };
    const user = {
      user_id: Meowth.getUserId(),
      role: "Member",
    };
    const payload = {
      variables: {
        input: {
          put: {
            start_at,
            time_zone: DateTime.local().zoneName,
            kind: RequestableAppointmentKind.CareNavigation,
            bookings: [user, provider],
            campaign: getIterableCampaignInfo(),
          },
        },
      },
    };
    bookAppointmentMutation(payload);
  }, [bookAppointmentMutation, resetBookAppointment, selectedTimeSlot]);

  return {
    bookedAppointment,
    careProviderData,
    careProviderLoading,
    firstAvailableDate,
    handleBackToCalendar,
    handleBookAppointment,
    handleNextClick,
    handleNewMonth,
    handleSelectedDay,
    handleSelectedTimeSlot: setSelectedTimeSlot,
    includedDates,
    isAvailableAppointmentsLoading,
    isBookingAppointment,
    isLoadingInitialAppointments:
      isAvailableAppointmentsLoading && !previousAppointmentData,
    isManuallyFetchingNextMonth,
    isMemberDataLoading,
    member,
    selectedDateTimeSlots,
    selectedDayString,
    selectedTimeSlot,
    shouldShowReviewScreen,
    startDate,
    selectedDate,
  };
};
