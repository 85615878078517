import {
  Box,
  Heading,
  SimpleGrid,
  GridItem,
  useBreakpointValue,
} from "design-system/components";
import { ActiveCareSection, BenefitsSummarySection } from "components";
import { useTranslation } from "react-i18next";

export const YourCareSection = ({
  careTeam,
  momentsAreRecommended,
  hasEverCompletedARecommendedCareItem,
  memberHasInPersonSupport,
  isDirectScheduling,
  isGlobalWithNoCoachingSessions,
  memberCountry,
  member,
  memberAssessments,
  columns,
  spacing,
  hasManagedDependents,
}) => {
  const yourCareHeadingVariant = useBreakpointValue([
    "md_v1",
    "md_v1",
    "lg_v1",
    "lg_v1",
  ]);
  const activeCareHeadingVariant = useBreakpointValue([
    "sm_v1",
    "sm_v1",
    "md_v1",
    "md_v1",
  ]);
  const benefitsSummaryHeadingVariant = useBreakpointValue([
    "sm_v1",
    "sm_v1",
    "md_v1",
    "md_v1",
  ]);
  const { t } = useTranslation(["homepage"]);

  return (
    <section>
      <Box pt={[56, 68, 72, 78]}>
        <Heading as="h2" variant={yourCareHeadingVariant} pb={24}>
          {t("yourCareHeading")}
        </Heading>
        <SimpleGrid columns={columns} spacing={spacing}>
          <GridItem colSpan={[4, 4, 8, 8]}>
            <Heading as="h3" variant={activeCareHeadingVariant} pb="24">
              {t("activeCare.activeCare")}
            </Heading>
            <ActiveCareSection
              memberHasInPersonSupport={memberHasInPersonSupport}
              memberCountry={memberCountry}
              memberNextUpcomingAppointments={
                member?.first_upcoming_appointments
              }
              careTeam={careTeam}
              includeMoments={!momentsAreRecommended}
              hasEverCompletedARecommendedCareItem={
                hasEverCompletedARecommendedCareItem
              }
              isDirectScheduling={isDirectScheduling}
              isGlobalWithNoCoachingSessions={isGlobalWithNoCoachingSessions}
              hasManagedDependents={hasManagedDependents}
            />
          </GridItem>
          <GridItem colSpan={[4, 4, 8, 4]} w="100%">
            <Box>
              <BenefitsSummarySection
                member={member}
                memberAssessments={memberAssessments}
              >
                <Heading
                  as="h3"
                  fontSize={[19, 24]}
                  fontWeight="600"
                  variant={benefitsSummaryHeadingVariant}
                  pb="24"
                >
                  {t("benefitsSummaryHeading")}
                </Heading>
              </BenefitsSummarySection>
            </Box>
          </GridItem>
        </SimpleGrid>
      </Box>
    </section>
  );
};

export default YourCareSection;
