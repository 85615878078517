import { useEffect, useRef } from "react";
import Router from "next/router";
import { useTranslation } from "react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

import { Box, Flex, Heading, Text, useMediaQuery } from "design-system";
import { Link, useDisclosure } from "@springcare/sh-component-library";
import { SHRecommendationsCard } from "design-system/components";

import BrowseHeader from "./components/HeaderSection/BrowseHeader";
import FilterSection from "./components/HeaderSection/FilterSection";
import SelectedFilterChips from "./components/HeaderSection/SelectedFilterChips";
import ActiveAddress from "./components/HeaderSection/ActiveAddress";
import TravelDisclaimer from "./components/HeaderSection/TravelDisclaimer";
import TimeZone from "./components/HeaderSection/TimeZone";
import CoachingBanner from "./components/HeaderSection/CoachingBanner";
import MinorFilterBanner from "./components/HeaderSection/MinorFilterBanner";
import { GenAIDisclosureModal } from "components/modals";

import ProvidersList from "./components/BodySection/ProvidersList";
import FindProviderCard from "components/templates/Browse/Listing/FindProviderCard";
import NoProvidersFound from "./components/BodySection/NoProvidersFound";

import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";
import { isMinor } from "utils/memberHelpers";
import { ProviderRole } from "@spring/constants";
import { isCNSchedulingOrNoScheduling } from "utils/global";
import {
  isBrecsVariant,
  BrecsVariants,
} from "components/templates/Browse/ProviderBrowsePage/utils";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import {
  trackLearnMoreModalOpened,
  trackLearnMoreModalClosed,
} from "components/organisms/ProviderCardGenAI/analytics";

interface ProviderBrowsePageProps {
  shouldShowCoaching?: boolean;
  qualifiesForCoachingViaCN?: boolean;
}

const ProviderBrowsePage = ({
  shouldShowCoaching,
  qualifiesForCoachingViaCN,
}: ProviderBrowsePageProps) => {
  const { t } = useTranslation(["careProvider", "common"]);
  const {
    isMobile,
    memberInfo,
    possibleFilters,
    addressUpdated,
    setAddressUpdated,
    queryRequestId,
    providerType,
    queriedFilters,
    paging,
    isCompanyExclusive,
  } = useProviderBrowseContext();
  const [isTablet] = useMediaQuery("(min-width: 540px) and (max-width: 960px)");
  const pageRefs = useRef({});
  const needsTravelDisclaimer = useFeatureFlag(FLAGS.TRAVEL_DISCLAIMER);
  const showLobBanner = useFeatureFlag(FLAGS.LOB_COACHING_PATHWAYS_CTA);
  const brecsVariantFlag = useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT);
  const isBrecsFlag = isBrecsVariant(brecsVariantFlag);
  const isMoreTherapistsButton =
    brecsVariantFlag === BrecsVariants.MoreTherapistsButton;
  const isAMinor = isMinor(memberInfo?.user?.member);
  const showBrecs =
    isBrecsFlag && providerType === ProviderRole.Therapist && !isAMinor;
  // areFiltersEmpty used to hide BrecsSection when filters are applied
  const areFiltersEmpty = Object.values(queriedFilters.filters).every(
    (filterArray) => filterArray.length === 0,
  );
  const showDedicatedProviderToggle = possibleFilters?.allow_company_exclusive;
  const country = memberInfo?.user?.member?.postal_address?.country;
  const isGlobal =
    country &&
    country.length > 0 &&
    !(country === "US" || country === "United States");

  const schedulingAccessName =
    memberInfo?.user?.member?.cohort?.contract_term?.scheduling_access?.name;
  let validSchedulingAccess: boolean;
  if (providerType === ProviderRole.Coach) {
    validSchedulingAccess = !qualifiesForCoachingViaCN && shouldShowCoaching;
  } else {
    validSchedulingAccess = !isCNSchedulingOrNoScheduling(
      schedulingAccessName,
      providerType,
    );
  }

  const {
    isOpen: isGenAIModalOpen,
    onOpen: onGenAIDisclosureOpen,
    onClose: onGenAIDisclosureClose,
  } = useDisclosure();

  function openGenAiModal() {
    trackLearnMoreModalOpened();
    onGenAIDisclosureOpen();
  }

  function closeAIDisclosureModal() {
    trackLearnMoreModalClosed();
    onGenAIDisclosureClose();
  }

  const scrollToSection = () => {
    pageRefs.current["findProvider"]?.scrollIntoView({ behavior: "smooth" });
  };

  const canShowRecommendationsCard =
    providerType === ProviderRole.Therapist &&
    !isAMinor &&
    !isGlobal &&
    !showBrecs;

  useEffect(() => {
    if (showLobBanner) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Coaching Banner viewed",
      );
    }
  }, [showLobBanner]);

  useEffect(() => {
    if (addressUpdated) {
      setAddressUpdated(false);
    }
  }, [addressUpdated, setAddressUpdated]);

  const providersAvailable =
    paging?.total <= 1000
      ? paging.total + " " + t("providerAmount.available")
      : t("providerAmount.amount") + " " + t("providerAmount.available");
  const heading =
    areFiltersEmpty || isMoreTherapistsButton
      ? t("browseHeader.schedule")
      : providersAvailable;

  const providerListTopMargin = () => {
    if (!showBrecs) {
      return 0;
    } else if (isTablet && showDedicatedProviderToggle) {
      return 200;
    } else if (isMobile) {
      return 100;
    } else if (areFiltersEmpty) {
      return 150;
    } else {
      return 130;
    }
  };

  return (
    //@ts-ignore
    <>
      <Box as="main" p={0}>
        {!showBrecs && <BrowseHeader providerType={providerType} />}

        {providerType === ProviderRole.Coach && showLobBanner && (
          <CoachingBanner />
        )}

        {canShowRecommendationsCard && (
          <SHRecommendationsCard
            marginTop={0}
            ariaLabel={t(
              "recommendedProviders.recommendationEntryCard.ariaLabel",
            )}
            marginBottom={40}
            onClick={() => {
              localStorage.setItem("toRecsFromBrowse", "true");
              const { to, as } = routes.RecommendedTherapists;
              Router.push(to, as, { shallow: true });

              TRACK_EVENT.BUTTON_CLICKED(
                window.location.pathname,
                "See Your Recommendations",
                {
                  location: "Browse Therapists",
                  to: routes.RecommendedTherapists.as,
                },
              );
            }}
            title={t("recommendedProviders.recommendationEntryCard.title")}
            subTitle={t(
              "recommendedProviders.recommendationEntryCard.subtitle",
            )}
            submitBtnText={t(
              "recommendedProviders.recommendationEntryCard.submitBtn",
            )}
            dataCy="recommendation-button"
            trackingText="Recommendations Button"
            TRACK_EVENT={TRACK_EVENT}
          />
        )}

        {validSchedulingAccess && (
          <>
            {!showBrecs && (
              <Flex
                mb={24}
                flexDirection={[
                  "column-reverse",
                  "column-reverse",
                  "column-reverse",
                  "row",
                ]}
              >
                <Box flex={1}>
                  <FilterSection />
                  <SelectedFilterChips />
                </Box>

                <Box flex={1}>
                  <Box
                    ml="auto"
                    w={["100%", "100%", "100%", "100%"]}
                    pl={!isMobile ? "8px" : 0}
                    maxW={["100%", "100%", "100%", "400px"]}
                  >
                    <ActiveAddress />

                    <Box mb={[16, 16, 36, 36]}>
                      {needsTravelDisclaimer && (
                        <TravelDisclaimer scrollToSection={scrollToSection} />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Flex>
            )}

            {!showBrecs && (
              <Flex
                alignItems="end"
                mb={24}
                flexDirection={[
                  "column-reverse",
                  "column-reverse",
                  "column-reverse",
                  "row",
                ]}
              >
                <Box flex={1}>
                  {isAMinor && <MinorFilterBanner memberInfo={memberInfo} />}
                </Box>
                <Box flex={1}>{!isMobile && <TimeZone />}</Box>
              </Flex>
            )}

            <Box mb={32} mt={providerListTopMargin()}>
              {showBrecs && providersAvailable && (
                <>
                  <Heading
                    size={
                      isMobile || (!areFiltersEmpty && !isMoreTherapistsButton)
                        ? "heading-small"
                        : "heading-large"
                    }
                    mb={areFiltersEmpty || isMoreTherapistsButton ? 8 : 32}
                  >
                    {heading}
                  </Heading>
                  {(areFiltersEmpty || isMoreTherapistsButton) && (
                    <Flex
                      flexDirection={isMobile ? "column" : "row"}
                      mb={24}
                      wrap="wrap"
                    >
                      <Text color="content-primary" size="body-medium">
                        {t("recommendedProviders.therapistRecsSubtitleGenAi")}
                      </Text>
                      <Link
                        ml={isMobile || isTablet ? 0 : 4}
                        onClick={() => openGenAiModal()}
                      >
                        {t("recommendedProviders.therapistRecsLearnMoreGenAi")}
                      </Link>
                    </Flex>
                  )}
                </>
              )}
              <ProvidersList />
            </Box>
          </>
        )}

        {!validSchedulingAccess && (
          <Box mt={32}>
            <NoProvidersFound isCoach={providerType === ProviderRole.Coach} />
          </Box>
        )}

        {possibleFilters && validSchedulingAccess && (
          <Box
            ref={(el) =>
              (pageRefs.current = { ...pageRefs.current, findProvider: el })
            }
          >
            <FindProviderCard
              // @ts-ignore
              providerType={providerType}
              filteredProviderTags={possibleFilters}
              isMinor={isAMinor}
              queryRequestId={queryRequestId}
            />
          </Box>
        )}
      </Box>
      {showBrecs && (
        <GenAIDisclosureModal
          isOpen={isGenAIModalOpen}
          onClose={() => closeAIDisclosureModal()}
        />
      )}
    </>
  );
};

export default ProviderBrowsePage;
