import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

import { cleanDeviceLabel } from "components/templates/SHSessionRoom/utils/utils";

export enum SessionViewStatus {
  TooEarly = "session_too_early",
  TooLate = "session_too_late",
  NotStarted = "not_started",
  Connecting = "connecting",
  MemberIsWaiting = "member_is_waiting",
  ProviderNoShow = "provider_no_show",
  InProgress = "in_progress",
  Ended = "ended",
}

type SessionViewStatusType = {
  sessionViewStatus: SessionViewStatus;
  setSessionViewStatus: Dispatch<SetStateAction<SessionViewStatus>>;
  SessionViewStatus: typeof SessionViewStatus;
};

// NOTE: This applies to isConnected+shouldConnect props for <LiveKitRoom>
type SessionConnectionStatusType = {
  shouldConnect: boolean;
  setShouldConnect: Dispatch<SetStateAction<boolean>>;
  isConnected: boolean;
  setIsConnected: Dispatch<SetStateAction<boolean>>;
  skipSetStatus: boolean;
  setSkipSetStatus: Dispatch<SetStateAction<boolean>>;
  handleDisconnect: () => void;
};

// NOTE: This applies to user media devices state+logic
type SessionDevicesType = {
  stream: MediaStream | null;
  isCameraEnabled: boolean;
  isMicrophoneEnabled: boolean;
  devices: {
    audioinput: MediaDeviceInfo[];
    videoinput: MediaDeviceInfo[];
    audiooutput: MediaDeviceInfo[];
  };
  selectedVideoDevice: { deviceId: string; label: string };
  selectedAudioInputDevice: { deviceId: string; label: string };
  selectedAudioOutputDevice: { deviceId: string; label: string };
  getDevices: () => Promise<void>;
};

type SessionRoomDrawerType = {
  isSessionRoomDrawerOpen: boolean;
  setIsSessionRoomDrawerOpen: Dispatch<SetStateAction<boolean>>;
  onSessionRoomDrawerClose: () => void;
  activeDrawerTab: string;
  setActiveDrawerTab: Dispatch<SetStateAction<string>>;
  drawerWidth: number;
  setDrawerWidth: Dispatch<SetStateAction<number>>;
};

interface SessionRoomContextType
  extends SessionViewStatusType,
    SessionRoomDrawerType,
    SessionConnectionStatusType {
  sessionDevices: SessionDevicesType;
  setSessionDevices: Dispatch<SetStateAction<SessionDevicesType>>;
  getDevices: () => Promise<void>;
}

// Create the context
const SessionRoomContext = createContext<SessionRoomContextType | undefined>(
  undefined,
);

// Create the context provider component
export const SessionRoomProvider = ({ children }: { children: ReactNode }) => {
  const [sessionViewStatus, setSessionViewStatus] = useState(
    SessionViewStatus.NotStarted,
  );

  // state+logic for livekit connect/disconnect
  const [shouldConnect, setShouldConnect] = useState(false);
  const [isConnected, setIsConnected] = useState(true);
  const [skipSetStatus, setSkipSetStatus] = useState(false);
  const handleDisconnect = () => {
    setShouldConnect(false);
    setIsConnected(false);
    // skipSetStatus is needed to avoid a collision when leaving the page
    // when handleDisconnect is called, that by default will set a different view
    // we don't want the view to change if we have to leave the page so we use skipSetStatus
    if (!skipSetStatus) {
      setSessionViewStatus(SessionViewStatus.Ended);
    }
  };

  // state+logic for device selection
  const [sessionDevices, setSessionDevices] = useState<SessionDevicesType>({
    stream: null,
    isCameraEnabled: false,
    isMicrophoneEnabled: false,
    devices: { audioinput: [], videoinput: [], audiooutput: [] },
    selectedVideoDevice: { deviceId: "", label: "" },
    selectedAudioInputDevice: { deviceId: "", label: "" },
    selectedAudioOutputDevice: { deviceId: "", label: "" },
    getDevices: () => Promise.resolve(),
  });

  const getDevices = async () => {
    try {
      const unfilteredDevicesInfo =
        await navigator.mediaDevices.enumerateDevices();
      const devicesInfo = unfilteredDevicesInfo.filter(
        (device) => !device.label.includes("Zoom"),
      );

      const audioinput = devicesInfo
        .filter((device) => device.kind === "audioinput")
        .map((device) => ({
          ...device,
          label: cleanDeviceLabel(device.label),
          deviceId: device.deviceId,
        }));
      const videoinput = devicesInfo
        .filter((device) => device.kind === "videoinput")
        .map((device) => ({
          ...device,
          label: cleanDeviceLabel(device.label),
          deviceId: device.deviceId,
        }));

      const audiooutput = devicesInfo
        .filter((device) => device.kind === "audiooutput")
        .map((device) => ({
          ...device,
          label: cleanDeviceLabel(device.label),
          deviceId: device.deviceId,
        }));

      setSessionDevices((prevState) => ({
        ...prevState,
        devices: { audioinput, videoinput, audiooutput },
        selectedAudioInputDevice:
          audioinput.length > 0
            ? {
                deviceId: audioinput[0].deviceId,
                label: audioinput[0].label,
              }
            : prevState.selectedAudioInputDevice,
        selectedVideoDevice:
          videoinput.length > 0
            ? {
                deviceId: videoinput[0].deviceId,
                label: videoinput[0].label,
              }
            : prevState.selectedVideoDevice,
        selectedAudioOutputDevice:
          audiooutput.length > 0
            ? {
                deviceId: audiooutput[0].deviceId,
                label: audiooutput[0].label,
              }
            : prevState.selectedAudioOutputDevice,
      }));
    } catch (error) {
      // TODO: handle the error appropriately
    }
  };
  // state+logic for session room drawer
  const [isSessionRoomDrawerOpen, setIsSessionRoomDrawerOpen] = useState(false);
  const onSessionRoomDrawerClose = () => {
    setIsSessionRoomDrawerOpen(false);
  };
  const [drawerWidth, setDrawerWidth] = useState(376);
  const [activeDrawerTab, setActiveDrawerTab] = useState("participants");

  useEffect(() => {
    const handleDeviceChange = () => {
      getDevices();
    };
    if (sessionDevices.stream) {
      navigator.mediaDevices.addEventListener(
        "devicechange",
        handleDeviceChange,
      );
      getDevices();
    }

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        handleDeviceChange,
      );
    };
  }, [sessionDevices.stream]);

  const sessionRoomContext: SessionRoomContextType = {
    sessionViewStatus,
    setSessionViewStatus,
    SessionViewStatus,
    shouldConnect,
    setShouldConnect,
    isConnected,
    setIsConnected,
    skipSetStatus,
    setSkipSetStatus,
    handleDisconnect,
    sessionDevices,
    setSessionDevices,
    getDevices,
    isSessionRoomDrawerOpen,
    setIsSessionRoomDrawerOpen,
    onSessionRoomDrawerClose,
    activeDrawerTab,
    setActiveDrawerTab,
    drawerWidth,
    setDrawerWidth,
  };

  return (
    <SessionRoomContext.Provider value={sessionRoomContext}>
      {children}
    </SessionRoomContext.Provider>
  );
};

export const useSessionRoom = (): SessionRoomContextType => {
  const context = useContext(SessionRoomContext);
  if (context === undefined) {
    throw new Error("useSessionRoom must be used within a SessionRoomProvider");
  }
  return context;
};

// Custom hook for Session View Status
export const useSessionViewStatus = (): SessionViewStatusType => {
  const { sessionViewStatus, setSessionViewStatus } = useSessionRoom();
  return { sessionViewStatus, setSessionViewStatus, SessionViewStatus };
};

// Custom hook for Session Connect Status
export const useSessionConnectStatus = (): SessionConnectionStatusType => {
  const {
    shouldConnect,
    setShouldConnect,
    isConnected,
    setIsConnected,
    skipSetStatus,
    setSkipSetStatus,
    handleDisconnect,
  } = useSessionRoom();
  return {
    shouldConnect,
    setShouldConnect,
    isConnected,
    setIsConnected,
    skipSetStatus,
    setSkipSetStatus,
    handleDisconnect,
  };
};

// Custom hook for Session Devices state+logic
export const useSessionDevices = (): {
  sessionDevices: SessionDevicesType;
  setSessionDevices: Dispatch<SetStateAction<SessionDevicesType>>;
  getDevices: () => Promise<void>;
} => {
  const { sessionDevices, setSessionDevices, getDevices } = useSessionRoom();
  return { sessionDevices, setSessionDevices, getDevices };
};

// Custom hook for Session Room Drawer state+logic
export const useSessionRoomDrawer = (): SessionRoomDrawerType => {
  const {
    isSessionRoomDrawerOpen,
    setIsSessionRoomDrawerOpen,
    onSessionRoomDrawerClose,
    activeDrawerTab,
    setActiveDrawerTab,
    drawerWidth,
    setDrawerWidth,
  } = useSessionRoom();
  return {
    isSessionRoomDrawerOpen,
    setIsSessionRoomDrawerOpen,
    onSessionRoomDrawerClose,
    activeDrawerTab,
    setActiveDrawerTab,
    drawerWidth,
    setDrawerWidth,
  };
};
