import Image from "next/image";
import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from "@springcare/sh-component-library";
import {
  SHFlyout,
  SHHeaderMaintenance,
  useDisclosure,
} from "design-system/components";
import { HelpingHand } from "design-system/assets";
import { MemberHelpCard, ResponsiveTermsAndPolicyFooter } from "components";
import { useTranslation } from "react-i18next";

export const Maintenance = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation("limitedLangMemberHelpCard");

  return (
    <>
      <Box as="main" p={0} maxW="100vw">
        <SHHeaderMaintenance onOpen={onOpen} />

        <SimpleGrid columns={[1, 1, 2, 2]}>
          {/* Left pane */}
          <Box as="section">
            <Flex
              align="center"
              justify="center"
              direction="column"
              bg={["background-base", "background-base", "accent-on-base"]}
              minH={["none", "none", "calc(100vh - 95px)"]}
            >
              <Box w={["80%", "80%", "55%"]}>
                {/* Height takes into account SHHeader (95) and TermsAndPolicyFooter (47) */}
                <Flex
                  align="center"
                  justify="center"
                  direction="column"
                  height={["auto", "auto", "calc(100vh - 150px)"]}
                  tabIndex={0}
                >
                  <Image
                    priority
                    src="/static/mindfulness.svg"
                    alt="Mindfulness"
                    width="360"
                    height="360"
                  />
                  <ResponsiveTermsAndPolicyFooter noBackground={true} />
                </Flex>
              </Box>
            </Flex>
          </Box>

          {/* Right pane */}
          <Box as="section" mt={[24, 24, 0]} mb={["176px", "176px", 0]}>
            <Flex
              align="center"
              justify="center"
              direction="column"
              height={["auto", "auto", "calc(100vh - 150px)"]}
              tabIndex={0}
            >
              <Box w={["80%", "80%", "55%"]}>
                <Box mb={48}>
                  <Heading
                    color="content-primary"
                    size="heading-medium"
                    mb={16}
                  >
                    Give us a moment. We're improving Spring Health.
                  </Heading>
                  <Text color="content-primary" size="body-medium-regular">
                    Spring Health is currently unavailable due to scheduled
                    maintenance to improve your experience. We expect our site
                    to be back at approximately 5 am EST. Thank you for your
                    patience.
                  </Text>
                </Box>
                <Box mb={48}>
                  <Text
                    color="content-primary"
                    size="body-medium-strong"
                    mb={16}
                  >
                    Help and support is available 24/7
                  </Text>
                  <Button
                    icon={HelpingHand}
                    w="100%"
                    size="md"
                    onClick={onOpen}
                    variant="low-emphasis"
                  >
                    <Text size="body-medium-strong">Get help</Text>
                  </Button>
                </Box>
                <Box>
                  <Text
                    color="content-primary"
                    size="body-medium-strong"
                    mb={16}
                  >
                    Join your appointment
                  </Text>
                  <Text color="content-primary" size="body-medium-regular">
                    If you have a scheduled appointment during this time, please
                    use the Zoom link in your appointment confirmation email.
                  </Text>
                </Box>
              </Box>
            </Flex>
          </Box>
        </SimpleGrid>
      </Box>
      <SHFlyout
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        size="md"
        headerText={t("footerHeader")}
      >
        <MemberHelpCard closeFlyout={onClose} />
      </SHFlyout>
    </>
  );
};
