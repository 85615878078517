// 

import React from 'react';

const SpringCommunity = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 69 70">
		<defs>
			<path id="a" d="M.09894459.21045652H21.00435V20.9821728H.09894459z"/>
		</defs>
		<g fill="none" fillRule="evenodd">
			<path fill="#010202" d="M57.00472959 12.4727557C44.51558985-.01638402 24.25936762-.08118753 11.85024309 12.327937-.55888136 24.73706144-.49407792 44.99328374 11.99506182 57.48242348c12.48913973 12.48913974 32.74552453 12.55410567 45.15464898.14498121 12.40912453-12.40912452 12.34415853-32.66550924-.14498121-45.15464898M10.38101373 59.09647157C-3.00367675 45.71178109-3.07330203 24.0026131 10.22580859 10.70350248c13.29927205-13.29927205 35.00827863-13.22948536 48.3929691.15520512 13.38469049 13.38469047 13.45463966 35.09385955.15536761 48.3931316-13.29911061 13.2991106-35.0084411 13.22932285-48.39313157-.15536763"/>
			<path fill="#2D9F97" d="M63.26087974 19.37452504c-3.61014137 3.61014138-9.4823194 3.59147009-13.11585448-.042065-3.6333726-3.6333726-3.65220632-9.50571304-.04206494-13.11585442 3.61014135-3.61014135 9.48248183-3.59130767 13.11585443.04206493 3.63353508 3.63353509 3.65220634 9.50571314.042065 13.1158545"/>
			<g transform="rotate(45 30.96204014 67.38009896)">
				<path fill="#010202" d="M10.5516473 2.49304239c-4.49695946 0-8.15540541 3.63505435-8.15540541 8.10326091 0 4.4682065 3.65844595 8.1032608 8.15540541 8.1032608 4.4969595 0 8.1554054-3.6350543 8.1554054-8.1032608 0-4.46820656-3.6584459-8.10326091-8.1554054-8.10326091m0 18.48913041c-5.76368919 0-10.4527027-4.6590326-10.4527027-10.3858695 0-5.726837 4.68901351-10.3858696 10.4527027-10.3858696 5.7636892 0 10.4527027 4.6590326 10.4527027 10.3858696 0 5.7268369-4.6890135 10.3858695-10.4527027 10.3858695"/>
			</g>
			<path fill="#2D9F97" d="M18.86195163 63.77345316c-3.61014138 3.61014138-9.4823194 3.59147009-13.1158545-.042065-3.63337259-3.6333726-3.6522063-9.50571304-.04206493-13.11585442 3.61014138-3.61014138 9.48248183-3.59130766 13.11585442.04206493 3.63353509 3.6335351 3.65220638 9.50571311.042065 13.1158545"/>
			<path fill="#010202" d="M18.01286261 51.46462268c-3.17983049-3.17983048-8.33711408-3.19637084-11.4966132-.03687172-3.1594991 3.15949911-3.14295875 8.3167827.03687174 11.49661319 3.17983055 3.17983056 8.33711407 3.19637084 11.4966132.03687173 3.1594991-3.15949912 3.14295882-8.31678264-.03687174-11.4966132M4.93907306 64.53841224C.86352934 60.46286852.84232964 53.85280174 4.89181492 49.80331646c4.0494852-4.04948521 10.65955206-4.02828558 14.73509578.04725813 4.07554372 4.07554372 4.09674335 10.68561058.04725814 14.73509579-4.04948528 4.04948528-10.65955207 4.02828558-14.73509578-.04725814"/>
			<path fill="#2D9F97" d="M18.6698416 18.66635541c-3.61014138 3.61014138-9.48248182 3.59130766-13.11585449-.042065-3.63337263-3.63337264-3.65220634-9.50571308-.04206497-13.11585446 3.61030275-3.61030274 9.48248182-3.59130766 13.11585446.04206497 3.63337266 3.63337267 3.65236774 9.50555175.042065 13.1158545"/>
			<path fill="#010202" d="M17.82075252 6.357525C14.64092199 3.17769447 9.4836385 3.1611541 6.32413939 6.3206532c-3.15949911 3.15949912-3.1429588 8.31678267.03687173 11.4966132 3.17983056 3.17983055 8.33711408 3.19637084 11.4966132.03687173 3.15949911-3.15949912 3.14295875-8.31678257-.0368718-11.49661313M4.74696303 19.4313145C.67141933 15.35577078.65021962 8.74570399 4.6997049 4.6962187 8.7491901.6467335 15.3592569.6679332 19.4348006 4.74347691c4.07554373 4.07554372 4.09674342 10.6856105.04725822 14.73509572-4.04948528 4.04948527-10.65955207 4.02828558-14.73509579-.04725814"/>
			<path fill="#2D9F97" d="M63.8706899 63.86720371c-3.61014137 3.61014138-9.48248182 3.59130766-13.11585448-.042065-3.63337267-3.63337267-3.65220632-9.50571304-.04206494-13.11585442 3.61030274-3.61030274 9.48248176-3.59130773 13.11585442.04206494 3.63337267 3.63337266 3.65236775 9.50555174.042065 13.11585448"/>
			<path fill="#010202" d="M63.02160082 51.5583733c-3.17983056-3.17983055-8.337114-3.19637091-11.49661312-.0368718-3.15949912 3.15949912-3.14295883 8.31678264.03687173 11.4966132 3.17983055 3.17983056 8.33711407 3.19637084 11.49661319.03687173 3.15949912-3.15949912 3.14295876-8.31678257-.0368718-11.49661312M49.94781134 64.63216279c-4.07554372-4.07554372-4.09674342-10.6856105-.04725814-14.73509578 4.0494852-4.04948521 10.659552-4.02828551 14.73509571.0472582 4.07554372 4.07554372 4.09674342 10.68561051.0472582 14.73509572-4.04948527 4.04948528-10.65955206 4.02828558-14.73509577-.04725814"/>
		</g>
	</svg>
);

export default SpringCommunity;
