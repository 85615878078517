import {
  WateringCan,
  Check,
  PlantLeaves,
  InfoIcon,
} from "design-system/assets";
import {
  Flex,
  Box,
  Tooltip,
  Text,
  Heading,
  Button,
} from "design-system/components";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { InNetworkStatusDisclaimer } from "components/molecules";
import { money_format } from "modules/MemberDashboard/Billing/utils";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import useHighmarkExperience from "hooks/useHighmarkExperience";

const BenefitsCardSummary = ({
  type,
  visits_covered,
  visits_remaining,
  visits_covered_specialist,
  visits_remaining_specialist,
  showGlobalExperience,
  inNetwork,
  internationalCohort,
  customerName,
  virtualSessionCostTerm,
  isATeen = false,
  memberCostOfCoaching,
}) => {
  const showHighmarkCopy = useFeatureFlag(
    FLAGS.HIGHMARK_COST_TRANSPARENCY_COPY,
  );
  const { showHighmarkBanners } = useHighmarkExperience();

  const hasVisitsRemaining = visits_remaining > 0;
  const halfCoveredVisits = Math.ceil(visits_covered / 2);
  const { t } = useTranslation("careProvider");
  const isMedManagement = visits_covered_specialist > 0;
  const cardTitle =
    type === "therapist"
      ? getCardTitleForTherapist()
      : t("benefitsSummary.coaching");
  const isPaidMemberWithNoSession = visits_covered === 0;

  const {
    enable_show: isCostTransparencyEnabled,
    percentage_covered: costTransparencyPercentageCovered,
  } = virtualSessionCostTerm || {};
  const partialCostCovered =
    isCostTransparencyEnabled &&
    costTransparencyPercentageCovered > 0 &&
    costTransparencyPercentageCovered < 100;
  const totalPercentageCovered =
    isCostTransparencyEnabled && costTransparencyPercentageCovered === 100;
  const showAtCostTag =
    (hasVisitsRemaining || isPaidMemberWithNoSession) &&
    !isCostTransparencyEnabled;
  const showTooltip = type === "therapist" && !isCostTransparencyEnabled;
  const noCostCoaching = memberCostOfCoaching == 0;

  const getAtCostTagVerbiage = () => {
    if (
      hasVisitsRemaining ||
      totalPercentageCovered ||
      (type === "coaching" && noCostCoaching)
    ) {
      return t("benefitsSummary.atNoCost");
    }

    if (partialCostCovered) {
      return t("benefitsSummary.partialCost");
    }

    return t("benefitsSummary.atCost");
  };

  function getCardTitleForTherapist() {
    return isATeen
      ? t("benefitsSummary.therapyAndMedicationTeen")
      : isMedManagement
        ? t("benefitsSummary.therapyAndMedication")
        : t("benefitsSummary.therapyAndMedicationGlobal");
  }

  function renderSessionAvailabilityVerbiage() {
    if (type === "therapist") {
      if (showGlobalExperience) {
        return t("benefitsSummary.additionalSessionTherapyGlobal");
      }
      return (
        <Box lineHeight="24px">
          <InNetworkStatusDisclaimer
            showCTA={!showHighmarkBanners}
            inNetwork={inNetwork}
            hasFreeSessions={!!visits_covered}
            telemetryProps={{ location: "Benefit Summary" }}
            internationalCohort={internationalCohort}
            customerName={customerName}
            virtualSessionCostTerm={virtualSessionCostTerm}
            inNetworkDisclaimerConfig={{
              inNetworkFreeSessions: t(
                "benefitsSummary.additionalInNetworkSessions",
                { customerName: customerName },
              ),
              inNetworkNoFreeSessions: t("benefitsSummary.inNetworkSessions", {
                customerName: customerName,
              }),
              notInNetworkFreeSessions: t(
                "benefitsSummary.additionalSessionTherapy",
              ),
              notInNetworkNoFreeSessions: t("benefitsSummary.sessionTherapy"),
              default: t("benefitsSummary.additionalSessionTherapy"),
            }}
          />
        </Box>
      );
    }

    if (showGlobalExperience && type === "coaching") {
      return t("benefitsSummary.additionalSessionCoachingGlobal");
    }

    if (isPaidMemberWithNoSession && memberCostOfCoaching > 0) {
      return t("benefitsSummary.sessionsAreAvailableAt", {
        SessionCost: money_format(memberCostOfCoaching),
      });
    }

    if (noCostCoaching) {
      if (showHighmarkCopy)
        return t("benefitsSummary.additionalNoCostSessionCoach");
      return t("benefitsSummary.additionalSessionCoach", {
        SessionCost: "no cost",
      });
    }

    return t("benefitsSummary.additionalSessionCoach", {
      SessionCost: money_format(memberCostOfCoaching),
    });
  }

  const tooltipLabel =
    isATeen || visits_remaining_specialist == 0
      ? t("benefitsSummary.yourTherapySessionsLeft", {
          remainingSessions: visits_remaining,
        })
      : t("benefitsSummary.yourSessionsLeft", {
          remainingSessions: visits_remaining,
          medicationSession: visits_remaining_specialist,
        });

  return (
    <Box>
      <Box
        width="100%"
        bg="tertiary.25"
        borderRadius={8}
        paddingBlock="36px 24px"
        paddingInline="16px 16px"
        color="grey.700"
        padding={["36px 8px 24px 8px", "24px 20px 24px 24px"]}
      >
        <Flex justifyContent="space-between" mb={5} width="95%">
          <Box>
            <Heading as={"h4"} variant="sm_v1" color="platform.900">
              {showAtCostTag && (
                <Flex
                  width={71}
                  height={24}
                  textAlign="center"
                  borderRadius={4}
                  alignItems="center"
                  justifyContent="center"
                  bg="primary.light"
                  as="span"
                >
                  <Text as="span" variant="caption_v1" color="primary.base">
                    {getAtCostTagVerbiage()}
                  </Text>
                </Flex>
              )}
              <Flex as="span" whiteSpace="nowrap">
                <Heading
                  as="span"
                  variant="sm_v1"
                  color="platform.900"
                  mt="8px"
                >
                  {cardTitle}
                </Heading>
                {showTooltip && (
                  <Flex as="span" ms="4px" alignItems="center" mt="8px">
                    <Tooltip
                      hasArrow
                      // @ts-ignore
                      arrowPadding="100px"
                      label={tooltipLabel}
                      aria-label={tooltipLabel}
                      placement="top"
                      id="benefitsSummaryTooltip"
                      role="tooltip"
                    >
                      <Button h={10} w={10} p={0} m={0}>
                        <InfoIcon
                          color="platform.900"
                          width="14px"
                          height="14px"
                          data-testid={`benefits-summary-tooltip-${type}`}
                        />
                      </Button>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
            </Heading>
            {hasVisitsRemaining || isPaidMemberWithNoSession ? (
              <Text variant="body_v1"> {t("benefitsSummary.sessions")} </Text>
            ) : (
              <Text variant="body_v1" width="75%" mt="4px">
                {" "}
                {t("benefitsSummary.bookedStatusMessage", {
                  count: visits_covered,
                })}{" "}
              </Text>
            )}
          </Box>
          {(!isPaidMemberWithNoSession || type === "therapist") && (
            <Flex justifyContent="center">
              <Box pt={10}>
                <Flex
                  position="relative"
                  borderRadius={50}
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                  width={56}
                  height={56}
                  backgroundColor="secondary.100"
                >
                  {hasVisitsRemaining ? (
                    <Text fontSize={30}>{visits_remaining}</Text>
                  ) : (
                    <Box display="inline">
                      <Check width="18px" height="12px" />
                    </Box>
                  )}
                  <Flex
                    position="absolute"
                    top="-18px"
                    right="-10px"
                    width="36px"
                    height="36px"
                    borderRadius={50}
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor="tertiary.25"
                  >
                    {type === "therapist" ? (
                      <PlantLeaves
                        color="platform.900"
                        width="22px"
                        height="21px"
                        aria-hidden="true"
                      />
                    ) : (
                      <WateringCan
                        color="platform.900"
                        width="22px"
                        height="21px"
                        aria-hidden="true"
                      />
                    )}
                  </Flex>
                </Flex>
                {hasVisitsRemaining && (
                  <Flex justifyContent="center" height={20}>
                    <Text variant="caption_v1" mt="4px">
                      {t("benefitsSummary.available")}
                    </Text>
                  </Flex>
                )}
              </Box>
            </Flex>
          )}
        </Flex>

        {(visits_remaining <= halfCoveredVisits || inNetwork) && (
          <Box
            borderTop="2px solid"
            borderColor="platform.100"
            lineHeight="18px"
            pt={16}
            width="95%"
          >
            {renderSessionAvailabilityVerbiage()}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ global: { showGlobalExperience } }) => ({
  showGlobalExperience,
});

export default connect(mapStateToProps, {})(BenefitsCardSummary);
