import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  HStack,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useDisclosure,
  useMediaQuery,
} from "design-system/components";
import { Box, ActionChip, VHelpIcon } from "@springcare/sh-component-library";
import { ProfileAvatar } from "design-system/components/custom/navigation-legacy";
import { ChildSvg, AdultSvg, NotificationSvgIcon } from "components/atoms";
import { TakeCheckinButton, HelpButton } from "components/molecules";
import { useCanChangeUser } from "lib/rememberUser";
import { HelpFlyout } from "components/flyouts/HelpFlyout";
import { useFeatureFlag, FLAGS } from "utils/launchdarkly";
import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";
import Router from "next/router";
import { MessagesButton } from "./MessagesButton";

const RightLinks = ({
  isGlobalUser,
  hasChildDependents,
  isAMinor,
  isATeen,
  isAT2Member,
  firstName,
  lastName,
  showBillingNotificationBubble,
  showTeenCheckInButton = false,
}) => {
  const { t } = useTranslation(["limitedLangMemberHelpCard", "common"]);

  const memberPaymentCollectionLDFlag = useFeatureFlag(
    FLAGS.MEMBER_PAYMENT_COLLECTION_M1,
  );

  const providerMemberMessagingLDFlag = useFeatureFlag(
    FLAGS.PROVIDER_MEMBER_MESSAGING,
  );

  // Control the help flyout state
  const { isOpen, onOpen, onClose } = useDisclosure();
  const canChangeUser = useCanChangeUser();
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const links = [
    !isGlobalUser &&
      !isAT2Member && {
        alias: "MemberLearningCenter",
        linkPath: "/members/learning-center",
        linkName: "Learning Center",
        translatedName: t("common:navigation.links.learningCenter"),
        showNotificationBubble: false,
      },
    {
      alias: "MemberBenefits",
      linkPath: "/members/benefits",
      linkName: "Your Benefits",
      translatedName: t("common:navigation.links.yourBenefits"),
      showNotificationBubble: false,
    },
    {
      alias: "MemberSettings",
      linkPath: "/members/settings",
      linkName: "Settings",
      translatedName: t("common:navigation.links.settings"),
      showNotificationBubble: false,
    },
    !isGlobalUser &&
      !isAT2Member &&
      memberPaymentCollectionLDFlag && {
        alias: "MemberBilling",
        linkPath: routes.Billing.as,
        linkName: "Billing",
        translatedName: t("common:navigation.links.billing"),
        showNotificationBubble: showBillingNotificationBubble,
      },
    canChangeUser &&
      !isAT2Member && {
        alias: hasChildDependents ? "ChooseUser" : "InviteDependents",
        linkPath: isAMinor
          ? `/members/choose_user/password`
          : "/members/choose_user",
        linkName: "Change User",
        translatedName: t("common:navigation.links.manageDependents"),
      },
    {
      alias: "Logout",
      linkPath: "/logout",
      linkName: "Logout",
      translatedName: t("common:navigation.links.logout"),
      showNotificationBubble: false,
    },
  ].filter((_) => _); // remove null links

  const openHelpFlyout = () => {
    onOpen();
    TRACK_EVENT.BUTTON_CLICKED(Router.asPath, "Help Button Clicked", {});
  };

  return (
    <>
      {((showTeenCheckInButton && !isMobile) || (!isAMinor && !isMobile)) &&
        !isAT2Member && <TakeCheckinButton isATeen={isATeen} />}
      {!isGlobalUser && providerMemberMessagingLDFlag && <MessagesButton />}
      {isAT2Member ? (
        <ActionChip
          _active={{ textColor: "content-primary" }}
          _focus={{ textColor: "content-primary" }}
          _focusVisible={{
            textColor: "primary-base",
            bg: "transparent",
            boxShadow: "var(--chakra-shadows-focus-visible)",
          }}
          color="content-primary"
          onClick={openHelpFlyout}
          borderRadius="30px"
          icon={VHelpIcon}
          data-cy={"help-button"}
        >
          {t("limitedLangMemberHelpCard:helpButtonText")}
        </ActionChip>
      ) : (
        <HelpButton setOnOpen={onOpen} />
      )}

      <Menu>
        {isMobile || isAT2Member ? (
          <ProfileAvatar
            firstName={firstName}
            lastName={lastName}
            memberPaymentCollectionLDFlag={memberPaymentCollectionLDFlag}
            showBillingNotificationBubble={showBillingNotificationBubble}
          />
        ) : (
          <MenuButton
            data-cy="member-right-dropdown"
            minW="120px"
            h="53px"
            px={16}
            bg="transparent"
            p="10px 24px"
            borderRadius={50}
            ml="15px"
            border="1px #a9a9a9 solid"
            _hover={{ bg: "white" }}
            _focus={{ border: "1px #007e5e solid" }}
            _focusVisible={{ outline: "2px solid black", outlineOffset: "4px" }}
            _active={{ border: "1px #007e5e solid" }}
            aria-label={firstName}
          >
            <HStack as="span" spacing={4} p="5px">
              <Box as="span" w="17px">
                {isAMinor ? (
                  <ChildSvg />
                ) : (
                  <AdultSvg width="8px" height="8px" />
                )}
              </Box>
              <Text as="span" size="body-medium-strong">
                {firstName}
              </Text>
              {links.some((link) => link.showNotificationBubble === true) && (
                <NotificationSvgIcon />
              )}
            </HStack>
          </MenuButton>
        )}
        <MenuList maxHeight={isMobile ? "70vh" : "60vh"} overflowY="scroll">
          {links.map((data) => (
            <MenuItem
              key={data.linkName}
              data-cy={`member-right-dropdown-${data.alias}-link`}
              as="a"
              href={data.linkPath}
              mptracking={{
                pageName: "Top Navigation",
                linkName: data.linkName,
              }}
              _focus={{ bg: "none" }}
              _hover={{ bg: "tertiary.50" }}
              _focusVisible={{
                outline: "2px solid black",
                outlineOffset: "-1px",
              }}
              fontWeight="bold"
            >
              <Flex justify="space-between" align="center" width="full">
                <Box>{data.translatedName}</Box>
                {data.showNotificationBubble && <NotificationSvgIcon />}
              </Flex>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <HelpFlyout isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
};

RightLinks.defaultProps = {
  isAMinor: false,
  firstName: "there",
  lastName: "",
};

RightLinks.propTypes = {
  firstName: PropTypes.string,
  isAMinor: PropTypes.bool,
  isGlobalUser: PropTypes.any,
  shadedBackground: PropTypes.any,
  lastName: PropTypes.string,
};

export default RightLinks;
