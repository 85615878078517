import {
  Box,
  Flex,
  Text,
  Avatar,
  VIndividualIcon,
} from "@springcare/sh-component-library";

const ChatMessageBubble = ({ message, localSid }) => {
  const text = message?.message;
  const senderSid = message?.from?.sid;
  const senderName = message?.from?.name;
  const timestamp = message?.timestamp;
  const formattedTime = new Date(timestamp).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const isLocalSent = senderSid === localSid;

  return (
    <Flex
      borderRadius="v-lg"
      flexDir={isLocalSent ? "row-reverse" : "row"}
      align="end"
      my="v-4"
    >
      <Flex direction="column" alignItems={isLocalSent ? "end" : "start"}>
        <Text
          size="body-small-regular"
          color="content-secondary"
          ms={isLocalSent ? "v-none" : "v-32"}
          me={isLocalSent ? "v-32" : "v-none"}
        >
          {senderName}
        </Text>
        <Flex alignItems="end" gap="v-4" my="v-4">
          {!isLocalSent && <Avatar name={senderName} icon={VIndividualIcon} />}
          <Text
            py="v-8"
            px="v-12"
            borderRadius="v-lg"
            bg={isLocalSent ? "primary-base" : "neutral-subtle"}
            color={isLocalSent ? "primary-on-base" : "neutral-on-subtle"}
            wordBreak="break-word"
          >
            {text}
          </Text>
          {isLocalSent && <Avatar name={senderName} icon={VIndividualIcon} />}
        </Flex>

        <Text
          size="body-small-regular"
          color="content-secondary"
          ms={isLocalSent ? "v-none" : "v-32"}
          me={isLocalSent ? "v-32" : "v-none"}
        >
          {formattedTime}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ChatMessageBubble;
