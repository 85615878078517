import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useMediaQuery,
  Flex,
  VCelebrationConfettiDuotoneSpot,
} from "@springcare/sh-component-library";
import React, { useState, useEffect } from "react";
import { useTranslation } from "hooks/react-i18next";

import { MomentsCompletionContent } from "./MomentsCompletionContent";
import {
  trackModalClosedABTest,
  trackModalOpenedABTestEnd,
} from "modules/MemberDashboard/Moments/components/analytics";
import { mobileAndTabletCheck } from "utils/displayHelpers";
import { MomentsCompletionModalHeader } from "./MomentsCompletionModalHeader";
import { useOnRampToCareExperiment } from "../hooks";

export const MomentsCompletionModal = ({
  isOpen,
  onClose,
  exerciseTitle,
  exerciseId,
  exerciseMediaType,
  exerciseCategory,
  onCloseVideoModal,
}) => {
  const { isTherapyTreatmentUser, isCoachingTreatmentUser } =
    useOnRampToCareExperiment();
  const { t } = useTranslation("moments");
  const [isLandscape] = useMediaQuery("(orientation: landscape)");
  const [isMobile] = useMediaQuery("(max-width:600px)");
  const [feeling, setFeeling] = useState<EfficacySurveyFeeling | null>(null);
  const isTallModal = !feeling;

  const isMobileOrTabletDevice = mobileAndTabletCheck();

  const handleClose = () => {
    trackModalClosedABTest(
      exerciseId,
      exerciseTitle,
      exerciseCategory,
      exerciseMediaType,
    );
    setFeeling(null);
    onClose();
  };

  useEffect(() => {
    if (isOpen && feeling) {
      trackModalOpenedABTestEnd(
        exerciseId,
        exerciseTitle,
        exerciseCategory,
        exerciseMediaType,
      );
    }
  }, [isOpen, feeling]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered={!isMobile}
      size={isMobile ? "full" : "2xl"}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        width="608px"
        top={
          isMobileOrTabletDevice && isLandscape && isTallModal
            ? "53px"
            : "v-none"
        }
      >
        <Flex
          flexDirection="row-reverse"
          alignItems="center"
          height="80"
          pt={isMobile ? "v-16" : "v-24"}
          pr="v-24"
          pb="v-16"
          pl="v-24"
        >
          <ModalCloseButton
            bg={isMobile ? undefined : "accent-subtle"}
            size="lg"
            position="relative"
            top="unset"
            right="unset"
          />
        </Flex>
        <ModalBody
          display="flex"
          pt="v-none"
          ps="v-24"
          pe="v-24"
          pb={isMobile ? "34px" : "v-24"}
        >
          <VStack
            spacing="v-32"
            w="100%"
            p="v-none"
            m="v-none"
            gap={isMobile ? undefined : "v-24"}
            justifyContent={isMobile ? "space-between" : undefined}
            marginTop={
              (isTherapyTreatmentUser || isCoachingTreatmentUser) && isMobile
                ? "48px"
                : "v-none"
            }
          >
            <MomentsCompletionModalHeader
              feeling={feeling}
              exerciseTitle={exerciseTitle}
            />
            <MomentsCompletionContent
              {...{
                exerciseTitle,
                exerciseId,
                exerciseMediaType,
                exerciseCategory,
                onClose,
                onCloseVideoModal,
                feeling,
                setFeeling,
              }}
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export enum EfficacySurveyFeeling {
  Better = "better",
  Same = "same",
  Worse = "worse",
}
