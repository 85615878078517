// 

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';


const SiteLoader = (props) => (
	<div
		className={classnames(styles.loadingWrapper, {
			[styles.in]: props.progress > 0,
		})}
	>
		<span className={styles.loadingBar} style={{ width: `${props.progress + 1}%` }} />
	</div>
);

export default SiteLoader;
