import React from "react";
import styles from "./styles.module.scss";
import {
  CareNavigatorIcon,
  CoachIcon,
  MedicationManagerIcon,
  TherapistIcon,
  Avatar,
} from "@spring/smeargle";
import { Flex, useMediaQuery, Box } from "design-system/components";
import { ProviderRole } from "@spring/constants";
import ModalHeading from "./ModalHeading";
import { CareProviderSubhead } from "components/molecules";
import { CareProvider } from "modules/shared/graphql-codegen/graphql";
import { useTranslation } from "hooks/react-i18next";
import { Text } from "@springcare/sh-component-library";

const ICON_COLOR = "rgba(6, 130, 98, 1)";
const ICON_SIZE = "18px";

interface CareProviderOverviewProps {
  isBrowseOrRecs: boolean;
  shouldShowNoCostBadge: boolean;
  careProvider: CareProvider;
  therapyTopSpecialtiesFlag: boolean;
  providerRole: string;
}

const getIcon = (providerRole) => {
  if (!providerRole) {
    return (
      <MedicationManagerIcon
        width={ICON_SIZE}
        height={ICON_SIZE}
        color={ICON_COLOR}
      />
    );
  }

  providerRole = providerRole.replace(" ", "_");

  switch (providerRole.toLowerCase()) {
    case ProviderRole.CareNavigator:
      return (
        <CareNavigatorIcon
          svgProps={{ width: ICON_SIZE, height: ICON_SIZE }}
          color={ICON_COLOR}
        />
      );
    case ProviderRole.Therapist:
      return (
        <TherapistIcon
          svgProps={{ width: ICON_SIZE, height: ICON_SIZE }}
          color={ICON_COLOR}
        />
      );
    case ProviderRole.AssociateProvider:
      return (
        <TherapistIcon
          svgProps={{ width: ICON_SIZE, height: ICON_SIZE }}
          color={ICON_COLOR}
        />
      );
    case ProviderRole.Coach:
      return (
        <CoachIcon height={ICON_SIZE} width={ICON_SIZE} color={ICON_COLOR} />
      );
    case ProviderRole.MedicationManager:
      return (
        <MedicationManagerIcon
          width={ICON_SIZE}
          height={ICON_SIZE}
          color={ICON_COLOR}
        />
      );
    case ProviderRole.CareAdvocate:
      return (
        <CareNavigatorIcon
          svgProps={{ width: ICON_SIZE, height: ICON_SIZE }}
          color={ICON_COLOR}
        />
      );
    case ProviderRole.CareConsultant:
      return (
        <CareNavigatorIcon
          svgProps={{ width: ICON_SIZE, height: ICON_SIZE }}
          color={ICON_COLOR}
        />
      );
    default:
      return (
        <MedicationManagerIcon
          width={ICON_SIZE}
          height={ICON_SIZE}
          color={ICON_COLOR}
        />
      );
  }
};

const CareProviderOverview = ({
  isBrowseOrRecs,
  shouldShowNoCostBadge,
  careProvider,
  therapyTopSpecialtiesFlag,
  providerRole,
}: CareProviderOverviewProps) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { t } = useTranslation("careProvider");

  return (
    <div
      className={styles.providerContainer}
      style={{
        marginTop:
          isMobile && isBrowseOrRecs && shouldShowNoCostBadge ? "-35px" : "",
        alignItems: "start",
        flexDirection: "row",
      }}
    >
      <Box h="116px" pr={16} position="relative">
        <div className={styles.avatar}>
          <Avatar
            src={careProvider.avatar}
            imgProps={{ alt: "" }}
            height={104}
            width={104}
            round
          />
        </div>
        <Flex
          w={40}
          h={40}
          bg="background-base"
          borderColor="border-subtle"
          borderWidth={1}
          borderRadius="50%"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          bottom={0}
        >
          {getIcon(providerRole)}
        </Flex>
      </Box>
      <Box
        marginTop={"auto"}
        marginBottom={"auto"}
        style={{ marginLeft: 0, alignItems: "start" }}
        className={styles.providerInfo}
        flexBasis={"50%"}
      >
        <div className={styles.providerName}>
          <ModalHeading
            providerName={careProvider.name}
            careProvider={careProvider}
            size={isMobile ? "heading-small" : "heading-medium"}
          />
        </div>
        <div
          style={{ paddingTop: "unset", marginTop: "-8px" }}
          className={styles.providerRoles}
        >
          <Text
            size={isMobile && "body-small-regular"}
            color="#content-secondary"
            width="100%"
          >
            <CareProviderSubhead
              roles={careProvider.roles}
              licenses={careProvider.licenses}
              categories={careProvider.categories}
              associateStatus={careProvider.associate_status}
              showNewProfilePage
              isMobile={isMobile}
            />
          </Text>
          {therapyTopSpecialtiesFlag &&
            !isBrowseOrRecs &&
            careProvider?.top_tags?.length > 0 && (
              <Flex
                direction={["column", "row"]}
                align={["center", "left"]}
                alignItems={"baseline"}
              >
                <Text
                  whiteSpace={"nowrap"}
                  variant="bodyBold_v1"
                  width={["100%", "auto"]}
                  textAlign={["center", "left"]}
                  mr={4}
                >
                  {t("topSpecialties")}
                </Text>
                <Text
                  w="100%"
                  variant="body_v1"
                  color="#737778"
                  textAlign={["center", "left"]}
                >
                  {[...careProvider.top_tags]?.sort()?.join(", ")}
                </Text>
              </Flex>
            )}
        </div>
      </Box>
    </div>
  );
};

export default CareProviderOverview;
