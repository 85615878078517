import { useTranslation } from "hooks/react-i18next";
import { Box, SHFlyout } from "design-system/components";
import MemberHelpCard from "components/organisms/MemberHelpCard/MemberHelpCard";

import { useProviderBrowseContext } from "context/ProviderBrowseContext";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const RequestSupportFlyout = ({ isOpen, onOpen, onClose }: Props) => {
  const { t } = useTranslation(["limitedLangMemberHelpCard"]);
  const { isMobile } = useProviderBrowseContext();
  return (
    <SHFlyout
      // @ts-ignore
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      size="md"
      headerText={t("footerHeader")}
    >
      <Box h={isMobile ? "calc(100vh - 121px)" : null}>
        <MemberHelpCard />
      </Box>
    </SHFlyout>
  );
};

export default RequestSupportFlyout;
