import { useDisclosure } from "design-system";

// This hook just consolidates all of the useDisclosure hooks we need for the homepage
export function useCareInfoFlyoutDisclosures() {
  // NOTE: there's probably a faster way to do this, but this works even if it's not pretty
  const {
    isOpen: isMomentsOpen,
    onOpen: onMomentsOpen,
    onClose: onMomentsClose,
  } = useDisclosure();
  const {
    isOpen: isCoachingOpen,
    onOpen: onCoachingOpen,
    onClose: onCoachingClose,
  } = useDisclosure();
  const {
    isOpen: isParentCoachingOpen,
    onOpen: onParentCoachingOpen,
    onClose: onParentCoachingClose,
  } = useDisclosure();
  const {
    isOpen: isTherapyOpen,
    onOpen: onTherapyOpen,
    onClose: onTherapyClose,
  } = useDisclosure();
  const {
    isOpen: isCouplesTherapyOpen,
    onOpen: onCouplesTherapyOpen,
    onClose: onCouplesTherapyClose,
  } = useDisclosure();
  const {
    isOpen: isMedicationManagementOpen,
    onOpen: onMedicationManagementOpen,
    onClose: onMedicationManagementClose,
  } = useDisclosure();
  const {
    isOpen: isCareNavigationOpen,
    onOpen: onCareNavigationOpen,
    onClose: onCareNavigationClose,
  } = useDisclosure();
  const {
    isOpen: isSubstanceUseOpen,
    onOpen: onSubstanceUseOpen,
    onClose: onSubstanceUseClose,
  } = useDisclosure();
  const {
    isOpen: istobaccoCessationOpen,
    onOpen: ontobaccoCessationOpen,
    onClose: ontobaccoCessationClose,
  } = useDisclosure();
  const {
    isOpen: isSpecializedCareNavigationOpen,
    onOpen: onSpecializedCareNavigationOpen,
    onClose: onSpecializedCareNavigationClose,
  } = useDisclosure();
  const {
    isOpen: isCareConsultantOpen,
    onOpen: onCareConsultantOpen,
    onClose: onCareConsultantClose,
  } = useDisclosure();

  return {
    moments: {
      isOpen: isMomentsOpen,
      onOpen: onMomentsOpen,
      onClose: onMomentsClose,
    },
    coaching: {
      isOpen: isCoachingOpen,
      onOpen: onCoachingOpen,
      onClose: onCoachingClose,
    },
    parentCoaching: {
      isOpen: isParentCoachingOpen,
      onOpen: onParentCoachingOpen,
      onClose: onParentCoachingClose,
    },
    therapy: {
      isOpen: isTherapyOpen,
      onOpen: onTherapyOpen,
      onClose: onTherapyClose,
    },
    couplesTherapy: {
      isOpen: isCouplesTherapyOpen,
      onOpen: onCouplesTherapyOpen,
      onClose: onCouplesTherapyClose,
    },
    medicationManagement: {
      isOpen: isMedicationManagementOpen,
      onOpen: onMedicationManagementOpen,
      onClose: onMedicationManagementClose,
    },
    careNavigation: {
      isOpen: isCareNavigationOpen,
      onOpen: onCareNavigationOpen,
      onClose: onCareNavigationClose,
    },
    specializedCareNavigation: {
      isOpen: isSpecializedCareNavigationOpen,
      onOpen: onSpecializedCareNavigationOpen,
      onClose: onSpecializedCareNavigationClose,
    },
    substanceUse: {
      isOpen: isSubstanceUseOpen,
      onOpen: onSubstanceUseOpen,
      onClose: onSubstanceUseClose,
    },
    tobaccoCessation: {
      isOpen: istobaccoCessationOpen,
      onOpen: ontobaccoCessationOpen,
      onClose: ontobaccoCessationClose,
    },
    careConsultant: {
      isOpen: isCareConsultantOpen,
      onOpen: onCareConsultantOpen,
      onClose: onCareConsultantClose,
    },
  };
}
