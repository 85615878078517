import React from "react";

const AdultSvg = () => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <g clipPath="url(#adultSvg)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm7.935 4.53a6 6 0 1 0-7.87 0 8.001 8.001 0 0 0-5.026 6.155l-1.025 6.15a1 1 0 1 0 1.973.33l1.025-6.151A6 6 0 0 1 10.93 12h2.14a6 6 0 0 1 5.919 5.014l1.025 6.15a1 1 0 1 0 1.973-.328l-1.026-6.151a8.001 8.001 0 0 0-5.026-6.155Z"
        fill="#424A4C"
      />
    </g>
    <defs>
      <clipPath id="adultSvg">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default AdultSvg;
