import {
  Heading,
  VCelebrationConfettiDuotoneSpot,
  VStack,
  useMediaQuery,
  useTheme,
} from "@springcare/sh-component-library";
import PropTypes from "prop-types";

const Welcome = ({ t }) => {
  const { breakpoints } = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints.sm})`);

  return (
    <VStack
      alignItems={["flex-start", "center"]}
      gap={16}
      mb={32}
      mt={[32, 72]}
      w="100%"
      px={24}
      role="banner"
    >
      <VCelebrationConfettiDuotoneSpot
        w={80}
        h={80}
        aria-label="celebration confetti icon"
      />
      <Heading size={isMobile ? "heading-medium" : "heading-large"} as="h1">
        {t("welcome.header")}
      </Heading>
    </VStack>
  );
};

Welcome.propTypes = {
  t: PropTypes.func,
};

export default Welcome;
