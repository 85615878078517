import React, { useState } from "react";
import { momentsExerciseCategories } from "constants/momentsExerciseCategories";
import { TRACK_EVENT } from "utils/mixpanel";
import {
  VFilterChip,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from "design-system/components";
import { VArrowDownIcon } from "@springcare/sh-component-library";
import classnames from "classnames";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

export const MomentsMenu = ({ refetch }) => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [isMoreMenuItem, setIsMoreMenuItem] = useState(false);
  const { t } = useTranslation("moments");

  const menuVariant = useBreakpointValue({
    base: 1,
    sm: 3,
    md: 5,
    lg: 9,
    xl: 11,
  });

  return (
    <div>
      {momentsExerciseCategories
        .slice(0, menuVariant)
        .map((category, index) => (
          <VFilterChip
            onClick={() => {
              setSelectedCategory(index);
              setIsMoreMenuItem(false);
              const category = momentsExerciseCategories[index].query;
              TRACK_EVENT.BUTTON_CLICKED(
                window.location.pathname,
                "Category: Filter Clicked",
                { category },
              );
              refetch({ category });
            }}
            variant="low-emphasis"
            key={index}
            type="single-select"
            selected={selectedCategory === index}
            aria-current={selectedCategory === index}
            className={styles.menu}
            // Temporary fix for focus styles overriding
            // selected styles - see #5071
            _hover={classnames({
              [styles.momentsMenu]: selectedCategory === index,
            })}
            _focusVisible={{ boxShadow: "0 0 0 3px black" }}
            // Temporary fix for focus styles overriding
            // selected styles - see #5071
            _focus={classnames({
              [styles.momentsMenu]: selectedCategory === index,
            })}
          >
            {t(`category.${category.query}`)}
          </VFilterChip>
        ))}
      <Menu>
        <MenuButton
          as={VFilterChip}
          // @ts-ignore
          rightIcon={<VArrowDownIcon />}
          onClick={() => {
            setIsMoreMenuItem(true);
          }}
          className={styles.menu}
          // Temporary fix for focus styles overriding
          // selected styles - see #5071
          _hover={classnames({
            [styles.momentsMenu]: isMoreMenuItem,
          })}
          _focusVisible={{ boxShadow: "0 0 0 3px black" }}
          // Temporary fix for focus styles overriding
          // selected styles - see #5071
          _focus={classnames({
            [styles.momentsMenu]: isMoreMenuItem,
          })}
          selected={selectedCategory >= menuVariant}
          aria-current={selectedCategory >= menuVariant}
        >
          {t("More")}
        </MenuButton>
        <MenuList>
          <MenuOptionGroup type="radio" value={`${selectedCategory}`}>
            {momentsExerciseCategories
              .slice(menuVariant)
              .map((category, index) => (
                <MenuItemOption
                  role="menuitemradio"
                  onClick={() => {
                    setSelectedCategory(index + menuVariant);
                    const category =
                      momentsExerciseCategories[index + menuVariant].query;
                    TRACK_EVENT.BUTTON_CLICKED(
                      window.location.pathname,
                      "Category: Filter Clicked",
                      { category },
                    );
                    refetch({ category });
                  }}
                  className={classnames({
                    [styles.selectedMoreMenu]:
                      selectedCategory === index + menuVariant,
                  })}
                  value={`${index + menuVariant}`}
                  key={index + menuVariant}
                >
                  {t(`category.${category.query}`)}
                </MenuItemOption>
              ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </div>
  );
};
