import { gql } from "@apollo/client";

const contractTerm = gql`
  fragment contractTerm on ContractTerm {
    id
    census_dependents_only
    coaching_support
    continued_coaching
    end_at
    visit_type
    sud_supported
    member_cost_of_coaching
    wpo_coaching_supported
    scheduling_access {
      id
      name
    }
    no_show_term {
      enable_members_pay
      start_date
    }
    virtual_session_cost_term {
      enable_show
      percentage_covered
      customer_display_name
    }
  }
`;

export default contractTerm;
