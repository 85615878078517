import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Flex,
  Spacer,
  HStack,
  Box,
  SHLanguageSelect,
  DIAL_CODES,
  SHFlyout,
  useDisclosure,
} from "design-system/components";
import { SpringLogoIcon } from "design-system/assets";
import { DesktopHelpBtn } from "./HeaderButtons";
import { MemberHelpCard } from "components";

import { normalizeToISO2 } from "utils/global";
import { ExpectationsContext } from "pages/apps/MemberDashboard/expectations";

import { lookupLanguage, getLanguageFromCountry } from "utils/language";

import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";
import { useDispatch, useSelector } from "react-redux";
import { localeCountry } from "actions/global/actions";
import Meowth from "@spring/meowth";
import { getUserInfo } from "operations/queries/user";
import { getMemberInfo } from "operations/queries/member";

const Header = ({ updateUserLang }) => {
  const { lang: userPreferredLang } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [lang, setLang] = useState(userPreferredLang || "en");
  const { t } = useTranslation("limitedLangMemberHelpCard");
  const { coveredLife, potentialCL, isDependentObj, global, setGlobal } =
    useContext(ExpectationsContext);

  const currentView = "MEMBER_EXPECTATIONS";

  function updateGlobalCtx(coveredLifeCountry) {
    // convert to iso2 format
    const iso2Country = normalizeToISO2(coveredLifeCountry);
    // update local state language
    let language = getLanguageFromCountry(iso2Country);
    language = lookupLanguage(language);
    setLang(language.code);
    // update global state country && lang && showGlobalExperience
    if (iso2Country !== "US") {
      // get country dial code (country_code)
      const country_code = updateGlobalCtxCountryCode(iso2Country);
      setGlobal({
        country: iso2Country,
        lang: language.code,
        preferred_language: language.englishName,
        showGlobalExperience: true,
        country_code,
      });
    }
  }

  function updateGlobalCtxCountryCode(coveredLifeCountry) {
    let { value } = DIAL_CODES.find(
      (country) => country.code === coveredLifeCountry,
    );
    return value;
  }

  const titleCase = (str) =>
    str
      .toLowerCase()
      .split("_")
      .filter((x) => x.length > 0)
      .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
      .join(" ");

  function helpClickBtnHandler() {
    TRACK_EVENT.BUTTON_CLICKED(routes.MemberExpectations.as, "Help", {
      page_version: titleCase(currentView),
      location: "Header",
      spring_doc_id: "register003",
    });
    onOpen();
  }

  useEffect(() => {
    if (coveredLife?.country) {
      updateGlobalCtx(coveredLife?.country);
    }
  }, [coveredLife?.country]);

  useEffect(() => {
    if (potentialCL?.country) {
      updateGlobalCtx(potentialCL?.country);
    }
  }, [potentialCL?.country]);

  useEffect(() => {
    let preferred_language = lookupLanguage(lang);
    preferred_language = preferred_language.englishName;
    setGlobal({ ...global, preferred_language, lang });
    dispatch(localeCountry({ lang }));
    updateUserLang({
      variables: {
        input: {
          id: Meowth.getUserId(),
          patch: {
            preferred_language: lookupLanguage(lang).englishName,
          },
        },
      },
      refetchQueries: [getUserInfo, getMemberInfo],
    });
  }, [lang]);

  const DesktopHeader = () => (
    <Box as="nav">
      <Grid templateColumns="repeat(2, 1fr)" gap={0} h={95}>
        <Flex bg="white" h={95} alignItems="center">
          <Box ps={{ base: 12, md: 48 }}>
            <SpringLogoIcon boxSize="28px" />
          </Box>
        </Flex>
        <Flex
          w={[null, null, "calc(100% - 48px)", "80%"]}
          alignItems="center"
          m="0 auto"
          bg="white"
        >
          <Spacer />
          <HStack spacing={8}>
            <SHLanguageSelect
              lang={lang}
              setLang={setLang}
              currentView={currentView}
              isDependentObj={isDependentObj}
              isMobile={false}
              setGlobalCtx={setGlobal}
              global={global}
            />
            <DesktopHelpBtn onClick={() => helpClickBtnHandler()} />
          </HStack>
        </Flex>
      </Grid>
    </Box>
  );

  return (
    <>
      <DesktopHeader />
      <SHFlyout
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        size="md"
        headerText={t("footerHeader")}
      >
        <MemberHelpCard closeFlyout={onClose} />
      </SHFlyout>
    </>
  );
};

export default Header;
