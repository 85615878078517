import { Text } from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";
import { useEffect, useState } from "react";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { TRACK_EVENT } from "utils/mixpanel";
import { getSeasonalityWarningConfig } from "./SeasonalityWarning.utils";

export const SeasonalityWarning = ({ appointmentTime }) => {
  const { t } = useTranslation("careProvider");
  const [eventTracked, setEventTracked] = useState(false);
  const seasonalityWarningEnabled = useFeatureFlag(
    FLAGS.SEASONALITY_APPOINTMENT_TIME_WARNING,
  );

  const { isWeekend, isOutsideOfNormalHours } =
    getSeasonalityWarningConfig(appointmentTime);

  useEffect(() => {
    if (!eventTracked && (isWeekend || isOutsideOfNormalHours)) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Seasonality Warning",
        {
          isWeekend: isWeekend,
          isOutsideOfNormalHours: isOutsideOfNormalHours,
        },
      );

      setEventTracked(true);
    }
  }, [appointmentTime, eventTracked, isWeekend, isOutsideOfNormalHours]);

  const SeasonalityWarning = () => {
    if (isOutsideOfNormalHours && isWeekend) {
      return t(
        "scheduleModal.appointmentSlots.isWeekendAndOutsideOfNormalHours",
      );
    }

    if (isOutsideOfNormalHours) {
      return t("scheduleModal.appointmentSlots.isOutsideOfNormalHours");
    }

    if (isWeekend) {
      return t("scheduleModal.appointmentSlots.isWeekend");
    }

    return "";
  };

  if (!seasonalityWarningEnabled || !appointmentTime) return null;

  return (
    <Text
      w="100%"
      pt={24}
      pb={16}
      textAlign={"center"}
      margin={"auto"}
      fontWeight={"semibold"}
      backgroundColor={"white"}
    >
      <SeasonalityWarning />
    </Text>
  );
};
