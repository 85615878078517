import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

import { AudioPlayer } from '../../../../components/atoms/AudioPlayer';
import { FlexCol } from '../../../../components';

const MomentsAudioStep = (props) => (

	<FlexCol shortHeight>
		<div
			style={{ backgroundImage: `url(${props.imageUrl})` }}
			className={classnames(styles.imageWrapper, { [styles.hideOnSmallScreen]: props.hideOnSmallScreen })}
		/>
		<h2 className={classnames(styles.title, { [styles.hideOnSmallScreen]: props.hideOnSmallScreen })} aria-label={props.title}>{props.title}</h2>
		<div className={styles.audioPlayer}>
			<AudioPlayer title={props.title} url={props.mediaUrl} subtitles={props.subtitles} trackLang={props.trackLang} height="110px" />
		</div>
	</FlexCol>
);

MomentsAudioStep.propTypes = {
	imageUrl          : PropTypes.string,
	title             : PropTypes.string,
	mediaUrl          : PropTypes.string,
	subtitles         : PropTypes.array,
	hideOnSmallScreen : PropTypes.string,
	trackLang         : PropTypes.string,
};

MomentsAudioStep.defaultProps = {
	showCoverImage: true,
};

export default MomentsAudioStep;
