import { useState, useEffect, useRef } from "react";
import { MicVolumeLevelHelper } from "components/templates/SHSessionRoom/utils/MicVolumeLevelHelper";

type InitializeMicVolumeHelper = (
  stream: MediaStream,
  soundMeterRef: React.MutableRefObject<MicVolumeLevelHelper | null>,
  audioContext: AudioContext,
) => void;

type HandleMicVolumeHelperError = (error: Error) => Error;

type UseMicVolumeLevel = () => [() => void, () => void, number];

// Initializes the MicVolumeLevelHelper with the provided media stream and audio context
// See the MicVolumeLevelHelper class for more details
const initializeMicVolumeHelper: InitializeMicVolumeHelper = (
  stream,
  soundMeterRef,
  audioContext,
) => {
  const soundMeter = (soundMeterRef.current = new MicVolumeLevelHelper(
    audioContext,
  ));
  soundMeter.moduleLoaded.then(() => {
    soundMeter.connectToSource(stream, function (e: Error | null) {
      if (e) {
        // TODO: handle the error (log)?
        return;
      }
    });
  });
};

const handleMicVolumeHelperError: HandleMicVolumeHelperError = (error) => {
  return error;
};

// Custom hook to get the microphone volume level in real-time from an active audio stream
// Returns a tuple with the following values:
// startRecording(): starts recording the microphone volume level
// stopRecording(): stops recording the microphone volume level
// level: the current microphone volume level (0-100)
// The practical use for this hook is to display microphone volume levels in UI
const useMicVolumeLevel: UseMicVolumeLevel = () => {
  const [level, setLevel] = useState<number>(0);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [max, setMax] = useState<number>(0);
  const soundMeterRef = useRef<MicVolumeLevelHelper | null>(null);

  const stopRecording = () => {
    setLevel(0);
    soundMeterRef.current?.stop();
    setIsRecording(false);
  };

  const startRecording = () => {
    const constraints: MediaStreamConstraints = {
      audio: true,
      video: false,
    };
    let audioContext: AudioContext;
    try {
      audioContext = new (AudioContext || (window as any).webkitAudioContext)();
    } catch (e) {
      //TODO: we want error handling? (UI+event)
      return e;
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) =>
        initializeMicVolumeHelper(stream, soundMeterRef, audioContext),
      )
      .catch(handleMicVolumeHelperError);

    setIsRecording(true);
  };

  const updateVolume = () => {
    if (soundMeterRef.current && isRecording) {
      const v = soundMeterRef.current.instant * 200;
      setLevel(Math.min(v, 100));
      setMax(Math.max(max, level));
    }
  };

  useEffect(() => {
    const intervalId = setInterval(updateVolume, 500);
    return () => clearInterval(intervalId);
  }, [isRecording]);

  return [startRecording, stopRecording, level];
};

export default useMicVolumeLevel;
