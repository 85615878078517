import { DateTime } from "luxon";

export const getSeasonalityWarningConfig = (appointmentTime) => {
  const selectedDateTime = DateTime.fromISO(appointmentTime);
  const isWeekend = selectedDateTime.weekday > 5;

  // Between the hours of 10pm and 6am.
  const isOutsideOfNormalHours =
    selectedDateTime.hour < 6 || selectedDateTime.hour >= 22;

  return {
    isWeekend,
    isOutsideOfNormalHours,
  };
};
