import { useRef, useEffect } from "react";
import { useRouter } from "next/router";
import { links } from "@spring/constants";
import { Flex, Box, Button } from "@springcare/sh-component-library";

import SessionDetailsContainer from "components/templates/SHSessionRoom/components/SessionDetailsContainer/SessionDetailsContainer";
import SessionDetailsHeader from "components/templates/SHSessionRoom/components/SessionDetailsContainer/SessionDetailsHeader";
import SessionDetailsBody from "components/templates/SHSessionRoom/components/SessionDetailsContainer/SessionDetailsBody";
import VideoPreviewSection from "components/templates/SHSessionRoom/components/VideoPreviewSection";

import {
  useSessionViewStatus,
  useSessionConnectStatus,
} from "context/SessionRoomContext";

const PreJoin = ({ sessionData, isMobile }) => {
  const timerRef = useRef(null);
  const router = useRouter();
  const { sessionViewStatus, setSessionViewStatus, SessionViewStatus } =
    useSessionViewStatus();
  const { setShouldConnect, setSkipSetStatus } = useSessionConnectStatus();

  // Grace period is 10 minutes (600K milliseconds)
  const PROVIDER_GRACE_PERIOD = 600_000;

  useEffect(() => {
    if (sessionViewStatus === SessionViewStatus.MemberIsWaiting) {
      // sets a Grace Period timer for Provider, when a Member requests to join room
      timerRef.current = setTimeout(() => {
        setSessionViewStatus(SessionViewStatus.ProviderNoShow);
      }, PROVIDER_GRACE_PERIOD);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [sessionViewStatus]);

  const handleJoinMeeting = () => {
    setShouldConnect(true);
    setSessionViewStatus(SessionViewStatus.Connecting);
  };

  const handleCancelJoinMeeting = () => {
    // NOTE: We need to reload here to disconnect from livekit
    // doing this reconnects, and resets the Member's permissions
    // which allows them to ask permission again, if they want
    window.location.reload();
  };

  const handleContactSupport = () => {
    setSkipSetStatus(true);
    router.push(links.Support);
  };
  // SessionViewStatus.NotStarted
  const notStartedCopy = {
    header: "Ready to join?",
    subHeader: "Your session will begin shortly with:",
  };
  // SessionViewStatus.MemberIsWaiting
  const memberWaitingCopy = {
    header: "Waiting for host",
    subHeader: "You’ll enter the session once the host grants access.",
  };
  // SessionViewStatus.ProviderNoShow
  const providerNoShowCopy = {
    header: "Need assistance?",
    subHeader:
      "The host hasn’t let you into the session. You can try reaching out to them directly or contact a Care Navigator for help.",
  };

  const getCopy = (status) => {
    switch (status) {
      case SessionViewStatus.MemberIsWaiting:
        return memberWaitingCopy;
      case SessionViewStatus.ProviderNoShow:
        return providerNoShowCopy;
      case SessionViewStatus.NotStarted:
      default:
        return notStartedCopy;
    }
  };

  const { header, subHeader } = getCopy(sessionViewStatus);

  return (
    <SessionDetailsContainer isMobile={isMobile}>
      <VideoPreviewSection isMobile={isMobile} />

      <Flex
        w={["100%", "100%", "v-4xl", "v-4xl"]}
        flexDir="column"
        mb="v-8"
        alignSelf="center"
      >
        <SessionDetailsHeader
          mainHeaderText={header}
          subHeaderText={subHeader}
          isMobile={isMobile}
        />

        <SessionDetailsBody sessionData={sessionData}>
          <Box>
            {/* START SESSION BUTTON */}
            {sessionViewStatus !== SessionViewStatus.ProviderNoShow && (
              <Button
                w="100%"
                variant="high-emphasis"
                onClick={handleJoinMeeting}
                isLoading={
                  sessionViewStatus === SessionViewStatus.Connecting ||
                  sessionViewStatus === SessionViewStatus.MemberIsWaiting
                }
              >
                Start Session
              </Button>
            )}
            {/* CANCEL JOIN MEETING BUTTON */}
            {sessionViewStatus === SessionViewStatus.MemberIsWaiting && (
              <Button
                w="100%"
                mt={16}
                variant="low-emphasis"
                onClick={handleCancelJoinMeeting}
              >
                Cancel
              </Button>
            )}
            {/* CONTACT Support BUTTON */}
            {sessionViewStatus === SessionViewStatus.ProviderNoShow && (
              <Button
                w="100%"
                variant="high-emphasis"
                onClick={handleContactSupport}
              >
                Contact Support
              </Button>
            )}
          </Box>
        </SessionDetailsBody>
      </Flex>
    </SessionDetailsContainer>
  );
};

export default PreJoin;
