// TODO: remove this eslint-disable when ready
/* eslint-disable no-unused-vars */
import { useTranslation } from "hooks/react-i18next";
import { Box } from "design-system/components";
import { ProviderOfficeLocation } from "./ProviderOfficeLocation";
import {
  AppointmentMedium,
  AppointmentKind,
  ProviderRole,
  getAppointmentKindByProviderRole,
} from "@spring/constants";

import { DateTime } from "luxon";

import { MobileTimeSlots } from "components/molecules/MobileTimeSlots";
import { RequestSupport } from "./RequestSupport";
import {
  trackAppointmentSlotSelected,
  trackMoreTimesClicked,
} from "components/templates/Browse/ProviderBrowsePage/analytics";
import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

export const MobileSlots = ({
  videoAppointments,
  inPersonAppointments,
  provider,
  providerIndex,
  pageNumber,
  queryRequestId,
  hasVideoAppointments,
  hasInPersonAppointments,
  setInitialStartTimeField,
  handleChange,
  openScheduleModal,
  isFilterInPerson,
}) => {
  const { t } = useTranslation(["careProvider"]);
  const { providerType, memberInfo } = useProviderBrowseContext();
  const inPersonMedManagerFlag = useFeatureFlag(
    FLAGS.IN_PERSON_MED_MANAGER_RELEASE,
  );

  const inPersonSupportedTherapist =
    memberInfo?.user?.member?.cohort?.in_person_supported;
  const inPersonSupportedMedicationManager =
    memberInfo?.user?.member?.cohort?.in_person_med_management_supported;
  const memberInPersonSupported =
    (providerType === ProviderRole.MedicationManager &&
      inPersonSupportedMedicationManager &&
      inPersonMedManagerFlag) ||
    (providerType === ProviderRole.Therapist && inPersonSupportedTherapist);

  const providerTooFar = !inPersonAppointments;
  const showGlobalExperience =
    memberInfo?.user?.member?.postal_address?.country !== "US";

  const appointmentKind = getAppointmentKindByProviderRole(providerType);

  const defaultOpenModalTriggers = (dateTime, medium) => {
    setInitialStartTimeField(dateTime, medium);
    handleChange(dateTime, medium);
    openScheduleModal(provider, providerIndex, pageNumber);
  };

  const defaultTrackingData = {
    index: 1,
    provider,
    pageNumber,
    providerIndex,
    queryRequestId,
    appointmentKind,
  };

  const showVirtualSlots = hasVideoAppointments && provider?.virtual_supported;
  const showInPersonSlots =
    hasInPersonAppointments &&
    provider?.in_person_supported &&
    memberInPersonSupported;

  return (
    <Box>
      {showVirtualSlots && (
        <MobileTimeSlots
          inPerson={false}
          timeSlotText={t("newAppointmentSlots.virtual")}
          timeSlot={DateTime.fromISO(videoAppointments?.[0])
            .toLocal()
            .toFormat("LLLL dd • h:mm a")}
          openSchedulingModal={(event) => {
            event.stopPropagation();
            // Events for Opening Schedule Modal
            const trackingData = {
              dateTime: videoAppointments?.[0],
              appointmentMedium: AppointmentMedium.Video,
              ...defaultTrackingData,
            };
            trackAppointmentSlotSelected(trackingData);
            defaultOpenModalTriggers(
              videoAppointments?.[0],
              AppointmentMedium.Video,
            );
          }}
          openSeeMoreTimes={(event) => {
            event.stopPropagation();
            // Events for See More Times
            const trackingData = {
              appointmentMedium: AppointmentMedium.Video,
              ...defaultTrackingData,
            };
            trackMoreTimesClicked(trackingData);
            defaultOpenModalTriggers(
              videoAppointments?.[0],
              AppointmentMedium.Video,
            );
          }}
          seeMoreTimesLinkText={t("newAppointmentSlots.seeMoreVirtualTimes")}
          // only true when used in MobileTimeSlots.tsx
          isRecsOrBrecs={false}
          kind={appointmentKind}
          queryRequestId={queryRequestId}
        />
      )}
      {provider?.in_person_supported &&
        memberInPersonSupported &&
        !providerTooFar && (
          <ProviderOfficeLocation {...{ provider, showGlobalExperience }} />
        )}
      {showInPersonSlots && (
        <MobileTimeSlots
          inPerson={true}
          timeSlotText={t("newAppointmentSlots.inPerson")}
          timeSlot={DateTime.fromISO(inPersonAppointments?.[0])
            .toLocal()
            .toFormat("LLLL dd • h:mm a")}
          openSchedulingModal={(event) => {
            event.stopPropagation();
            // Events for Opening Schedule Modal
            const trackingData = {
              dateTime: inPersonAppointments?.[0],
              appointmentMedium: AppointmentMedium.InPerson,
              ...defaultTrackingData,
            };
            trackAppointmentSlotSelected(trackingData);
            defaultOpenModalTriggers(
              inPersonAppointments?.[0],
              AppointmentMedium.InPerson,
            );
          }}
          openSeeMoreTimes={(event) => {
            event.stopPropagation();
            // Events for See More Times
            const trackingData = {
              appointmentMedium: AppointmentMedium.InPerson,
              ...defaultTrackingData,
            };
            trackMoreTimesClicked(trackingData);
            defaultOpenModalTriggers(
              inPersonAppointments?.[0],
              AppointmentMedium.InPerson,
            );
          }}
          seeMoreTimesLinkText={t("newAppointmentSlots.seeMoreInPersonTimes")}
          // only true when used in MobileTimeSlots.tsx
          isRecsOrBrecs={false}
          kind={appointmentKind}
          queryRequestId={queryRequestId}
        />
      )}
      {!showVirtualSlots && !showInPersonSlots && (
        <Box mt={10}>
          <RequestSupport
            {...{
              provider,
              providerIndex,
              pageNumber,
              medium: isFilterInPerson
                ? AppointmentMedium.InPerson
                : AppointmentMedium.Video,
              kind: AppointmentKind.Therapy,
              queryRequestId: queryRequestId,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default MobileSlots;
