import { Flex, VMicOffIcon } from "@springcare/sh-component-library";

const MicrophoneDisabledIndicator = ({ isSmall }) => (
  <Flex
    bg="background-media-overlay"
    borderRadius={isSmall ? "v-sm" : "v-lg"}
    p={isSmall ? "v-4" : "v-8"}
    aria-label="Microphone is disabled"
    role="img"
  >
    <VMicOffIcon
      height={isSmall ? "12px" : "16px"}
      width={isSmall ? "12px" : "16px"}
      color="white"
      aria-hidden="true"
    />
  </Flex>
);

export default MicrophoneDisabledIndicator;
