/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-indent-props */
import { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import Meowth from "@spring/meowth";
import { SHStickyFooter } from "design-system/components";
import {
  Button,
  HStack,
  Text,
  Box,
  Flex,
  Spacer,
} from "@springcare/sh-component-library";
import { AssessmentResultsContext } from "./YourAssessmentResults";
import {
  addCustomFlag,
  get_property,
  register,
  TRACK_EVENT,
} from "utils/mixpanel";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { getMemberInfo } from "operations/queries/member";
import { useMemberBenefits } from "hooks/useMemberBenefits";
import { isBrecsVariant } from "components/templates/Browse/ProviderBrowsePage/utils";

const FooterButtons = ({
  primaryButtonText,
  secondaryButtonText,
  primaryOnClick,
  secondaryOnClick,
  primaryDataCy,
  secondaryDataCy,
}) => (
  <>
    <Button
      variant="medium-emphasis"
      colorScheme="base"
      sx={{ width: "fit-content" }}
      ms={4}
      onClick={secondaryOnClick}
      data-cy={secondaryDataCy}
    >
      {secondaryButtonText}
    </Button>
    <Button
      variant="high-emphasis"
      me={4}
      colorScheme="base"
      onClick={primaryOnClick}
      data-cy={primaryDataCy}
    >
      {primaryButtonText}
    </Button>
  </>
);

const SUDFooter = ({
  t,
  trackSubstanceUsageDisorderClick,
  handleRoute,
  trackMemberResultsLinkClick,
  sudRoute,
  memberHomeRoute,
}) => (
  <Flex
    w="100%"
    h="100%"
    alignItems="center"
    direction={{ base: "column", lg: "row" }}
  >
    <Box mb={{ base: 16, lg: 0 }}>
      <Text
        fontWeight={600}
        fontSize={16}
        display={{ base: "inline", lg: "block" }}
      >
        {t("footerCTA.substanceUseSupport.next")}
      </Text>
      <Text
        ms={{ lg: 0, base: 4 }}
        fontWeight={400}
        fontSize={16}
        display={{ base: "inline", lg: "block" }}
      >
        {t("footerCTA.substanceUseSupport.nextStep")}
      </Text>
    </Box>
    <Spacer />
    <Flex
      w={{ base: "100%", lg: "auto" }}
      alignItems="center"
      gap={16}
      justifyContent="center"
    >
      <Button
        variant="medium-emphasis"
        colorScheme="base"
        onClick={() => {
          const button_order = 1;
          trackSubstanceUsageDisorderClick(button_order);
          handleRoute(sudRoute);
        }}
      >
        {t("footerCTA.substanceUseSupport.learnMore")}
      </Button>
      <Button
        variant="high-emphasis"
        colorScheme="base"
        onClick={() => {
          const button_order = 2;
          trackMemberResultsLinkClick(button_order);
          handleRoute(memberHomeRoute);
        }}
      >
        {t("footerCTA.substanceUseSupport.exploreRecommendations")}
      </Button>
    </Flex>
  </Flex>
);

const FooterCTA = ({ t, Router, assessmentRiskScore }) => {
  const { data: memberInfoData, loading: memberInfoLoading } = useQuery(
    getMemberInfo,
    Meowth.apolloOptionsUserId(),
  );
  const country = memberInfoData?.user?.member?.postal_address?.country;

  const { isCoachingRecommended, isSUDRecommended } = useMemberBenefits(
    memberInfoData?.user?.member?.id,
  );

  const isAMinor = memberInfoData?.user?.member?.minor;
  const isGlobal = country && country.length > 0 && country !== "US";
  const shouldShowMPQuestionnaire = useFeatureFlag(FLAGS.MP_QUESTIONNAIRE);
  const showNewCoachingChanges = useFeatureFlag(
    FLAGS.LOB_COACHING_ASSESSMENT_RESULTS_CTA,
  );
  const showNewSUDChanges = useFeatureFlag(
    FLAGS.LOB_SUD_ASSESSMENT_RESULTS_CTA,
  );
  const showSUDPrimary = isSUDRecommended && showNewSUDChanges;
  const shouldUseBrecsRoute = isBrecsVariant(
    useFeatureFlag(FLAGS.BRECS_ABC_EXPERIMENT),
  );

  const values = useContext(AssessmentResultsContext);
  let riskScore = assessmentRiskScore;
  let routes;
  if (values) {
    routes = values.routes;
  }

  const showCoachingPrimary = isCoachingRecommended && showNewCoachingChanges;

  const therapyRecsEligible = !isAMinor && !isGlobal;

  useEffect(() => {
    if (isCoachingRecommended || isSUDRecommended) {
      const feature_flags = get_property("feature_flags") || [];
      if (isCoachingRecommended) {
        localStorage.setItem(
          FLAGS.LOB_COACHING_ASSESSMENT_RESULTS_CTA.trackingName,
          showNewCoachingChanges.toString(),
        );
        addCustomFlag(
          FLAGS.LOB_COACHING_ASSESSMENT_RESULTS_CTA.trackingName,
          feature_flags,
          showNewCoachingChanges,
        );
      }
      if (isSUDRecommended) {
        localStorage.setItem(
          FLAGS.LOB_SUD_ASSESSMENT_RESULTS_CTA.trackingName,
          showNewSUDChanges.toString(),
        );
        addCustomFlag(
          FLAGS.LOB_SUD_ASSESSMENT_RESULTS_CTA.trackingName,
          feature_flags,
          showNewSUDChanges,
        );
      }
      register({ feature_flags });
    }
  }, [isCoachingRecommended, isSUDRecommended]);

  // route options
  const BROWSE_THERAPIST = "TherapistsBrowse";
  const MEMBER_RESULTS_DASHBOARD = "MemberResults";
  const MEMBER_HOME = "MemberHome";
  const RECOMMENDED_THERAPISTS = "RecommendedTherapists";
  const SUBSTANCE_USE_SUPPORT = "SubstanceUseSupport";
  const BROWSE_COACHES = "CoachesBrowse";
  const carePlanRoute = shouldShowMPQuestionnaire
    ? RECOMMENDED_THERAPISTS
    : MEMBER_HOME;

  const trackBrowseTherapistButtonClick = (button_order) => {
    TRACK_EVENT.BUTTON_CLICKED(Router.router.asPath, "Browse Therapists", {
      to: routes[BROWSE_THERAPIST].as,
      order: button_order,
    });
  };

  const trackTherapistRecsButtonClick = (button_order) => {
    TRACK_EVENT.BUTTON_CLICKED(Router.router.asPath, "Recommended Therapists", {
      to: routes[RECOMMENDED_THERAPISTS].as,
      order: button_order,
    });
  };

  const trackMemberResultsLinkClick = (button_order) => {
    TRACK_EVENT.BUTTON_CLICKED(Router.router.asPath, "See My Plan", {
      to: routes[MEMBER_RESULTS_DASHBOARD].as,
      order: button_order,
    });
  };

  const trackSubstanceUsageDisorderClick = (button_order) => {
    TRACK_EVENT.BUTTON_CLICKED(
      Router.router.asPath,
      "Substance Usage Disorder",
      {
        to: routes[SUBSTANCE_USE_SUPPORT].as,
        order: button_order,
      },
    );
  };

  const trackBrowseCoachesClick = (button_order) => {
    TRACK_EVENT.BUTTON_CLICKED(Router.router.asPath, "Browse Coaches", {
      to: routes[BROWSE_COACHES].as,
      order: button_order,
    });
  };

  const onClickAction = (buttonOrder) => {
    if (therapyRecsEligible && !shouldUseBrecsRoute) {
      trackTherapistRecsButtonClick(buttonOrder);
      handleRoute(RECOMMENDED_THERAPISTS);
      return;
    }

    trackBrowseTherapistButtonClick(buttonOrder);
    handleRoute(BROWSE_THERAPIST);
  };

  function handleRoute(route) {
    const { to, as, query } = routes[route];
    if (shouldShowMPQuestionnaire && query?.intro) {
      Router.push({ pathname: to, query }, as);
    } else {
      Router.push(to, as);
    }
  }

  if (memberInfoLoading) {
    return null;
  }

  return (
    <div role="navigation" aria-label="Footer Links">
      <SHStickyFooter hasShadow>
        <HStack
          w="100%"
          h={
            !showCoachingPrimary && showSUDPrimary ? "fit-content" : [102, 120]
          }
          alignItems="center"
          justifyContent={["center", "flex-end"]}
          padding="24px 28px 30px 28px"
          gap={8}
        >
          {showCoachingPrimary && !showSUDPrimary && (
            <>
              <FooterButtons
                primaryButtonText={t("footerCTA.browseCoachesButtonText")}
                secondaryButtonText={t("footerCTA.carePlanButtonText")}
                primaryOnClick={() => {
                  const button_order = 1;
                  trackBrowseCoachesClick(button_order);
                  handleRoute(BROWSE_COACHES);
                }}
                secondaryOnClick={() => {
                  const button_order = 2;
                  trackMemberResultsLinkClick(button_order);
                  handleRoute(MEMBER_HOME);
                }}
                primaryDataCy={"browse-coaches-button"}
                secondaryDataCy={"see-my-care-plan-button"}
              />
            </>
          )}
          {!showCoachingPrimary &&
            !showSUDPrimary &&
            riskScore &&
            riskScore <= 2 && (
              <>
                <FooterButtons
                  primaryButtonText={t("footerCTA.browseTherapistButtonText")}
                  secondaryButtonText={t("footerCTA.carePlanButtonText")}
                  primaryOnClick={() => {
                    const button_order = 1;
                    onClickAction(button_order);
                  }}
                  secondaryOnClick={() => {
                    const button_order = 2;
                    trackMemberResultsLinkClick(button_order);
                    handleRoute(carePlanRoute);
                  }}
                />
              </>
            )}
          {!showCoachingPrimary &&
            !showSUDPrimary &&
            riskScore &&
            riskScore > 2 && (
              <FooterButtons
                primaryButtonText={t("footerCTA.carePlanButtonText")}
                secondaryButtonText={t("footerCTA.browseTherapistButtonText")}
                primaryOnClick={() => {
                  const button_order = 1;
                  trackMemberResultsLinkClick(button_order);
                  handleRoute(carePlanRoute);
                }}
                secondaryOnClick={() => {
                  const button_order = 2;
                  onClickAction(button_order);
                }}
                primaryDataCy={"see-my-care-plan-button"}
                secondaryDataCy={"browse-therapists-button"}
              />
            )}
          {!showCoachingPrimary && showSUDPrimary && (
            <>
              <SUDFooter
                t={t}
                trackSubstanceUsageDisorderClick={
                  trackSubstanceUsageDisorderClick
                }
                handleRoute={handleRoute}
                trackMemberResultsLinkClick={trackMemberResultsLinkClick}
                sudRoute={SUBSTANCE_USE_SUPPORT}
                memberHomeRoute={MEMBER_HOME}
              />
            </>
          )}
        </HStack>
      </SHStickyFooter>
    </div>
  );
};

FooterCTA.propTypes = {
  Router: PropTypes.object,
  styles: PropTypes.object,
  t: PropTypes.func,
};

export default FooterCTA;
