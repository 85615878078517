import {
  Checklist,
  Calendar,
  BarChart,
  MomentsSidebar,
  HeartHand,
  SettingsGear,
} from "components/templates/MemberDashboard/Icons";
import { Home } from "design-system/assets";
import { VWorkplaceIcon } from "@springcare/sh-component-library";

export const WEB_LINKS = {
  memberHome: {
    alias: "MemberHome",
    path: "/members/home",
    navText: "Home",
    linkName: "Home",
    trackingText: "Home",
    translatedText: "navigation.links.home",
  },
  teenHome: {
    alias: "TeenHome",
    path: "/members/teen/home",
    navText: "Home",
    linkName: "Home",
    trackingText: "Teen Home",
    translatedText: "navigation.links.home",
  },
  minorHome: {
    alias: "MemberCareVisits",
    path: "/members/care_visits",
    navText: "Schedule",
    linkName: "Schedule",
    trackingText: "Schedule",
    translatedText: "navigation.links.myCare",
  },
  adultCareVisits: {
    alias: "MemberCareVisits",
    path: "/members/care_visits",
    navText: "Schedule",
    linkName: "Care Visits",
    trackingText: "Schedule",
    translatedText: "navigation.links.myCare",
  },
  careVisits: {
    alias: "MemberCareVisits",
    path: "/members/care_visits",
    navText: "Schedule",
    linkName: "Schedule",
    trackingText: "Schedule",
    translatedText: "navigation.links.myCare",
  },
  memberProgressDashboard: {
    alias: "MemberProgressDashboard",
    path: "/members/journey",
    navText: "Journey",
    linkName: "Journey",
    trackingText: "Journey",
    translatedText: "navigation.links.journey",
  },
  memberMoments: {
    alias: "MemberMoments",
    path: "/members/moments",
    navText: "Moments",
    linkName: "Moments",
    trackingText: "Moments",
    translatedText: "navigation.links.moments",
  },
  workplaceManager: {
    alias: "WorkplaceManager",
    path: "/members/workplace",
    navText: "Workplace",
    linkName: "Workplace",
    trackingText: "Workplace",
    translatedText: "navigation.links.workplace",
  },
} as const;

export const adultWebLeftLinks = [
  WEB_LINKS.memberHome,
  WEB_LINKS.memberProgressDashboard,
  WEB_LINKS.adultCareVisits,
  WEB_LINKS.memberMoments,
];
export const adultWebWorkplaceManagerLeftLinks = [
  WEB_LINKS.memberHome,
  WEB_LINKS.memberProgressDashboard,
  WEB_LINKS.adultCareVisits,
  WEB_LINKS.memberMoments,
  WEB_LINKS.workplaceManager,
];
export const teenWebLeftLinks = [
  WEB_LINKS.teenHome,
  WEB_LINKS.careVisits,
  WEB_LINKS.memberMoments,
];
export const minorWebLeftLinks = [WEB_LINKS.minorHome, WEB_LINKS.memberMoments];

export const t2MemberWebLeftLinks = [
  WEB_LINKS.memberHome,
  WEB_LINKS.memberMoments,
];

export const MOBILE_LINKS = {
  memberHome: {
    icon: <Home w={27} h={27} />,
    text: "navigation.links.home",
    trackingText: "Home",
    linkname: "Home",
    alias: "MemberHome",
  },
  teenHome: {
    icon: <Home w={27} h={27} />,
    alias: "TeenHome",
    linkname: "Home",
    trackingText: " Teen Home",
    text: "navigation.links.home",
  },
  minorHome: {
    icon: <Calendar />,
    alias: "MemberCareVisits",
    text: "navigation.links.myCare",
    trackingText: "Schedule",
    linkname: "Schedule",
  },
  memberCareVisits: {
    icon: <Calendar />,
    text: "navigation.links.myCare",
    trackingText: "Schedule",
    linkname: "Schedule",
    alias: "MemberCareVisits",
  },
  yourCareVisits: {
    icon: <Calendar />,
    alias: "MemberCareVisits",
    text: "navigation.links.yourCareVisits",
    trackingText: "Care Visits",
    linkname: "Your Care Visits",
  },
  memberBenefits: {
    icon: <Checklist />,
    text: "navigation.links.yourBenefits",
    trackingText: "Benefits",
    linkname: "Your Benefits",
    alias: "MemberBenefits",
  },
  memberProgressDashboard: {
    icon: <BarChart />,
    text: "navigation.links.yourJourney",
    trackingText: "Journey",
    linkname: "Your Journey",
    alias: "MemberProgressDashboard",
  },
  memberMoments: {
    icon: <MomentsSidebar />,
    text: "navigation.links.moments",
    trackingText: "Moments",
    linkname: "Moments",
    alias: "MemberMoments",
  },
  learningCenter: {
    icon: <HeartHand />,
    text: "navigation.links.learningCenter",
    trackingText: "Learning Center",
    linkname: "Learning Center",
    alias: "MemberLearningCenter",
  },
  memberSettings: {
    icon: <SettingsGear />,
    text: "navigation.links.settings",
    trackingText: "Settings",
    linkname: "Settings",
    alias: "MemberSettings",
  },
  workplaceManager: {
    icon: <VWorkplaceIcon width={28} height={28} aria-hidden={true} />,
    text: "navigation.links.workplace",
    trackingText: "Workplace",
    linkName: "Workplace",
    alias: "WorkplaceManager",
  },
} as const;

export const adultMobileSidebarLinks = [
  MOBILE_LINKS.memberHome,
  MOBILE_LINKS.memberCareVisits,
  MOBILE_LINKS.memberBenefits,
  MOBILE_LINKS.memberProgressDashboard,
  MOBILE_LINKS.memberMoments,
  MOBILE_LINKS.learningCenter,
  MOBILE_LINKS.memberSettings,
];

export const globalAdultMobileSidebarLinks = [
  MOBILE_LINKS.memberHome,
  MOBILE_LINKS.memberCareVisits,
  MOBILE_LINKS.memberBenefits,
  MOBILE_LINKS.memberProgressDashboard,
  MOBILE_LINKS.memberMoments,
  MOBILE_LINKS.memberSettings,
];

export const adultMobileWorkplaceManagerSidebarLinks = [
  MOBILE_LINKS.memberHome,
  MOBILE_LINKS.memberCareVisits,
  MOBILE_LINKS.memberBenefits,
  MOBILE_LINKS.memberProgressDashboard,
  MOBILE_LINKS.memberMoments,
  MOBILE_LINKS.workplaceManager,
  MOBILE_LINKS.learningCenter,
  MOBILE_LINKS.memberSettings,
];

export const teenMobileSidebarLinks = [
  MOBILE_LINKS.teenHome,
  MOBILE_LINKS.yourCareVisits,
  MOBILE_LINKS.memberMoments,
  MOBILE_LINKS.learningCenter,
  MOBILE_LINKS.memberSettings,
];

export const t2MemberMobileSidebarLinks = [
  MOBILE_LINKS.memberHome,
  MOBILE_LINKS.memberMoments,
  MOBILE_LINKS.memberSettings,
];

export const minorMobileSidebarLinks = [
  MOBILE_LINKS.minorHome,
  MOBILE_LINKS.memberMoments,
  MOBILE_LINKS.learningCenter,
  MOBILE_LINKS.memberSettings,
];
