import { useState, useEffect } from "react";
import { getWorkplaceBannerPosition } from "components/templates/HomePage/utils/helpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useRouter } from "next/router";
import { updateMemberExperienceState } from "operations/mutations/member";
import { useMutation } from "@apollo/client";
import { isGlobalUser } from "utils/global";

export const useWorkplaceManagerBanner = (member) => {
  const router = useRouter();
  const isAGlobalMember = isGlobalUser(member?.postal_address?.country);
  const isADependent = member?.covered_life?.dependent;
  const memberShouldSeeManagerExperienceBanners =
    !isAGlobalMember && !isADependent;
  const isAWorkplaceManager = member?.workplace_manager;
  const dismissedWorkplaceBanner =
    member && member?.experience_state?.workplace_banner_dismissed;
  const canViewWorkplaceBanner = useFeatureFlag(
    FLAGS.ENABLE_WORKPLACE_MANAGER_NAV_LINK,
  );
  const workplacePageViewed =
    member && member?.experience_state?.workplace_page_viewed;

  const { showBannerOnTop, showBannerOnBottom, showExploreCard } =
    getWorkplaceBannerPosition(
      isAWorkplaceManager,
      dismissedWorkplaceBanner,
      canViewWorkplaceBanner,
      workplacePageViewed,
      memberShouldSeeManagerExperienceBanners,
    );

  const [
    showWorkplaceManagerSelfIdCardOnTop,
    setShowWorkplaceManagerSelfIdCardOnTop,
  ] = useState(showBannerOnTop);

  const [
    showWorkplaceManagerSelfIdCardOnBottom,
    setShowWorkplaceManagerSelfIdCardOnBottom,
  ] = useState(showBannerOnBottom);

  useEffect(() => {
    if (
      showBannerOnTop &&
      canViewWorkplaceBanner &&
      memberShouldSeeManagerExperienceBanners
    ) {
      setShowWorkplaceManagerSelfIdCardOnTop(showBannerOnTop);
    }
    if (
      showBannerOnBottom &&
      canViewWorkplaceBanner &&
      memberShouldSeeManagerExperienceBanners
    ) {
      setShowWorkplaceManagerSelfIdCardOnBottom(showBannerOnBottom);
    }
  }, [showBannerOnTop, showBannerOnBottom]);

  const [updateWorkplaceBannerDismissed] = useMutation(
    updateMemberExperienceState,
    {
      variables: {
        member_id: member?.id,
        workplace_banner_dismissed: false,
      },
    },
  );
  /*
    This will detect route change and trigger mutation to 
    display the banner on the correct position when member returns to homepage
  */
  useEffect(() => {
    if (canViewWorkplaceBanner && memberShouldSeeManagerExperienceBanners) {
      const updateWorkplaceBannerDismissedToFalse = () => {
        updateWorkplaceBannerDismissed();
      };
      router.events.on(
        "routeChangeStart",
        updateWorkplaceBannerDismissedToFalse,
      );
      return () => {
        router.events.off(
          "routeChangeStart",
          updateWorkplaceBannerDismissedToFalse,
        );
      };
    }
  }, [canViewWorkplaceBanner]);

  const removeWorkplaceManagerBanner = () => {
    if (showWorkplaceManagerSelfIdCardOnTop) {
      setShowWorkplaceManagerSelfIdCardOnTop(false);
    }
    if (showWorkplaceManagerSelfIdCardOnBottom) {
      setShowWorkplaceManagerSelfIdCardOnBottom(false);
    }
  };

  return {
    showWorkplaceManagerSelfIdCardOnTop,
    showWorkplaceManagerSelfIdCardOnBottom,
    showExploreCard,
    removeWorkplaceManagerBanner,
  };
};
