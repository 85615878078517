import { getAppointmentKindForTracking } from "@spring/constants";
import {
  HStack,
  RadioGroup as ChakraRadioGroup,
  Text,
  VAdultCareIcon,
  VCoupleCareIcon,
} from "@springcare/sh-component-library";
import { useEffect, useRef, useState } from "react";

import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import { Heading } from "design-system/components";
import { useInViewportOnce } from "hooks";
import { useTranslation } from "hooks/react-i18next";
import { RequestableAppointmentKindEnum } from "modules/shared/graphql-codegen/graphql";
import { TRACK_EVENT } from "utils/mixpanel";

import { TherapyTypeButton } from "./TherapyTypeButton";
import {
  RadioOptionType,
  TherapyButtonValueType,
  TherapyTypeProps,
} from "./types";

export const TherapyTypeSection: React.FC<TherapyTypeProps> = ({
  careProviderTags,
  setSelectedTherapyType,
}) => {
  const { selectedFilters } = useProviderBrowseContext();
  const isCouplesFilterSelected =
    selectedFilters?.filters.specialties.includes("Couples");
  const initialButtonValue: TherapyButtonValueType = isCouplesFilterSelected
    ? RequestableAppointmentKindEnum.CouplesTherapy
    : RequestableAppointmentKindEnum.Therapy;

  const [buttonValue, setButtonValue] =
    useState<TherapyButtonValueType>(initialButtonValue);

  useEffect(() => {
    setSelectedTherapyType(initialButtonValue);
  }, [initialButtonValue]);

  const ref = useRef();
  const therapyTypeInViewport = useInViewportOnce(ref);
  const [viewedTherapyTypeOnce, setViewedTherapyTypeOnce] = useState(false);

  useEffect(() => {
    if (therapyTypeInViewport && !viewedTherapyTypeOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Therapy Type radio buttons",
        {
          location: "Care Provider Schedule Modal",
          spring_doc_id: "couples_therapy_004",
          initially_selected_therapy_type:
            getAppointmentKindForTracking(initialButtonValue),
        },
      );
      setViewedTherapyTypeOnce(true);
    }
  }, [therapyTypeInViewport, viewedTherapyTypeOnce, initialButtonValue]);

  const { t } = useTranslation("careProvider");

  const offersIndividualText = t("scheduleModal.therapyType.offersIndividual");

  const handleSelectTherapyType = (value: TherapyButtonValueType): void => {
    setButtonValue(value);
    setSelectedTherapyType(value);

    TRACK_EVENT.BUTTON_CLICKED(
      window.location.pathname,
      "Therapy Type radio buttons",
      {
        location: "Care Provider Schedule Modal",
        spring_doc_id: "couples_therapy_005",
        selected_therapy_type: getAppointmentKindForTracking(value),
      },
    );
  };

  const radioOptions: RadioOptionType[] = [
    {
      value: RequestableAppointmentKindEnum.Therapy,
      icon: VAdultCareIcon,
      displayText: t("scheduleModal.therapyType.individual"),
    },
    {
      value: RequestableAppointmentKindEnum.CouplesTherapy,
      icon: VCoupleCareIcon,
      displayText: t("scheduleModal.therapyType.couples"),
    },
  ];

  const offersCouplesTherapy = careProviderTags.some(
    (tag) => tag.kind === "SPECIALTY" && tag.name === "Couples",
  );

  return (
    <>
      <Heading variant="sm_v1" pt="v-16" pb="v-8">
        {t("scheduleModal.therapyType.heading")}
      </Heading>

      {!offersCouplesTherapy && <Text pb="v-24">{offersIndividualText}</Text>}

      {offersCouplesTherapy && (
        <ChakraRadioGroup
          ref={ref}
          onChange={handleSelectTherapyType}
          value={buttonValue}
          pb="v-40"
        >
          <HStack pt="v-8">
            {radioOptions.map((radioOption) => (
              <TherapyTypeButton key={radioOption.value} {...radioOption} />
            ))}
          </HStack>
        </ChakraRadioGroup>
      )}
    </>
  );
};
