import { useQuery } from "@apollo/client";
import { DateTime } from "luxon";

import { AppointmentMedium } from "@spring/constants";

import Meowth from "@spring/meowth";
import { getAppointmentSlots } from "operations/queries/appointment";
import { getStartSpan } from "utils/schedulingHelpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";

// NOTE: This is a clone of useAllUpcomingAppointmentSlots.js but it only returns 1 hook and a consistent data structure
export const useAllUpcomingAppointmentSlotsV2 = (
  careProvider,
  kind,
  increment = 30,
  isCareNavigator = false,
) => {
  const shouldShowLeadTime =
    useFeatureFlag(FLAGS.DYNAMIC_LEAD_TIME_RELEASE) &&
    careProvider?.in_house_availability_source_of_truth_at;

  const startSpan = getStartSpan(kind, shouldShowLeadTime);
  const {
    data: virtualAppointmentSlotsData,
    loading: virtualAppointmentSlotsLoading,
  } = useQuery(getAppointmentSlots, {
    variables: {
      user_ids: [Meowth.getUserId(), careProvider?.user_id],
      slot_increment: increment,
      kind: kind, // e.g Therapy, Coaching, etc
      medium: isCareNavigator
        ? AppointmentMedium.Phone
        : AppointmentMedium.Video, // e.g video, in-person
      start_span: startSpan,
      end_span: DateTime.fromISO(startSpan)
        .plus({ days: 29 })
        .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 })
        .toISO(),
      upcoming_slots_only: true,
      upcoming_slots_count: 1,
    },
    skip: !careProvider || !kind,
  });

  // Only for Therapy
  const {
    data: inPersonAppointmentSlotsData,
    loading: inPersonAppointmentSlotsLoading,
  } = useQuery(getAppointmentSlots, {
    variables: {
      user_ids: [Meowth.getUserId(), careProvider?.user_id],
      slot_increment: increment,
      kind: kind, // e.g Therapy, Coaching, etc
      medium: AppointmentMedium.InPerson, // e.g video, in-person
      start_span: startSpan,
      end_span: DateTime.fromISO(startSpan)
        .plus({ days: 29 })
        .set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 })
        .toISO(),
      upcoming_slots_only: true,
      upcoming_slots_count: 1,
    },
    skip: !careProvider || !kind || !careProvider?.in_person_supported,
  });

  return {
    data: {
      firstVirtualSlot: virtualAppointmentSlotsData,
      firstInPersonSlot: inPersonAppointmentSlotsData,
    },
    loading: virtualAppointmentSlotsLoading && inPersonAppointmentSlotsLoading,
  };
};
