import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { Content, LinkButton } from "@spring/smeargle";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  SHSelectInput,
  SHStickyFooter,
  SimpleGrid,
  Text,
  Spinner,
  SHInputWithLoadingState,
} from "design-system/components";
import {
  requiredValidation,
  MONTHS,
  ageValidation,
  passwordValidation,
  emailValidation,
} from "schemas/hookFormSchema";
import { links, modalIds, timeFormats } from "@spring/constants";
import { openModal } from "@spring/smeargle/actions";
import { Center } from "@springcare/sh-component-library";
import { LINK_TYPE, TRACK_EVENT } from "utils/mixpanel";

import styles from "./styles.module.scss";

import { Link, NoChildEmailModal } from "components";
import { showGlobalExperience } from "actions/global/actions";
import routes from "routes";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { CountryGlobalHint } from "./CountryGlobalHint";
import { DateTime } from "luxon";
import { getUserInfo } from "operations/queries/user";
import { useLazyQuery } from "@apollo/client";
import { useCountryStates } from "hooks";
import { formatMemberExactAge } from "utils/displayHelpers";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { getIterableCampaignInfo } from "utils/localStorage";
import { SHPasswordInput } from "design-system/components/custom/forms/inputs/SHPasswordInput";

const ChildForm = (props) => {
  const { isLoggedIn, userId } = props;
  const [doGetUserInfo, { data: userInfo }] = useLazyQuery(getUserInfo);
  const { t } = useTranslation([
    "limitedLangAuth",
    "error",
    "limitedLangValidations",
  ]);

  async function fetchUserInfo() {
    await doGetUserInfo({ variables: { id: userId } });
  }

  useEffect(() => {
    if (isLoggedIn && userId) {
      fetchUserInfo();
    }
  }, [isLoggedIn, userId]);

  function onSubmit(data) {
    let { year, month, day, ...newData } = data;
    newData.date_of_birth = {
      actualDate: DateTime.fromObject({ year, month, day }).toFormat(
        timeFormats.datePickerFormat,
      ),
    };

    const campaignObj = {
      campaign: getIterableCampaignInfo(),
    };

    Object.assign(newData, campaignObj);

    props.submit(newData);
  }

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      day: "",
      month: "",
      year: "",
      street_address_1: "",
      street_address_2: "",
      city: "",
      state: "",
      country: "",
      zip_code: "",
      password: "",
      tos_pp_hippa: false,
      informed_consent: false,
      cohort_id: "",
    },
  });

  useEffect(() => {
    setValue(
      "street_address_1",
      userInfo?.user?.member?.postal_address?.street_address_1,
    );
    setValue(
      "street_address_2",
      userInfo?.user?.member?.postal_address?.street_address_2,
    );
    setValue("city", userInfo?.user?.member?.postal_address?.city);
    setValue("state", userInfo?.user?.member?.postal_address?.state);
    setValue("country", userInfo?.user?.member?.postal_address?.country);
    setValue("zip_code", userInfo?.user?.member?.postal_address?.zip_code);
    setValue("cohort_id", userInfo?.user?.member?.cohort?.id);
  }, [userInfo]);

  const country = userInfo?.user?.member?.postal_address?.country;
  const isUS = country === "US";
  const { data: countryStates, loading: countryStatesLoading } =
    useCountryStates();
  const dateOfBirthValue = watch(["month", "day", "year"]);

  const age = formatMemberExactAge(dateOfBirthValue.join("/"));
  const showZeroToFive = useFeatureFlag(FLAGS.ZERO_TO_FIVE_ACCOUNTS);
  const minAge = showZeroToFive ? 0 : 6;
  const isAgeValid = age >= minAge && age <= 17;
  const isDateValid =
    dateOfBirthValue.filter((t) => t).length === 3 &&
    !(errors?.month || errors?.day || errors?.year);

  const validationMessage = showZeroToFive
    ? t("underEighteen", { ns: "limitedLangValidations" })
    : t("sixToSeventeen", { ns: "limitedLangValidations" });

  if (countryStatesLoading)
    return (
      <Center>
        <Spinner speed="1s" size="xl" />
      </Center>
    );

  return (
    <div>
      <Box
        m="0 auto"
        pb={["100px", "100px", "75px", "75px", "75px"]}
        w={[
          "calc(100% - 48px)",
          "calc(100% - 48px)",
          "calc(100% - 48px)",
          "80%",
        ]}
      >
        <Box>
          <Heading variant="display" mb={8} fontWeight="bold">
            {t("forms.childSignUpForm.createAccount.familySignUp")}
          </Heading>
          <Text variant="body1" color="platform.600" fontWeight="bold" mb={24}>
            {t("forms.childSignUpForm.createAccount.subText1")}{" "}
            {t("forms.childSignUpForm.createAccount.subText2")}
          </Text>
          <Box mb={16}>{!isUS && <CountryGlobalHint />}</Box>
        </Box>

        <SHInputWithLoadingState
          fcMb={16}
          name="first_name"
          validation={requiredValidation(
            t("forms.childSignUpForm.firstName.placeholder"),
          )}
          errors={errors?.first_name}
          inputType="text"
          required
          register={register}
          autoComplete="given-name"
          label={t("forms.childSignUpForm.firstName.label")}
          placeholder={t("forms.childSignUpForm.firstName.placeholder")}
          autoFocus
          dataCy="dependent-first-name-input"
          fontWeight={400}
        />

        <SHInputWithLoadingState
          fcMb={16}
          name="last_name"
          validation={requiredValidation(
            t("forms.childSignUpForm.lastName.placeholder"),
          )}
          errors={errors?.last_name}
          inputType="text"
          required
          register={register}
          label={t("forms.childSignUpForm.lastName.label")}
          autoComplete="family-name"
          placeholder={t("forms.childSignUpForm.lastName.placeholder")}
          dataCy="dependent-last-name-input"
          fontWeight={400}
        />

        <FormControl
          isRequired
          mb={16}
          isInvalid={isDateValid && !isAgeValid}
          as="fieldset"
        >
          <FormLabel
            as="legend"
            fontWeight={400}
            requiredIndicator={
              <span style={{ marginLeft: "4px" }} title="required field">
                *
              </span>
            }
          >
            {t("forms.childSignUpForm.dateOfBirth")}
          </FormLabel>
          <SimpleGrid columns={[1, 1, 1, 3, 3]} spacingX={16}>
            <SHSelectInput
              errors={errors?.month}
              name="month"
              label={t("dateLabels.monthLabel", { ns: "common" })}
              required
              register={register}
              validation={ageValidation(dateOfBirthValue, "month", false)}
              onBlur={() => trigger("month")}
              placeholder={t("datePlaceholders.monthPlaceholder", {
                ns: "common",
              })}
              options={MONTHS}
              hasOptionLabel
              autoComplete="bday-month"
              dataCy="dependent-month-select"
              /*the below attibutes are to color match the design system input components in the onboarding flow
                 - this is temporary until I am able to resolve the issues with the design system Combobox component */
              backgroundColor="background-subtle"
              height={"48px"}
              borderWidth="1px"
              borderColor="accent-subtle"
              isOnboarding
              fontWeight={400}
            />
            <SHInputWithLoadingState
              fcMb={[16, 16, 16, 0]}
              required
              errors={errors?.day}
              name="day"
              label={t("dateLabels.dayLabel", { ns: "common" })}
              isSubLabel
              placeholder={t("datePlaceholders.dayPlaceholder", {
                ns: "common",
              })}
              register={register}
              inputType="number"
              autoComplete="bday-day"
              validation={ageValidation(dateOfBirthValue, "day", false)}
              onBlur={() => trigger("day")}
              dataCy="dependent-day-input"
              fontWeight={400}
            />
            <SHInputWithLoadingState
              errors={errors?.year}
              name="year"
              label={t("dateLabels.yearLabel", { ns: "common" })}
              required
              isSubLabel
              placeholder={t("datePlaceholders.yearPlaceholder", {
                ns: "common",
              })}
              register={register}
              inputType="number"
              autoComplete="bday-year"
              validation={ageValidation(dateOfBirthValue, "minorYear", false)}
              onBlur={() => trigger("year")}
              dataCy="dependent-year-input"
              fontWeight={400}
            />
          </SimpleGrid>
          <FormErrorMessage color="error.base" fontWeight="bold">
            {validationMessage}
          </FormErrorMessage>
        </FormControl>

        <SimpleGrid columns={[1, 1, 1, 1, 2]} spacingX={16}>
          <SHInputWithLoadingState
            fcMb={16}
            name="street_address_1"
            validation={
              isUS &&
              requiredValidation(
                t("forms.childSignUpForm.streetAddress1.placeholder"),
              )
            }
            disabled={!isUS}
            errors={errors?.street_address_1}
            inputType="text"
            required
            register={register}
            label={t("forms.childSignUpForm.streetAddress1.label")}
            placeholder={t("forms.childSignUpForm.streetAddress1.placeholder")}
            fontWeight={400}
          />

          <SHInputWithLoadingState
            fcMb={16}
            name="street_address_2"
            disabled={!isUS}
            errors={errors?.street_address_2}
            inputType="text"
            register={register}
            label={t("forms.childSignUpForm.streetAddress2.label")}
            placeholder={t("forms.childSignUpForm.streetAddress2.placeholder")}
            fontWeight={400}
          />
        </SimpleGrid>

        <SHInputWithLoadingState
          fcMb={16}
          name="city"
          validation={
            isUS &&
            requiredValidation(t("forms.childSignUpForm.city.placeholder"))
          }
          disabled={!isUS}
          errors={errors?.city}
          inputType="text"
          required
          register={register}
          label={t("forms.childSignUpForm.city.label")}
          placeholder={t("forms.childSignUpForm.city.placeholder")}
          fontWeight={400}
        />

        <SimpleGrid columns={[1, 1, 1, 1, 2]} spacingX={16}>
          <SHSelectInput
            fcMb={16}
            name="state"
            required
            errors={errors?.state}
            validation={
              isUS &&
              requiredValidation(t("forms.childSignUpForm.state.placeholder"))
            }
            disabled={!isUS}
            register={register}
            label={t("forms.childSignUpForm.state.label")}
            placeholder={t("forms.childSignUpForm.state.placeholder")}
            options={countryStates?.states || []}
            hasOptionLabel
            autoComplete="state"
            dataCy="dependent-state-select"
            /*the below attibutes are to color match the design system input components in the onboarding flow
                 - this is temporary until I am able to resolve the issues with the design system Combobox component */
            backgroundColor="background-subtle"
            height={"48px"}
            borderWidth="1px"
            borderColor="accent-subtle"
            isOnboarding
            fontWeight={400}
          />

          <SHInputWithLoadingState
            fcMb={16}
            name="zip_code"
            errors={errors?.zip_code}
            validation={
              isUS &&
              requiredValidation(t("forms.childSignUpForm.zipCode.placeholder"))
            }
            disabled={!isUS}
            inputType="text"
            required
            register={register}
            label={t("forms.childSignUpForm.zipCode.label")}
            placeholder={t("forms.childSignUpForm.zipCode.placeholder")}
            fontWeight={400}
          />
        </SimpleGrid>

        <SHInputWithLoadingState
          fcMb={8}
          name="country"
          disabled
          errors={errors?.country}
          validation={requiredValidation(
            t("forms.childSignUpForm.country.placeholder"),
          )}
          label={t("forms.childSignUpForm.country.label")}
          register={register}
          placeholder={t("forms.childSignUpForm.country.placeholder")}
          dataCy="dependent-country-select"
          color="content-disabled"
          fontWeight={400}
        />

        <Box mb={16}>
          {isUS && <p>{t("forms.childSignUpForm.country.wrongCountry")}</p>}
        </Box>

        <SHInputWithLoadingState
          fcMb={8}
          name="email"
          errors={errors?.email}
          required
          register={register}
          label={t("forms.childSignUpForm.email.label")}
          dataCy="dependent-email-input"
          validation={emailValidation}
          fontWeight={400}
        />

        <div className={styles.info} style={{ marginBottom: 16 }}>
          <Content>
            {t("forms.childSignUpForm.communication.subtext1")}
            <LinkButton
              inlineTextLink
              role="Link"
              text={t("forms.childSignUpForm.communication.linktext")}
              onClick={() => {
                props.openModal(modalIds.noChildEmailModal);

                TRACK_EVENT.LINK_CLICKED(
                  routes.FamilySignup.as,
                  "No Child Email Modal",
                  "No Child Email",
                );
              }}
            />
          </Content>
        </div>

        <SHPasswordInput
          label={t("forms.childSignUpForm.password")}
          id="password"
          register={register}
          variant="outline"
          colorScheme={errors?.password ? "error" : "platform"}
          autoComplete="new-password"
          dataCy="password-input"
          fcMb={54}
          required
          name="password"
          t={t}
          validation={passwordValidation}
          errors={errors?.password}
          fontWeight={400}
        />

        <Text mb={16}>{t("forms.childSignUpForm.helpText")}</Text>

        <FormControl isRequired isInvalid={errors?.tos_pp_hippa}>
          <Box bg="tertiary.light" py={20} px={14} borderRadius={8} mb={8}>
            <HStack alignItems="start">
              <Checkbox
                name="tos_pp_hippa"
                {...register("tos_pp_hippa", {
                  ...requiredValidation("Privacy consent"),
                })}
                variant="primary"
                size="lg"
                pt={4}
                data-cy="tos-privacy-notice-checkbox"
                isRequired
              />
              <Box pr={4}>
                <FormLabel
                  fontSize={14}
                  fontWeight="bold"
                  lineHeight="24px"
                  m={0}
                  requiredIndicator={
                    <span style={{ marginLeft: "4px" }} title="required field">
                      *
                    </span>
                  }
                >
                  <Trans
                    ns={"limitedLangAuth"}
                    i18nKey={"forms.childSignUpForm.agreement.policies"}
                    components={[
                      <Link
                        key={0}
                        inlineTextLink
                        to={links.TermsOfService}
                        mpTracking={{
                          page: routes.FamilySignup.as,
                          to: links.TermsOfService,
                          type: LINK_TYPE.TOS,
                          location: "Child Sign Up",
                        }}
                      ></Link>,
                      <Link
                        key={1}
                        inlineTextLink
                        to={links.PrivacyPolicy}
                        mpTracking={{
                          page: routes.FamilySignup.as,
                          to: links.PrivacyPolicy,
                          type: LINK_TYPE.PP,
                          location: "Child Sign Up",
                        }}
                      ></Link>,
                      <Link
                        key={2}
                        inlineTextLink
                        to={links.PrivacyNotice}
                        mpTracking={{
                          page: routes.FamilySignup.as,
                          to: links.PrivacyNotice,
                          type: LINK_TYPE.HPN,
                          location: "Child Sign Up",
                        }}
                      ></Link>,
                    ]}
                  />
                </FormLabel>
              </Box>
            </HStack>
            {errors?.tos_pp_hippa && (
              <FormErrorMessage color="error.base" fontWeight="bold">
                {errors?.tos_pp_hippa.message}
              </FormErrorMessage>
            )}
          </Box>
        </FormControl>

        <FormControl mb={144} isRequired isInvalid={errors?.informed_consent}>
          <Box bg="tertiary.light" py={20} px={14} borderRadius={8} mb={8}>
            <HStack alignItems="center">
              <Checkbox
                name="informed_consent"
                {...register("informed_consent", {
                  ...requiredValidation("Informed consent"),
                })}
                variant="primary"
                size="lg"
                pt={4}
                data-cy="informed-consent-checkbox"
              />
              <Box>
                <FormLabel
                  fontSize={14}
                  fontWeight="bold"
                  lineHeight="24px"
                  m={0}
                  requiredIndicator={
                    <span style={{ marginLeft: "4px" }} title="required field">
                      *
                    </span>
                  }
                >
                  <Trans
                    ns={"limitedLangAuth"}
                    i18nKey={"forms.childSignUpForm.agreement.consent"}
                    components={[
                      <Link
                        key={0}
                        inlineTextLink
                        to={links.MinorInformedConsent}
                        mpTracking={{
                          page: routes.FamilySignup.as,
                          to: links.MinorInformedConsent,
                          type: LINK_TYPE.MIC,
                          location: "Child Sign Up",
                        }}
                      ></Link>,
                      <Link
                        key={1}
                        inlineTextLink
                        to={links.Coppa}
                        mpTracking={{
                          page: routes.FamilySignup.as,
                          to: links.MinorInformedConsent,
                          type: LINK_TYPE.COPPA,
                          location: "Child Sign Up",
                        }}
                      ></Link>,
                    ]}
                  />
                </FormLabel>
              </Box>
            </HStack>
            {errors?.informed_consent && (
              <FormErrorMessage color="error.base" fontWeight="bold">
                {errors?.informed_consent.message}
              </FormErrorMessage>
            )}
          </Box>
        </FormControl>
      </Box>

      <SHStickyFooter
        width={["100vw", "100vw", "50vw", "50vw", "50vw"]}
        hasShadow
      >
        <Flex
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
          height={100}
        >
          <Button
            type="submit"
            isLoading={props.isLoading}
            isDisabled={!isUS}
            onClick={handleSubmit(onSubmit)}
            variant="solid"
            colorScheme="primary"
            w={[
              "calc(100% - 48px)",
              "calc(100% - 48px)",
              "calc(100% - 48px)",
              "80%",
            ]}
            h={56}
            data-cy="create-account-button"
          >
            {t("forms.childSignUpForm.submitNext")}
          </Button>
        </Flex>
      </SHStickyFooter>
      <NoChildEmailModal />
    </div>
  );
};

ChildForm.propTypes = {
  enableGlobalExperience: PropTypes.shape({
    showGlobalExperience: PropTypes.bool,
  }),
  familySignupModel: PropTypes.object,
  findMyMembershipModel: PropTypes.object,
  formData: PropTypes.object,
  openModal: PropTypes.func,
  submit: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default connect(
  (state) => ({
    formData: getOr({}, "inviteEmail.data", state.form),
    findMyMembershipModel: state.signUp.findMyMembershipModel,
    familySignupModel: state.signUp.familySignupModel,
    userIdentifiers: state.auth?.userIdentifiers,
    isLoggedIn: state.auth?.isLoggedIn || false,
    userId: state.auth?.userIdentifiers?.user?.id || false,
  }),
  { showGlobalExperience, openModal },
)(ChildForm);
