//

import { useContext, useEffect, useState } from "react";
import { Link } from "components";
import { Trans, useTranslation } from "react-i18next";
import { Spinner, useMediaQuery } from "design-system/components";
import { Box, Text } from "@springcare/sh-component-library";
import AppointmentsTable from "./Appointment";
import { CareContextValues } from "pages/apps/MemberDashboard/care_visits";
import { TRACK_EVENT } from "utils/mixpanel";

const UpcomingAppointments = ({
  memberTherapyDetails,
  appointmentsData,
  appointmentsLoading,
}) => {
  const [isAboveMd] = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation(["careVisits", "memberDashboard"]);
  const { currentTab, setHasAppointment } = useContext(CareContextValues);
  const [trackedOnce, setTrackedOnce] = useState(0);

  const {
    therapyCoveredCount,
    therapyUsedCount,
    therapistEmail,
    isGlobalMemberWithNoVisitsRemaining,
  } = memberTherapyDetails;

  useEffect(() => {
    if (!appointmentsLoading) {
      setHasAppointment(appointmentsData?.length > 0);
    }
    if (!appointmentsLoading && currentTab === 0 && !trackedOnce) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        "Upcoming Appointments",
        {
          total: appointmentsData?.length || "0",
        },
      );
      setTrackedOnce(true);
    }
  }, [appointmentsLoading, currentTab]);

  if (appointmentsLoading) {
    return <Spinner speed="1s" size="xl" />;
  }

  return (
    <>
      {!appointmentsData.length && isGlobalMemberWithNoVisitsRemaining && (
        <Box py={20} backgroundColor={"#F9FBFC"}>
          <Text>
            {t("careVisits:careTeamCard.zeroVisitsLeft.sessionsUsed", {
              therapyUsedCount: therapyUsedCount,
              therapyCoveredCount: therapyCoveredCount,
            })}
          </Text>
          <Text mt={16}>
            {t("careVisits:careTeamCard.zeroVisitsLeft.continueTherapy")}
          </Text>
          <Text mt={16}>
            <Trans
              ns="careVisits"
              i18nKey="careTeamCard.zeroVisitsLeft.therapistEmail"
              components={[<Link key={"0"} to={`mailto: ${therapistEmail}`} />]}
              values={{ email: therapistEmail }}
            />
          </Text>
        </Box>
      )}
      {!appointmentsData.length && (
        <Box borderRadius={7} my={16} p={16} bg="background-subtle">
          <Text fontWeight={500} as="span" display={["block", "inline"]}>
            {t("appointmentsTable.noAppointments", {
              past_or_upcoming: "upcoming appointments",
            })}
          </Text>{" "}
          <Text as="span" display={["block", "inline"]}>
            {t("appointmentsTable.noAppointmentsSubtext")}
          </Text>{" "}
          <Link
            bold
            color="primary.base"
            alias="MemberLearningCenterArticle"
            ariaLabel={t("a11y:learnMoreAppointment")}
            display={["block", "inline"]}
            queries={{
              category_slug: "understanding-your-care",
              article_slug: "staying-engaged-in-your-care",
            }}
            mpTracking={{
              page: window.location.pathname,
              type: "Learn More",
              location: "Upcoming Appointments",
            }}
          >
            {t("appointmentsTable.noAppointmentsCTA")}
          </Link>
        </Box>
      )}
      {appointmentsData.length > 0 && (
        <AppointmentsTable
          isAboveMd={isAboveMd}
          appointmentsData={appointmentsData}
          actionType={"link"}
          isGlobalMemberWithNoVisitsRemaining={
            isGlobalMemberWithNoVisitsRemaining
          }
        />
      )}
    </>
  );
};

export default UpcomingAppointments;
