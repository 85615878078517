//
import { assessments as assessmentConstants } from "@spring/constants";

import DoublyLinkedList from "utils/dataStructures/DoublyLinkedList";

import { formatPrompts } from "./assessmentUtils";

import builtInPromptData from "utils/assessments/questionnaires";

const { assessmentStatuses, assessmentKinds } = assessmentConstants;
const { goals, complete, sprassComplete, confidence } = builtInPromptData;

// @TODO better type

export default class AssessmentController {
  list;
  currentNode;
  currentIndex;
  isStatic;
  meta;

  constructor(
    questionnairesLength,
    questionnaireData,
    id,
    isStatic,
    updateAssessment,
    kind,
    initialStatus,
  ) {
    this.currentIndex = 0;
    this.isStatic = isStatic;
    this.meta = {
      id,
      questionnaireData,
      updateAssessment,
      kind,
      severity: 0,
      initialStatus,
    };

    if (questionnairesLength) {
      this.list = AssessmentController.fromCurrentPrompts(
        kind,
        questionnaireData,
        initialStatus,
      );
    } else {
      this.list = new DoublyLinkedList();
    }

    this.currentNode = this.list ? this.list.head : null;
  }

  // Start list with initial prompts
  static fromCurrentPrompts(kind, questionnaireData, initialStatus) {
    // If no questions left but assessment is not 'finished', show 'complete' prompt
    if (
      kind &&
      !questionnaireData &&
      initialStatus === assessmentStatuses.STARTED
    ) {
      if (kind === assessmentKinds.SPRASS) {
        return DoublyLinkedList.fromArray(sprassComplete);
      } else if (kind === assessmentKinds.INITIAL_ASSESSMENT) {
        // Initial assessment also needs the confidence prompt displayed in order to create a care plan
        return DoublyLinkedList.fromArray([
          ...goals,
          ...confidence,
          ...complete,
        ]);
      }
      return DoublyLinkedList.fromArray(complete);
    }
    const { current_prompts, id, questionnaire_kind } = questionnaireData;
    const prompts = formatPrompts(current_prompts, questionnaire_kind, id);

    return DoublyLinkedList.fromArray(prompts);
  }

  get length() {
    return this.list.length;
  }

  toJSON = () => {
    let currentNode = this.list.head;
    const payload = {};

    while (currentNode) {
      const prompt = currentNode.value.id;
      const title = currentNode.value.title;
      let questions = [];

      if (currentNode.value.questions) {
        questions = currentNode.value.questions.reduce((acc, val) => {
          acc[val.props.fieldKey.split(".")[1]] = {
            label: val.props.label,
            questions: val.props.options,
          };

          return acc;
        }, {});
      }
      payload[prompt] = { title, questions };

      currentNode = currentNode.next;
    }

    return payload;
  };

  prev = () => {
    this.currentIndex -= 1;
    this.currentNode = this.currentNode.prev;
  };

  next = () => {
    this.currentIndex += 1;
    this.currentNode = this.currentNode.next;
  };

  addPrompts = (prompts, questionnaire_id, questionnaire_kind) => {
    this.list.insertArrayAsTail(
      formatPrompts(prompts, questionnaire_kind, questionnaire_id),
      this.currentIndex,
    );
  };

  appendQuestionnaire = () => {};
}
