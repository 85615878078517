import PropTypes from "prop-types";
import React from "react";
import { Tag, LocationPin, ZoomIcon } from "@spring/smeargle";
import { AppointmentMedium } from "@spring/constants";

const ProviderMediumTags = (props) => {
  const { supported_appointment_mediums } = props.provider;

  if (supported_appointment_mediums.length === 0) return null;

  return supported_appointment_mediums.map((medium) => {
    if (medium === AppointmentMedium.InPerson && props.supportsInPerson) {
      return (
        <Tag
          text="In-person visits"
          color="informational"
          icon={<LocationPin />}
          large
          rounded
          normalWeight
          key={medium}
        />
      );
    } else if (medium === AppointmentMedium.Video) {
      return (
        <Tag
          text="Virtual visits"
          color="informational"
          icon={<ZoomIcon />}
          large
          rounded
          normalWeight
          key={medium}
        />
      );
    }
  });
};

ProviderMediumTags.propTypes = {
  provider: PropTypes.shape({
    supported_appointment_mediums: PropTypes.shape({
      length: PropTypes.number,
      map: PropTypes.func,
    }),
  }),
  supportsInPerson: PropTypes.any,
};

export default ProviderMediumTags;
