import React from "react";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import {
  ActionChip,
  VMessageIcon,
  Text,
  IconButton,
  Box,
  useBreakpointValue,
  Flex,
} from "@springcare/sh-component-library";
import { useHasUnreadMessages } from "@springcare/sh-messaging-library";

import routes from "routes";
import { TRACK_EVENT } from "utils/mixpanel";

export const MessagesButton = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const hasUnread = useHasUnreadMessages();

  const handleMessagesClick = () => {
    router.push(
      {
        pathname: routes.Messages.as,
        query: { from: router.pathname },
      },
      routes.Messages.as,
    );
    TRACK_EVENT.BUTTON_CLICKED(router.asPath, "Messages");
  };

  const CircularNoTextButton = ({ handleMessagesClick }) => {
    return (
      <Box position="relative" marginEnd={["6px", "16px"]} p="1px">
        <IconButton
          variant="low-emphasis"
          size="md"
          colorScheme="neutral"
          borderRadius="30px"
          borderWidth="1px"
          h={["40px", "40px", "48px"]}
          borderStyle="solid"
          aria-label={t("common:navigation.links.messages")}
          onClick={handleMessagesClick}
          fontSize="lg"
          _focusVisible={{
            outline: "2px solid",
            outlineColor: "black",
            outlineOffset: "4px",
          }}
          icon={VMessageIcon}
          px="26px"
          w="50px"
        />
      </Box>
    );
  };

  const FullTextbutton = ({
    handleMessagesClick,
    hasUnread,
    shouldHideText,
  }) => {
    return (
      <Box position="relative" marginInlineEnd={["8px", "16px"]}>
        <ActionChip
          variant="low-emphasis"
          colorScheme="base"
          icon={VMessageIcon}
          onClick={handleMessagesClick}
          h="full"
        >
          <Flex alignItems="center">
            {!shouldHideText && (
              <Text size="body-medium-strong">
                {t("common:navigation.links.messages")}
              </Text>
            )}
            {hasUnread && (
              <Box
                ml={shouldHideText ? "0px" : "8px"}
                bg="red.500"
                borderRadius="full"
                width="8px"
                height="8px"
              />
            )}
          </Flex>
        </ActionChip>
      </Box>
    );
  };

  const shouldHideText = useBreakpointValue({ base: true, xl: false });

  if (!router) {
    return null;
  }

  return shouldHideText && !hasUnread ? (
    <CircularNoTextButton handleMessagesClick={handleMessagesClick} />
  ) : (
    <FullTextbutton
      handleMessagesClick={handleMessagesClick}
      shouldHideText={shouldHideText}
      hasUnread={hasUnread}
    />
  );
};
