import { Button, Flex, useMediaQuery } from "@springcare/sh-component-library";

type ActionType = {
  label: string;
  onClick: () => void;
  isDisabled: boolean;
  dataCy: string;
  variant: string;
  isPCL5Header?: boolean;
};

type ActionTypes = {
  primary: ActionType;
  secondary: ActionType;
};

export const VTempFooter = ({
  actions,
  headerState = "scrolled",
}: {
  actions: ActionTypes;
  headerState?: string;
}) => {
  const [isMobile] = useMediaQuery("(max-width: 450px)");
  return (
    <>
      <Flex
        p={isMobile ? "16px 24px 36px" : "8px 24px"}
        width="100%"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        alignSelf="stretch"
        position={headerState === "scrolled" ? "fixed" : "absolute"}
        bottom="0px"
        left="0px"
        zIndex={10}
        backgroundColor="#FFFFFFCC"
        backdropFilter="blur(24px)"
      >
        <Flex
          height="48px"
          width="100%"
          flexDir="row-reverse"
          justifyContent="flex-start"
          alignItems="center"
          gap="8px"
          flex="1 0 0"
        >
          {Object.values(actions).map((action, index) => {
            return <FooterAction key={`footer-action-${index}`} {...action} />;
          })}
        </Flex>
      </Flex>
    </>
  );
};

const FooterAction = ({
  label,
  dataCy,
  isDisabled = false,
  isPCL5Header = false,
  ...other
}: ActionType) => (
  <Button
    {...other}
    data-cy={dataCy}
    isDisabled={isPCL5Header ? false : isDisabled}
    _focusVisible={{
      boxShadow: "0 0 0 3px black",
    }}
  >
    {label}
  </Button>
);
