import React from "react";
import { Text, Spinner, Flex } from "design-system/components";

export const SessionCountText = ({ sessionSnapshot }) => {
  // NOTE: probably should have a loader here
  if (!sessionSnapshot) {
    return (
      <Flex h={48} mb={40} justifyContent="center" alignItems="center">
        <Spinner label="Loading your therapy/ medication management session counts." />
      </Flex>
    );
  }

  return (
    <Text mb={40} data-testid="session-count">
      {`You have ${sessionSnapshot.remainingCovered} out of ${sessionSnapshot.totalVisitsCovered} remaining sessions until ${sessionSnapshot.expirationDate} at no cost to you! All may be used for therapy. ${sessionSnapshot.specialtyVistsRemaining} may be used for medication management.`}
    </Text>
  );
};
