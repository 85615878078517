/* eslint-disable react/no-children-prop */
/**
 * NOTE: @param are props
 * NOTE: RHF refers to the react-hook-form library
 * @param {number}   [fcMb]         margin-bottom for <FormControl>
 * @param {required} [required]     Used w/ 'errors' to display <FormErrorMessage> if true, and 'errors' true
 * @param {object}   errors         Passed from RHF - will be passed if input has error
 * @param {string}   name           Important - this the key for the input value that gets Submitted
 * @param {string}   label          Text value for <FormLabel>
 * @param {function} register       Important - RHF function that registers the key (i.e 'name' prop)
 * @param {object}   validation     Important - this object sets validation used by RHF, pass false if not needed
 * @param {string}   inputType      String to set HTML input type, use standard HTML input values (i.e text, number etc)
 * @param {string}   autoComplete   String to set HTML autocomplete tag, use standard HTML autocomplete values
 * @param {boolean}  [disabled]     Set input to disabled
 * @param {string}  [placeholder]   Set placeholder to the input
 * @param {boolean}  fontWeight     Sets fontWeight of label
 * @param {boolean}  fontSize       Sets fontSize of label
 * @param {string}   helpText       String for help text displayed
 * @param {string}   helpTextColor  Sets color for helpText
 * @param {string}   ariaLabel      Sets aria-label
 * @param {string}   [dataCy]       Sets data-cy for cypress tests
 * @param {bool}     [autoFocus]    Sets autoFocus for input (default false)
 */

import PropTypes from "prop-types";
import { useState } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  IconButton,
  Text,
} from "../../../index";
import { ClosedEye, Eye } from "design-system/assets";
import { Input } from "@springcare/sh-component-library";

export const SHPasswordInput = ({
  fcMb,
  errors,
  name,
  label,
  register,
  required,
  validation,
  autoComplete = null,
  disabled,
  placeholder,
  dataCy = "password-input",
  fontWeight,
  fontSize,
  helpText,
  ariaLabel,
  helpTextColor,
  autoFocus,
  testId,
  t,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <FormControl mb={fcMb} isRequired isInvalid={errors}>
      {label && (
        <FormLabel
          fontSize={fontSize}
          fontWeight={fontWeight}
          htmlFor={name}
          requiredIndicator
        >
          {label} *
        </FormLabel>
      )}

      <Input
        {...register(name, validation)}
        required={required}
        pl={14}
        id={name}
        type={passwordShown ? "text" : "password"}
        placeholder={placeholder}
        autoComplete={autoComplete}
        disabled={disabled}
        data-cy={dataCy}
        data-testid={testId}
        aria-label={ariaLabel}
        autoFocus={autoFocus}
        fontWeight={fontWeight}
        isInvalid={errors}
      />
      <InputRightElement
        children={
          <IconButton
            mr={errors ? 48 : 12}
            position="relative"
            top="1.8rem"
            aria-label={
              !passwordShown
                ? t("createAccount.form.password.ariaShown")
                : t("createAccount.form.password.ariaHidden")
            }
            icon={
              passwordShown ? (
                <Eye color="primary.500" boxSize={24} />
              ) : (
                <ClosedEye color="primary.500" boxSize={24} />
              )
            }
            onClick={() => setPasswordShown(!passwordShown)}
            sx={{
              _hover: {
                bg: "transparent",
              },
              _active: {
                bg: "transparent",
              },
              _focusVisible: {
                height: 32,
                border: "3px solid #068464",
                width: 32,
              },
            }}
          />
        }
      />

      {errors && (
        <FormErrorMessage color="error.base" fontWeight="bold">
          {errors?.message}
        </FormErrorMessage>
      )}
      {helpText && (
        <Text color={helpTextColor} mt={8} fontSize={16} fontWeight={900}>
          {helpText}
        </Text>
      )}
    </FormControl>
  );
};

SHPasswordInput.propTypes = {
  fcMb: PropTypes.number,
  required: PropTypes.bool,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  validation: PropTypes.object,
  inputType: PropTypes.string.isRequired,
  autoComplete: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  helpTextColor: PropTypes.string,
  ariaLabel: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autoFocus: PropTypes.bool,
  testId: PropTypes.string,
  pl: PropTypes.number,
  type: PropTypes.string,
};
