import { gql } from "@apollo/client";

import { assessmentToTake } from "operations/fragments/assessment";

const submitAssessmentResponses = gql`
  mutation SubmitAssessmentResponses(
    $questionnaire_id: ID!
    $prompt_responses: [PromptResponseInputType!]!
    $campaign: CampaignInput
  ) {
    submitAssessmentResponses(
      questionnaire_id: $questionnaire_id
      prompt_responses: $prompt_responses
      campaign: $campaign
    ) {
      success
      assessment {
        ...assessmentToTake
      }
    }
  }
  ${assessmentToTake}
`;

export default submitAssessmentResponses;
