// 

import React from 'react';

const SpringGroup = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 127 97">
		<g fill="none" fillRule="evenodd">
			<path fill="#010202" d="M3.14134276 77.8310568H123.858657V3.16894324H3.14134276V77.8310568zM124.578922 81H2.42107774C1.08600707 81 0 79.9044511 0 78.5581029V2.44189712C0 1.09554895 1.08600707 0 2.42107774 0H124.578922C125.913993 0 127 1.09554895 127 2.44189712V78.5581029C127 79.9044511 125.913993 81 124.578922 81z"/>
			<path fill="#010202" d="M62 95h3V79h-3z"/>
			<path fill="#010202" d="M76.4397817 97H50.5602183C49.6989778 97 49 96.3284286 49 95.5s.6989778-1.5 1.5602183-1.5h25.8795634C77.301468 94 78 94.6715714 78 95.5s-.698532 1.5-1.5602183 1.5M104.093039 19H39.9069609C39.405865 19 39 18.552 39 18s.405865-1 .9069609-1h64.1860781c.500643 0 .906961.448.906961 1s-.406318 1-.906961 1M92.1017939 24H40.8982061C40.4023963 24 40 23.552 40 23s.4023963-1 .8982061-1h51.2035878C92.5976037 22 93 22.448 93 23s-.4023963 1-.8982061 1M91.098529 40H40.901471C40.4034083 40 40 39.552 40 39s.4034083-1 .901471-1h50.197058C91.596141 38 92 38.448 92 39s-.403859 1-.901471 1M96.0961649 45H39.9038351C39.4049181 45 39 44.552 39 44s.4049181-1 .9038351-1h56.1923298C96.5950819 43 97 43.448 97 44s-.4049181 1-.9038351 1M92.1017939 61H40.8982061C40.4023963 61 40 60.552 40 60s.4023963-1 .8982061-1h51.2035878C92.5976037 59 93 59.448 93 60s-.4023963 1-.8982061 1M102.106522 67H40.8934776C40.3998312 67 40 66.552 40 66s.3998312-1 .8934776-1h61.2130444c.4932 0 .893478.448.893478 1s-.400278 1-.893478 1"/>
			<path fill="#2D9F97" d="M33 61.9997839C33 64.7618327 30.7611618 67 28.0002161 67 25.2388382 67 23 64.7618327 23 61.9997839 23 59.2381673 25.2388382 57 28.0002161 57 30.7611618 57 33 59.2381673 33 61.9997839"/>
			<path fill="#010202" d="M28 57.7688679c-2.3331368 0-4.2311321 1.8979953-4.2311321 4.2306899 0 2.333579 1.8979953 4.2315743 4.2311321 4.2315743s4.2311321-1.8979953 4.2311321-4.2315743c0-2.3326946-1.8979953-4.2306899-4.2311321-4.2306899M28 68c-3.3082252 0-6-2.6917748-6-6.0004422C22 58.6913325 24.6917748 56 28 56s6 2.6913325 6 5.9995578C34 65.3082252 31.3082252 68 28 68"/>
			<path fill="#2D9F97" d="M33 41.9997839C33 44.7614005 30.7611618 47 28.0002161 47 25.2388382 47 23 44.7614005 23 41.9997839 23 39.2381673 25.2388382 37 28.0002161 37 30.7611618 37 33 39.2381673 33 41.9997839"/>
			<path fill="#010202" d="M28 37.7688679c-2.3331368 0-4.2311321 1.8979953-4.2311321 4.2306899 0 2.333579 1.8979953 4.2315743 4.2311321 4.2315743s4.2311321-1.8979953 4.2311321-4.2315743c0-2.3326946-1.8979953-4.2306899-4.2311321-4.2306899M28 48c-3.3082252 0-6-2.6917748-6-6.0004422C22 38.6913325 24.6917748 36 28 36s6 2.6913325 6 5.9995578C34 45.3082252 31.3082252 48 28 48"/>
			<path fill="#2D9F97" d="M33 21.0002161C33 23.7618327 30.7611618 26 28.0002161 26 25.2388382 26 23 23.7618327 23 21.0002161 23 18.2385995 25.2388382 16 28.0002161 16 30.7611618 16 33 18.2385995 33 21.0002161"/>
			<path fill="#010202" d="M28 16.7688679c-2.3331368 0-4.2311321 1.8979953-4.2311321 4.2311321S25.6668632 25.2311321 28 25.2311321 32.2311321 23.3331368 32.2311321 21 30.3331368 16.7688679 28 16.7688679M28 27c-3.3082252 0-6-2.6913325-6-6 0-3.3082252 2.6917748-6 6-6s6 2.6917748 6 6c0 3.3086675-2.6917748 6-6 6"/>
			<path fill="#010202" d="M27.5636109 24l-3.2807638-3.1223634c-.3574201-.340275-.3790418-.9142055-.0480973-1.2821562.3313859-.3688581.889579-.390182 1.2469992-.0494533l2.0284695 1.9304935 5.9803889-6.2070697c.3424174-.3570619.9019343-.3593304 1.2478817-.0063518.3463886.3529786.3490362.9273628.0057364 1.2830636L27.5636109 24zM27.5636109 44l-3.2807638-3.1225208c-.3574201-.3402922-.3790418-.9142516-.0480973-1.2822208.3313859-.3688767.889579-.3902017 1.2469992-.0494558l2.0284695 1.9305908 5.9803889-6.2069289c.3432999-.3570799.9019343-.3593485 1.2478817-.0063521.3463886.3525426.3490362.9274095.0057364 1.2831282L27.5636109 44zM27.5636109 65l-3.2807638-3.1220077c-.3574201-.3402857-.3790418-.9142342-.0480973-1.2821964.3313859-.368416.889579-.3901943 1.2469992-.0494549l2.0284695 1.9301004 5.9803889-6.2068108c.3432999-.3570732.9019343-.3597954 1.2478817-.006352.3463886.3525359.3490362.9273919.0057364 1.2831038L27.5636109 65z"/>
		</g>
	</svg>
);

export default SpringGroup;
