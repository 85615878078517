//

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  LoadingCircle,
  Headline,
  DangerousHtml,
  Stout,
  Col,
  Grid,
  Bolded,
  Underlined,
} from "@spring/smeargle";
import { isLoading, isLoaded, hasException } from "@spring/immutability";
import { useWordPressContent } from "@spring/hooks";

import { Link } from "components/atoms";
import { BackButton, ArticleError } from "components/molecules";

import { TRACK_EVENT } from "utils/mixpanel";
import styles from "./styles.module.scss";

/**
 * Because we're returning all the articles per category, it would be redundant to
 * show the current article on the "Read More" list. This serves to remove that
 * current article.
 */
const filteredArticles = (articles, articleSlug) =>
  articles.filter((article) => article.slug !== articleSlug);

const ReadMore = ({ articleSlug, categoryId, categoryName, categorySlug }) => {
  const { data, status } = useWordPressContent(
    `/posts?categories=${categoryId}`,
  );

  return (
    <>
      {hasException(status) && null}
      {isLoading(status) && <LoadingCircle />}
      {isLoaded(status) && (
        <>
          <Stout>
            <div className={styles.title}>Read more about {categoryName}</div>
          </Stout>
          {filteredArticles(data, articleSlug).map((article) => (
            <Link
              aria-label={`Read More - ${article.title.rendered.replace("&#038;", "&")}`}
              alias="MemberLearningCenterArticle"
              key={article.id}
              queries={{
                category_slug: categorySlug,
                article_slug: article.slug,
              }}
            >
              <div className={styles.readMoreContainer}>
                <div className={styles.readMoreImage}>
                  <ArticleImage
                    alt={`Article Image: ${article.title.rendered.replace("&#038;", "&")}`}
                    imageId={article.featured_media}
                    asIcon
                  />
                </div>
                <div className={styles.readMoreContent}>
                  <Bolded>
                    <Underlined>
                      <DangerousHtml>{article.title.rendered}</DangerousHtml>
                    </Underlined>
                  </Bolded>
                </div>
              </div>
            </Link>
          ))}
        </>
      )}
    </>
  );
};

ReadMore.propTypes = {
  articleSlug: PropTypes.any,
  categoryId: PropTypes.any,
  categoryName: PropTypes.any,
  categorySlug: PropTypes.any,
};

/**
 *
 * @param {string} articleTitle
 * 1. Removes special characters
 * 2. Replaces whitespace with hyphens
 * 3. Make everything lowercase
 */
const getIdLink = (articleTitle) =>
  articleTitle
    .replace(/[^\w\s]/gi, "")
    .replace(/\s+/g, "-")
    .toLowerCase();

/**
 * @param {HTMLCollection} elements
 * 1. Since HTMLCollections are not an array, this modifies the collection
 *    by transforming it into an array and trim the strings of whitespace first.
 * 2. Once an array, it will remove the node's numbers from innerText
 *    (eg: '1. What is mental health?' => 'What is mental health?')
 * 3. Lastly, it will be rendered as JSX.
 * Input: [h2#what-are-the-types-and-levels-of-care, h2#what-are-the-common-types-of-therapy]: HTMLCollection
 * Output:
 * [
 *  <li><a href="#what-are-the-types-and-levels-of-care">What are the types and levels of care?</a></li>,
 *  <li><a href="#what-are-the-common-types-of-therapy">What are the common types of therapy?</a></li>
 * ]
 */
const modifyHTMLCollection = (elements) =>
  Array.from(elements)
    .map((el) => el.innerText.replace(/\d. |\d{2}./g, "").trim())
    .map((el) => (
      <li key={el} className={styles.linkContainer}>
        <a href={`#${getIdLink(el)}`} className={styles.link}>
          <Bolded>
            <Underlined>{el}</Underlined>
          </Bolded>
        </a>
      </li>
    ));

const ArticleImage = ({ alt, imageId, asIcon }) => {
  const { data, status } = useWordPressContent(`/media/${imageId}`);
  const imageStyle = asIcon ? styles.readMoreImage : styles.featuredImage;

  return (
    <>
      {isLoading(status) && <LoadingCircle />}
      {isLoaded(status) && (
        <img className={imageStyle} src={data.source_url} alt={alt} />
      )}
    </>
  );
};

ArticleImage.propTypes = {
  asIcon: PropTypes.any,
  imageId: PropTypes.any,
};

const TableOfContents = ({ collection }) => (
  <ul className={styles.tableOfContents}>{collection.map((el) => el)}</ul>
);

TableOfContents.propTypes = {
  collection: PropTypes.shape({
    map: PropTypes.func,
  }),
};

const ArticleContent = ({
  articleSlug,
  categoryId,
  categoryName,
  categorySlug,
}) => {
  const router = useRouter();
  const el = document.getElementById(router.query.scrollTo) || null;
  const { data: articleData, status: articleStatus } = useWordPressContent(
    `/posts?slug=${articleSlug}`,
  );
  const [collection, setCollection] = useState(null);

  useEffect(() => {
    const elements = document
      .getElementById("main-content")
      .getElementsByTagName("h2");
    if (elements.length) {
      setCollection(modifyHTMLCollection(elements));
    }
  }, [articleStatus]);

  useEffect(() => {
    // TODO: find a better way to do this
    const timer =
      el && setTimeout(() => el.scrollIntoView({ behavior: "smooth" }), 1000);

    return () => clearTimeout(timer);
  }, [el]);

  // Fires MP tracking event on link click via event bubbling
  // ...which is why the click handler is registered on a div
  const fireMPEventOnLinkClick = ({ target }) => {
    if (target.tagName === "A") {
      TRACK_EVENT.LINK_CLICKED(
        window.location.pathname,
        target.getAttribute("href"),
        target.textContent,
      );
    }
  };

  return (
    <>
      {isLoading(articleStatus) && <LoadingCircle />}
      {isLoaded(articleStatus) && (
        <>
          {!articleData.length && <ArticleError />}
          {!!articleData.length && (
            <>
              <Headline>
                <h1 className={styles.articleTitle}>
                  <DangerousHtml>{articleData[0].title.rendered}</DangerousHtml>
                </h1>
              </Headline>
              <ArticleImage
                alt={`Featured Image: ${articleData[0].title.rendered.replace("&#038;", "&")}`}
                imageId={articleData[0].featured_media}
              />

              {collection && <TableOfContents collection={collection} />}

              <div
                className={styles.content}
                id="article-content"
                onClick={fireMPEventOnLinkClick}
              >
                <Grid>
                  <Col sm={12} lg={8}>
                    <DangerousHtml>
                      {articleData[0].content.rendered}
                    </DangerousHtml>
                  </Col>
                  <Col sm={12} lg={4}>
                    <ReadMore
                      articleSlug={articleSlug}
                      categoryId={categoryId}
                      categorySlug={categorySlug}
                      categoryName={categoryName}
                    />
                  </Col>
                </Grid>
              </div>
            </>
          )}
        </>
      )}
      {hasException(articleStatus) && <ArticleError />}
    </>
  );
};

ArticleContent.propTypes = {
  articleSlug: PropTypes.any,
  categoryId: PropTypes.any,
  categoryName: PropTypes.any,
  categorySlug: PropTypes.any,
};

const Article = ({ categorySlug, articleSlug }) => {
  const {
    data: categoryData,
    status: categoryStatus,
    error,
  } = useWordPressContent(`/categories?slug=${categorySlug}`);

  return (
    <>
      {hasException(categoryStatus) && (
        <ArticleError>
          {error.data.status}: {error.message}
        </ArticleError>
      )}
      {isLoading(categoryStatus) && <LoadingCircle />}
      {isLoaded(categoryStatus) && (
        <>
          {!categoryData.length && <ArticleError />}
          {!!categoryData.length && (
            <div role="main">
              <BackButton
                alias="MemberLearningCenterArticles"
                queries={{ category_slug: categoryData[0].slug }}
              >
                <Stout>
                  <Underlined>Back to {categoryData[0].name}</Underlined>
                </Stout>
              </BackButton>
              <ArticleContent
                articleSlug={articleSlug}
                categoryId={categoryData[0].id}
                categorySlug={categoryData[0].slug}
                categoryName={categoryData[0].name}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

Article.propTypes = {
  articleSlug: PropTypes.any,
  categorySlug: PropTypes.any,
};

export default Article;
