import { Link, Flex, useMediaQuery } from "@springcare/sh-component-library";
import classnames from "classnames";
import NextLink from "next/link";
import { CircleCheck } from "components/atoms";
import styles from "./CostEstimateDisclaimer.module.scss";
import { useTranslation } from "hooks/react-i18next";
import routes from "routes";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useQuery } from "@apollo/client";
import { getMemberInfo, getMemberInsurance } from "operations/queries/member";
import Meowth from "@spring/meowth";
import { getAppointments } from "operations/queries/appointment";
import {
  ChannelPartnerData,
  getChannelPartner,
} from "operations/queries/customer";
import { TRACK_EVENT } from "utils/mixpanel";
import { useEffect } from "react";
import useHighmarkExperience from "hooks/useHighmarkExperience";

export const CostEstimateDisclaimerTypes = {
  FULL: "full",
  HALF: "half",
  CARD: "card",
};

interface CostEstimateDisclaimerProps {
  variant: string;
  isModal?: boolean;
}

const MIXPANEL_COMPONENT_NAME = "Payer cost estimate banner";

const CostEstimateDisclaimer = ({
  variant,
  isModal,
}: CostEstimateDisclaimerProps) => {
  const isCard = variant === CostEstimateDisclaimerTypes.CARD;
  const isHalf = variant === CostEstimateDisclaimerTypes.HALF;
  const isFull = variant === CostEstimateDisclaimerTypes.FULL;
  const { t } = useTranslation("benefits");
  const [isMobileOrTablet] = useMediaQuery("(max-width: 900px)");

  const { showHighmarkBanners, showMainHighmarkBanner } =
    useHighmarkExperience();

  const getIconStyles = () => {
    if (isCard || isMobileOrTablet || isModal) {
      return styles.cardIcon;
    }
    if (isHalf) {
      return styles.halfIcon;
    }
    return styles.bannerIcon;
  };

  const handleClick = () => {
    TRACK_EVENT.LINK_CLICKED(
      window.location.pathname,
      routes.MembersInsurance.to,
      MIXPANEL_COMPONENT_NAME,
    );
  };

  useEffect(() => {
    if (showHighmarkBanners) {
      TRACK_EVENT.COMPONENT_VIEWED(
        window.location.pathname,
        MIXPANEL_COMPONENT_NAME,
      );
    }
  }, [showHighmarkBanners]);

  if (!showHighmarkBanners || (!showMainHighmarkBanner && isFull)) {
    return null;
  }

  return (
    <Flex
      className={classnames(styles.container, {
        [styles.full]: isFull,
        [styles.half]: isHalf && !isModal,
        [styles.card]: isCard || isModal,
      })}
    >
      <div className={getIconStyles()}>
        <CircleCheck />
      </div>
      <div>{t("costEstimateDisclaimerBody")}</div>
      <NextLink
        onClick={handleClick}
        href={routes.MembersInsurance.as}
        passHref
      >
        <a
          className={classnames(styles.cta, {
            [styles.cardCta]: isCard || isMobileOrTablet || isModal,
            [styles.halfCta]: isHalf && !isModal,
          })}
        >
          {t("costEstimateDisclaimerCTA")}
        </a>
      </NextLink>
    </Flex>
  );
};

export default CostEstimateDisclaimer;
