import { Box, Grid, Heading, Text } from "@springcare/sh-component-library";

const CardWrapper = ({ children, header, subheader, styles }) => (
  <Box pb={60}>
    <Heading size="heading-small" pb={8}>
      {header}
    </Heading>
    <Text size="body-medium">{subheader}</Text>
    <Grid
      templateColumns={{
        base: "1fr",
        md: "repeat(2, 1fr)",
        lg: "repeat(3, 1fr)",
      }}
      columnGap={20}
      rowGap={{ base: 24, lg: 20 }}
      mt={16}
      className={styles.cardSectionContainer}
    >
      {children}
    </Grid>
  </Box>
);

export default CardWrapper;
