import { useRef } from "react";
import { useTranslation } from "hooks/react-i18next";
import {
  Box,
  Flex,
  Text,
  Stack,
  SHTooltip as Tooltip,
  SHModal,
  Spinner,
  useDisclosure,
  useMediaQuery,
  Button,
} from "design-system/components";
import { InfoIcon, Pencil } from "design-system/assets";
import { truncateWords } from "@spring/immutability";

import { UpdateAddressForm } from "./UpdateAddressForm";

import { useProviderBrowseContext } from "context/ProviderBrowseContext";
import { trackEditAddressClicked } from "components/templates/Browse/ProviderBrowsePage/analytics";

const ActiveAddress = () => {
  const { t } = useTranslation(["careProvider", "common"]);
  const { memberAddressCopy, isCompanyExclusive } = useProviderBrowseContext();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const closeModalFocusRef = useRef(null);

  let truncateWordCount = 8;

  if (isMobile) {
    truncateWordCount = 6;
  }

  const onClick = () => {
    if (isCompanyExclusive) return;
    trackEditAddressClicked();
    onOpen();
  };

  return (
    <Box mb={12}>
      {memberAddressCopy ? (
        <>
          <Stack direction="row" align="center" mb={4}>
            <Text
              variant="bodyBold_v1"
              color={isCompanyExclusive ? "tertiary.400" : "platform.base"}
            >
              {t("browserPageWrapper.yourLocation")}
            </Text>
            <Tooltip
              label={t("browserPageWrapper.tooltipLocation")}
              placement="top"
              hasArrow
              id="yourLocationTooltip"
              role="tooltip"
              ontouchstart={(event) => {
                event.currentTarget.setAttribute(
                  "data-active",
                  event.currentTarget.getAttribute("data-active") !== "true",
                );
              }}
            >
              <InfoIcon boxSize={20} ml={8} color="positive-base" />
            </Tooltip>
          </Stack>

          <Box
            as="button"
            onClick={() => onClick()}
            w="100%"
            pl={16}
            pr={12}
            py={18}
            border="2px solid #B4B9BB"
            borderRadius={8}
            tabIndex={0}
            cursor={isCompanyExclusive ? "not-allowed" : "default"}
          >
            <Stack direction="row" align="center" justify="space-between">
              <Text
                variant="bodyBold_v1"
                color={isCompanyExclusive ? "tertiary.400" : "platform.base"}
              >
                {truncateWords(truncateWordCount, memberAddressCopy)}
              </Text>
              {!isCompanyExclusive && <Pencil boxSize={22} />}
            </Stack>
          </Box>
        </>
      ) : (
        <Flex h="72px" align="center" justify="center">
          <Spinner speed="1s" size="lg" />
        </Flex>
      )}

      <SHModal
        isOpen={isOpen}
        onClose={onClose}
        modalTitle={t("updateAddress.title")}
        finalFocusRef={closeModalFocusRef}
      >
        <UpdateAddressForm onClose={onClose} />
      </SHModal>
    </Box>
  );
};

export default ActiveAddress;
