import yup from "schemas/yup";

export const getEmergencyContactSchema = (t) => {
  return yup.object().shape({
    name: yup.string().required(),
    phoneNumber: yup
      .object()
      .shape({
        code: yup.string(),
        country: yup.string(),
        number: yup
          .string()
          .when("code", {
            is: "us",
            then: yup
              .string()
              .min(
                10,
                t(
                  "limitedLangAuth:manualVerificationForm.phoneNumberVerify.exactly10Digits",
                ),
              )
              .max(
                10,
                t(
                  "limitedLangAuth:manualVerificationForm.phoneNumberVerify.exactly10Digits",
                ),
              )
              .matches(
                /^[0-9]+$/,
                t(
                  "limitedLangAuth:manualVerificationForm.phoneNumberVerify.numericOnly",
                ),
              ),
          })
          .matches(
            /^[0-9]+$/,
            t(
              "limitedLangAuth:manualVerificationForm.phoneNumberVerify.numericOnly",
            ),
          )
          .required(),
      })
      .required(),
  });
};
