//
import { useState, useEffect } from "react";
import { ModalSheet } from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";
import * as constants from "components/templates/CostEstimation/constants";
import { useMemberInsurance } from "hooks";
import { PaymentInfoUpdateWrapper } from "./PaymentInfoUpdateWrapper";

export type MemberInsuranceData = {
  user: User;
};
type User = {
  id: string;
  member: Member;
};
type Member = {
  id: string;
  payment_preference: string;
  visits_remaining_total: number;
  insurance_policy: InsurancePolicy;
  cohort: Cohort;
};
type InsurancePolicy = {
  id: string;
  carrier_name: string;
  insurance_group_id: string;
  insurance_member_id: string;
  plan_name: string;
  insurance_card_front_id?: string;
  insurance_card_back_id?: string;
  cost_estimate_requested_date?: string;
};
type Cohort = {
  in_network: boolean;
};

const lookupModalTitle = (t) => ({
  [constants.COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY]: t(
    "modal.initialDisplayHeading",
  ),
  [constants.COST_ESTIMATE_DISPLAY_STATES.SELF_PAY_DISPLAY]: t(
    "modal.selfPayHeading",
  ),
  [constants.COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM]: t(
    "modal.insuranceFormHeading",
  ),
});

export const PaymentInfoUpdateModal = ({ isChooseOpen, onClose }) => {
  const { t } = useTranslation("costEstimate");
  const {
    data: wrappedMemberInsuranceData,
    loading: isMemberInsuranceLoading,
  }: { data: MemberInsuranceData; loading: boolean } = useMemberInsurance();

  const [displayState, setDisplayState] =
    useState<constants.ValueOfCostEstimateDisplayStates>(
      constants.COST_ESTIMATE_DISPLAY_STATES.LOADING,
    );

  useEffect(() => {
    if (!isMemberInsuranceLoading) {
      setDisplayState(constants.COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
    }
  }, [isMemberInsuranceLoading]);

  const handleBackButtonClick = () =>
    setDisplayState(constants.COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
  const shouldShowBackButton =
    displayState === constants.COST_ESTIMATE_DISPLAY_STATES.INSURANCE_FORM ||
    displayState === constants.COST_ESTIMATE_DISPLAY_STATES.SELF_PAY_DISPLAY
      ? "on"
      : "off";

  const closeModal = () => {
    setDisplayState(constants.COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY);
    onClose();
  };

  return (
    <ModalSheet
      isOpen={isChooseOpen}
      onClose={closeModal}
      title={
        lookupModalTitle(t)[
          constants.COST_ESTIMATE_DISPLAY_STATES[displayState]
        ]
      }
      placement={
        constants.COST_ESTIMATE_DISPLAY_STATES[displayState] ===
        constants.COST_ESTIMATE_DISPLAY_STATES.INITIAL_DISPLAY
          ? "center"
          : "center-scroll"
      }
      size="lg"
      backButton={shouldShowBackButton}
      onBack={handleBackButtonClick}
    >
      <PaymentInfoUpdateWrapper
        closeModal={closeModal}
        displayState={displayState}
        setDisplayState={setDisplayState}
        wrappedMemberInsuranceData={wrappedMemberInsuranceData}
        isMemberInsuranceLoading={isMemberInsuranceLoading}
      />
    </ModalSheet>
  );
};
