import React from "react";
import FlowerPot from "design-system/assets/spot-icons/FlowerPot";
import { Flex, Box, Heading, Text, Button } from "design-system/components";
import Router from "next/router";
import routes from "routes";
import { useTranslation } from "react-i18next";

function ErrorCensusDependent({ handleAddLaterClick, isMobileTrue }) {
  const { t } = useTranslation("inviteDependents");

  function continueToDashBoard() {
    Router.push(routes.MemberExpectations.to, routes.MemberExpectations.as);
  }

  const LightBtnActiveStyles = {
    bg: "tertiary.100",
    color: "tertiary.base",
  };

  return (
    <Flex
      h="calc(100vh - 130px)"
      alignItems="center"
      direction="column"
      justifyContent={{ base: "space-between", md: "flex-start" }}
      w="100%"
      marginTop={isMobileTrue ? "8px" : "min(2%,214px)"}
    >
      <Box maxW={482} px={isMobileTrue ? "24px" : "0px"}>
        <FlowerPot width={80} height={80} />

        <Heading as="h1" variant="md_v1" pt={isMobileTrue ? "16px" : "24px"}>
          {t(`errorCensusDependent.heading`)}
        </Heading>

        <Flex pt="8px" pb={isMobileTrue ? "0px" : "24px"} alignItems={"center"}>
          <Text>{t(`errorCensusDependent.text`)}</Text>
        </Flex>
      </Box>
      <Box
        maxW={482}
        w="100%"
        pt={isMobileTrue ? "0px" : "8px"}
        px={isMobileTrue ? "16px" : "0px"}
        justifyContent="center"
      >
        <Flex direction="column">
          <Button
            variant="solid"
            colorScheme="primary"
            h={48}
            p={0}
            mb={16}
            onClick={continueToDashBoard}
          >
            {t(`errorCensusDependent.button.continue`)}
          </Button>
          <Button
            background="#F1EFEA"
            color="tertiary.base"
            _hover={LightBtnActiveStyles}
            _active={LightBtnActiveStyles}
            _focus={LightBtnActiveStyles}
            colorScheme="primary"
            h={48}
            onClick={handleAddLaterClick}
            data-cy="add-dependent-button"
          >
            {t(`errorCensusDependent.button.addAnotherDependent`)}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}

export default ErrorCensusDependent;
