import React, { useRef, useState } from "react";
import { Box, Heading, Text } from "@springcare/sh-component-library";
import { useTranslation } from "react-i18next";
import { InsuranceForm } from "./InsuranceForm";
import { TRACK_EVENT } from "utils/mixpanel";
import { useInViewportOnce } from "hooks";

export const InsuranceFormWrapper = ({
  memberInsuranceInfo,
  changeDisplay,
}) => {
  const { t } = useTranslation("costEstimate");
  const ref = useRef();
  const inViewport = useInViewportOnce(ref, "0px");
  const [trackedOnce, setTrackedOnce] = useState(false);

  if (inViewport && !trackedOnce) {
    TRACK_EVENT.PAGE_VERSION_VIEWED(
      window.location.pathname,
      "Add insurance info",
      {
        in_network: String(memberInsuranceInfo?.user?.member.cohort.in_network),
        available_sessions: String(
          memberInsuranceInfo?.user?.member.visits_remaining_total,
        ),
      },
    );
    setTrackedOnce(true);
  }

  return (
    <Box width="100%" maxWidth="728px" paddingX={[0, 8, 8]} mx="auto" ref={ref}>
      <Text>{t("modal.insuranceSubheading")}</Text>

      <Heading size="heading-small" as="h3" fontSize={16} marginTop={20}>
        {t("modal.form.heading")}
      </Heading>
      <Text marginBottom={16}>{t("modal.form.subHeading")}</Text>

      <InsuranceForm
        memberInsuranceInfo={memberInsuranceInfo}
        changeDisplay={changeDisplay}
      />
    </Box>
  );
};
