import {
  Card,
  HStack,
  Radio as ChakraRadio,
  Show,
  Text,
} from "@springcare/sh-component-library";

import { TherapyTypeButtonProps } from "./types";

// TODO: This TherapyTypeButton component is intended to be temporary and will be replaced once we have
// built a RadioItem in our component library https://springhealth.atlassian.net/browse/FAM-648

export const TherapyTypeButton: React.FC<TherapyTypeButtonProps> = ({
  value,
  icon: Icon,
  displayText,
}) => (
  <Card
    w="100%"
    variant="medium-emphasis"
    size="none"
    sx={{
      "&:has(input:focus-visible)": {
        outline: "solid 2px black",
        outlineOffset: "4px",
      },
    }}
  >
    <HStack w="100%">
      <ChakraRadio
        size="lg"
        width="100%"
        py={20}
        px={8}
        flexDir="row-reverse"
        justifyContent="space-between"
        value={value}
      >
        <HStack>
          <Show above="sm">
            <Icon height={24} width={24} />
          </Show>
          <Text>{displayText}</Text>
        </HStack>
      </ChakraRadio>
    </HStack>
  </Card>
);
