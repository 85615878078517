import { Box, Text, VExternalLinkIcon } from "@springcare/sh-component-library";
import { TRACK_EVENT } from "utils/mixpanel";

interface ResourceCardProps {
  dataCy: string;
  description: string;
  heading: string;
  icon: React.ElementType;
  isExternal?: boolean;
  onClick: () => void;
  trackingAction: string;
}

export function ResourceCard({
  dataCy,
  description,
  heading,
  icon: Icon,
  isExternal = false,
  onClick,
  trackingAction,
}: ResourceCardProps) {
  const handleClick = () => {
    TRACK_EVENT.CARD_CLICKED(window.location.pathname, trackingAction, heading);
    onClick();
  };

  return (
    <Box
      as={"button"}
      data-cy={dataCy}
      display="flex"
      flexDirection="column"
      textAlign="start"
      p={16}
      gap={8}
      w={["100%", "325px"]}
      minH="196"
      borderRadius={8}
      boxShadow="0px 2px 8px rgba(106, 84, 77, 0.16)"
      _hover={{
        transform: "scale(1.025)",
        boxShadow: "0px 4px 16px rgba(106, 84, 77, 0.2)",
      }}
      onClick={handleClick}
    >
      <Box display="flex" w="100%" justifyContent="space-between">
        <Icon width={40} height={40} aria-hidden="true" />
        {isExternal && (
          <VExternalLinkIcon width={18} height={18} aria-hidden="true" />
        )}
      </Box>
      <Text as="h3" size="body-medium-strong">
        {heading}
      </Text>
      <Text size="body-medium-regular">{description}</Text>
    </Box>
  );
}
