import React from "react";
import { modalIds } from "@spring/constants";
import { Trans } from "react-i18next";
import {
  Box,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
} from "@springcare/sh-component-library";
import { useTranslation } from "hooks/react-i18next";
import { FLAGS, useFeatureFlag } from "utils/launchdarkly";
import { useMemberInfo } from "hooks";

const CancellationDetailsModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation("careVisits");
  const shouldShowDeltaCopy = useFeatureFlag(
    FLAGS.DELTA_CANCELATION_POLICY_COPY,
  );

  const shouldShowAutoCancelCopy = useFeatureFlag(
    FLAGS.DELTA_UPCOMING_APPT_CARD_CONFIRMATION_COPY,
  );

  const { data: memberData } = useMemberInfo();
  const isPayer = memberData?.user?.member?.cohort?.customer?.is_health_plan;

  return (
    <Modal
      size="xl"
      id={modalIds.cancellationDetailsModal}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t("cancelAppointment.cancelPolicyModal.header")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={16} pb={32}>
          <Box mb={16}>
            {shouldShowDeltaCopy ? (
              <Trans
                ns="careVisits"
                i18nKey="cancelAppointment.cancelPolicyModal.customerSpecificPolicy"
                values={{
                  customerName: "Delta",
                  noShowCharge: 215,
                }}
              />
            ) : (
              <Trans
                ns="careVisits"
                i18nKey="cancelAppointment.cancelPolicyModal.policy"
                values={{
                  customerType: isPayer ? "payer" : "employer",
                }}
              />
            )}
          </Box>
          {shouldShowAutoCancelCopy && (
            <Box>
              <Trans
                ns="careVisits"
                i18nKey="cancelAppointment.cancelPolicyModal.autoCancelPolicy"
              />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CancellationDetailsModal;
